export enum TranslationKey {
	/** English translation: "Activate file viewer" */
	ActivateFileViewer = "Activate_File_Viewer",

	/** English translation: "Allow opening Office Desktop" */
	ActivateOfficeDesktop = "Activate_Office_Desktop",

	/** English translation: "Allow opening Office for the web" */
	ActivateOfficeOnline = "Activate_Office_Online",

	/** English translation: "Add widget" */
	AddWidgetTitle = "Add_Widget_Title",

	/** English translation: "Admin authentication" */
	AdminAuthentication = "Admin_Authentication",

	/** English translation: "Organizations that will be removed" */
	AdminCompanyExtractBeingExtracted = "Admin_CompanyExtract_Being_Extracted",

	/** English translation: "Folder to extract to" */
	AdminCompanyExtractDestinationPath = "Admin_CompanyExtract_DestinationPath",

	/** English translation: "C:\\path\\to\\folder" */
	AdminCompanyExtractDestinationPathPlaceholder = "Admin_CompanyExtract_DestinationPath_Placeholder",

	/** English translation: "Remove organization resources" */
	AdminCompanyExtractTitle = "Admin_CompanyExtract_Title",

	/** English translation: "The organization must have at least one admin." */
	AdminCompanyRequireOneAdmin = "Admin_Company_Require_One_Admin",

	/** English translation: "The admin is the owner of the organization-level hub." */
	AdminCompanyUserIsCompanyHubOwner = "Admin_Company_User_Is_Company_Hub_Owner",

	/** English translation: "Export of organization data has started." */
	AdminExtractionStarted = "Admin_Extraction_Started",

	/** English translation: "Get admin's email address" */
	AdminGetAdminsEmail = "Admin_Get_Admins_Email",

	/** English translation: "Domain names of internal users" */
	AdminInternalDomainName = "Admin_Internal_Domain_Name",

	/** English translation: "Manage administrators" */
	AdminManageAdministrators = "Admin_Manage_Administrators",

	/** English translation: "Manage API tokens" */
	AdminManageTokens = "Admin_Manage_Tokens",

	/** English translation: "Search for user" */
	AdminSearchUser = "Admin_Search_User",

	/** English translation: "Send system emails" */
	AdminSendSystemEmails = "Admin_Send_System_Emails",

	/** English translation: "Super administrator token" */
	AdminSuperAdminToken = "Admin_SuperAdminToken",

	/** English translation: "API Access Tokens" */
	AdminTokens = "Admin_Tokens",

	/** English translation: "Scopes" */
	AdminTokenScopes = "Admin_Token_Scopes",

	/** English translation: "Tools" */
	AdminTools = "Admin_Tools",

	/** English translation: "Add message" */
	AdvancedRightsCommentWithinEmail = "Advanced_Rights_Comment_Within_Email",

	/** English translation: "Do you want to add this share?" */
	AdvancedRightsConfirmAddShare = "Advanced_Rights_Confirm_Add_Share",

	/** English translation: "Do you want to add these shares?" */
	AdvancedRightsConfirmAddShares = "Advanced_Rights_Confirm_Add_Shares",

	/** English translation: "Do you want to reset all shares?" */
	AdvancedRightsConfirmResetAllShares = "Advanced_Rights_Confirm_Reset_All_Shares",

	/** English translation: "Custom rights" */
	AdvancedRightsCustomRights = "Advanced_Rights_Custom_Rights",

	/** English translation: "No users or groups changed." */
	AdvancedRightsErrorNoUsersGroupsModified = "Advanced_Rights_Error_No_Users_Groups_Modified",

	/** English translation: "No users or groups selected." */
	AdvancedRightsErrorNoUsersGroupsSelected = "Advanced_Rights_Error_No_Users_Groups_Selected",

	/** English translation: "All users must have at least the right to read the folders." */
	AdvancedRightsErrorUserWithoutFolderRead = "Advanced_Rights_Error_User_Without_Folder_Read",

	/** English translation: "Expired" */
	AdvancedRightsExpired = "Advanced_Rights_Expired",

	/** English translation: "Expires" */
	AdvancedRightsExpires = "Advanced_Rights_Expires",

	/** English translation: "Inherit" */
	AdvancedRightsInherit = "Advanced_Rights_Inherit",

	/** English translation: "Defines if the element has the same rights as the parent folder." */
	AdvancedRightsInheritDesc = "Advanced_Rights_Inherit_Desc",

	/** English translation: "My rights" */
	AdvancedRightsMyRights = "Advanced_Rights_My_Rights",

	/** English translation: "No rights" */
	AdvancedRightsNoRights = "Advanced_Rights_No_Rights",

	/** English translation: "Profile" */
	AdvancedRightsProfile = "Advanced_Rights_Profile",

	/** English translation: "Profile changed" */
	AdvancedRightsProfileChanged = "Advanced_Rights_Profile_Changed",

	/** English translation: "Propagates rights to content." */
	AdvancedRightsPropagatesRights = "Advanced_Rights_Propagates_Rights",

	/** English translation: "Defines if the folder elements inherit the rights defined on it." */
	AdvancedRightsPropagatesRightsDesc = "Advanced_Rights_Propagates_Rights_Desc",

	/** English translation: "Protected with password" */
	AdvancedRightsProtectedWithPassword = "Advanced_Rights_Protected_With_Password",

	/** English translation: "Public share" */
	AdvancedRightsPublicShared = "Advanced_Rights_Public_Shared",

	/** English translation: "The current element will not inherit from a parent folder." */
	AdvancedRightsRemoveInheritanceDesc = "Advanced_Rights_Remove_Inheritance_Desc",

	/** English translation: "Restore content inheritance" */
	AdvancedRightsRestoreInheritance = "Advanced_Rights_Restore_Inheritance",

	/** English translation: "Removes individual rights from items and adds the rights of the parent folder." */
	AdvancedRightsRestoreInheritanceDesc = "Advanced_Rights_Restore_Inheritance_Desc",

	/** English translation: "Manage rights" */
	AdvancedRightsRightsManagement = "Advanced_Rights_Rights_Management",

	/** English translation: "Shared with link" */
	AdvancedRightsSharedWithLink = "Advanced_Rights_Shared_With_Link",

	/** English translation: "Shared with password" */
	AdvancedRightsSharedWithPassword = "Advanced_Rights_Shared_With_Password",

	/** English translation: "Share" */
	AdvancedRightsSharesManagement = "Advanced_Rights_Shares_Management",

	/** English translation: "You can edit and delete shares in the root folder of the share. Click this link icon to go to the root folder of the share." */
	AdvancedRightsShareChildRestriction = "Advanced_Rights_Share_Child_Restriction",

	/** English translation: "Expiry date" */
	AdvancedRightsShareExpiryDateShort = "Advanced_Rights_Share_Expiry_Date_Short",

	/** English translation: "This folder has the same rights as the parent folder." */
	AdvancedRightsShareInheritDesc = "Advanced_Rights_Share_Inherit_Desc",

	/** English translation: "Link" */
	AdvancedRightsShareLink = "Advanced_Rights_Share_Link",

	/** English translation: "To copy the link, select the link and press Ctrl + C." */
	AdvancedRightsShareLinkCopyError = "Advanced_Rights_Share_Link_Copy_Error",

	/** English translation: "To copy the password, select the password and press Ctrl + C." */
	AdvancedRightsSharePasswordCopyError = "Advanced_Rights_Share_Password_Copy_Error",

	/** English translation: "Send to" */
	AdvancedRightsShareSendTo = "Advanced_Rights_Share_Send_To",

	/** English translation: "Unlimited" */
	AdvancedRightsShareUnlimited = "Advanced_Rights_Share_Unlimited",

	/** English translation: "User rights overview" */
	AdvancedRightsShareUserRights = "Advanced_Rights_Share_User_Rights",

	/** English translation: "Share with link" */
	AdvancedRightsShareWithLink = "Advanced_Rights_Share_With_Link",

	/** English translation: "Please confirm that you want to remove this user from the share." */
	AdvancedRightsUserRemoveConfirmMessage = "Advanced_Rights_User_Remove_Confirm_Message",

	/** English translation: "User rights" */
	AdvancedRightsUserRights = "Advanced_Rights_User_Rights",

	/** English translation: "Changes to user rights are not saved." */
	AdvancedRightsUserRightsModifiedNotSaved = "Advanced_Rights_User_Rights_Modified_Not_Saved",

	/** English translation: "Comment by" */
	AdvancedSearchBlogCommentBy = "Advanced_Search_Blog_Comment_By",

	/** English translation: "Subject by" */
	AdvancedSearchBlogSubjectBy = "Advanced_Search_Blog_Subject_By",

	/** English translation: "If you disable searching in file content, all your previous indexes will be deleted. If you enable it later, you must build the indexes again. If you have a large number of documents in your organization, the rebuild can take a long time." */
	AdvancedSearchDesactivationHubshareMessage = "Advanced_Search_Desactivation_Hubshare_Message",

	/** English translation: "If you disable searching in file content, all your previous indexes will be deleted. If you enable it later, you must build the indexes again. If you have a large number of documents in your hubs, the rebuild can take a long time." */
	AdvancedSearchDesactivationHubsMessage = "Advanced_Search_Desactivation_Hubs_Message",

	/** English translation: "If you disable searching in file content, all your previous indexes will be deleted. If you enable it later, you must build the indexes again. If you have a large number of documents in your hubs, the rebuild can take a long time." */
	AdvancedSearchDesactivationHubMessage = "Advanced_Search_Desactivation_Hub_Message",

	/** English translation: "Deactivate advanced search" */
	AdvancedSearchDesactivationTitle = "Advanced_Search_Desactivation_Title",

	/** English translation: "Document comments" */
	AdvancedSearchDocumentComments = "Advanced_Search_Document_Comments",

	/** English translation: "Document content" */
	AdvancedSearchDocumentContent = "Advanced_Search_Document_Content",

	/** English translation: "Document or folder name" */
	AdvancedSearchDocumentFolderName = "Advanced_Search_Document_Folder_Name",

	/** English translation: "Allow searching in file content" */
	AdvancedSearchEnableFileIndexation = "Advanced_Search_Enable_File_Indexation",

	/** English translation: "Enable advanced search for hubs" */
	AdvancedSearchEnableForHubs = "Advanced_Search_Enable_For_Hubs",

	/** English translation: "Enable advanced search for Hubshare" */
	AdvancedSearchEnableForHubshare = "Advanced_Search_Enable_For_Hubshare",

	/** English translation: "File index" */
	AdvancedSearchFileIndex = "Advanced_Search_File_Index",

	/** English translation: "Go to" */
	AdvancedSearchGoTo = "Advanced_Search_Go_To",

	/** English translation: "Enable this setting to highlight the search criteria in the search results.
If you enable this setting, the search performance can decrease." */
	AdvancedSearchHighlightsMessage = "Advanced_Search_Highlights_Message",

	/** English translation: "Hub name" */
	AdvancedSearchHubName = "Advanced_Search_Hub_Name",

	/** English translation: "With this filter, you can filter hubs, files, task attachments, topics, and comments (including Blog and Project modules)." */
	AdvancedSearchInfoCreationDateAndUser = "Advanced_Search_Info_Creation_Date_And_User",

	/** English translation: "Use quotation marks to search for a group of words." */
	AdvancedSearchInfoQueryPhrase = "Advanced_Search_Info_Query_Phrase",

	/** English translation: "Use quotation marks to exclude a group of words." */
	AdvancedSearchInfoQueryPhraseExclude = "Advanced_Search_Info_Query_Phrase_Exclude",

	/** English translation: "Use a space between words when you search for many words." */
	AdvancedSearchInfoQuerySpace = "Advanced_Search_Info_Query_Space",

	/** English translation: "Use a space between words when you want to exclude many words." */
	AdvancedSearchInfoQuerySpaceExclude = "Advanced_Search_Info_Query_Space_Exclude",

	/** English translation: "Don't use special characters like {0}." */
	AdvancedSearchInfoQuerySpecialChar = "Advanced_Search_Info_Query_SpecialChar",

	/** English translation: "Contains all" */
	AdvancedSearchLabelContainsAll = "Advanced_Search_Label_Contains_All",

	/** English translation: "Contains any" */
	AdvancedSearchLabelContainsAny = "Advanced_Search_Label_Contains_Any",

	/** English translation: "Created between" */
	AdvancedSearchLabelCreatedBetween = "Advanced_Search_Label_Created_Between",

	/** English translation: "Updated on {0} by {1}" */
	AdvancedSearchLabelDocumentUpdate = "Advanced_Search_Label_Document_Update",

	/** English translation: "Exclude" */
	AdvancedSearchLabelExclude = "Advanced_Search_Label_Exclude",

	/** English translation: "Hide search" */
	AdvancedSearchLabelHideSearch = "Advanced_Search_Label_Hide_Search",

	/** English translation: "Show highlights" */
	AdvancedSearchLabelHighlights = "Advanced_Search_Label_Highlights",

	/** English translation: "Hub modules" */
	AdvancedSearchLabelHubModules = "Advanced_Search_Label_Hub_Modules",

	/** English translation: "Location" */
	AdvancedSearchLabelLocation = "Advanced_Search_Label_Location",

	/** English translation: "Search for" */
	AdvancedSearchLabelSearchFor = "Advanced_Search_Label_Search_For",

	/** English translation: "Show search" */
	AdvancedSearchLabelShowSearch = "Advanced_Search_Label_Show_Search",

	/** English translation: "Search in sub-items" */
	AdvancedSearchLabelSubItems = "Advanced_Search_Label_Sub_Items",

	/** English translation: "Task attachments" */
	AdvancedSearchLabelTaskAttachments = "Advanced_Search_Label_Task_Attachments",

	/** English translation: "Task conversations" */
	AdvancedSearchLabelTaskConversations = "Advanced_Search_Label_Task_Conversations",

	/** English translation: "Task details" */
	AdvancedSearchLabelTaskDetails = "Advanced_Search_Label_Task_Details",

	/** English translation: "Click this link to " */
	AdvancedSearchLoadingCancel1 = "Advanced_Search_Loading_Cancel_1",

	/** English translation: "cancel the search. " */
	AdvancedSearchLoadingCancel2 = "Advanced_Search_Loading_Cancel_2",

	/** English translation: "If the search is taking too long, please specify your search." */
	AdvancedSearchLoadingCancel3 = "Advanced_Search_Loading_Cancel_3",

	/** English translation: "Please fill at least one criteria to search." */
	AdvancedSearchMissingMinimalCriteria = "Advanced_Search_Missing_Minimal_Criteria",

	/** English translation: "Search" */
	AdvancedSearchSearch = "Advanced_Search_Search",

	/** English translation: "Please enter search criteria." */
	AdvancedSearchTutorial = "Advanced_Search_Tutorial",

	/** English translation: "Please give a valid file." */
	APIErrorFileNotProvided = "API_Error_File_Not_Provided",

	/** English translation: "The Hubshare certificate is invalid." */
	APIErrorInvalidCSP = "API_Error_Invalid_CSP",

	/** English translation: "API Login" */
	APILogin = "API_Login",

	/** English translation: "Assistance" */
	Assistance = "Assistance",

	/** English translation: "All shares" */
	AuditAllShares = "Audit_All_Shares",

	/** English translation: "Do you want to subscribe to "{0}"?" */
	AuditConfirmShareSubscribe = "Audit_Confirm_Share_Subscribe",

	/** English translation: "Do you want to unsubscribe from "{0}"?" */
	AuditConfirmShareUnsubscribe = "Audit_Confirm_Share_Unsubscribe",

	/** English translation: "Disk space" */
	AuditDiskSpace = "Audit_Disk_Space",

	/** English translation: "File or folder history" */
	AuditFileFolderHistory = "Audit_File_Folder_History",

	/** English translation: "Report on" */
	AuditHubListLabel = "Audit_Hub_List_Label",

	/** English translation: "Latest user action" */
	AuditLatestUserAction = "Audit_Latest_User_Action",

	/** English translation: "My shares" */
	AuditMyShares = "Audit_My_Shares",

	/** English translation: "Select file or folder..." */
	AuditSelectFileFolder = "Audit_Select_File_Folder",

	/** English translation: "Shared with me" */
	AuditSharedWithMe = "Audit_Shared_With_Me",

	/** English translation: "Consulted talks" */
	AuditTalksConsulted = "Audit_Talks_Consulted",

	/** English translation: "Created talks" */
	AuditTalksCreated = "Audit_Talks_Created",

	/** English translation: "Deleted talks" */
	AuditTalksDeleted = "Audit_Talks_Deleted",

	/** English translation: "Modified talks" */
	AuditTalksModified = "Audit_Talks_Modified",

	/** English translation: "User history" */
	AuditUserHistory = "Audit_User_History",

	/** English translation: "Consulted versions" */
	AuditVersionsConsulted = "Audit_Versions_Consulted",

	/** English translation: "Created versions" */
	AuditVersionsCreated = "Audit_Versions_Created",

	/** English translation: "Deleted versions" */
	AuditVersionsDeleted = "Audit_Versions_Deleted",

	/** English translation: "Modified versions" */
	AuditVersionsModified = "Audit_Versions_Modified",

	/** English translation: "Authenticated" */
	Authenticated = "Authenticated",

	/** English translation: "Authentication" */
	Authentication = "Authentication",

	/** English translation: "Authentication" */
	AuthenticationInProgress = "AuthenticationInProgress",

	/** English translation: "Back" */
	BackToDocstore = "Back_To_Docstore",

	/** English translation: "Download all selections" */
	BasketDownloadAllElements = "Basket_Download_All_Elements",

	/** English translation: "Download selected" */
	BasketDownloadSelectedElements = "Basket_Download_Selected_Elements",

	/** English translation: "Selected files and folders" */
	BasketFilesSelection = "Basket_Files_Selection",

	/** English translation: "This item is in the recycle bin." */
	BasketItemInRecycleBin = "Basket_Item_In_Recycle_Bin",

	/** English translation: "Remove all selections" */
	BasketRemoveAllElements = "Basket_Remove_All_Elements",

	/** English translation: "Remove selection" */
	BasketRemoveElement = "Basket_Remove_Element",

	/** English translation: "Remove selected" */
	BasketRemoveSelectedElements = "Basket_Remove_Selected_Elements",

	/** English translation: "Select items in the list and an action, and click Apply." */
	BasketSelectAndApply = "Basket_Select_And_Apply",

	/** English translation: "Select action" */
	BasketSelectAnAction = "Basket_Select_An_Action",

	/** English translation: "Selected" */
	BasketYourSelection = "Basket_Your_Selection",

	/** English translation: "The files and folders that you have selected in all the hubs are shown here." */
	BasketYourSelectionDescription = "Basket_Your_Selection_Description",

	/** English translation: "restoration was canceled." */
	BinRestorationRecapCancel = "Bin_Restoration_Recap_Cancel",

	/** English translation: "couldn't be restored because you don't have creation rights in this folder." */
	BinRestorationRecapRenameFailed = "Bin_Restoration_Recap_Rename_Failed",

	/** English translation: "is successfully restored under the name {0}." */
	BinRestorationRecapRenameSuccess = "Bin_Restoration_Recap_Rename_Success",

	/** English translation: "is successfully restored." */
	BinRestorationRecapSuccess = "Bin_Restoration_Recap_Success",

	/** English translation: "These resources were not restored" */
	BinRestorationRecapTitleErrors = "Bin_Restoration_Recap_Title_Errors",

	/** English translation: "These resources were restored" */
	BinRestorationRecapTitleSuccess = "Bin_Restoration_Recap_Title_Success",

	/** English translation: "The restoration was successful." */
	BinRestorationSuccessfull = "Bin_Restoration_Successfull",

	/** English translation: "Restore and rename" */
	BinRestoreRenameFile = "Bin_Restore_Rename_File",

	/** English translation: "Restore your file as a new file named "{0}"." */
	BinRestoreRenameFileDesc = "Bin_Restore_Rename_File_Desc",

	/** English translation: "Restore and rename" */
	BinRestoreRenameFolder = "Bin_Restore_Rename_Folder",

	/** English translation: "Restore your folder as a new folder named "{0}"." */
	BinRestoreRenameFolderDesc = "Bin_Restore_Rename_Folder_Desc",

	/** English translation: "Comment by" */
	BlogActivityCommentBy = "Blog_Activity_Comment_By",

	/** English translation: "Subject by" */
	BlogActivitySubjectBy = "Blog_Activity_Subject_By",

	/** English translation: "You don't have the right to see  comments." */
	BlogNoCommentsReadingRight = "Blog_No_Comments_Reading_Right",

	/** English translation: "You don't have the right to see topics." */
	BlogNoTopicsReadingRight = "Blog_No_Topics_Reading_Right",

	/** English translation: "Subject" */
	CertificateSubject = "Certificate_Subject",

	/** English translation: "Change password" */
	ChangePassword = "Change_Password",

	/** English translation: "Current password" */
	ChangePasswordCurrentPassword = "Change_Password_Current_Password",

	/** English translation: "The password is invalid." */
	ChangePasswordInvalid = "Change_Password_Invalid",

	/** English translation: "This link is no longer valid." */
	ChangePasswordInvalidLink = "Change_Password_Invalid_Link",

	/** English translation: "The passwords match." */
	ChangePasswordMatch = "Change_Password_Match",

	/** English translation: "New password" */
	ChangePasswordNewPassword = "Change_Password_New_Password",

	/** English translation: "Confirm new password" */
	ChangePasswordNewPasswordConfirm = "Change_Password_New_Password_Confirm",

	/** English translation: "The passwords do not match." */
	ChangePasswordNotMatch = "Change_Password_Not_Match",

	/** English translation: "Please enter your current password." */
	ChangePasswordOldPassNull = "Change_Password_Old_Pass_Null",

	/** English translation: "Password recovery" */
	ChangePasswordRecovery = "Change_Password_Recovery",

	/** English translation: "The password contains a blacklist word." */
	ChangePasswordRequirementBlacklist = "Change_Password_Requirement_Blacklist",

	/** English translation: "At least 8 characters long" */
	ChangePasswordRequirementNbCharacters = "Change_Password_Requirement_NbCharacters",

	/** English translation: "Contains numbers" */
	ChangePasswordRequirementNumbers = "Change_Password_Requirement_Numbers",

	/** English translation: "Contains special characters" */
	ChangePasswordRequirementSpecialCharacters = "Change_Password_Requirement_Special_Characters",

	/** English translation: "Contains uppercase and lowercase letters" */
	ChangePasswordRequirementUpLowCase = "Change_Password_Requirement_UpLowCase",

	/** English translation: "Password strength" */
	ChangePasswordStrength = "Change_Password_Strength",

	/** English translation: "Good" */
	ChangePasswordStrengthGood = "Change_Password_Strength_Good",

	/** English translation: "Moderate" */
	ChangePasswordStrengthModerate = "Change_Password_Strength_Moderate",

	/** English translation: "Quite weak" */
	ChangePasswordStrengthRatherWeak = "Change_Password_Strength_Rather_Weak",

	/** English translation: "Strong" */
	ChangePasswordStrengthStrong = "Change_Password_Strength_Strong",

	/** English translation: "Too short" */
	ChangePasswordStrengthTooShort = "Change_Password_Strength_Too_Short",

	/** English translation: "Too weak" */
	ChangePasswordStrengthTooWeak = "Change_Password_Strength_Too_Weak",

	/** English translation: "Very strong" */
	ChangePasswordStrengthVeryStrong = "Change_Password_Strength_Very_Strong",

	/** English translation: "Very weak" */
	ChangePasswordStrengthVeryWeak = "Change_Password_Strength_Very_Weak",

	/** English translation: "Weak" */
	ChangePasswordStrengthWeak = "Change_Password_Strength_Weak",

	/** English translation: "Your password was changed successfully." */
	ChangePasswordSuccess = "Change_Password_Success",

	/** English translation: "The password is too short. The minimum length is 8 characters." */
	ChangePasswordTooShort = "Change_Password_Too_Short",

	/** English translation: "The password is too weak. Use lowercase and uppercase letters, numbers and symbols to strengthen the password." */
	ChangePasswordTooWeak = "Change_Password_Too_Weak",

	/** English translation: "Validate password" */
	ChangePasswordValidate = "Change_Password_Validate",

	/** English translation: "The passwords do not match." */
	ChangePasswordVerificationError = "Change_Password_Verification_Error",

	/** English translation: "Maximum number of shown items" */
	ChartConfigurationMaxShownItems = "Chart_Configuration_Max_Shown_Items",

	/** English translation: "If there are more items than the entered number allows, the smallest items are not shown." */
	ChartConfigurationMaxShownItemsDescription = "Chart_Configuration_Max_Shown_Items_Description",

	/** English translation: "Bar" */
	ChartKindBar = "Chart_Kind_Bar",

	/** English translation: "Doughnut" */
	ChartKindDoughnut = "Chart_Kind_Doughnut",

	/** English translation: "Line" */
	ChartKindLine = "Chart_Kind_Line",

	/** English translation: "Pie" */
	ChartKindPie = "Chart_Kind_Pie",

	/** English translation: "Polar" */
	ChartKindPolar = "Chart_Kind_Polar",

	/** English translation: "Proportion" */
	ChartKindProportion = "Chart_Kind_Proportion",

	/** English translation: "Radar" */
	ChartKindRadar = "Chart_Kind_Radar",

	/** English translation: "Series" */
	ChartKindSeries = "Chart_Kind_Series",

	/** English translation: "The description is too long." */
	CompanyGroupDescriptionTooLong = "CompanyGroup_Description_Too_Long",

	/** English translation: "The group name is too long. Please use a shorter name." */
	CompanyGroupNameTooLong = "CompanyGroup_Name_Too_Long",

	/** English translation: "5 minutes" */
	CompanyAutoDisablingFrequencyFiveMinute = "Company_Auto_Disabling_Frequency_Five_Minute",

	/** English translation: "1 month" */
	CompanyAutoDisablingFrequencyOneMonth = "Company_Auto_Disabling_Frequency_One_Month",

	/** English translation: "10 minutes" */
	CompanyAutoDisablingFrequencyTenMinute = "Company_Auto_Disabling_Frequency_Ten_Minute",

	/** English translation: "3 months" */
	CompanyAutoDisablingFrequencyThreeMonth = "Company_Auto_Disabling_Frequency_Three_Month",

	/** English translation: "2 months" */
	CompanyAutoDisablingFrequencyTwoMonth = "Company_Auto_Disabling_Frequency_Two_Month",

	/** English translation: "15 days" */
	CompanyAutoDisablingFrequencyTwoWeek = "Company_Auto_Disabling_Frequency_Two_Week",

	/** English translation: "Default notification frequency" */
	CompanyDefaultNotificationFrequency = "Company_Default_Notification_Frequency",

	/** English translation: "Disable hub invitation notifications for users" */
	CompanyDisableHubInvitationNotification = "Company_Disable_Hub_Invitation_Notification",

	/** English translation: "Use custom percentage" */
	CompanyDiskUsageThresholdCustom = "Company_Disk_Usage_Threshold_Custom",

	/** English translation: "When 50% is used" */
	CompanyDiskUsageThresholdFifty = "Company_Disk_Usage_Threshold_Fifty",

	/** English translation: "Never" */
	CompanyDiskUsageThresholdNever = "Company_Disk_Usage_Threshold_Never",

	/** English translation: "When 90% is used" */
	CompanyDiskUsageThresholdNinety = "Company_Disk_Usage_Threshold_Ninety",

	/** English translation: "Send email to organization-level admins about storage space" */
	CompanyDiskUsageThresholdNotification = "Company_Disk_Usage_Threshold_Notification",

	/** English translation: "When 75% is used" */
	CompanyDiskUsageThresholdSeventyFive = "Company_Disk_Usage_Threshold_Seventy_Five",

	/** English translation: "When 25% is used" */
	CompanyDiskUsageThresholdTwentyFive = "Company_Disk_Usage_Threshold_Twenty_Five",

	/** English translation: "If you delete this group, the group members can no longer access hubs that this group is a member of, or to any element shared to this group." */
	CompanyGroupsDeleteConfirmMessage = "Company_Groups_Delete_Confirm_Message",

	/** English translation: "{0} has invited you to use Hubshare." */
	CompanyInvitedYou = "Company_Invited_You",

	/** English translation: "Organization logo" */
	CompanyLogo = "Company_Logo",

	/** English translation: "File only" */
	CompanyModeFile = "Company_Mode_File",

	/** English translation: "Hub only" */
	CompanyModeHub = "Company_Mode_Hub",

	/** English translation: "Intranet only" */
	CompanyModeIntranet = "Company_Mode_Intranet",

	/** English translation: "Organization name" */
	CompanyName = "Company_Name",

	/** English translation: "Do you want to update the organization name for all users?" */
	CompanySettingsApplyNameToUsers = "Company_Settings_Apply_Name_To_Users",

	/** English translation: "Live" */
	CompanyStatusLive = "Company_Status_Live",

	/** English translation: "Trial" */
	CompanyStatusTrial = "Company_Status_Trial",

	/** English translation: "Validity time of account creation email" */
	CompanyUserValidationDuration = "Company_User_Validation_Duration",

	/** English translation: "90 days" */
	CompanyUserValidationDurationExpireInNinetyDays = "Company_User_Validation_Duration_Expire_In_Ninety_Days",

	/** English translation: "1 day" */
	CompanyUserValidationDurationExpireInOneDay = "Company_User_Validation_Duration_Expire_In_One_Day",

	/** English translation: "180 days" */
	CompanyUserValidationDurationExpireInOneHundredAndHeightyDays = "Company_User_Validation_Duration_Expire_In_One_Hundred_And_Heighty_Days",

	/** English translation: "7 days" */
	CompanyUserValidationDurationExpireInSevenDays = "Company_User_Validation_Duration_Expire_In_Seven_Days",

	/** English translation: "30 days" */
	CompanyUserValidationDurationExpireInThirtyDays = "Company_User_Validation_Duration_Expire_In_Thirty_Days",

	/** English translation: "Confidentiality and utilization policy" */
	Confidentiality = "Confidentiality",

	/** English translation: "Widget titles" */
	ConfigurationWidgetTitles = "Configuration_Widget_Titles",

	/** English translation: "Edit template" */
	ContextMenuEditTemplate = "Context_Menu_Edit_Template",

	/** English translation: "Wizard" */
	ContextMenuHubWizard = "Context_Menu_Hub_Wizard",

	/** English translation: "Launch hub" */
	ContextMenuStartHub = "Context_Menu_Start_Hub",

	/** English translation: "Copy new activation link to clipboard" */
	CopyNewActivationLink = "Copy_New_Activation_Link",

	/** English translation: "Missing configuration" */
	CreateOrUpdateDashboardPageErrorMissing = "CreateOrUpdateDashboardPage_Error_Missing",

	/** English translation: "Please enter a name for the page in the default language of the hub." */
	CreateOrUpdateDashboardPageErrorMissingDefaultHubPageName = "CreateOrUpdateDashboardPage_Error_Missing_Default_Hub_Page_Name",

	/** English translation: "Please select a picture or icon for the page." */
	CreateOrUpdateDashboardPageErrorPicture = "CreateOrUpdateDashboardPage_Error_Picture",

	/** English translation: "Please enter a name for the page." */
	CreateOrUpdateDashboardPageErrorTitle = "CreateOrUpdateDashboardPage_Error_Title",

	/** English translation: "The page icon is the first letter of the name by default." */
	CreateOrUpdateDashboardPageDefaultIcon = "CreateOrUpdate_DashboardPage_Default_Icon",

	/** English translation: "There are no parent pages available." */
	CreateOrUpdateDashboardPageNoParentAvailable = "CreateOrUpdate_DashboardPage_No_Parent_Available",

	/** English translation: "Page name" */
	CreateOrUpdateDashboardPagePageName = "CreateOrUpdate_DashboardPage_PageName",

	/** English translation: "Select parent page" */
	CreateOrUpdateDashboardPageSetParentPage = "CreateOrUpdate_DashboardPage_Set_Parent_Page",

	/** English translation: "Page settings" */
	CreateOrUpdateDashboardPageTitle = "CreateOrUpdate_DashboardPage_Title",

	/** English translation: "Use as Dashboard home page" */
	CreateOrUpdateDashboardPageUseAsDefaultPage = "CreateOrUpdate_DashboardPage_UseAsDefaultPage",

	/** English translation: "Show in sidebar" */
	CreateOrUpdateDashboardPageUseAsModule = "CreateOrUpdate_DashboardPage_UseAsModule",

	/** English translation: "This hub is the organization-level hub." */
	CurrentHubIsCompanyHub = "Current_Hub_Is_Company_Hub",

	/** English translation: "We recommend that you upload .mp4 videos with H.264 video codec." */
	CustomContentVideoBrowserExplanation = "Custom_Content_Video_Browser_Explanation",

	/** English translation: "Add custom field" */
	CustomFieldAddCustomField = "Custom_Field_Add_Custom_Field",

	/** English translation: "A collection must contain at least 2 values." */
	CustomFieldCollectionMinimumValues = "Custom_Field_Collection_Minimum_Values",

	/** English translation: "Force removal" */
	CustomFieldDeleteConfirmCheckbox = "Custom_Field_Delete_Confirm_Checkbox",

	/** English translation: "Are you sure you want to delete this custom field?" */
	CustomFieldDeleteConfirmText = "Custom_Field_Delete_Confirm_Text",

	/** English translation: "Remove a custom field" */
	CustomFieldDeleteConfirmTitle = "Custom_Field_Delete_Confirm_Title",

	/** English translation: "The custom field wasn't deleted." */
	CustomFieldDeleteUsedConfirmText = "Custom_Field_Delete_Used_Confirm_Text",

	/** English translation: "You can force the deletion of this custom field and break its dependencies. Do you want to force it?" */
	CustomFieldDeleteUsedConfirmTextForceDeletion = "Custom_Field_Delete_Used_Confirm_Text_Force_Deletion",

	/** English translation: "The custom field to delete is used" */
	CustomFieldDeleteUsedConfirmTitle = "Custom_Field_Delete_Used_Confirm_Title",

	/** English translation: "Edit in HTML editor" */
	CustomFieldEditInHTMLEditor = "Custom_Field_Edit_In_HTML_Editor",

	/** English translation: "The custom field wasn't edited." */
	CustomFieldEditUsedConfirmText = "Custom_Field_Edit_Used_Confirm_Text",

	/** English translation: "You can force the edition of this custom field and break its dependencies. Do you want to force it?" */
	CustomFieldEditUsedConfirmTextForceEdition = "Custom_Field_Edit_Used_Confirm_Text_Force_Edition",

	/** English translation: "The custom field to edit is used" */
	CustomFieldEditUsedConfirmTitle = "Custom_Field_Edit_Used_Confirm_Title",

	/** English translation: "The custom field values are not the same." */
	CustomFieldErrorNotSameLength = "Custom_Field_Error_Not_Same_Length",

	/** English translation: "Fixed fields" */
	CustomFieldFixedFields = "Custom_Field_Fixed_Fields",

	/** English translation: "Hub name" */
	CustomFieldHubName = "Custom_Field_Hub_Name",

	/** English translation: "The identifier of the custom field can't be changed because it is used in another hub." */
	CustomFieldIdentifierCannotChanged = "Custom_Field_Identifier_Cannot_Changed",

	/** English translation: "Number" */
	CustomFieldKindNumber = "Custom_Field_Kind_Number",

	/** English translation: "Number collection" */
	CustomFieldKindNumberCollection = "Custom_Field_Kind_Number_Collection",

	/** English translation: "Text" */
	CustomFieldKindText = "Custom_Field_Kind_Text",

	/** English translation: "Text collection" */
	CustomFieldKindTextCollection = "Custom_Field_Kind_Text_Collection",

	/** English translation: "Manage custom field" */
	CustomFieldManage = "Custom_Field_Manage",

	/** English translation: "Are you sure you want to delete this custom field?" */
	CustomFieldModalDeleteText = "Custom_Field_Modal_Delete_Text",

	/** English translation: "Delete custom field" */
	CustomFieldModalDeleteTitle = "Custom_Field_Modal_Delete_Title",

	/** English translation: "Multi-language fields" */
	CustomFieldMultiLangFields = "Custom_Field_Multi_Lang_Fields",

	/** English translation: "Not shared" */
	CustomFieldNotShared = "Custom_Field_Not_Shared",

	/** English translation: "No custom fields" */
	CustomFieldNoCustomField = "Custom_Field_No_Custom_Field",

	/** English translation: "A number value must be less than or equal to {0}." */
	CustomFieldNumberMaxValue = "Custom_Field_Number_Max_Value",

	/** English translation: "Shared field" */
	CustomFieldSharedField = "Custom_Field_Shared_Field",

	/** English translation: "Shared value" */
	CustomFieldSharedValue = "Custom_Field_Shared_Value",

	/** English translation: "Short name" */
	CustomFieldShortName = "Custom_Field_Short_Name",

	/** English translation: "The maximum length is 64 characters. Allowed characters are . _ a-z A-Z 0-9. You can't start with a number or a dot, and you can't have two or more following dots." */
	CustomFieldShortNameInfo = "Custom_Field_Short_Name_Info",

	/** English translation: "The short name of the custom field can only contain alphanumeric characters." */
	CustomFieldShortNameInvalidChar = "Custom_Field_Short_Name_Invalid_Char",

	/** English translation: "The short name of the custom field includes characters that are not allowed." */
	CustomFieldShortNameInvalidCharacters = "Custom_Field_Short_Name_Invalid_Characters",

	/** English translation: "The short name of the custom field can't start with a number or a dot." */
	CustomFieldShortNameInvalidFirstChar = "Custom_Field_Short_Name_Invalid_First_Char",

	/** English translation: "The short name of the custom field can't contain two or more following dots." */
	CustomFieldShortNameInvalidMultipleDot = "Custom_Field_Short_Name_Invalid_Multiple_Dot",

	/** English translation: "The custom content {0} uses this custom field." */
	CustomFieldUsedByCustomContent = "Custom_Field_Used_By_Custom_Content",

	/** English translation: "The widget {0} uses this custom field." */
	CustomFieldUsedByWidget = "Custom_Field_Used_By_Widget",

	/** English translation: "It is used by:" */
	CustomFieldUsedConfirmTextUsedBy = "Custom_Field_Used_Confirm_Text_Used_By",

	/** English translation: "List of values" */
	CustomFieldValuesTitle = "Custom_Field_Values_Title",

	/** English translation: "The value types Number and Collection of numbers can contain only numbers." */
	CustomFieldValueOnlyNumber = "Custom_Field_Value_Only_Number",

	/** English translation: "Mark all as to-do" */
	CustomTaskAddAllToDo = "Custom_Task_Add_All_To_Do",

	/** English translation: "Mark as to-do" */
	CustomTaskAddToDo = "Custom_Task_Add_To_Do",

	/** English translation: "Show done tasks" */
	CustomTaskDisplayDone = "Custom_Task_Display_Done",

	/** English translation: "Done" */
	CustomTaskDone = "Custom_Task_Done",

	/** English translation: "Important tasks" */
	CustomTaskFlaggedFlag = "Custom_Task_Flagged_Flag",

	/** English translation: "Flag as important" */
	CustomTaskFlagATask = "Custom_Task_Flag_A_Task",

	/** English translation: "Hide done tasks" */
	CustomTaskHideDone = "Custom_Task_Hide_Done",

	/** English translation: "Important tasks" */
	CustomTaskImportant = "Custom_Task_Important",

	/** English translation: "Late tasks" */
	CustomTaskLateFilter = "Custom_Task_Late_Filter",

	/** English translation: "Mark all as done" */
	CustomTaskMarkAllAsDone = "Custom_Task_Mark_All_As_Done",

	/** English translation: "Mark as done" */
	CustomTaskMarkAsDone = "Custom_Task_Mark_As_Done",

	/** English translation: "No task" */
	CustomTaskNoTask = "Custom_Task_No_Task",

	/** English translation: "No task assigned" */
	CustomTaskNoTaskAssigned = "Custom_Task_No_Task_Assigned",

	/** English translation: "No done tasks" */
	CustomTaskNoTaskDone = "Custom_Task_No_Task_Done",

	/** English translation: "Order tasks" */
	CustomTaskOrderTask = "Custom_Task_Order_Task",

	/** English translation: "Clear filter" */
	CustomTaskresetfilter = "Custom_Task_reset_filter",

	/** English translation: "To-do" */
	CustomTaskToDo = "Custom_Task_To_Do",

	/** English translation: "Clear flag" */
	CustomTaskUnflagATask = "Custom_Task_Unflag_A_Task",

	/** English translation: "Last updated (ascending)" */
	CustomTaskUpdateAsc = "Custom_Task_Update_Asc",

	/** English translation: "Last updated (descending)" */
	CustomTaskUpdateDesc = "Custom_Task_Update_Desc",

	/** English translation: "Create page" */
	DashboardPageAddPage = "DashboardPage_Add_Page",

	/** English translation: "Create sub-page" */
	DashboardPageAddSubPage = "DashboardPage_Add_Sub_Page",

	/** English translation: "Page link wasn't copied to clipboard" */
	DashboardPageCopyToClipboardError = "DashboardPage_Copy_To_Clipboard_Error",

	/** English translation: "Page link copied to clipboard" */
	DashboardPageCopyToClipboardSuccess = "DashboardPage_Copy_To_Clipboard_Success",

	/** English translation: "Delete page" */
	DashboardPageDelete = "DashboardPage_Delete",

	/** English translation: "If you delete this page, the widgets that are linked to it will be deleted also. Do you want to delete this page?" */
	DashboardPageDeleteMessage = "DashboardPage_Delete_Message",

	/** English translation: "Delete all sub-pages (otherwise, sub-pages will be attached to the closest parent above)" */
	DashboardPageDeleteSubPages = "DashboardPage_Delete_Sub_Pages",

	/** English translation: "In sidebar" */
	DashboardPageModule = "DashboardPage_Module",

	/** English translation: "Sub-pages" */
	DashboardPageSubPages = "DashboardPage_Sub_Pages",

	/** English translation: "A page can have only {0} levels of sub-pages." */
	DashboardPageSubPageDepthLimited = "DashboardPage_Sub_Page_Depth_Limited",

	/** English translation: "Manage pages" */
	DashboardPageTitle = "DashboardPage_Title",

	/** English translation: "Data transfer" */
	DataTransfer = "Data_Transfer",

	/** English translation: "Delete the forms of the children's hubs" */
	DeleteOtherHubChildForms = "Delete_Other_Hub_Child_Forms",

	/** English translation: "If you change the visibility of the form hub, the child forms in other hubs might be deleted. Do you want to change the visibility of the form hub?" */
	DeleteOtherHubChildFormsMessage = "Delete_Other_Hub_Child_Forms_Message",

	/** English translation: "Activated modules" */
	DemoActivatedModules = "Demo_ActivatedModules",

	/** English translation: "When I submit this form, I accept that the information entered will be exploited as part of the demo application and the commercial relationship that may result. Hubshare will not in any circumstances transmit your details to other companies, confidentiality and professionalism characterize the DNA of the Hubshare team." */
	DemoConditions = "Demo_Conditions",

	/** English translation: "Employee number" */
	DemoEmployeeNumber = "Demo_EmployeeNumber",

	/** English translation: "A confirmation email is sent to you at {0}." */
	DemoSuccessText1 = "Demo_SuccessText1",

	/** English translation: "Please go to your inbox and follow the link
to complete your registration." */
	DemoSuccessText2 = "Demo_SuccessText2",

	/** English translation: "Thank you for choosing Hubshare!" */
	DemoSuccessTitle = "Demo_SuccessTitle",

	/** English translation: "You can't create a demo account because you already have an account. Please {0}disconnect{1} and then create a demo account." */
	DemoWarningMessage = "Demo_WarningMessage",

	/** English translation: "The DocuSign account is suspended." */
	DocusignErrorAccountSuspended = "Docusign_Error_Account_Suspended",

	/** English translation: "The Docusign administration rights are suspended." */
	DocusignErrorAdminRequired = "Docusign_Error_Admin_Required",

	/** English translation: "There is an error with the server configuration." */
	DocusignErrorConfigurationError = "Docusign_Error_Configuration_Error",

	/** English translation: "The file can't be empty." */
	DocusignErrorEmptyFile = "Docusign_Error_Empty_File",

	/** English translation: "It is not possible to sign the file." */
	DocusignErrorFileGeneric = "Docusign_Error_File_Generic",

	/** English translation: "An error occured. Please try again." */
	DocusignErrorGeneric = "Docusign_Error_Generic",

	/** English translation: "The list of files is invalid. Please make sure that there are files in the list and the files are in correct format." */
	DocusignErrorInvalidFiles = "Docusign_Error_Invalid_Files",

	/** English translation: "The parameters are invalid." */
	DocusignErrorInvalidParameters = "Docusign_Error_Invalid_Parameters",

	/** English translation: "You need more permissions in DocuSign." */
	DocusignErrorMissingPermission = "Docusign_Error_Missing_Permission",

	/** English translation: "The maximum length of the receiver email is {0} characters." */
	DocusignErrorRecipientEmailTooLong = "Docusign_Error_Recipient_Email_Too_Long",

	/** English translation: "The envelope is created but one or more files can't be added." */
	DocusignErrorRequestErrorFiles = "Docusign_Error_Request_Error_Files",

	/** English translation: "The envelope status is not supported." */
	DocusignErrorStatusNotSupported = "Docusign_Error_Status_Not_Supported",

	/** English translation: "You don't have the right to do this action in DocuSign." */
	DocusignErrorUnauthorizedAccess = "Docusign_Error_Unauthorized_Access",

	/** English translation: "An error occured with the server configuration or you have to agree to Docusign's terms." */
	DocusignErrorUserConsentOrConfigRequired = "Docusign_Error_User_Consent_Or_Config_Required",

	/** English translation: "You have to agree to Docusign's terms." */
	DocusignErrorUserConsentRequired = "Docusign_Error_User_Consent_Required",

	/** English translation: "Keep the envelope" */
	DocusignKeepEnvelope = "Docusign_Keep_Envelope",

	/** English translation: "The Office desktop plugin is not installed. Do you want to install it?" */
	DownloadOfficePlugin = "Download_Office_Plugin",

	/** English translation: "Drop XML File" */
	DropXMLFile = "Drop_XML_File",

	/** English translation: "Edit widgets" */
	EditWidgetTitle = "Edit_Widget_Title",

	/** English translation: "You can't request signatures." */
	EdsCannotRequestSignature = "Eds_Cannot_Request_Signature",

	/** English translation: "You can't revoke this request because its status is {0}." */
	EdsCantRevokeSignature = "Eds_Cant_Revoke_Signature",

	/** English translation: "Can request document signatures" */
	EdsCanRequest = "Eds_Can_Request",

	/** English translation: "Check signatures status" */
	EdsCheckSignaturesStatus = "Eds_Check_Signatures_Status",

	/** English translation: "The electronic document signing is disabled." */
	EdsDisabled = "Eds_Disabled",

	/** English translation: "Enable DocuSign Connect" */
	EDSDocusignConfigEnableWebhook = "EDS_Docusign_Config_Enable_Webhook",

	/** English translation: "Integrator key" */
	EDSDocusignConfigIntegratorKey = "EDS_Docusign_Config_Integrator_Key",

	/** English translation: "Private key" */
	EDSDocusignConfigPrivateKey = "EDS_Docusign_Config_Private_Key",

	/** English translation: "Secret key" */
	EDSDocusignConfigSecretKey = "EDS_Docusign_Config_Secret_Key",

	/** English translation: "Use sandbox" */
	EDSDocusignConfigUseSandbox = "EDS_Docusign_Config_Use_Sandbox",

	/** English translation: "Redirect URI" */
	EdsDocusignRedirectUri = "Eds_Docusign_Redirect_Uri",

	/** English translation: "Ignore user rights for signed documents" */
	EdsIgnoreResourceRights = "Eds_Ignore_Resource_Rights",

	/** English translation: "When this setting is enabled, all signed documents are uploaded. This includes documents without the necessary user rights." */
	EdsIgnoreResourceRightsInfo = "Eds_Ignore_Resource_Rights_Info",

	/** English translation: "Cannot contain the characters "/\*:<|>?" */
	EdsIllegalCharacters = "Eds_Illegal_Characters",

	/** English translation: "The configuration is not valid." */
	EdsInvalidConfig = "Eds_Invalid_Config",

	/** English translation: "You have not selected a user.Do you still want to continue?" */
	EDSNoUserConfirmMessage = "EDS_NoUser_Confirm_Message",

	/** English translation: "No users" */
	EDSNoUserConfirmTitle = "EDS_NoUser_Confirm_Title",

	/** English translation: "Provider" */
	EdsProvider = "Eds_Provider",

	/** English translation: "Docusign" */
	EDSProviderDocusign = "EDS_Provider_Docusign",

	/** English translation: "None" */
	EDSProviderNone = "EDS_Provider_None",

	/** English translation: "Please confirm that the current configuration will be deleted.
The users can no longer request signatures or check signature status from Hubshare or hubs." */
	EDSProviderNoneMessage = "EDS_Provider_None_Message",

	/** English translation: "Delete the configuration" */
	EDSProviderNoneTitle = "EDS_Provider_None_Title",

	/** English translation: "Request electronic signature" */
	EdsRequestElectronicSignature = "Eds_Request_Electronic_Signature",

	/** English translation: "Some of the files are not supported. Do you still want to continue?" */
	EdsRequestErrorConfirmation = "Eds_Request_Error_Confirmation",

	/** English translation: "Excluded file" */
	EdsRequestExcludedFile = "Eds_Request_Excluded_File",

	/** English translation: "Excluded files" */
	EdsRequestExcludedFiles = "Eds_Request_Excluded_Files",

	/** English translation: "Included file" */
	EdsRequestIncludedFile = "Eds_Request_Included_File",

	/** English translation: "Included files" */
	EdsRequestIncludedFiles = "Eds_Request_Included_Files",

	/** English translation: "You don't have the right to request a signature." */
	EdsRequestRightRequired = "Eds_Request_Right_Required",

	/** English translation: "The request was sent." */
	EdsRequestSent = "Eds_Request_Sent",

	/** English translation: "Signed file name" */
	EdsRequestTitle = "Eds_Request_Title",

	/** English translation: "Select the users that you want to have a signature from." */
	EdsRequestUserSelection = "Eds_Request_User_Selection",

	/** English translation: "Revoke signature" */
	EdsRevokeSignature = "Eds_Revoke_Signature",

	/** English translation: "Enable electronic signature" */
	EDSSettingsEnable = "EDS_Settings_Enable",

	/** English translation: "Electronic document signing" */
	EDSSettingsTitle = "EDS_Settings_Title",

	/** English translation: "Users authorization" */
	EDSSettingsUsersAuthorization = "EDS_Settings_Users_Authorization",

	/** English translation: "Signature requests" */
	EdsSignatureRequests = "Eds_Signature_Requests",

	/** English translation: "Refused" */
	EdsSignatureStatusRefused = "Eds_Signature_Status_Refused",

	/** English translation: "Revoked" */
	EdsSignatureStatusRevoked = "Eds_Signature_Status_Revoked",

	/** English translation: "Signed" */
	EdsSignatureStatusSigned = "Eds_Signature_Status_Signed",

	/** English translation: "Waiting for signature" */
	EdsSignatureStatusWaitingForSignature = "Eds_Signature_Status_Waiting_For_Signature",

	/** English translation: "Email notifications" */
	EmailNotifications = "Email_Notifications",

	/** English translation: "Notifications settings" */
	EmailNotificationsSettings = "Email_Notifications_Settings",

	/** English translation: "Organization emails" */
	EmailNotificationCompanySettingsCompanyEmails = "Email_Notification_Company_Settings_Company_Emails",

	/** English translation: "The preferences are incorrect. Scheduled email notifications can't be sent before the preferences are correctly configured." */
	EmailNotificationCompanySettingsNoConfig = "Email_Notification_Company_Settings_No_Config",

	/** English translation: "Hub emails" */
	EmailNotificationCompanySettingsRoomEmails = "Email_Notification_Company_Settings_Room_Emails",

	/** English translation: "Show organization's name in email subject from" */
	EmailNotificationCompanySettingsShowSubject = "Email_Notification_Company_Settings_Show_Subject",

	/** English translation: "The settings are saved." */
	EmailNotificationCompanySettingsSuccess = "Email_Notification_Company_Settings_Success",

	/** English translation: "Daily" */
	EmailNotificationFrequencyDaily = "Email_Notification_Frequency_Daily",

	/** English translation: "Immediately" */
	EmailNotificationFrequencyImmediatly = "Email_Notification_Frequency_Immediatly",

	/** English translation: "Weekly" */
	EmailNotificationFrequencyWeekly = "Email_Notification_Frequency_Weekly",

	/** English translation: "Inherit from" */
	EmailNotificationInheritFrom = "Email_Notification_Inherit_From",

	/** English translation: "Email notification manager" */
	EmailNotificationManager = "Email_Notification_Manager",

	/** English translation: "Here you can set up your notification preferences for scheduled emails." */
	EmailNotificationManagerDescription = "Email_Notification_Manager_Description",

	/** English translation: "Made by me" */
	EmailNotificationMyself = "Email_Notification_Myself",

	/** English translation: "Made by others" */
	EmailNotificationOthers = "Email_Notification_Others",

	/** English translation: "Propagate to sub-elements" */
	EmailNotificationPropagate = "Email_Notification_Propagate",

	/** English translation: "This action restores the inheritance of all sub-elements and overwrites all parameters defined on them. When the action is done, you can't cancel it. Do you want to do this action?" */
	EmailNotificationRestoreInheritanceMessage = "Email_Notification_Restore_Inheritance_Message",

	/** English translation: "The inheritance was restored." */
	EmailNotificationRestoreInheritanceSuccess = "Email_Notification_Restore_Inheritance_Success",

	/** English translation: "Send daily notification at" */
	EmailNotificationSendDailyNotificationAt = "Email_Notification_Send_Daily_Notification_At",

	/** English translation: "Send weekly notifications on" */
	EmailNotificationSendWeeklyNotificationOn = "Email_Notification_Send_Weekly_Notification_On",

	/** English translation: "The email notification parameters are updated." */
	EmailNotificationUpdateSuccess = "Email_Notification_Update_Success",

	/** English translation: "Please add at least one parent field or a new field in your related form." */
	ErrorAddAtLastOneFieldOnRelatif = "Error_AddAtLastOneFieldOnRelatif",

	/** English translation: "All accounts for internal organization users are now in use." */
	ErrorCompanyFull = "Error_CompanyFull",

	/** English translation: "The organization is expired or disabled." */
	ErrorCompanyDisabledOrExpired = "Error_Company_Disabled_Or_Expired",

	/** English translation: "The custom content of the widget contains scripts. It is recommended to make sure that they can’t be used for malicious purposes." */
	ErrorCustomContentContainsScriptDetails = "Error_CustomContentContainsScriptDetails",

	/** English translation: "The custom fields listed here can’t be found in the target vault. To make sure that your custom content widgets operate correctly, please add the listed custom fields to the target hub." */
	ErrorDataFieldNotFoundDetails = "Error_DataFieldNotFoundDetails",

	/** English translation: "The file is locked." */
	ErrorFileLocked = "Error_FileLocked",

	/** English translation: "This user is an internal user in another organization." */
	ErrorInternalUserInAnotherCompany = "Error_InternalUserInAnotherCompany",

	/** English translation: "Please fill in the required fields." */
	ErrorRequiredFields = "Error_Required_Fields",

	/** English translation: "A file with the same name already exists in the target hub for the files listed here. Please rename or delete the files in the target hub to resolve this error." */
	ErrorRoomImportExportFileAlreadyExistsDetails = "Error_RoomImportExportFileAlreadyExistsDetails",

	/** English translation: "The anti-CSRF token is invalid." */
	ErrorSessionTokenInvalid = "Error_SessionTokenInvalid",

	/** English translation: "Please write a title in default language." */
	ErrorTitleRequiredDefaultLanguage = "Error_Title_Required_Default_Language",

	/** English translation: "This link is no longer valid." */
	ErrorUserValidationTokenInvalid = "Error_UserValidationTokenInvalid",

	/** English translation: "This name already exists." */
	ERRPATHNAMEALREADYEXISTS = "ERR_PATH_NAME_ALREADY_EXISTS",

	/** English translation: "Insufficient rights" */
	ExplorerInsufficientRights = "Explorer_Insufficient_Rights",

	/** English translation: "An error occurred while getting the files." */
	ExplorerLoadingError = "Explorer_Loading_Error",

	/** English translation: "The ZIP file is being created. In the notification center, you can see the status of the ZIP file and retrieve it." */
	ExplorerResourceZipStarted = "Explorer_ResourceZip_Started",

	/** English translation: "Here you can export hub configurations to a file." */
	ExportFilenameConfigurationDescription = "Export_Filename_Configuration_Description",

	/** English translation: "Export hub configurations" */
	ExportModalTitle = "Export_Modal_Title",

	/** English translation: "Configurations included in the exported file" */
	ExportSelectConfigurationDescription = "Export_Select_Configuration_Description",

	/** English translation: "{0} - Configurations" */
	ExtensionsConfigurationTitle = "Extensions_Configuration_Title",

	/** English translation: "Audit" */
	ExtensionAudit = "Extension_Audit",

	/** English translation: "Activities" */
	ExtensionAuditActivities = "Extension_Audit_Activities",

	/** English translation: "Filters" */
	ExtensionAuditFilters = "Extension_Audit_Filters",

	/** English translation: "No record available" */
	ExtensionAuditNoRecordAvailable = "Extension_Audit_No_Record_Available",

	/** English translation: "Select event" */
	ExtensionAuditSelectEvent = "Extension_Audit_Select_Event",

	/** English translation: "Select a hub" */
	ExtensionAuditSelectHub = "Extension_Audit_Select_Hub",

	/** English translation: "Select user" */
	ExtensionAuditSelectUser = "Extension_Audit_Select_User",

	/** English translation: "Updated by" */
	ExtensionAuditUpdatedBy = "Extension_Audit_Updated_By",

	/** English translation: "The vault has been reached." */
	ExtensionCheckSuccess = "Extension_Check_Success",

	/** English translation: "Clear cache" */
	ExtensionClearCache = "Extension_Clear_Cache",

	/** English translation: "The cache was cleared." */
	ExtensionClearCacheSuccess = "Extension_Clear_Cache_Success",

	/** English translation: "A certificate is set for this configuration. Click Reset to delete this certificate and add a new one." */
	ExtensionConfigurationCertificateInUse = "Extension_Configuration_Certificate_In_Use",

	/** English translation: "An error occurred in the authentication. Make sure that your configuration is correct and try again." */
	ExtensionConfigurationIdentityProviderAuthenticationFailed = "Extension_Configuration_Identity_Provider_Authentication_Failed",

	/** English translation: "Connect" */
	ExtensionConfigurationIdentityProviderConnect = "Extension_Configuration_Identity_Provider_Connect",

	/** English translation: "Connected as" */
	ExtensionConfigurationIdentityProviderConnectedAs = "Extension_Configuration_Identity_Provider_Connected_As",

	/** English translation: "Disconnect" */
	ExtensionConfigurationIdentityProviderDisconnect = "Extension_Configuration_Identity_Provider_Disconnect",

	/** English translation: "The configuration and connection are complete.<br />To change the configuration, you must disconnect the user account with the <strong>{0}</strong> button." */
	ExtensionConfigurationIdentityProviderInUse = "Extension_Configuration_Identity_Provider_In_Use",

	/** English translation: "The authentication tokens have expired. To create new tokens, click <strong>{0}</strong>, and then <strong>{1}</strong>." */
	ExtensionConfigurationIdentityProviderTokensExpired = "Extension_Configuration_Identity_Provider_Tokens_Expired",

	/** English translation: "The configuration is used in one or more widgets." */
	ExtensionConfigurationInUse = "Extension_Configuration_In_Use",

	/** English translation: "When you disconnect the current user account, the hubs and widgets that use this configuration will stop operating.<br /><br />Number of affected hubs: {0}<br />Number of affected widgets: {1}<br /><br />Do you want to disconnect the user account?" */
	ExtensionConfigurationInUseRevokeTokenConfirmText = "Extension_Configuration_In_Use_Revoke_Token_Confirm_Text",

	/** English translation: "linked to the template" */
	ExtensionConfigurationInUseRevokeTokenLinkedHubs = "Extension_Configuration_In_Use_Revoke_Token_Linked_Hubs",

	/** English translation: "Configuration name" */
	ExtensionConfigurationName = "Extension_Configuration_Name",

	/** English translation: "This operation will disconnect the current user account from the identity provider.<br /><br />Do you want to continue?" */
	ExtensionConfigurationNotInUseRevokeTokenConfirmText = "Extension_Configuration_Not_In_Use_Revoke_Token_Confirm_Text",

	/** English translation: "Reset" */
	ExtensionConfigurationReset = "Extension_Configuration_Reset",

	/** English translation: "Disconnect user account" */
	ExtensionConfigurationRevokeTokenConfirmTitle = "Extension_Configuration_Revoke_Token_Confirm_Title",

	/** English translation: "Security" */
	ExtensionConfigurationSecurityOptions = "Extension_Configuration_Security_Options",

	/** English translation: "The configuration is used in linked hubs. Are you sure that you want to change it?" */
	ExtensionConfigurationWarningLinkedHubs = "Extension_Configuration_Warning_Linked_Hubs",

	/** English translation: "Are you sure you want to delete the configuration : {0} ?" */
	ExtensionDeleteConfigurationConfirm = "Extension_Delete_Configuration_Confirm",

	/** English translation: "This integration is used in a widget." */
	ExtensionInUse = "Extension_In_Use",

	/** English translation: "Mode" */
	ExtensionModeLabel = "Extension_Mode_Label",

	/** English translation: "New configuration" */
	ExtensionNewConfiguration = "Extension_New_Configuration",

	/** English translation: "For optimal performance and user experience, enable gRPC on the M-Files server." */
	ExtensionWarningDegradedMode = "Extension_Warning_Degraded_Mode",

	/** English translation: "External organization name" */
	ExternalCompanyName = "External_Company_Name",

	/** English translation: "Show external users as anonymous users in file activity" */
	ExternalUserAsAnonymous = "External_User_As_Anonymous",

	/** English translation: "Created on" */
	FileIndexCreatedOn = "FileIndex_Created_On",

	/** English translation: "Reset" */
	FileIndexReset = "FileIndex_Reset",

	/** English translation: "Updated by" */
	FileActivityUpdatedBy = "File_Activity_Updated_By",

	/** English translation: "File index" */
	FileIndex = "File_Index",

	/** English translation: "Advanced index" */
	FileIndexAdvanced = "File_Index_Advanced",

	/** English translation: "Custom selection" */
	FileIndexCustomSelection = "File_Index_Custom_Selection",

	/** English translation: "Enable file index view" */
	FileIndexEnable = "File_Index_Enable",

	/** English translation: "Enable file index view for Hubshare" */
	FileIndexEnableForDocstore = "File_Index_Enable_For_Docstore",

	/** English translation: "It can take a few minutes to expand all the elements." */
	FileIndexExpandAllMessage = "File_Index_Expand_All_Message",

	/** English translation: "Export index" */
	FileIndexExport = "File_Index_Export",

	/** English translation: "Index" */
	FileIndexIndex = "File_Index_Index",

	/** English translation: "You can set the sort order for your files and folders." */
	FileIndexInformation = "File_Index_Information",

	/** English translation: "Max file count" */
	FileMax = "File_Max",

	/** English translation: "No preview available. The file is empty." */
	FilePreviewFileIsEmpty = "File_Preview_File_Is_Empty",

	/** English translation: "Files types manager" */
	FileTypesManager = "File_Types_Manager",

	/** English translation: "Cancel upload" */
	FileUploadCancel = "File_Upload_Cancel",

	/** English translation: "Don't upload this file and go to the next one." */
	FileUploadCancelDesc = "File_Upload_Cancel_Desc",

	/** English translation: "Clear finished" */
	FileUploadClearFinished = "File_Upload_Clear_Finished",

	/** English translation: "Drag and drop files here or use the Browse button." */
	FileUploadDropzoneplaceholder = "File_Upload_Dropzone_placeholder",

	/** English translation: "Drop files here or " */
	FileUploadDropFilesHereOr = "File_Upload_Drop_Files_Here_Or",

	/** English translation: "Error during upload" */
	FileUploadError = "File_Upload_Error",

	/** English translation: "It isn't possible to add a file" */
	FileUploadErrorAddImpossible = "File_Upload_Error_Add_Impossible",

	/** English translation: "Another user has checked out this file." */
	FileUploadErrorCheckedOutAnotheruser = "File_Upload_Error_Checked_Out_Another_user",

	/** English translation: "Existing folder: content will be merged" */
	FileUploadErrorExistingFolder = "File_Upload_Error_Existing_Folder",

	/** English translation: "You don't have the right to upload a file." */
	FileUploadErrorFileCreationNotAllowed = "File_Upload_Error_File_Creation_Not_Allowed",

	/** English translation: "You don't have the right to create a folder." */
	FileUploadErrorFolderCreationNotAllowed = "File_Upload_Error_Folder_Creation_Not_Allowed",

	/** English translation: "Parent folder was not found." */
	FileUploadErrorFolderNotFound = "File_Upload_Error_Folder_Not_Found",

	/** English translation: "Illegal characters in path" */
	FileUploadErrorIllegalCaracterPath = "File_Upload_Error_Illegal_Caracter_Path",

	/** English translation: "There isn't enough storage space." */
	FileUploadErrorInsufficientStorage = "File_Upload_Error_Insufficient_Storage",

	/** English translation: "An internal server error occured. Please try again." */
	FileUploadErrorInternalServer = "File_Upload_Error_Internal_Server",

	/** English translation: "Upload limit is exceeded." */
	FileUploadErrorLimitExceeded = "File_Upload_Error_Limit_Exceeded",

	/** English translation: "You can't update the file because you have not checked it out." */
	FileUploadErrorNotCheckedOut = "File_Upload_Error_Not_Checked_Out",

	/** English translation: "The file is not checked out." */
	FileUploadErrorNotCheckedOutShort = "File_Upload_Error_Not_Checked_Out_Short",

	/** English translation: "The maximum size of the file is {0}." */
	FileUploadErrorSize = "File_Upload_Error_Size",

	/** English translation: "You don't have the right to update a file." */
	FileUploadErrorUpdateNotAllowed = "File_Upload_Error_Update_Not_Allowed",

	/** English translation: "You don't have the right to upload a file." */
	FileUploadErrorUploadNotAllowed = "File_Upload_Error_Upload_Not_Allowed",

	/** English translation: "You can't add a new non-official version." */
	FileUploadErrorVersioningCannotAddNonOfficial = "File_Upload_Error_Versioning_Cannot_Add_Non_Official",

	/** English translation: "You can't save more versions of this file." */
	FileUploadErrorVersioningMaxCountReached = "File_Upload_Error_Versioning_Max_Count_Reached",

	/** English translation: "Do this for all current conflicts" */
	FileUploadForAllConflicts = "File_Upload_For_All_Conflicts",

	/** English translation: "Do this for all non-updatable files" */
	FileUploadForAllNotUpdatable = "File_Upload_For_All_Not_Updatable",

	/** English translation: "A file named "{0}" is already in your documents. How would you like to continue?" */
	FileUploadNameConflictDesc = "File_Upload_Name_Conflict_Desc",

	/** English translation: "A file with this name is already in your documents." */
	FileUploadNameConflictTittle = "File_Upload_Name_Conflict_Tittle",

	/** English translation: "Upload and rename" */
	FileUploadRenameFile = "File_Upload_Rename_File",

	/** English translation: "Upload your file as a new file named "{0}"." */
	FileUploadRenameFileDesc = "File_Upload_Rename_File_Desc",

	/** English translation: "The maximum size of the file is {0}." */
	FileUploadSizeInfo = "File_Upload_Size_Info",

	/** English translation: "Update existing file" */
	FileUploadUpdateFile = "File_Upload_Update_File",

	/** English translation: "Replace existing file content and keep comments." */
	FileUploadUpdateFileDesc = "File_Upload_Update_File_Desc",

	/** English translation: "Replace file in all existing custom content." */
	FileUploadUpdateFileDescCustomContent = "File_Upload_Update_File_Desc_Custom_Content",

	/** English translation: "File viewer configuration" */
	FileViewerConfiguration = "File_Viewer_Configuration",

	/** English translation: "File viewer configuration updated" */
	FileViewerConfigurationUpdated = "File_Viewer_Configuration_Updated",

	/** English translation: "The file viewer is disabled." */
	FileViewerIsDisabled = "File_Viewer_Is_Disabled",

	/** English translation: "A folder named "{0}" is already in your documents. How would you like to continue?" */
	FolderNameConflictDesc = "Folder_Name_Conflict_Desc",

	/** English translation: "Forgot your password?" */
	ForgotPassword = "ForgotPassword",

	/** English translation: "All hubs" */
	FormsAllHubs = "Forms_All_Hubs",

	/** English translation: "Available inputs" */
	FormsAvailableAreaTitle = "Forms_Available_Area_Title",

	/** English translation: "Cancel" */
	FormsCancelButton = "Forms_Cancel_Button",

	/** English translation: "Can be shown in" */
	FormsCanBeVisibleIn = "Forms_Can_Be_Visible_In",

	/** English translation: "Color" */
	FormsChartColor = "Forms_Chart_Color",

	/** English translation: "Filter" */
	FormsChartFilter = "Forms_Chart_Filter",

	/** English translation: "Label" */
	FormsChartLabel = "Forms_Chart_Label",

	/** English translation: "No multiple choice fields were found to create graphics. Please add the fields or try another questionnaire." */
	FormsChartNoCorrectInput = "Forms_Chart_No_Correct_Input",

	/** English translation: "No conditions are applied" */
	FormsChartNoParentCondition = "Forms_Chart_No_Parent_Condition",

	/** English translation: "Average" */
	FormsChartNumberConfigurationAverage = "Forms_Chart_Number_Configuration_Average",

	/** English translation: "Sum" */
	FormsChartNumberConfigurationSum = "Forms_Chart_Number_Configuration_Sum",

	/** English translation: "Option" */
	FormsChartOption = "Forms_Chart_Option",

	/** English translation: "Parent filter" */
	FormsChartParentFilter = "Forms_Chart_Parent_Filter",

	/** English translation: "Preview" */
	FormsChartPreview = "Forms_Chart_Preview",

	/** English translation: "Property" */
	FormsChartProperty = "Forms_Chart_Property",

	/** English translation: "Property value" */
	FormsChartPropertyValue = "Forms_Chart_Property_Value",

	/** English translation: "Select all" */
	FormsChartSelectAll = "Forms_Chart_Select_All",

	/** English translation: "Select all dynamically" */
	FormsChartSelectAllDynamic = "Forms_Chart_Select_All_Dynamic",

	/** English translation: "Serie configuration" */
	FormsChartSerieConfiguration = "Forms_Chart_Serie_Configuration",

	/** English translation: "Unselect all" */
	FormsChartUnselectAll = "Forms_Chart_Unselect_All",

	/** English translation: "Use parents conditions" */
	FormsChartUseParentCondition = "Forms_Chart_Use_Parent_Condition",

	/** English translation: "Your questionnaire" */
	FormsCreationAreaTitle = "Forms_Creation_Area_Title",

	/** English translation: "Validate your questionnaire" */
	FormsCreationCheckErrorButton = "Forms_Creation_Check_Error_Button",

	/** English translation: "Questionnaire title" */
	FormsCreationWidgetTitle = "Forms_Creation_Widget_Title",

	/** English translation: "Missing configuration" */
	FormsErrorMissing = "Forms_Error_Missing",

	/** English translation: "Please complete the configuration of the hub default language." */
	FormsErrorMissingDefaultHub = "Forms_Error_Missing_Default_Hub",

	/** English translation: "input n°" */
	FormsErrorPosition = "Forms_Error_Position",

	/** English translation: "Questionnaire title" */
	FormsErrorTitle = "Forms_Error_Title",

	/** English translation: "Add image" */
	FormsInputAddImage = "Forms_Input_Add_Image",

	/** English translation: "Action" */
	FormsInputConfigurationAction = "Forms_Input_Configuration_Action",

	/** English translation: "Add option" */
	FormsInputConfigurationAddOption = "Forms_Input_Configuration_Add_Option",

	/** English translation: "Default value" */
	FormsInputConfigurationDefault = "Forms_Input_Configuration_Default",

	/** English translation: "Hide" */
	FormsInputConfigurationHidden = "Forms_Input_Configuration_Hidden",

	/** English translation: "Hide this content when an answer is created" */
	FormsInputConfigurationHiddenDescription = "Forms_Input_Configuration_Hidden_Description",

	/** English translation: "Label" */
	FormsInputConfigurationLabel = "Forms_Input_Configuration_Label",

	/** English translation: "Allow multi-select" */
	FormsInputConfigurationMultiSelect = "Forms_Input_Configuration_Multi_Select",

	/** English translation: "Name" */
	FormsInputConfigurationName = "Forms_Input_Configuration_Name",

	/** English translation: "Parent" */
	FormsInputConfigurationParent = "Forms_Input_Configuration_Parent",

	/** English translation: "Reset to default value" */
	FormsInputConfigurationRemoveDefault = "Forms_Input_Configuration_Remove_Default",

	/** English translation: "Required" */
	FormsInputConfigurationRequired = "Forms_Input_Configuration_Required",

	/** English translation: "Show as stepper" */
	FormsInputConfigurationStepper = "Forms_Input_Configuration_Stepper",

	/** English translation: "Show the order of the steps" */
	FormsInputConfigurationStepperOrderDisplay = "Forms_Input_Configuration_Stepper_Order_Display",

	/** English translation: "Tree options" */
	FormsInputConfigurationSubList = "Forms_Input_Configuration_SubList",

	/** English translation: "You can't set a child as a parent." */
	FormsInputConfigurationSubListCannotSelectChildAsParent = "Forms_Input_Configuration_SubList_Cannot_Select_Child_As_Parent",

	/** English translation: "You can't set the option as its own parent." */
	FormsInputConfigurationSubListCannotSelectSameOption = "Forms_Input_Configuration_SubList_Cannot_Select_Same_Option",

	/** English translation: "You must set the number of allowed answers as 1 or higher." */
	FormsInputConfigurationSubListCannotSetNegativeValue = "Forms_Input_Configuration_SubList_Cannot_Set_Negative_Value",

	/** English translation: "Disable the selection of parents" */
	FormsInputConfigurationSubListDisableParent = "Forms_Input_Configuration_SubList_Disable_Parent",

	/** English translation: "Number of allowed answers" */
	FormsInputConfigurationSubListResponseNumberAllowed = "Forms_Input_Configuration_SubList_Response_Number_Allowed",

	/** English translation: "Input configuration" */
	FormsInputConfigurationTitle = "Forms_Input_Configuration_Title",

	/** English translation: "Values" */
	FormsInputConfigurationValue = "Forms_Input_Configuration_Value",

	/** English translation: "is required." */
	FormsInputRequired = "Forms_Input_Required",

	/** English translation: "When you have completed the questionnaire, the file is uploaded." */
	FormsInputUploadWaiting = "Forms_Input_Upload_Waiting",

	/** English translation: "Background color" */
	FormsInputValueConfigurationBackgroundColor = "Forms_Input_Value_Configuration_Background_Color",

	/** English translation: "Font color" */
	FormsInputValueConfigurationColor = "Forms_Input_Value_Configuration_Color",

	/** English translation: "Preview" */
	FormsPreview = "Forms_Preview",

	/** English translation: "Can add new fields" */
	FormsRelativeAddNewContents = "Forms_Relative_Add_New_Contents",

	/** English translation: "Can add answers" */
	FormsRelativeAddResponse = "Forms_Relative_Add_Response",

	/** English translation: "All answers" */
	FormsRelativeAll = "Forms_Relative_All",

	/** English translation: "My answers" */
	FormsRelativeAuthorOnly = "Forms_Relative_Author_Only",

	/** English translation: "Can archive responses" */
	FormsRelativeCanArchiveResponse = "Forms_Relative_Can_Archive_Response",

	/** English translation: "Show comments" */
	FormsRelativeCanDisplayComments = "Forms_Relative_Can_Display_Comments",

	/** English translation: "Can edit data" */
	FormsRelativeCanEditData = "Forms_Relative_Can_Edit_Data",

	/** English translation: "Can edit origin data" */
	FormsRelativeCanEditParentData = "Forms_Relative_Can_Edit_Parent_Data",

	/** English translation: "All conditions will be aggregated and valid." */
	FormsRelativeConditionDescription = "Forms_Relative_Condition_Description",

	/** English translation: "Data" */
	FormsRelativeConfigurationData = "Forms_Relative_Configuration_Data",

	/** English translation: "Form" */
	FormsRelativeConfigurationForm = "Forms_Relative_Configuration_Form",

	/** English translation: "Properties" */
	FormsRelativeConfigurationProperties = "Forms_Relative_Configuration_Properties",

	/** English translation: "Answer details" */
	FormsRelativeDetailResponse = "Forms_Relative_Detail_Response",

	/** English translation: "Show records from" */
	FormsRelativeDisplayRecordsFrom = "Forms_Relative_Display_Records_From",

	/** English translation: "Active" */
	FormsRelativeFilterActive = "Forms_Relative_Filter_Active",

	/** English translation: "All" */
	FormsRelativeFilterAll = "Forms_Relative_Filter_All",

	/** English translation: "Archived" */
	FormsRelativeFilterArchived = "Forms_Relative_Filter_Archived",

	/** English translation: "New answer" */
	FormsRelativeNewResponse = "Forms_Relative_New_Response",

	/** English translation: "Origin form data to include" */
	FormsRelativeOriginalData = "Forms_Relative_Original_Data",

	/** English translation: "Visibility" */
	FormsRelativePropertiesVisibility = "Forms_Relative_Properties_Visibility",

	/** English translation: "Answer in which I participated" */
	FormsRelativeRelatedAuthor = "Forms_Relative_Related_Author",

	/** English translation: "Select all parent fields" */
	FormsRelativeSelectAllContentParent = "Forms_Relative_Select_All_Content_Parent",

	/** English translation: "Select an existing questionnaire" */
	FormsRelativeSelectForm = "Forms_Relative_Select_Form",

	/** English translation: "Filter by groups or users" */
	FormsRelativeSelectGroupsOrUser = "Forms_Relative_Select_Groups_Or_User",

	/** English translation: "Send new answer" */
	FormsRelativeSendNewResponse = "Forms_Relative_Send_New_Response",

	/** English translation: "Unselect all parent fields" */
	FormsRelativeUnselectAllContentParent = "Forms_Relative_Unselect_All_Content_Parent",

	/** English translation: "Your answer couldn't be sent. Please try again." */
	FormsResponseError = "Forms_Response_Error",

	/** English translation: "The files must have unique names." */
	FormsResponseErrorNonUniqueFile = "Forms_Response_Error_Non_Unique_File",

	/** English translation: "Your answer is now sent." */
	FormsResponseSuccess = "Forms_Response_Success",

	/** English translation: "Your answer is uploading." */
	FormsResponseUploadInfo = "Forms_Response_Upload_Info",

	/** English translation: "Save" */
	FormsSaveButton = "Forms_Save_Button",

	/** English translation: "Stepper" */
	FormsStepper = "Forms_Stepper",

	/** English translation: "This hub and sub-hubs" */
	FormsThisHubAndSubHub = "Forms_This_Hub_And_Sub_Hub",

	/** English translation: "This hub only" */
	FormsThisHubOnly = "Forms_This_Hub_Only",

	/** English translation: "Author" */
	FormAuthor = "Form_Author",

	/** English translation: "Author (email)" */
	FormAuthorEmail = "Form_Author_Email",

	/** English translation: "Author (profile)" */
	FormAuthorProfile = "Form_Author_Profile",

	/** English translation: "Please give valid data." */
	FormChartInvalidPreview = "Form_Chart_Invalid_Preview",

	/** English translation: "Translation missing" */
	FormChartMissingTranslation = "Form_Chart_Missing_Translation",

	/** English translation: "You must select content and value." */
	FormChartSelectAContent = "Form_Chart_Select_A_Content",

	/** English translation: "Add condition" */
	FormConditionAddNewCondition = "Form_Condition_Add_New_Condition",

	/** English translation: "Different from" */
	FormConditionKindDifferent = "Form_Condition_Kind_Different",

	/** English translation: "Empty" */
	FormConditionKindEmpty = "Form_Condition_Kind_Empty",

	/** English translation: "Equal to" */
	FormConditionKindEqual = "Form_Condition_Kind_Equal",

	/** English translation: "Condition type" */
	FormConditionKindLabel = "Form_Condition_Kind_Label",

	/** English translation: "Less than" */
	FormConditionKindLower = "Form_Condition_Kind_Lower",

	/** English translation: "Greater than" */
	FormConditionKindSuperior = "Form_Condition_Kind_Superior",

	/** English translation: "Condition" */
	FormConditionReadOnlyCondition = "Form_Condition_Read_Only_Condition",

	/** English translation: "Condition value" */
	FormConditionValueLabel = "Form_Condition_Value_Label",

	/** English translation: "Creation date" */
	FormCreationDate = "Form_Creation_Date",

	/** English translation: "Drag and drop items from the left column to the right one to add fields to your questionnaire." */
	FormCreationExplained = "Form_Creation_Explained",

	/** English translation: "No input is given" */
	FormErrorNoInput = "Form_Error_No_Input",

	/** English translation: "No parent questionnaire is selected" */
	FormErrorSelectedParent = "Form_Error_Selected_Parent",

	/** English translation: "Hub" */
	FormHub = "Form_Hub",

	/** English translation: "This value is used in some conditions." */
	FormInputContentValueUsedInConditions = "Form_Input_Content_Value_Used_In_Conditions",

	/** English translation: "This value is used in some responses and conditions." */
	FormInputContentValueUsedInConditionsAndResponses = "Form_Input_Content_Value_Used_In_Conditions_And_Responses",

	/** English translation: "This value is used in some responses." */
	FormInputContentValueUsedInResponses = "Form_Input_Content_Value_Used_In_Responses",

	/** English translation: "Author" */
	FormInputKindAuthor = "Form_Input_Kind_Author",

	/** English translation: "Author email" */
	FormInputKindAuthorEmail = "Form_Input_Kind_AuthorEmail",

	/** English translation: "Author profile" */
	FormInputKindAuthorProfile = "Form_Input_Kind_AuthorProfile",

	/** English translation: "Checkbox" */
	FormInputKindCheckbox = "Form_Input_Kind_Checkbox",

	/** English translation: "Created hub" */
	FormInputKindCreatedHub = "Form_Input_Kind_CreatedHub",

	/** English translation: "Created widget" */
	FormInputKindCreatedWidget = "Form_Input_Kind_CreatedWidget",

	/** English translation: "Creation date" */
	FormInputKindCreationDate = "Form_Input_Kind_CreationDate",

	/** English translation: "Divider" */
	FormInputKindDivider = "Form_Input_Kind_Divider",

	/** English translation: "File" */
	FormInputKindFile = "Form_Input_Kind_File",

	/** English translation: "Image" */
	FormInputKindImage = "Form_Input_Kind_Image",

	/** English translation: "Number" */
	FormInputKindNumber = "Form_Input_Kind_Number",

	/** English translation: "Radio" */
	FormInputKindRadio = "Form_Input_Kind_Radio",

	/** English translation: "Reference" */
	FormInputKindReference = "Form_Input_Kind_Reference",

	/** English translation: "Select" */
	FormInputKindSelect = "Form_Input_Kind_Select",

	/** English translation: "Simple note" */
	FormInputKindSimplenote = "Form_Input_Kind_Simplenote",

	/** English translation: "Text" */
	FormInputKindText = "Form_Input_Kind_Text",

	/** English translation: "Text area" */
	FormInputKindTextarea = "Form_Input_Kind_Textarea",

	/** English translation: "Update date" */
	FormInputKindUpdateDate = "Form_Input_Kind_UpdateDate",

	/** English translation: "Update date" */
	FormInputKindUpdatedDate = "Form_Input_Kind_UpdatedDate",

	/** English translation: "Void" */
	FormInputKindVoid = "Form_Input_Kind_Void",

	/** English translation: "Reference" */
	FormReference = "Form_Reference",

	/** English translation: "Hiding responses to children's forms" */
	FormRelativeHideResponseToChildrenForm = "Form_Relative_Hide_Response_To_Children_Form",

	/** English translation: "Updated date" */
	FormUpdatedDate = "Form_Updated_Date",

	/** English translation: "This group doesn't have access to the module." */
	GroupNotAccessModule = "Group_Not_Access_Module",

	/** English translation: "Hide advanced settings" */
	HideAdvancedSettings = "Hide_Advanced_Settings",

	/** English translation: "File created" */
	HistoryEventFileCreation = "HistoryEvent_FileCreation",

	/** English translation: "File permanently deleted" */
	HistoryEventFileDeletion = "HistoryEvent_FileDeletion",

	/** English translation: "File downloaded" */
	HistoryEventFileDownload = "HistoryEvent_FileDownload",

	/** English translation: "File moved" */
	HistoryEventFileMove = "HistoryEvent_FileMove",

	/** English translation: "File printed" */
	HistoryEventFilePrint = "HistoryEvent_FilePrint",

	/** English translation: "File opened" */
	HistoryEventFileRead = "HistoryEvent_FileRead",

	/** English translation: "File deleted" */
	HistoryEventFileRecycle = "HistoryEvent_FileRecycle",

	/** English translation: "File renamed" */
	HistoryEventFileRename = "HistoryEvent_FileRename",

	/** English translation: "File restored" */
	HistoryEventFileRestore = "HistoryEvent_FileRestore",

	/** English translation: "File version created" */
	HistoryEventFileVersionCreation = "HistoryEvent_FileVersionCreation",

	/** English translation: "File version deleted" */
	HistoryEventFileVersionDeletion = "HistoryEvent_FileVersionDeletion",

	/** English translation: "Official file version updated" */
	HistoryEventFileVersionOfficialChange = "HistoryEvent_FileVersionOfficialChange",

	/** English translation: "File version overwritten" */
	HistoryEventFileVersionWrite = "HistoryEvent_FileVersionWrite",

	/** English translation: "File updated" */
	HistoryEventFileWrite = "HistoryEvent_FileWrite",

	/** English translation: "Folder created" */
	HistoryEventFolderCreation = "HistoryEvent_FolderCreation",

	/** English translation: "Folder permanently deleted" */
	HistoryEventFolderDeletion = "HistoryEvent_FolderDeletion",

	/** English translation: "Folder downloaded" */
	HistoryEventFolderDownload = "HistoryEvent_FolderDownload",

	/** English translation: "Folder moved" */
	HistoryEventFolderMove = "HistoryEvent_FolderMove",

	/** English translation: "Folder opened" */
	HistoryEventFolderRead = "HistoryEvent_FolderRead",

	/** English translation: "Folder deleted" */
	HistoryEventFolderRecycle = "HistoryEvent_FolderRecycle",

	/** English translation: "Folder renamed" */
	HistoryEventFolderRename = "HistoryEvent_FolderRename",

	/** English translation: "Folder restored" */
	HistoryEventFolderRestore = "HistoryEvent_FolderRestore",

	/** English translation: "Undefined action" */
	HistoryEventUndefined = "HistoryEvent_Undefined",

	/** English translation: "There was an error in the server." */
	HttpErrorDesc = "HttpError_Desc",

	/** English translation: "The URL was not found on the server." */
	HttpErrorDesc404 = "HttpError_Desc_404",

	/** English translation: "{0}" */
	HttpErrorTitle = "HttpError_Title",

	/** English translation: "The server is taking too long to respond. Please try again later." */
	HttpXhrTimeoutError = "Http_Xhr_Timeout_Error",

	/** English translation: "Enable checking files in and out" */
	HubActivateCheckOut = "Hub_Activate_Check_Out",

	/** English translation: "Overview" */
	HubActivitiesGeneral = "Hub_Activities_General",

	/** English translation: "M-Files widgets" */
	HubActivitiesMFilesWidgetActivities = "Hub_Activities_MFiles_Widget_Activities",

	/** English translation: "Background image for hub" */
	HubBackgroundImageLabel = "Hub_Background_Image_Label",

	/** English translation: "Background image for login page" */
	HubBackgroundImageLoginLabel = "Hub_Background_Image_Login_Label",

	/** English translation: "Manage social rights" */
	HubBlogManageRights = "Hub_Blog_Manage_Rights",

	/** English translation: "Social rights" */
	HubBlogRight = "Hub_Blog_Right",

	/** English translation: "Add calendar" */
	HubCalendarAddCalendar = "Hub_Calendar_Add_Calendar",

	/** English translation: "Add event" */
	HubCalendarAddEvent = "Hub_Calendar_Add_Event",

	/** English translation: "Assigned to" */
	HubCalendarAssignedTo = "Hub_Calendar_Assigned_To",

	/** English translation: "Available calendars" */
	HubCalendarAvailableCalendars = "Hub_Calendar_Available_Calendars",

	/** English translation: "Choose type image" */
	HubCalendarChooseTypeImage = "Hub_Calendar_Choose_Type_Image",

	/** English translation: "Delete calendar" */
	HubCalendarDeleteCalendar = "Hub_Calendar_Delete_Calendar",

	/** English translation: "Do you want to delete this calendar?" */
	HubCalendarDeleteCalendarConfirm = "Hub_Calendar_Delete_Calendar_Confirm",

	/** English translation: "Delete event" */
	HubCalendarDeleteEvent = "Hub_Calendar_Delete_Event",

	/** English translation: "Do you want to delete this event?" */
	HubCalendarDeleteEventConfirm = "Hub_Calendar_Delete_Event_Confirm",

	/** English translation: "Edit calendar" */
	HubCalendarEditCalendar = "Hub_Calendar_Edit_Calendar",

	/** English translation: "Edit event" */
	HubCalendarEditEvent = "Hub_Calendar_Edit_Event",

	/** English translation: "Event" */
	HubCalendarEvent = "Hub_Calendar_Event",

	/** English translation: "Event details" */
	HubCalendarEventDetails = "Hub_Calendar_Event_Details",

	/** English translation: "More options in the project module" */
	HubCalendarMoreOptionsInProjectModule = "Hub_Calendar_More_Options_In_Project_Module",

	/** English translation: "No calendars" */
	HubCalendarNoCalendar = "Hub_Calendar_No_Calendar",

	/** English translation: "No events" */
	HubCalendarNoEvent = "Hub_Calendar_No_Event",

	/** English translation: "Open in calendar module" */
	HubCalendarOpenInCalendarModule = "Hub_Calendar_Open_In_Calendar_Module",

	/** English translation: "Select calendar" */
	HubCalendarSelectCalendar = "Hub_Calendar_Select_Calendar",

	/** English translation: "Task details" */
	HubCalendarTaskDetails = "Hub_Calendar_Task_Details",

	/** English translation: "You can not delete this type because it is in use." */
	HubCalendarTypeIsUsed = "Hub_Calendar_Type_Is_Used",

	/** English translation: "My events" */
	HubCalendarWidgetTitle = "Hub_Calendar_Widget_Title",

	/** English translation: "Checked-out files" */
	HubCheckedOutFiles = "Hub_Checked_Out_Files",

	/** English translation: "Choose location of the hub" */
	HubChooseLocation = "Hub_Choose_Location",

	/** English translation: "Hub configuration" */
	HubConfiguration = "Hub_Configuration",

	/** English translation: "Colors configuration" */
	HubConfigurationColoursTitleSection = "Hub_Configuration_Colours_Title_Section",

	/** English translation: "Default date format" */
	HubConfigurationDefaultDateFormat = "Hub_Configuration_Default_Date_Format",

	/** English translation: "Absolute (for example, 20/4/2024)" */
	HubConfigurationDefaultDateFormatAbsolute = "Hub_Configuration_Default_Date_Format_Absolute",

	/** English translation: "Relative (for example, 3 weeks ago)" */
	HubConfigurationDefaultDateFormatRelative = "Hub_Configuration_Default_Date_Format_Relative",

	/** English translation: "Default sorting in {0} module" */
	HubConfigurationDefaultSortingInFilesModule = "Hub_Configuration_Default_Sorting_In_Files_Module",

	/** English translation: "Files configuration" */
	HubConfigurationFilesTitleSection = "Hub_Configuration_Files_Title_Section",

	/** English translation: "Use the selected sorting for" */
	HubConfigurationForceSelectedSortingFor = "Hub_Configuration_Force_Selected_Sorting_For",

	/** English translation: "The short name gives you a link to the hub:
https://{0}/#/Hub/{1}" */
	HubConfigurationGeneralHubShortNamePopoverText = "Hub_Configuration_General_HubShortName_Popover_Text",

	/** English translation: "Short name" */
	HubConfigurationGeneralHubShortNamePopoverTitle = "Hub_Configuration_General_HubShortName_Popover_Title",

	/** English translation: "Change the title that is shown when there is no logo." */
	HubConfigurationGeneralHubTitlePopoverText = "Hub_Configuration_General_HubTitle_Popover_Text",

	/** English translation: "You must select an item from the list." */
	HubConfigurationGeneralSelectLocationNoSelection = "Hub_Configuration_General_Select_Location_No_Selection",

	/** English translation: "Public hub registry" */
	HubConfigurationGeneralUserSection = "Hub_Configuration_General_User_Section",

	/** English translation: "Pictures configuration" */
	HubConfigurationPicturesTitleSection = "Hub_Configuration_Pictures_Title_Section",

	/** English translation: "Configuration and style" */
	HubConfigurationTitle = "Hub_Configuration_Title",

	/** English translation: "Hub message configuration" */
	HubConfigMessageModalConfiguration = "Hub_Config_Message_Modal_Configuration",

	/** English translation: "Customizable validation button" */
	HubConfigMessageModalConfigurationButtonInput = "Hub_Config_Message_Modal_Configuration_Button_Input",

	/** English translation: "Default value: "I have read and accepted the terms and conditions"" */
	HubConfigMessageModalConfigurationCheckboxDefault = "Hub_Config_Message_Modal_Configuration_Checkbox_Default",

	/** English translation: "I have read and accepted the terms and conditions." */
	HubConfigMessageModalConfigurationCheckboxDefaultValue = "Hub_Config_Message_Modal_Configuration_Checkbox_Default_Value",

	/** English translation: "Confirmation checkbox" */
	HubConfigMessageModalConfigurationCheckboxInput = "Hub_Config_Message_Modal_Configuration_Checkbox_Input",

	/** English translation: "Content" */
	HubConfigMessageModalConfigurationContentInput = "Hub_Config_Message_Modal_Configuration_Content_Input",

	/** English translation: "Default value: Accept" */
	HubConfigMessageModalConfigurationDefaultValue = "Hub_Config_Message_Modal_Configuration_Default_Value",

	/** English translation: "Unavailable for organization-level hubs" */
	HubConfigMessageModalConfigurationExceptRoot = "Hub_Config_Message_Modal_Configuration_Except_Root",

	/** English translation: "Logo" */
	HubConfigMessageModalConfigurationLogoInput = "Hub_Config_Message_Modal_Configuration_Logo_Input",

	/** English translation: "All" */
	HubConfigMessageModalConfigurationLogoInputAll = "Hub_Config_Message_Modal_Configuration_Logo_Input_All",

	/** English translation: "Organization" */
	HubConfigMessageModalConfigurationLogoInputCompany = "Hub_Config_Message_Modal_Configuration_Logo_Input_Company",

	/** English translation: "Hub" */
	HubConfigMessageModalConfigurationLogoInputHub = "Hub_Config_Message_Modal_Configuration_Logo_Input_Hub",

	/** English translation: "None" */
	HubConfigMessageModalConfigurationLogoInputNone = "Hub_Config_Message_Modal_Configuration_Logo_Input_None",

	/** English translation: "Title" */
	HubConfigMessageModalConfigurationMainTitleInput = "Hub_Config_Message_Modal_Configuration_Main_Title_Input",

	/** English translation: "Preview" */
	HubConfigMessageModalConfigurationPreviewButton = "Hub_Config_Message_Modal_Configuration_Preview_Button",

	/** English translation: "Remove messages from the selected hubs and templates." */
	HubConfigMessageModalConfigurationRevertButton = "Hub_Config_Message_Modal_Configuration_Revert_Button",

	/** English translation: "Applied to" */
	HubConfigMessageModalConfigurationSectionApply = "Hub_Config_Message_Modal_Configuration_Section_Apply",

	/** English translation: "Configuration" */
	HubConfigMessageModalConfigurationSectionConfiguration = "Hub_Config_Message_Modal_Configuration_Section_Configuration",

	/** English translation: "Subtitle" */
	HubConfigMessageModalConfigurationSubtitleInput = "Hub_Config_Message_Modal_Configuration_Subtitle_Input",

	/** English translation: "Hub message configuration" */
	HubConfigMessageModalConfigurationTitle = "Hub_Config_Message_Modal_Configuration_Title",

	/** English translation: "Shows until user validation" */
	HubConfigMessageModalConfigurationTypeClickTime = "Hub_Config_Message_Modal_Configuration_Type_Click_Time",

	/** English translation: "Shown every time the hub is accessed" */
	HubConfigMessageModalConfigurationTypeEachTime = "Hub_Config_Message_Modal_Configuration_Type_Each_Time",

	/** English translation: "Shown the first time the hub is accessed" */
	HubConfigMessageModalConfigurationTypeFirstTime = "Hub_Config_Message_Modal_Configuration_Type_First_Time",

	/** English translation: "User list" */
	HubConfigMessageModalConfigurationUserList = "Hub_Config_Message_Modal_Configuration_User_List",

	/** English translation: "You must fill all the required inputs." */
	HubConfigMessageModalErrorMissingInputs = "Hub_Config_Message_Modal_Error_Missing_Inputs",

	/** English translation: "You must select at least one hub or template." */
	HubConfigMessageModalErrorMissingTemplatesHubs = "Hub_Config_Message_Modal_Error_Missing_Templates_Hubs",

	/** English translation: "Unchanged hubs or templates" */
	HubConfigMessageModalInfo = "Hub_Config_Message_Modal_Info",

	/** English translation: "Accept" */
	HubConfigMessageModalPreviewDefaultButton = "Hub_Config_Message_Modal_Preview_Default_Button",

	/** English translation: "Your changes have been saved." */
	HubConfigMessageModalSuccess = "Hub_Config_Message_Modal_Success",

	/** English translation: "You have not saved your changes. Do you want to continue without saving?" */
	HubConfirmQuitEditModeText = "Hub_Confirm_Quit_Edit_Mode_Text",

	/** English translation: "Exit edit mode" */
	HubConfirmQuitEditModeTitle = "Hub_Confirm_Quit_Edit_Mode_Title",

	/** English translation: "Convert to template" */
	HubConvertToTemplate = "Hub_Convert_To_Template",

	/** English translation: "Hub creator" */
	HubCreator = "Hub_Creator",

	/** English translation: "Current location" */
	HubCurrentLocation = "Hub_Current_Location",

	/** English translation: "Custom font content" */
	HubCustomFontContent = "Hub_Custom_Font_Content",

	/** English translation: "Custom font for content" */
	HubCustomFontText = "Hub_Custom_Font_Text",

	/** English translation: "Custom font for titles" */
	HubCustomFontTitle = "Hub_Custom_Font_Title",

	/** English translation: "The chart data couldn't be retrieved." */
	HubDashboardChartErrorData = "Hub_Dashboard_Chart_Error_Data",

	/** English translation: "Exit focus mode" */
	HubDashboardLeaveSharedLinkModeButton = "Hub_Dashboard_Leave_Shared_Link_Mode_Button",

	/** English translation: "Click here to see all content on the dashboard." */
	HubDashboardLeaveSharedLinkModeTooltip = "Hub_Dashboard_Leave_Shared_Link_Mode_Tooltip",

	/** English translation: "No widget" */
	HubDashboardNoWidget = "Hub_Dashboard_No_Widget",

	/** English translation: "New hub" */
	HubDefaultName = "Hub_Default_Name",

	/** English translation: "Show organization logo" */
	HubDisplayCompanyLogo = "Hub_Display_Company_Logo",

	/** English translation: "Files and blogs" */
	HubFilesAndBlogs = "Hub_Files_And_Blogs",

	/** English translation: "This group no longer belongs to the hub." */
	HubGroupNotInHub = "Hub_Group_Not_In_Hub",

	/** English translation: "This group no longer belongs to the template." */
	HubGroupNotInTemplate = "Hub_Group_Not_In_Template",

	/** English translation: "This group no longer has access to the calendar module." */
	HubGroupNoAccessCalendar = "Hub_Group_No_Access_Calendar",

	/** English translation: "This group no longer has access to the project module." */
	HubGroupNoAccessProject = "Hub_Group_No_Access_Project",

	/** English translation: "This group no longer has access to the module {0}." */
	HubGroupNoModuleAccess = "Hub_Group_No_Module_Access",

	/** English translation: "Header background color" */
	HubHeaderBackgroundColour = "Hub_Header_Background_Colour",

	/** English translation: "Show header icons in white" */
	HubHeaderTextColour = "Hub_Header_Text_Colour",

	/** English translation: "Hub location" */
	HubLocation = "Hub_Location",

	/** English translation: "This hub is not currently active." */
	HubLoginRoomNotActive = "Hub_Login_Room_Not_Active",

	/** English translation: "You can't access an active template when the template is being copied." */
	HubLoginTemplateActive = "Hub_Login_Template_Active",

	/** English translation: "Hub logo" */
	HubLogo = "Hub_Logo",

	/** English translation: "Main menu background color" */
	HubMainMenuBackgroundColour = "Hub_Main_Menu_Background_Colour",

	/** English translation: "Main menu text color" */
	HubMainMenuTextColour = "Hub_Main_Menu_Text_Colour",

	/** English translation: "Manage hub" */
	HubManage = "Hub_Manage",

	/** English translation: "Module disabled" */
	HubModuleDisabled = "Hub_Module_Disabled",

	/** English translation: "Hub name" */
	HubName = "Hub_Name",

	/** English translation: "The name can't be empty." */
	HubNameEmpty = "Hub_Name_Empty",

	/** English translation: "Info" */
	HubNotificationInfoTitle = "Hub_Notification_Info_Title",

	/** English translation: "Success" */
	HubNotificationSuccessTitle = "Hub_Notification_Success_Title",

	/** English translation: "Warning" */
	HubNotificationWarningTitle = "Hub_Notification_Warning_Title",

	/** English translation: "This hub is not accessible." */
	HubNotAccessible = "Hub_Not_Accessible",

	/** English translation: "This hub is not currently active." */
	HubNotActive = "Hub_Not_Active",

	/** English translation: "The hub is not created." */
	HubNotCreated = "Hub_Not_Created",

	/** English translation: "The hub can't be found. You are redirected to Hubshare." */
	HubNotFoundRedirecting = "Hub_Not_Found_Redirecting",

	/** English translation: "You don't have access to this hub." */
	HubNotInvited = "Hub_Not_Invited",

	/** English translation: "Page background color" */
	HubPageBackgroundColour = "Hub_Page_Background_Colour",

	/** English translation: "Projects" */
	HubProjects = "Hub_Projects",

	/** English translation: "Active projects" */
	HubProjectsActive = "Hub_Projects_Active",

	/** English translation: "Archived projects" */
	HubProjectsArchived = "Hub_Projects_Archived",

	/** English translation: "Add attachments" */
	HubProjectAddAttachments = "Hub_Project_Add_Attachments",

	/** English translation: "Add email reminder" */
	HubProjectAddEmailReminder = "Hub_Project_Add_Email_Reminder",

	/** English translation: "Add or import project" */
	HubProjectAddOrImportproject = "Hub_Project_Add_Or_Import_project",

	/** English translation: "Add project" */
	HubProjectAddProject = "Hub_Project_Add_Project",

	/** English translation: "Apply to all projects" */
	HubProjectApplyToAll = "Hub_Project_Apply_To_All",

	/** English translation: "Do you want to add the rights of this project to all projects?" */
	HubProjectApplyToAllConfirmation = "Hub_Project_Apply_To_All_Confirmation",

	/** English translation: "Archive project" */
	HubProjectArchive = "Hub_Project_Archive",

	/** English translation: "Assigned to" */
	HubProjectAssignedTo = "Hub_Project_Assigned_To",

	/** English translation: "Assign task" */
	HubProjectAssignTask = "Hub_Project_Assign_Task",

	/** English translation: "Assign users" */
	HubProjectAssignUsers = "Hub_Project_Assign_Users",

	/** English translation: "Show task numbers" */
	HubProjectAutoNumber = "Hub_Project_Auto_Number",

	/** English translation: "Please write a description for the board in default language." */
	HubProjectBoardErrorDescription = "Hub_Project_Board_Error_Description",

	/** English translation: "Please select a correct date." */
	HubProjectChooseCorectDateEmailReminder = "Hub_Project_Choose_Corect_Date_Email_Reminder",

	/** English translation: "Please select a correct frequency." */
	HubProjectChooseRightFrequencyEmailReminder = "Hub_Project_Choose_Right_Frequency_Email_Reminder",

	/** English translation: "Make sure that you select a correct frequency." */
	HubProjectChooseTheRightFrequencyEmailReminder = "Hub_Project_Choose_The_Right_Frequency_Email_Reminder",

	/** English translation: "Project configuration" */
	HubProjectConfiguration = "Hub_Project_Configuration",

	/** English translation: "Copy project link to clipboard" */
	HubProjectCopyToClipboard = "Hub_Project_Copy_To_Clipboard",

	/** English translation: "Project link is not copied" */
	HubProjectCopyToClipboardError = "Hub_Project_Copy_To_Clipboard_Error",

	/** English translation: "Project link is copied" */
	HubProjectCopyToClipboardSuccess = "Hub_Project_Copy_To_Clipboard_Success",

	/** English translation: "Create project" */
	HubProjectCreate = "Hub_Project_Create",

	/** English translation: "Create board" */
	HubProjectCreateBoard = "Hub_Project_Create_Board",

	/** English translation: "Create email reminder" */
	HubProjectCreateEmailReminder = "Hub_Project_Create_Email_Reminder",

	/** English translation: "Create your first project" */
	HubProjectCreateFirstProject = "Hub_Project_Create_First_Project",

	/** English translation: "Create your first task for this project" */
	HubProjectCreateFirstTask = "Hub_Project_Create_First_Task",

	/** English translation: "Create project" */
	HubProjectCreateProject = "Hub_Project_Create_Project",

	/** English translation: "Create subjects" */
	HubProjectCreateSubject = "Hub_Project_Create_Subject",

	/** English translation: "Create tag" */
	HubProjectCreateTag = "Hub_Project_Create_Tag",

	/** English translation: "Create tag group" */
	HubProjectCreateTagGroup = "Hub_Project_Create_Tag_Group",

	/** English translation: "Create task" */
	HubProjectCreateTask = "Hub_Project_Create_Task",

	/** English translation: "Create this reminder" */
	HubProjectCreateThisReminder = "Hub_Project_Create_This_Reminder",

	/** English translation: "Create your first board for this project" */
	HubProjectCreateYourFirstBoard = "Hub_Project_Create_Your_First_Board",

	/** English translation: "Task creator" */
	HubProjectCreatorOfTheTask = "Hub_Project_Creator_Of_The_Task",

	/** English translation: "Custom project rights" */
	HubProjectCustomRight = "Hub_Project_Custom_Right",

	/** English translation: "There are no projects in this hub. When you have created your first project, you can customize the user rights of a project." */
	HubProjectCustomRightNoProject = "Hub_Project_Custom_Right_No_Project",

	/** English translation: "Default project" */
	HubProjectDefaultProject = "Hub_Project_Default_Project",

	/** English translation: "Delete project" */
	HubProjectDelete = "Hub_Project_Delete",

	/** English translation: "Delete attachment" */
	HubProjectDeleteAttachments = "Hub_Project_Delete_Attachments",

	/** English translation: "If you delete this project, all associated elements are also deleted." */
	HubProjectDeleteConfirmMessageContent = "Hub_Project_Delete_Confirm_Message_Content",

	/** English translation: "Are you sure you want to delete this project?" */
	HubProjectDeleteConfirmTitle = "Hub_Project_Delete_Confirm_Title",

	/** English translation: "Delete subjects" */
	HubProjectDeleteSubject = "Hub_Project_Delete_Subject",

	/** English translation: "Delete task" */
	HubProjectDeleteTask = "Hub_Project_Delete_Task",

	/** English translation: "Do you want to delete this task?" */
	HubProjectDeleteTaskConfirm = "Hub_Project_Delete_Task_Confirm",

	/** English translation: "Edit board" */
	HubProjectEditBoard = "Hub_Project_Edit_Board",

	/** English translation: "Edit Project" */
	HubProjectEditProject = "Hub_Project_Edit_Project",

	/** English translation: "Edit tag" */
	HubProjectEditTag = "Hub_Project_Edit_Tag",

	/** English translation: "Edit tag group" */
	HubProjectEditTagGroup = "Hub_Project_Edit_Tag_Group",

	/** English translation: "Edit task" */
	HubProjectEditTask = "Hub_Project_Edit_Task",

	/** English translation: "This tab is available after the task is saved." */
	HubProjectEditTaskLockedTabInfo = "Hub_Project_Edit_Task_Locked_Tab_Info",

	/** English translation: "Email reminder" */
	HubProjectEmailReminder = "Hub_Project_Email_Reminder",

	/** English translation: "Email reminder created" */
	HubProjectEmailReminderCreated = "Hub_Project_Email_Reminder_Created",

	/** English translation: "The start date should be earlier than the end date." */
	HubProjectErrorDateBefore = "Hub_Project_Error_Date_Before",

	/** English translation: "A description in default language is required." */
	HubProjectErrorDescription = "Hub_Project_Error_Description",

	/** English translation: "The task couldn't be updated." */
	HubProjectErrorEditTask = "Hub_Project_Error_Edit_Task",

	/** English translation: "The color must be given in hexadecimal, for example #FFFF0000." */
	HubProjectErrorInvalidColor = "Hub_Project_Error_Invalid_Color",

	/** English translation: "The \"% completed\" input must be a number between 1 and 100." */
	HubProjectErrorInvalidCompleted = "Hub_Project_Error_Invalid_Completed",

	/** English translation: "The date must be valid." */
	HubProjectErrorInvalidDate = "Hub_Project_Error_Invalid_Date",

	/** English translation: "Email reminder couldn't be created" */
	HubProjectErrorInEmailReminderCreation = "Hub_Project_Error_In_Email_Reminder_Creation",

	/** English translation: "Maximum length:" */
	HubProjectErrorMaxLength = "Hub_Project_Error_Max_Length",

	/** English translation: "Please set a status for the task." */
	HubProjectErrorUndefinedStatus = "Hub_Project_Error_Undefined_Status",

	/** English translation: "Please write a title for the task in default language." */
	HubProjectErrorUndefinedTitle = "Hub_Project_Error_Undefined_Title",

	/** English translation: "Project templates" */
	HubProjectExistingTemplate = "Hub_Project_Existing_Template",

	/** English translation: "All tasks" */
	HubProjectFilterAllTasks = "Hub_Project_Filter_All_Tasks",

	/** English translation: "Done" */
	HubProjectFilterDone = "Hub_Project_Filter_Done",

	/** English translation: "Future tasks" */
	HubProjectFilterFutureTasks = "Hub_Project_Filter_Future_Tasks",

	/** English translation: "In progress" */
	HubProjectFilterInProgress = "Hub_Project_Filter_In_Progress",

	/** English translation: "Tasks in progress" */
	HubProjectFilterNotDone = "Hub_Project_Filter_Not_Done",

	/** English translation: "Past tasks" */
	HubProjectFilterPastTasks = "Hub_Project_Filter_Past_Tasks",

	/** English translation: "To-do" */
	HubProjectFilterToDo = "Hub_Project_Filter_To_Do",

	/** English translation: "Completed:" */
	HubProjectFormCompleted = "Hub_Project_Form_Completed",

	/** English translation: "Status:" */
	HubProjectFormStatus = "Hub_Project_Form_Status",

	/** English translation: "Frequency" */
	HubProjectFrequency = "Hub_Project_Frequency",

	/** English translation: "Frequency value" */
	HubProjectFrequencyValue = "Hub_Project_Frequency_Value",

	/** English translation: "Global project rights" */
	HubProjectGlobalRight = "Hub_Project_Global_Right",

	/** English translation: "Hide finished files" */
	HubProjectHideAllFinishedFiles = "Hub_Project_HideAllFinishedFiles",

	/** English translation: "Show time selector" */
	HubProjectImportDisplayTime = "Hub_Project_Import_Display_Time",

	/** English translation: "Set starting date" */
	HubProjectImportStartingDate = "Hub_Project_Import_Starting_Date",

	/** English translation: "You do not have the rights to do this operation." */
	HubProjectInsufficientRights = "Hub_Project_Insufficient_Rights",

	/** English translation: "Appointment" */
	HubProjectKindAppointment = "Hub_Project_Kind_Appointment",

	/** English translation: "Call" */
	HubProjectKindCall = "Hub_Project_Kind_Call",

	/** English translation: "Dinner" */
	HubProjectKindDiner = "Hub_Project_Kind_Diner",

	/** English translation: "Meeting" */
	HubProjectKindMeeting = "Hub_Project_Kind_Meeting",

	/** English translation: "Late tasks" */
	HubProjectLateTasks = "Hub_Project_Late_Tasks",

	/** English translation: "Email reminders" */
	HubProjectListOfEmailReminders = "Hub_Project_List_Of_Email_Reminders",

	/** English translation: "Manage project rights" */
	HubProjectManageRights = "Hub_Project_Manage_Rights",

	/** English translation: "Manage task type" */
	HubProjectManageTaskKind = "Hub_Project_Manage_Task_Kind",

	/** English translation: "Mark as done" */
	HubProjectMarkAsDone = "Hub_Project_Mark_As_Done",

	/** English translation: "All tasks linked to this category will be deleted. Do you still want to delete this category?" */
	HubProjectModalDeleteCategoryConfirm = "Hub_Project_Modal_Delete_Category_Confirm",

	/** English translation: "Edit email reminder" */
	HubProjectModifyEmailReminder = "Hub_Project_Modify_Email_Reminder",

	/** English translation: "Edit this reminder" */
	HubProjectModifyThisReminder = "Hub_Project_Modify_This_Reminder",

	/** English translation: "Monthly" */
	HubProjectMonthly = "Hub_Project_Monthly",

	/** English translation: "My tasks" */
	HubProjectMyTasks = "Hub_Project_My_Tasks",

	/** English translation: "Please give a name to your project." */
	HubProjectNameRequired = "Hub_Project_Name_Required",

	/** English translation: "You are not allowed add attachments to the task." */
	HubProjectNoTaskAttachmentRight = "Hub_Project_NoTaskAttachmentRight",

	/** English translation: "No board" */
	HubProjectNoBoard = "Hub_Project_No_Board",

	/** English translation: "You don't have access to boards in this project." */
	HubProjectNoBoardAccessible = "Hub_Project_No_Board_Accessible",

	/** English translation: "No email reminder" */
	HubProjectNoEmailReminder = "Hub_Project_No_Email_Reminder",

	/** English translation: "No category" */
	HubProjectNoGroup = "Hub_Project_No_Group",

	/** English translation: "You don't have access to projects in this hub." */
	HubProjectNoProjectAccessible = "Hub_Project_No_Project_Accessible",

	/** English translation: "No subtasks" */
	HubProjectNoSubTask = "Hub_Project_No_Sub_Task",

	/** English translation: "No tag" */
	HubProjectNoTag = "Hub_Project_No_Tag",

	/** English translation: "No tag group" */
	HubProjectNoTagGroup = "Hub_Project_No_Tag_Group",

	/** English translation: "No task" */
	HubProjectNoTask = "Hub_Project_No_Task",

	/** English translation: "You have no assigned tasks in this project." */
	HubProjectNoTaskAssigned = "Hub_Project_No_Task_Assigned",

	/** English translation: "Post comments" */
	HubProjectPostComment = "Hub_Project_Post_Comment",

	/** English translation: "Reminder date" */
	HubProjectReminderDate = "Hub_Project_Reminder_Date",

	/** English translation: "every {0} days" */
	HubProjectReminderEveryDay = "Hub_Project_Reminder_Every_Day",

	/** English translation: "every {0} hours" */
	HubProjectReminderEveryHour = "Hub_Project_Reminder_Every_Hour",

	/** English translation: "every {0} months" */
	HubProjectReminderEveryMonth = "Hub_Project_Reminder_Every_Month",

	/** English translation: "every {0} weeks" */
	HubProjectReminderEveryWeek = "Hub_Project_Reminder_Every_Week",

	/** English translation: "This reminder will be sent {0} from {1} to {2} to the assigned users." */
	HubProjectReminderRepeatedSendToAssigned = "Hub_Project_Reminder_Repeated_Send_To_Assigned",

	/** English translation: "This reminder will be sent {0} from {1} to {2} to the task creator." */
	HubProjectReminderRepeatedSendToCreator = "Hub_Project_Reminder_Repeated_Send_To_Creator",

	/** English translation: "This reminder will be sent {0} from {1} to {2} to the task creator and assigned users." */
	HubProjectReminderRepeatedSendToCreatorAndAssigned = "Hub_Project_Reminder_Repeated_Send_To_Creator_And_Assigned",

	/** English translation: "This reminder will be sent on {0} to the assigned users." */
	HubProjectReminderUniqueSendToAssigned = "Hub_Project_Reminder_Unique_Send_To_Assigned",

	/** English translation: "This reminder will be sent on {0} to the task creator." */
	HubProjectReminderUniqueSendToCreator = "Hub_Project_Reminder_Unique_Send_To_Creator",

	/** English translation: "This reminder will be sent on {0} to the task creator and the assigned users." */
	HubProjectReminderUniqueSendToCreatorAndAssigned = "Hub_Project_Reminder_Unique_Send_To_Creator_And_Assigned",

	/** English translation: "Repeated reminder" */
	HubProjectRepeatedReminder = "Hub_Project_Repeated_Reminder",

	/** English translation: "Project rights" */
	HubProjectRight = "Hub_Project_Right",

	/** English translation: "When a task is assigned to a user, the user automatically gets the right to manage the subjects, comments, and attachments of the task." */
	HubProjectRightsDisclaimer = "Hub_Project_Rights_Disclaimer",

	/** English translation: "Project rights are now updated." */
	HubProjectRightUpdated = "Hub_Project_Right_Updated",

	/** English translation: "See project" */
	HubProjectSeeProject = "Hub_Project_See_Project",

	/** English translation: "Send to" */
	HubProjectSendTo = "Hub_Project_Send_To",

	/** English translation: "Send to" */
	HubProjectSendToThe = "Hub_Project_Send_To_The",

	/** English translation: "Sent until the task is completed" */
	HubProjectSentUntilTaskDone = "Hub_Project_Sent_Until_Task_Done",

	/** English translation: "Completed" */
	HubProjectStatusCompleted = "Hub_Project_Status_Completed",

	/** English translation: "In progress" */
	HubProjectStatusInProgress = "Hub_Project_Status_In_Progress",

	/** English translation: "To-do" */
	HubProjectStatusToDo = "Hub_Project_Status_To_Do",

	/** English translation: "You have successfully added the board." */
	HubProjectSuccessAddBoard = "Hub_Project_Success_Add_Board",

	/** English translation: "You have successfully added the category." */
	HubProjectSuccessAddCategory = "Hub_Project_Success_Add_Category",

	/** English translation: "You have successfully added the group." */
	HubProjectSuccessAddGroup = "Hub_Project_Success_Add_Group",

	/** English translation: "You have successfully added the tag." */
	HubProjectSuccessAddTag = "Hub_Project_Success_Add_Tag",

	/** English translation: "You have successfully added the tag group." */
	HubProjectSuccessAddTagGroup = "Hub_Project_Success_Add_Tag_Group",

	/** English translation: "You have successfully added the task." */
	HubProjectSuccessAddTask = "Hub_Project_Success_Add_Task",

	/** English translation: "You have successfully deleted the board." */
	HubProjectSuccessDeleteBoard = "Hub_Project_Success_Delete_Board",

	/** English translation: "You have successfully deleted the category." */
	HubProjectSuccessDeleteCategory = "Hub_Project_Success_Delete_Category",

	/** English translation: "You have successfully deleted the group." */
	HubProjectSuccessDeleteGroup = "Hub_Project_Success_Delete_Group",

	/** English translation: "You have successfully deleted the tag." */
	HubProjectSuccessDeleteTag = "Hub_Project_Success_Delete_Tag",

	/** English translation: "You have successfully deleted the tag group." */
	HubProjectSuccessDeleteTagGroup = "Hub_Project_Success_Delete_Tag_Group",

	/** English translation: "You have successfully deleted the task." */
	HubProjectSuccessDeleteTask = "Hub_Project_Success_Delete_Task",

	/** English translation: "Board edited" */
	HubProjectSuccessEditBoard = "Hub_Project_Success_Edit_Board",

	/** English translation: "You have successfully edited the category." */
	HubProjectSuccessEditCategory = "Hub_Project_Success_Edit_Category",

	/** English translation: "You have successfully edited the group." */
	HubProjectSuccessEditGroup = "Hub_Project_Success_Edit_Group",

	/** English translation: "You have successfully edited the tag." */
	HubProjectSuccessEditTag = "Hub_Project_Success_Edit_Tag",

	/** English translation: "You have successfully edited the tag group." */
	HubProjectSuccessEditTagGroup = "Hub_Project_Success_Edit_Tag_Group",

	/** English translation: "You have successfully edited the task." */
	HubProjectSuccessEditTask = "Hub_Project_Success_Edit_Task",

	/** English translation: "Please write a description for the tag in default language." */
	HubProjectTagErrorDescription = "Hub_Project_Tag_Error_Description",

	/** English translation: "Please write a description for the tag group in default language." */
	HubProjectTagGroupErrorDescription = "Hub_Project_Tag_Group_Error_Description",

	/** English translation: "Task" */
	HubProjectTask = "Hub_Project_Task",

	/** English translation: "Tasks" */
	HubProjectTasks = "Hub_Project_Tasks",

	/** English translation: "Tasks created by other users" */
	HubProjectTasksCreatedByOthers = "Hub_Project_Tasks_Created_By_Others",

	/** English translation: "Task number" */
	HubProjectTasksNumber = "Hub_Project_Tasks_Number",

	/** English translation: "Total" */
	HubProjectTasksTotal = "Hub_Project_Tasks_Total",

	/** English translation: "Add task" */
	HubProjectTaskAdd = "Hub_Project_Task_Add",

	/** English translation: "Add attachment" */
	HubProjectTaskAddAttachment = "Hub_Project_Task_Add_Attachment",

	/** English translation: "Assigned to" */
	HubProjectTaskAssignedTo = "Hub_Project_Task_Assigned_To",

	/** English translation: "Attachments" */
	HubProjectTaskAttachments = "Hub_Project_Task_Attachments",

	/** English translation: "Attachment description" */
	HubProjectTaskAttachmentDescription = "Hub_Project_Task_Attachment_Description",

	/** English translation: "Author" */
	HubProjectTaskAuthor = "Hub_Project_Task_Author",

	/** English translation: "Task color" */
	HubProjectTaskColor = "Hub_Project_Task_Color",

	/** English translation: "Conversations" */
	HubProjectTaskComments = "Hub_Project_Task_Comments",

	/** English translation: "Completed" */
	HubProjectTaskCompleted = "Hub_Project_Task_Completed",

	/** English translation: "Subtasks" */
	HubProjectTaskCustomTask = "Hub_Project_Task_Custom_Task",

	/** English translation: "Description" */
	HubProjectTaskDescription = "Hub_Project_Task_Description",

	/** English translation: "Task details" */
	HubProjectTaskDetails = "Hub_Project_Task_Details",

	/** English translation: "Edit task" */
	HubProjectTaskEdit = "Hub_Project_Task_Edit",

	/** English translation: "Edit all tasks" */
	HubProjectTaskEditAll = "Hub_Project_Task_Edit_All",

	/** English translation: "Edit assigned tasks" */
	HubProjectTaskEditAssigned = "Hub_Project_Task_Edit_Assigned",

	/** English translation: "End:" */
	HubProjectTaskEnd = "Hub_Project_Task_End",

	/** English translation: "End date" */
	HubProjectTaskEndDate = "Hub_Project_Task_End_Date",

	/** English translation: "Manage task" */
	HubProjectTaskManage = "Hub_Project_Task_Manage",

	/** English translation: "Manage users" */
	HubProjectTaskManageUsers = "Hub_Project_Task_Manage_Users",

	/** English translation: "Task not found" */
	HubProjectTaskNotFound = "Hub_Project_Task_Not_Found",

	/** English translation: "No attachment" */
	HubProjectTaskNoAttachment = "Hub_Project_Task_No_Attachment",

	/** English translation: "No conversation" */
	HubProjectTaskNoComment = "Hub_Project_Task_No_Comment",

	/** English translation: "Start:" */
	HubProjectTaskStart = "Hub_Project_Task_Start",

	/** English translation: "Start date" */
	HubProjectTaskStartDate = "Hub_Project_Task_Start_Date",

	/** English translation: "Status" */
	HubProjectTaskStatus = "Hub_Project_Task_Status",

	/** English translation: "Tags" */
	HubProjectTaskTags = "Hub_Project_Task_Tags",

	/** English translation: "Title" */
	HubProjectTaskTitle = "Hub_Project_Task_Title",

	/** English translation: "Type" */
	HubProjectTaskType = "Hub_Project_Task_Type",

	/** English translation: "Select the tasks that you want to import to this project. You can also set a starting date for the first task in the selected template, and the other tasks will be timed accordingly." */
	HubProjectTemplateContent = "Hub_Project_Template_Content",

	/** English translation: "Please give a project template." */
	HubProjectTemplateRequired = "Hub_Project_Template_Required",

	/** English translation: "Please write the type title in default language." */
	HubProjectTypeErrorUndefinedTitle = "Hub_Project_Type_Error_Undefined_Title",

	/** English translation: "Please set the task type." */
	HubProjectTypeUndefined = "Hub_Project_Type_Undefined",

	/** English translation: "Undefined group" */
	HubProjectUndefinedGroup = "Hub_Project_Undefined_Group",

	/** English translation: "One-time reminder" */
	HubProjectUniqueReminder = "Hub_Project_Unique_Reminder",

	/** English translation: "The content is being uploaded." */
	HubProjectUploadInProgress = "Hub_Project_Upload_In_Progress",

	/** English translation: "assigned users" */
	HubProjectUserAssigned = "Hub_Project_User_Assigned",

	/** English translation: "assigned user" */
	HubProjectUserConcerned = "Hub_Project_User_Concerned",

	/** English translation: "Use category color" */
	HubProjectUseCategoryColor = "Hub_Project_Use_Category_Color",

	/** English translation: "Use template color" */
	HubProjectUseTemplateColor = "Hub_Project_Use_Template_Color",

	/** English translation: "Show task details" */
	HubProjectViewTaskDetail = "Hub_Project_View_Task_Detail",

	/** English translation: "My tasks" */
	HubProjectWidgetTitle = "Hub_Project_Widget_Title",

	/** English translation: "You can't remove your administrative rights." */
	HubSettingsAdminRightsCurrentUser = "Hub_Settings_Admin_Rights_Current_User",

	/** English translation: "You can't give administrative rights to this user because the user is not a hub template creator." */
	HubSettingsAdminRightsHubTemplateCreator = "Hub_Settings_Admin_Rights_Hub_Template_Creator",

	/** English translation: "You can't give administrative rights to external users." */
	HubSettingsAdminRightsNotInternal = "Hub_Settings_Admin_Rights_Not_Internal",

	/** English translation: "You can't give administrative rights to these users because they are not Hubshare users." */
	HubSettingsAdminRightsNotUser = "Hub_Settings_Admin_Rights_Not_User",

	/** English translation: "The owner already has administrative rights." */
	HubSettingsAdminRightsOwner = "Hub_Settings_Admin_Rights_Owner",

	/** English translation: "You can't edit the rights of a group in which you are a member." */
	HubSettingsAdminRightsSetOwnAdminGroup = "Hub_Settings_Admin_Rights_Set_Own_Admin_Group",

	/** English translation: "Only administrators can give or remove administrative and hub manager rights." */
	HubSettingsAdminRightsUserManager = "Hub_Settings_Admin_Rights_User_Manager",

	/** English translation: "You can't delete a hub creator." */
	HubSettingsCanNotDeleteOwner = "Hub_Settings_Can_Not_Delete_Owner",

	/** English translation: "Apply changes to manage the user rights of widgets." */
	HubSettingsModuleNeedApplyDashboard = "Hub_Settings_Module_Need_Apply_Dashboard",

	/** English translation: "Apply changes to manage the user rights of files." */
	HubSettingsModuleNeedApplyFiles = "Hub_Settings_Module_Need_Apply_Files",

	/** English translation: "You haven't yet created any widgets." */
	HubSettingsNoWidgetToShow = "Hub_Settings_No_Widget_To_Show",

	/** English translation: "Remove hub designer rights" */
	HubSettingsRemoveHubDesignerRights = "Hub_Settings_Remove_Hub_Designer_Rights",

	/** English translation: "Do you want to remove hub designer rights from this user?" */
	HubSettingsRemoveHubDesignerRightsConfirm = "Hub_Settings_Remove_Hub_Designer_Rights_Confirm",

	/** English translation: "Do you want to remove hub designer rights from this group?" */
	HubSettingsRemoveHubDesignerRightsGroupConfirm = "Hub_Settings_Remove_Hub_Designer_Rights_Group_Confirm",

	/** English translation: "Remove user manager rights" */
	HubSettingsRemoveUserManagerRights = "Hub_Settings_Remove_User_Manager_Rights",

	/** English translation: "Do you want to remove user manager rights from this user?" */
	HubSettingsRemoveUserManagerRightsConfirm = "Hub_Settings_Remove_User_Manager_Rights_Confirm",

	/** English translation: "Do you want to remove user manager rights from this group?" */
	HubSettingsRemoveUserManagerRightsGroupConfirm = "Hub_Settings_Remove_User_Manager_Rights_Group_Confirm",

	/** English translation: "Set as hub designer" */
	HubSettingsSetHubDesigner = "Hub_Settings_Set_Hub_Designer",

	/** English translation: "A hub designer can change the hub message and the appearance of the hub. Do you want to set this user as a hub designer?" */
	HubSettingsSetHubDesignerConfirm = "Hub_Settings_Set_Hub_Designer_Confirm",

	/** English translation: "The internal members of a hub designer group can change the hub message and the appearance of the hub. Do you want to set the members of this group as hub designers?" */
	HubSettingsSetHubDesignerGroupConfirm = "Hub_Settings_Set_Hub_Designer_Group_Confirm",

	/** English translation: "Set as user manager" */
	HubSettingsSetUserManager = "Hub_Settings_Set_User_Manager",

	/** English translation: "A user manager can change the rights of other users in the modules that the user manager has access to. Do you want to set this user as a user manager?" */
	HubSettingsSetUserManagerConfirm = "Hub_Settings_Set_User_Manager_Confirm",

	/** English translation: "The internal members of a user manager group change the rights of other users in the modules that the user managers have access to. Do you want to set the members of this  group as user managers?'" */
	HubSettingsSetUserManagerGroupConfirm = "Hub_Settings_Set_User_Manager_Group_Confirm",

	/** English translation: "Set the blog rights for users and groups." */
	HubSettingsStepperBlogInfo = "Hub_Settings_Stepper_Blog_Info",

	/** English translation: "Here you can create an external user." */
	HubSettingsStepperCreationInfo = "Hub_Settings_Stepper_Creation_Info",

	/** English translation: "Create external user" */
	HubSettingsStepperCreationTitle = "Hub_Settings_Stepper_Creation_Title",

	/** English translation: "Select the available modules" */
	HubSettingsStepperModulesInfo = "Hub_Settings_Stepper_Modules_Info",

	/** English translation: "Modules" */
	HubSettingsStepperModulesTitle = "Hub_Settings_Stepper_Modules_Title",

	/** English translation: "Next step" */
	HubSettingsStepperNextStep = "Hub_Settings_Stepper_Next_Step",

	/** English translation: "Here you can add users and user groups to your hub. You can use the filter and search features to find people more quickly. After you click "Next step", M-Files Hubshare sends an email invitation to the selected users." */
	HubSettingsStepperPickerHubInfo = "Hub_Settings_Stepper_Picker_Hub_Info",

	/** English translation: "Select the groups you would like to add to this hub." */
	HubSettingsStepperPickerHubInfoGroupsOnly = "Hub_Settings_Stepper_Picker_Hub_Info_Groups_Only",

	/** English translation: "Select the users or groups you would like to add to this {0}." */
	HubSettingsStepperPickerInfo = "Hub_Settings_Stepper_Picker_Info",

	/** English translation: "Select the users or groups you would like to add to this template." */
	HubSettingsStepperPickerTemplateInfo = "Hub_Settings_Stepper_Picker_Template_Info",

	/** English translation: "User and group list" */
	HubSettingsStepperPickerTitle = "Hub_Settings_Stepper_Picker_Title",

	/** English translation: "Group list" */
	HubSettingsStepperPickerTitleGroupsOnly = "Hub_Settings_Stepper_Picker_Title_Groups_Only",

	/** English translation: "Set the project rights for users and groups." */
	HubSettingsStepperProjectInfo = "Hub_Settings_Stepper_Project_Info",

	/** English translation: "Select a user or group and set the user rights for them." */
	HubSettingsStepperRightsInfo = "Hub_Settings_Stepper_Rights_Info",

	/** English translation: "User and group rights" */
	HubSettingsStepperRightsTitle = "Hub_Settings_Stepper_Rights_Title",

	/** English translation: "Select the widgets that you want to show for users and groups." */
	HubSettingsStepperWidgetsInfo = "Hub_Settings_Stepper_Widgets_Info",

	/** English translation: "Available widgets" */
	HubSettingsStepperWidgetsTitle = "Hub_Settings_Stepper_Widgets_Title",

	/** English translation: "The users are now added to the {0}." */
	HubSettingsUserAddSuccess = "Hub_Settings_User_Add_Success",

	/** English translation: "{0} has been created and added to the {1}." */
	HubSettingsUserCreationSuccess = "Hub_Settings_User_Creation_Success",

	/** English translation: "A user manager can't manage his own rights." */
	HubSettingsUserManagerCannotManageHimself = "Hub_Settings_User_Manager_Cannot_Manage_Himself",

	/** English translation: "Modules rights are now updated." */
	HubSettingsUserModulesSuccess = "Hub_Settings_User_Modules_Success",

	/** English translation: "Rights are now updated." */
	HubSettingsUserRightsSuccess = "Hub_Settings_User_Rights_Success",

	/** English translation: "The selected users are now added to the {0}." */
	HubSettingsUserSelectSuccess = "Hub_Settings_User_Select_Success",

	/** English translation: "Widgets rights are now updated." */
	HubSettingsUserWidgetsSuccess = "Hub_Settings_User_Widgets_Success",

	/** English translation: "Short name for hub" */
	HubShortName = "Hub_Short_Name",

	/** English translation: "The status of the hub was changed." */
	HubStatusChanged = "Hub_Status_Changed",

	/** English translation: "New hub template" */
	HubTemplatesAdd = "Hub_Templates_Add",

	/** English translation: "New hub" */
	HubTemplatesAddHub = "Hub_Templates_Add_Hub",

	/** English translation: "Busy" */
	HubTemplatesBusy = "Hub_Templates_Busy",

	/** English translation: "Creating from template" */
	HubTemplatesCreatingFromTemplate = "Hub_Templates_Creating_From_Template",

	/** English translation: "Something went wrong. Please try again." */
	HubTemplatesCreatingHubFromImportError = "Hub_Templates_Creating_Hub_From_Import_Error",

	/** English translation: "We are now creating the hub for you. Please wait." */
	HubTemplatesCreatingHubFromImportHub = "Hub_Templates_Creating_Hub_From_Import_Hub",

	/** English translation: "We are now creating the hub template for you. Please wait." */
	HubTemplatesCreatingHubFromImportTemplate = "Hub_Templates_Creating_Hub_From_Import_Template",

	/** English translation: "If you delete a hub template, all data inside this template will be removed. Do you still want to delete this hub template?" */
	HubTemplatesDeleteConfirmMessage = "Hub_Templates_Delete_Confirm_Message",

	/** English translation: "From file" */
	HubTemplatesFromImport = "Hub_Templates_From_Import",

	/** English translation: "Creates a hub that uses the hub configurations set in the file." */
	HubTemplatesFromImportDescriptionHub = "Hub_Templates_From_Import_Description_Hub",

	/** English translation: "Creates a template that uses the hub configurations set in the file." */
	HubTemplatesFromImportDescriptionTemplate = "Hub_Templates_From_Import_Description_Template",

	/** English translation: "From scratch" */
	HubTemplatesFromScratch = "Hub_Templates_From_Scratch",

	/** English translation: "Creates an empty template." */
	HubTemplatesFromScratchDescription = "Hub_Templates_From_Scratch_Description",

	/** English translation: "Creates an empty hub." */
	HubTemplatesFromScratchDescriptionHub = "Hub_Templates_From_Scratch_Description_Hub",

	/** English translation: "From template" */
	HubTemplatesFromTemplate = "Hub_Templates_From_Template",

	/** English translation: "Creates a customizable copy of the template." */
	HubTemplatesFromTemplateDescription = "Hub_Templates_From_Template_Description",

	/** English translation: "You can't create more hubs." */
	HubTemplatesHubLimitReached = "Hub_Templates_Hub_Limit_Reached",

	/** English translation: "Loading templates" */
	HubTemplatesLoadingTemplates = "Hub_Templates_Loading_Templates",

	/** English translation: "Manage hub templates" */
	HubTemplatesManagement = "Hub_Templates_Management",

	/** English translation: "Available" */
	HubTemplatesNotBusy = "Hub_Templates_Not_Busy",

	/** English translation: "You don't have the rights to create hubs and hub templates." */
	HubTemplatesNoRightsToCreate = "Hub_Templates_No_Rights_To_Create",

	/** English translation: "No active templates" */
	HubTemplatesNoTemplates = "Hub_Templates_No_Templates",

	/** English translation: "Pick template" */
	HubTemplatesPickTemplate = "Hub_Templates_Pick_Template",

	/** English translation: "Ready to copy" */
	HubTemplatesTemplateIdle = "Hub_Templates_Template_Idle",

	/** English translation: "This template is in use." */
	HubTemplatesTemplateIsBusy = "Hub_Templates_Template_Is_Busy",

	/** English translation: "This template is now in active mode and you can use it. Change to design mode to edit the template." */
	HubTemplateActive = "Hub_Template_Active",

	/** English translation: "The user will be an administrator of the hub created from this template." */
	HubTemplateAdminHubCreatorOnly = "Hub_Template_Admin_Hub_Creator_Only",

	/** English translation: "Only a hub creator or a hub template creator can be a hub template administrator." */
	HubTemplateAdminNoRights = "Hub_Template_Admin_No_Rights",

	/** English translation: "The user can edit the template, but the user will have only normal user rights to the hub that is created from this template." */
	HubTemplateAdminTemplateCreatorOnly = "Hub_Template_Admin_Template_Creator_Only",

	/** English translation: "This template is in use. Please try again later." */
	HubTemplateBusy = "Hub_Template_Busy",

	/** English translation: "Hub template creator" */
	HubTemplateCreator = "Hub_Template_Creator",

	/** English translation: "Creating users is disabled on templates." */
	HubTemplateLimitationCreateUser = "Hub_Template_Limitation_Create_User",

	/** English translation: "Hub template name" */
	HubTemplateName = "Hub_Template_Name",

	/** English translation: "The template name is not available." */
	HubTemplateNameUnavailable = "Hub_Template_Name_Unavailable",

	/** English translation: "Hub template title" */
	HubTemplateTitle = "Hub_Template_Title",

	/** English translation: "If you change the template to active mode, you can't make changes to it. Are you sure you want to change this template to active mode?" */
	HubTemplateUpdateActiveStatus = "Hub_Template_Update_Active_Status",

	/** English translation: "Hub title" */
	HubTitle = "Hub_Title",

	/** English translation: "Hub title color" */
	HubTitleColour = "Hub_Title_Colour",

	/** English translation: "The hub is now updated." */
	HubUpdated = "Hub_Updated",

	/** English translation: "You can't remove your administrative rights." */
	HubUserCantRemoveYourSelfAdmin = "Hub_User_Cant_Remove_YourSelf_Admin",

	/** English translation: "The user is an internal user in another organization." */
	HubUserInternalInAnotherCompany = "Hub_User_Internal_In_Another_Company",

	/** English translation: "The user no longer belongs to this hub." */
	HubUserNotInHub = "Hub_User_Not_In_Hub",

	/** English translation: "The user no longer belongs to the template." */
	HubUserNotInTemplate = "Hub_User_Not_In_Template",

	/** English translation: "The user no longer has access to the calendar module." */
	HubUserNoAccessCalendar = "Hub_User_No_Access_Calendar",

	/** English translation: "The user no longer has access to the project module." */
	HubUserNoAccessProject = "Hub_User_No_Access_Project",

	/** English translation: "This user no longer has access to the module {0}." */
	HubUserNoModuleAccess = "Hub_User_No_Module_Access",

	/** English translation: "Use default colors" */
	HubUseDefaultColor = "Hub_Use_Default_Color",

	/** English translation: "Use default logo" */
	HubUseDefaultLogo = "Hub_Use_Default_Logo",

	/** English translation: "Widget automatic refresh" */
	HubWidgetsAutoRefresh = "Hub_Widgets_Auto_Refresh",

	/** English translation: "Widget title color" */
	HubWidgetsTitlesColor = "Hub_Widgets_Titles_Color",

	/** English translation: "Please write a title for the widget." */
	HubWidgetErrorEnterTitle = "Hub_Widget_Error_Enter_Title",

	/** English translation: "Please give the size of the widget." */
	HubWidgetErrorSelectSize = "Hub_Widget_Error_Select_Size",

	/** English translation: "Widget size" */
	HubWidgetSize = "Hub_Widget_Size",

	/** English translation: "Widget title" */
	HubWidgetTitle = "Hub_Widget_Title",

	/** English translation: "Widget title color" */
	HubWidgetTitlesColour = "Hub_Widget_Titles_Colour",

	/** English translation: "Image browser" */
	ImageBrowserTitle = "Image_Browser_Title",

	/** English translation: "If you close the dialog now, your changes are not saved. Do you still want to close the dialog?" */
	ImportCloseModalWarning = "Import_Close_Modal_Warning",

	/** English translation: "Please add a vault connection." */
	ImportConfigurationAddConfiguration = "Import_Configuration_Add_Configuration",

	/** English translation: "Add vault connection" */
	ImportConfigurationAddVaultConnection = "Import_Configuration_Add_Vault_Connection",

	/** English translation: "Enter a name for your hub." */
	ImportConfigurationDefaultMessageHubCreation = "Import_Configuration_Default_Message_Hub_Creation",

	/** English translation: "Select the hubs you want to update." */
	ImportConfigurationDefaultMessageNotCreation = "Import_Configuration_Default_Message_Not_Creation",

	/** English translation: "Enter a name for your template." */
	ImportConfigurationDefaultMessageTemplateCreation = "Import_Configuration_Default_Message_Template_Creation",

	/** English translation: "Keep the configurations" */
	ImportConfigurationDisableDeletions = "Import_Configuration_Disable_Deletions",

	/** English translation: "If the selected hubs have configurations that are not included in the imported configuration file, what would you like to do with those configurations?" */
	ImportConfigurationDisableDeletionsInfo = "Import_Configuration_Disable_Deletions_Info",

	/** English translation: "Delete the configurations" */
	ImportConfigurationEnableDeletions = "Import_Configuration_Enable_Deletions",

	/** English translation: "Degraded mode" */
	ImportConfigurationExtensionConfigurationDegraded = "Import_Configuration_Extension_Configuration_Degraded",

	/** English translation: "Imported vault connection" */
	ImportConfigurationExtensionConfigurationSameDotHub = "Import_Configuration_Extension_Configuration_Same_Dot_Hub",

	/** English translation: "{0} hubs selected" */
	ImportConfigurationHubsSelectedCount = "Import_Configuration_Hubs_Selected_Count",

	/** English translation: "Hub contains an error" */
	ImportConfigurationHubError = "Import_Configuration_Hub_Error",

	/** English translation: "Hub contains a warning" */
	ImportConfigurationHubWarning = "Import_Configuration_Hub_Warning",

	/** English translation: "Ignore warnings" */
	ImportConfigurationIgnoreWarnings = "Import_Configuration_Ignore_Warnings",

	/** English translation: "M-Files widget configuration" */
	ImportConfigurationMfilesWidgetSection = "Import_Configuration_Mfiles_Widget_Section",

	/** English translation: "Please select at least one hub." */
	ImportConfigurationNoHubsSelected = "Import_Configuration_No_Hubs_Selected",

	/** English translation: "Validate" */
	ImportConfigurationPrepareButton = "Import_Configuration_Prepare_Button",

	/** English translation: "Validating file" */
	ImportConfigurationPreparingButton = "Import_Configuration_Preparing_Button",

	/** English translation: "You can now create the new {0}." */
	ImportConfigurationReadyCreateRoom = "Import_Configuration_Ready_Create_Room",

	/** English translation: "Almost done!" */
	ImportConfigurationReadyTitle = "Import_Configuration_Ready_Title",

	/** English translation: "You can now proceed with the update of your hubs" */
	ImportConfigurationReadyUpdateHubs = "Import_Configuration_Ready_Update_Hubs",

	/** English translation: "Select a configuration" */
	ImportConfigurationSelectConfiguration = "Import_Configuration_Select_Configuration",

	/** English translation: "Select the hubs you want to update" */
	ImportConfigurationSelectHubsDescription = "Import_Configuration_Select_Hubs_Description",

	/** English translation: "Show all" */
	ImportConfigurationShowAll = "Import_Configuration_Show_All",

	/** English translation: "Show only linked hubs (if the imported hub is a template)" */
	ImportConfigurationShowLinkedHubs = "Import_Configuration_Show_Linked_Hubs",

	/** English translation: "Please specify the configuration for each imported M-Files widget." */
	ImportConfigurationSpecifyVaultConnection = "Import_Configuration_Specify_Vault_Connection",

	/** English translation: "Configurations included in the imported file" */
	ImportConfigurationSummary = "Import_Configuration_Summary",

	/** English translation: "Update selected hubs" */
	ImportConfigurationUpdateSelectedHubs = "Import_Configuration_Update_Selected_Hubs",

	/** English translation: "Validation error" */
	ImportConfigurationValidationError = "Import_Configuration_Validation_Error",

	/** English translation: "Validation" */
	ImportConfigurationValidationNotification = "Import_Configuration_Validation_Notification",

	/** English translation: "Resolve the errors and then click Validate to continue." */
	ImportConfigurationValidationNotificationErrors = "Import_Configuration_Validation_Notification_Errors",

	/** English translation: "Resolve the issues described in the warnings or enable the option "Ignore warnings" to continue." */
	ImportConfigurationValidationNotificationWarnings = "Import_Configuration_Validation_Notification_Warnings",

	/** English translation: "This section shows the errors and warnings in the incoming import package. Please resolve the errors to continue. It is also recommended that you resolve the issues described in the warnings. Optionally, you can enable the option "Ignore warnings"." */
	ImportConfigurationValidationTooltip = "Import_Configuration_Validation_Tooltip",

	/** English translation: "Validation warning" */
	ImportConfigurationValidationWarning = "Import_Configuration_Validation_Warning",

	/** English translation: "draft" */
	ImportConfigurationVaultConnectionCrafted = "Import_Configuration_Vault_Connection_Crafted",

	/** English translation: "Activate M-Files integration" */
	ImportFileActivateMFilesExtensionButton = "Import_File_Activate_MFiles_Extension_Button",

	/** English translation: "Are you sure you want to activate the M-Files integration for your organization?" */
	ImportFileActivateMFilesExtensionConfirm = "Import_File_Activate_MFiles_Extension_Confirm",

	/** English translation: "M-Files widgets are not created or updated" */
	ImportFileContinueWithoutExtensions = "Import_File_Continue_Without_Extensions",

	/** English translation: "Continue without M-Files integration" */
	ImportFileContinueWithoutExtensionsButton = "Import_File_Continue_Without_Extensions_Button",

	/** English translation: "Are you sure you want to continue without M-Files widgets?" */
	ImportFileContinueWithoutExtensionsConfirm = "Import_File_Continue_Without_Extensions_Confirm",

	/** English translation: "M-Files integration is not activated" */
	ImportFileMFilesExtensionUnavailable = "Import_File_MFiles_Extension_Unavailable",

	/** English translation: "To use M-Files widgets, M-Files integration must be activated." */
	ImportFileMFilesExtensionUnavailableActivateIt = "Import_File_MFiles_Extension_Unavailable_Activate_It",

	/** English translation: "Please tell your organization-level admin to activate M-Files integration." */
	ImportFileMFilesExtensionUnavailableReferToAdmin = "Import_File_MFiles_Extension_Unavailable_Refer_To_Admin",

	/** English translation: "Browse files" */
	ImportFileSelectionBrowseButton = "Import_File_Selection_Browse_Button",

	/** English translation: "Select a file" */
	ImportFileSelectionDescription = "Import_File_Selection_Description",

	/** English translation: "Drag and drop the .hub file here or use the Browse button." */
	ImportFileSelectionDropDescription = "Import_File_Selection_Drop_Description",

	/** English translation: "Something went wrong. Please try again." */
	ImportFileSelectionErrorReadFile = "Import_File_Selection_Error_Read_File",

	/** English translation: "Sorry, we can't use your file {0}. Please select another file." */
	ImportFileSelectionErrorTitle = "Import_File_Selection_Error_Title",

	/** English translation: "Please wait while we import your file." */
	ImportFileSelectionProcessingDescription = "Import_File_Selection_Processing_Description",

	/** English translation: "Importing..." */
	ImportFileSelectionProcessingTitle = "Import_File_Selection_Processing_Title",

	/** English translation: "What do you want to do with the file?" */
	ImportFileSelectionSuccessOptionsTitle = "Import_File_Selection_Success_Options_Title",

	/** English translation: "Create a hub" */
	ImportFileSelectionSuccessOptionNewHub = "Import_File_Selection_Success_Option_New_Hub",

	/** English translation: "Create a hub template" */
	ImportFileSelectionSuccessOptionNewTemplate = "Import_File_Selection_Success_Option_New_Template",

	/** English translation: "Update hubs" */
	ImportFileSelectionSuccessOptionUpdateHub = "Import_File_Selection_Success_Option_Update_Hub",

	/** English translation: "Your file {0} is now ready!" */
	ImportFileSelectionSuccessTitle = "Import_File_Selection_Success_Title",

	/** English translation: "To update the configurations of a hub, you must be the hub owner in that hub." */
	ImportFileSelectionUpdateHubWarning = "Import_File_Selection_Update_Hub_Warning",

	/** English translation: "Import hub configurations" */
	ImportModalTitle = "Import_Modal_Title",

	/** English translation: "The vault connections are not the same." */
	ImportModalTitleErrors = "Import_Modal_Title_Errors",

	/** English translation: "The selected file has been exported from an earlier M-Files Hubshare version. Please ask for a file that is exported from the latest version." */
	ImportValidateConfigurationInvalidVersion = "Import_Validate_Configuration_Invalid_Version",

	/** English translation: "The selected file is empty. Please select a file that has content in it." */
	ImportValidateConfigurationNoData = "Import_Validate_Configuration_No_Data",

	/** English translation: "Your password is now set." */
	InitPasswordSuccess = "Init_Password_Success",

	/** English translation: "Stay signed in" */
	KeepMeLoggedIn = "KeepMeLoggedIn",

	/** English translation: "Superior" */
	L = "L",

	/** English translation: "Please select at least one language." */
	LanguagesNeedSelect = "Languages_Need_Select",

	/** English translation: "Lock widget" */
	LockWidget = "Lock_Widget",

	/** English translation: "Login" */
	Login = "Login",

	/** English translation: "Back to the classic login page" */
	LoginBackToClassicLogin = "Login_Back_To_Classic_Login",

	/** English translation: "Login and password authentication" */
	LoginBasic = "Login_Basic",

	/** English translation: "Classic authentication" */
	LoginClassicAuthentication = "Login_Classic_Authentication",

	/** English translation: "Single sign-on" */
	LoginSSOTitle = "Login_SSO_Title",

	/** English translation: "Manage checked-out files" */
	ManageCheckedOutFiles = "Manage_Checked_Out_Files",

	/** English translation: "Company custom fields" */
	ManageCustomFieldsCompanyMode = "Manage_Custom_Fields_Company_Mode",

	/** English translation: "Company custom fields only" */
	ManageCustomFieldsCompanyModeOnly = "Manage_Custom_Fields_Company_Mode_Only",

	/** English translation: "Current hub custom fields" */
	ManageCustomFieldsLocalMode = "Manage_Custom_Fields_Local_Mode",

	/** English translation: "Manage custom fields" */
	ManageCustomFieldsTitle = "Manage_Custom_Fields_Title",

	/** English translation: "Manage languages" */
	ManageLanguagesTitle = "Manage_Languages_Title",

	/** English translation: "If you disable the files, blog, and project modules, the related rights will be deleted." */
	ManageModulesDisableModuleWarning = "Manage_Modules_Disable_Module_Warning",

	/** English translation: "Enabled modules" */
	ManageModulesEnabled = "Manage_Modules_Enabled",

	/** English translation: "The activated modules couldn't be updated." */
	ManageModulesEnabledError = "Manage_Modules_Enabled_Error",

	/** English translation: "The activated modules are now updated." */
	ManageModulesEnabledSuccess = "Manage_Modules_Enabled_Success",

	/** English translation: "An administrator has rights in all modules." */
	ManageModulesRightsAdministratorAllRights = "Manage_Modules_Rights_Administrator_All_Rights",

	/** English translation: "The module rights couldn't be updated." */
	ManageModulesRightsError = "Manage_Modules_Rights_Error",

	/** English translation: "A user has always rights to the landing page." */
	ManageModulesRightsLandingPageUsersRight = "Manage_Modules_Rights_Landing_Page_Users_Right",

	/** English translation: "Module rights are now updated." */
	ManageModulesRightsSuccess = "Manage_Modules_Rights_Success",

	/** English translation: "Manage modules" */
	ManageModulesTitle = "Manage_Modules_Title",

	/** English translation: "The module titles couldn't be updated." */
	ManageModulesTitlesError = "Manage_Modules_Titles_Error",

	/** English translation: "The modules titles are now updated." */
	ManageModulesTitlesSuccess = "Manage_Modules_Titles_Success",

	/** English translation: "A user manager can only manage module rights in modules that the user manager has access to." */
	ManageModulesUserManagerCannotManageRightsWithoutAccess = "Manage_Modules_User_Manager_Cannot_Manage_Rights_Without_Access",

	/** English translation: "Please confirm that this user is accepted." */
	ManagePendingAcceptConfirmText = "Manage_Pending_Accept_Confirm_Text",

	/** English translation: "Accept user" */
	ManagePendingAcceptConfirmTitle = "Manage_Pending_Accept_Confirm_Title",

	/** English translation: "Advanced configurations" */
	ManagePendingAdvancedConfiguration = "Manage_Pending_Advanced_Configuration",

	/** English translation: "Allowed domains without validation" */
	ManagePendingAllowlistedDomainWithoutValidate = "Manage_Pending_Allowlisted_Domain_Without_Validate",

	/** English translation: "Allowed domains with validation" */
	ManagePendingAllowlistedDomainWithValidate = "Manage_Pending_Allowlisted_Domain_With_Validate",

	/** English translation: "Allow domains with validation" */
	ManagePendingAllowWithValidation = "Manage_Pending_Allow_With_Validation",

	/** English translation: "Allow all domains without validation" */
	ManagePendingAutoValidate = "Manage_Pending_Auto_Validate",

	/** English translation: "Blocklisted domains" */
	ManagePendingBlocklistedDomain = "Manage_Pending_Blocklisted_Domain",

	/** English translation: "Blocklist all domains" */
	ManagePendingBlocklistAllDomain = "Manage_Pending_Blocklist_All_Domain",

	/** English translation: "Request date" */
	ManagePendingColumnDate = "Manage_Pending_Column_Date",

	/** English translation: " Please write domains in format "condoso.com"." */
	ManagePendingDomainInformation = "Manage_Pending_Domain_Information",

	/** English translation: "The domain name is invalid." */
	ManagePendingIncorrectDomain = "Manage_Pending_Incorrect_Domain",

	/** English translation: "Please confirm that this user is rejected." */
	ManagePendingRejectConfirmText = "Manage_Pending_Reject_Confirm_Text",

	/** English translation: "Reject user" */
	ManagePendingRejectConfirmTitle = "Manage_Pending_Reject_Confirm_Title",

	/** English translation: "Manage pending user" */
	ManagePendingUser = "Manage_Pending_User",

	/** English translation: "Accepted users" */
	ManagePendingUserAccepted = "Manage_Pending_User_Accepted",

	/** English translation: "Copy link to public hub registry" */
	ManagePendingUserCopyPublicHubLink = "Manage_Pending_User_Copy_Public_Hub_Link",

	/** English translation: "Unable to copy link." */
	ManagePendingUserCopyToClipboardError = "Manage_Pending_User_Copy_To_Clipboard_Error",

	/** English translation: "The link has been copied." */
	ManagePendingUserCopyToClipboardSuccess = "Manage_Pending_User_Copy_To_Clipboard_Success",

	/** English translation: "Pending users" */
	ManagePendingUserPending = "Manage_Pending_User_Pending",

	/** English translation: "Rejected users" */
	ManagePendingUserRejected = "Manage_Pending_User_Rejected",

	/** English translation: "Manage template rights" */
	ManageTemplateRights = "Manage_Template_Rights",

	/** English translation: "File and comment rights" */
	ManageUserFilesCommentsRights = "Manage_User_Files_Comments_Rights",

	/** English translation: "Module rights" */
	ManageUserModulesRights = "Manage_User_Modules_Rights",

	/** English translation: "Widget rights" */
	ManageUserWidgetsRights = "Manage_User_Widgets_Rights",

	/** English translation: "Manage watermark templates" */
	ManageWatermarksTitle = "Manage_Watermarks_Title",

	/** English translation: "Manage widget rights" */
	ManageWidgetsRights = "Manage_Widgets_Rights",

	/** English translation: "Manual" */
	ManualValue = "Manual_Value",

	/** English translation: "Maximum length:" */
	MaxLength = "Max_Length",

	/** English translation: "There seems to be nothing to show here." */
	MessageEmptyDataDescription = "Message_Empty_Data_Description",

	/** English translation: "Look at all this space" */
	MessageEmptyDataTitle = "Message_Empty_Data_Title",

	/** English translation: "Please change the filtering settings and try again." */
	MessageEmptyFilteredDataDescriptionFilter = "Message_Empty_Filtered_Data_Description_Filter",

	/** English translation: "Please edit your search or change the filtering settings and try again." */
	MessageEmptyFilteredDataDescriptionGeneric = "Message_Empty_Filtered_Data_Description_Generic",

	/** English translation: "Please edit your search and try again." */
	MessageEmptyFilteredDataDescriptionSearch = "Message_Empty_Filtered_Data_Description_Search",

	/** English translation: "Let’s find another way" */
	MessageEmptyFilteredDataTitle = "Message_Empty_Filtered_Data_Title",

	/** English translation: "Sometimes M-Files Hubshare doesn’t work as it’s
meant to. Please try again later." */
	MessageErrorDescription = "Message_Error_Description",

	/** English translation: "Sorry, something went wrong" */
	MessageErrorTitle = "Message_Error_Title",

	/** English translation: "There isn't yet information that could be shown in this widget." */
	MessageNothingToShowDescription = "Message_Nothing_To_Show_Description",

	/** English translation: "Nothing to show for now" */
	MessageNothingToShowTitle = "Message_Nothing_To_Show_Title",

	/** English translation: "M-Files" */
	MFiles = "MFiles",

	/** English translation: "Allow file upload" */
	MFilesAllowFileUpload = "MFiles_Allow_File_Upload",

	/** English translation: "Object is already checked out" */
	MFilesAlreadyCheckedOut = "MFiles_Already_Checked_Out",

	/** English translation: "From" */
	MFilesAuditFromVersion = "MFiles_Audit_From_Version",

	/** English translation: "Version history" */
	MFilesAuditHistoryDiff = "MFiles_Audit_History_Diff",

	/** English translation: "Show changes only" */
	MFilesAuditShowChangesOnly = "MFiles_Audit_Show_Changes_Only",

	/** English translation: "To" */
	MFilesAuditToVersion = "MFiles_Audit_To_Version",

	/** English translation: "Object is already checked out" */
	MFilesCheckedOutBySomeoneElse = "MFiles_Checked_Out_By_Someone_Else",

	/** English translation: "Object checked in" */
	MFilesCheckInSuccess = "MFiles_Check_In_Success",

	/** English translation: "When you have made your changes to the document, remember to check the document back in." */
	MFilesCheckOutExplanation = "MFiles_Check_Out_Explanation",

	/** English translation: "Object checked out" */
	MFilesCheckOutSuccess = "MFiles_Check_Out_Success",

	/** English translation: "Class" */
	MFilesClass = "MFiles_Class",

	/** English translation: "Class label" */
	MFilesClassLabel = "MFiles_Class_Label",

	/** English translation: "Clear icon cache" */
	MFilesClearIconsCache = "MFiles_Clear_Icons_Cache",

	/** English translation: "Next asynchronous task clears the icon cache." */
	MFilesClearIconsCacheTaskInfo = "MFiles_Clear_Icons_Cache_Task_Info",

	/** English translation: "Maximum number of files" */
	MFilesCreationMaxFiles = "MFiles_Creation_Max_Files",

	/** English translation: "What do you want to create?" */
	MFilesCreatorOptionsPrompt = "MFiles_Creator_Options_Prompt",

	/** English translation: "This will delete the filter {0}. Do you want to continue?" */
	MFilesDeleteFacetFiltersConfigurationMessage = "MFiles_Delete_Facet_Filters_Configuration_Message",

	/** English translation: "Add filter" */
	MFilesDependencyDirectFilterAdd = "MFiles_Dependency_Direct_Filter_Add",

	/** English translation: "Allows that the alternatives in the value list are filtered." */
	MFilesDependencyDirectFilterInfo = "MFiles_Dependency_Direct_Filter_Info",

	/** English translation: "Contextual filters" */
	MFilesDependencyDirectFilterTitle = "MFiles_Dependency_Direct_Filter_Title",

	/** English translation: "Value list filters for the property "{0}"" */
	MFilesDependencyModalTitle = "MFiles_Dependency_Modal_Title",

	/** English translation: "Add filter" */
	MFilesDependencyParentFilterAdd = "MFiles_Dependency_Parent_Filter_Add",

	/** English translation: "Allows to specify or filter a parent value list item that will be used to show only the respective children alternatives." */
	MFilesDependencyParentFilterInfo = "MFiles_Dependency_Parent_Filter_Info",

	/** English translation: "Filters for value list inheritance" */
	MFilesDependencyParentFilterTitle = "MFiles_Dependency_Parent_Filter_Title",

	/** English translation: "Documents and document collections" */
	MFilesDocumentsAndDocumentCollections = "MFiles_Documents_And_Document_Collections",

	/** English translation: "Document collection" */
	MFilesDocumentCollection = "MFiles_Document_Collection",

	/** English translation: "Download the file." */
	MFilesDownloadFilesContentlink = "MFiles_Download_Files_Content_link",

	/** English translation: "The ZIP file contains {0} items from the widget {1}. The size of the file is {2}. Do you want to start the download?" */
	MFilesDownloadFilesContentModalDesc = "MFiles_Download_Files_Content_Modal_Desc",

	/** English translation: "Use a file format whitelist" */
	MFilesEnableUploadedFilesExtensionsWhitelist = "MFiles_Enable_Uploaded_Files_Extensions_Whitelist",

	/** English translation: "File downloaded" */
	MFilesEventFileDownloaded = "MFiles_Event_File_Downloaded",

	/** English translation: "File printed" */
	MFilesEventFilePrinted = "MFiles_Event_File_Printed",

	/** English translation: "File uploaded" */
	MFilesEventFileUploaded = "MFiles_Event_File_Uploaded",

	/** English translation: "File viewed" */
	MFilesEventFileViewed = "MFiles_Event_File_Viewed",

	/** English translation: "Object content changed" */
	MFilesEventObjectContentChanged = "MFiles_Event_Object_Content_Changed",

	/** English translation: "Object created" */
	MFilesEventObjectCreated = "MFiles_Event_Object_Created",

	/** English translation: "Object metadata changed" */
	MFilesEventObjectMetadataChanged = "MFiles_Event_Object_Metadata_Changed",

	/** English translation: "Object workflow state changed" */
	MFilesEventObjectWorkflowStateChanged = "MFiles_Event_Object_Workflow_State_Changed",

	/** English translation: "Only organization-level admins can do this operation" */
	MFilesInsufficientRightToUndoCheckedOut = "MFiles_Insufficient_Right_To_Undo_Checked_Out",

	/** English translation: "Metadata" */
	MFilesMetadata = "MFiles_Metadata",

	/** English translation: "Multi-file document" */
	MFilesMultiFileDocument = "MFiles_Multi_File_Document",

	/** English translation: "Ignore this file" */
	MFilesNameConflictIgnore = "MFiles_Name_Conflict_Ignore",

	/** English translation: "Keep both files" */
	MFilesNameConflictKeep = "MFiles_Name_Conflict_Keep",

	/** English translation: "The uploaded file is named {0}." */
	MFilesNameConflictKeepLabel = "MFiles_Name_Conflict_Keep_Label",

	/** English translation: "There is already a file named {0}." */
	MFilesNameConflictMessage = "MFiles_Name_Conflict_Message",

	/** English translation: "Replace the file in the destination" */
	MFilesNameConflictReplace = "MFiles_Name_Conflict_Replace",

	/** English translation: "Replace or ignore files" */
	MFilesNameConflictTitle = "MFiles_Name_Conflict_Title",

	/** English translation: "Please refresh your browser and try again" */
	MFilesNotCheckedOut = "MFiles_Not_Checked_Out",

	/** English translation: "Link couldn't be copied" */
	MFilesObjectCopyToClipboardError = "MFiles_Object_Copy_To_Clipboard_Error",

	/** English translation: "Link copied" */
	MFilesObjectCopyToClipboardSuccess = "MFiles_Object_Copy_To_Clipboard_Success",

	/** English translation: "Shown" */
	MFilesPropertyVisibilityDisplayed = "MFiles_Property_Visibility_Displayed",

	/** English translation: "Hidden" */
	MFilesPropertyVisibilityHidden = "MFiles_Property_Visibility_Hidden",

	/** English translation: "Prefilled" */
	MFilesPropertyVisibilityPrefilled = "MFiles_Property_Visibility_Prefilled",

	/** English translation: "Required" */
	MFilesPropertyVisibilityRequired = "MFiles_Property_Visibility_Required",

	/** English translation: "Replace the content of an M-Files document" */
	MFilesReplaceFile = "MFiles_Replace_File",

	/** English translation: "Do you want to replace the contents of the file {0}?" */
	MFilesReplaceFileConfirmMessage = "MFiles_Replace_File_Confirm_Message",

	/** English translation: "Replace contents" */
	MFilesReplaceFileConfirmTitle = "MFiles_Replace_File_Confirm_Title",

	/** English translation: "Please select a document to replace its content." */
	MFilesReplaceFileLabel = "MFiles_Replace_File_Label",

	/** English translation: "Requested during upload" */
	MFilesRequestedUpload = "MFiles_Requested_Upload",

	/** English translation: "Tells the user to set a value for the uploaded document." */
	MFilesRequestOnUpload = "MFiles_Request_On_Upload",

	/** English translation: "Show only properties that can be edited" */
	MFilesTogglePropertiesEditableOnly = "MFiles_Toggle_Properties_Editable_Only",

	/** English translation: "Show only properties with values" */
	MFilesTogglePropertiesWithValuesOnly = "MFiles_Toggle_Properties_With_Values_Only",

	/** English translation: "Error occured when uploading file {0}." */
	MFilesUnauthorizedExtensionUpload = "MFiles_Unauthorized_Extension_Upload",

	/** English translation: "Checkout undone" */
	MFilesUndoCheckOutSuccess = "MFiles_Undo_Check_Out_Success",

	/** English translation: "Adding and editing files is disabled for this widget." */
	MFilesUploadAllDisabled = "MFiles_Upload_All_Disabled",

	/** English translation: "Upload was canceled." */
	MFilesUploadCancelled = "MFiles_Upload_Cancelled",

	/** English translation: "Editing files is disabled for this widget." */
	MFilesUploadCantReplaceFile = "MFiles_Upload_Cant_Replace_File",

	/** English translation: "Please select file upload action." */
	MFilesUploadChoiceLabel = "MFiles_Upload_Choice_Label",

	/** English translation: "Upload file to the current multi-file document." */
	MFilesUploadInsideMultifile = "MFiles_Upload_Inside_Multifile",

	/** English translation: "Update metadata each time the object is replaced or in any way edited." */
	MFilesUploadMetadataWhenEditing = "MFiles_Upload_Metadata_When_Editing",

	/** English translation: "You don't have the necessary permissions to add documents to the specified class. Please ask your M-Files Hubshare contact person to give you the permissions." */
	MFilesUploadMissingPropertyNameForGivenClass = "MFiles_Upload_Missing_Property_Name_For_Given_Class",

	/** English translation: "Here you can enter metadata for the file <strong>{0}</strong>. The upload process starts when you have set the metadata for all items and click <strong>Upload</strong> or <strong>Upload all</strong>." */
	MFilesUploadModalDesc = "MFiles_Upload_Modal_Desc",

	/** English translation: "The uploaded files will be added to a document collection. Here you can add metadata for the document collection. The upload process starts when you have set the metadata for all items and click <strong>Upload</strong> or <strong>Upload all</strong>." */
	MFilesUploadModalDescDocumentCollection = "MFiles_Upload_Modal_Desc_Document_Collection",

	/** English translation: "The uploaded files will be added to a multi-file document with a single set of metadata. Here you can add metadata for the document. The upload process starts when you have set the metadata and click <strong>Upload</strong>." */
	MFilesUploadModalDescMultiFileDocument = "MFiles_Upload_Modal_Desc_Multi_File_Document",

	/** English translation: "Upload file {0}" */
	MFilesUploadModalTitle = "MFiles_Upload_Modal_Title",

	/** English translation: "Upload files to a document collection" */
	MFilesUploadModalTitleDocumentCollection = "MFiles_Upload_Modal_Title_Document_Collection",

	/** English translation: "Upload files to a multi-file document" */
	MFilesUploadModalTitleMultiFileDocument = "MFiles_Upload_Modal_Title_Multi_File_Document",

	/** English translation: "Upload file to a new M-Files document." */
	MFilesUploadNewFile = "MFiles_Upload_New_File",

	/** English translation: "Adding new files is disabled for this widget." */
	MFilesUploadNewFilesDisabled = "MFiles_Upload_New_Files_Disabled",

	/** English translation: "Creating objects is not enabled for this widget. Ask your hub admin to enable it and try again." */
	MFilesUploadNewFilesToCreateObjectDisabled = "MFiles_Upload_New_Files_To_Create_Object_Disabled",

	/** English translation: "Adding files to objects is not enabled for this widget. Ask your hub admin to enable it and try again." */
	MFilesUploadNewFilesToExistingObjectDisabled = "MFiles_Upload_New_Files_To_Existing_Object_Disabled",

	/** English translation: "The selected object can't include files." */
	MFilesUploadObjectCantHaveFiles = "MFiles_Upload_Object_Cant_Have_Files",

	/** English translation: "The object version is not up to date." */
	MFilesUploadObjectVersionNotLatest = "MFiles_Upload_Object_Version_Not_Latest",

	/** English translation: "Files (max. {0})" */
	MFilesUploadZoneTitle = "MFiles_Upload_Zone_Title",

	/** English translation: "Select a configuration. The content of the selected vault is shown in the widget." */
	MFilesVaultConnectionLabel = "MFiles_Vault_Connection_Label",

	/** English translation: "View name" */
	MFilesViewFilterLabel = "MFiles_View_Filter_Label",

	/** English translation: "When <strong>Automatic</strong> is selected, the colors are automatically selected for you. This makes sure that the colors are accessible for individuals with color blindness." */
	MFilesWidgetChartsAutomaticColorsInfo = "MFiles_Widget_Charts_Automatic_Colors_Info",

	/** English translation: "What type of chart you want to create?" */
	MFilesWidgetChartsChartTypeLabel = "MFiles_Widget_Charts_Chart_Type_Label",

	/** English translation: "Shows the specified M-Files data in visual form" */
	MFilesWidgetChartsDescription = "MFiles_Widget_Charts_Description",

	/** English translation: "To create an M-Files Charts widget, you must first create an M-Files Explorer or Views widget to the hub." */
	MFilesWidgetChartsDisabledDescription = "MFiles_Widget_Charts_Disabled_Description",

	/** English translation: "Creates a sum of the objects for each grouping property value." */
	MFilesWidgetChartsFunctionCountDescription = "MFiles_Widget_Charts_Function_Count_Description",

	/** English translation: "Object addition" */
	MFilesWidgetChartsFunctionCountLabel = "MFiles_Widget_Charts_Function_Count_Label",

	/** English translation: "Which mathematical operation the widget should do for the property data?" */
	MFilesWidgetChartsFunctionLabel = "MFiles_Widget_Charts_Function_Label",

	/** English translation: "Creates a sum of the property values for each grouping property value. If you select this operation, you must also select which properties you want to add." */
	MFilesWidgetChartsFunctionSumDescription = "MFiles_Widget_Charts_Function_Sum_Description",

	/** English translation: "Property addition" */
	MFilesWidgetChartsFunctionSumLabel = "MFiles_Widget_Charts_Function_Sum_Label",

	/** English translation: "Which property should be used for grouping the data?" */
	MFilesWidgetChartsGroupingLabel = "MFiles_Widget_Charts_Grouping_Label",

	/** English translation: "Hide labels" */
	MFilesWidgetChartsHideLabels = "MFiles_Widget_Charts_Hide_Labels",

	/** English translation: "Please add at least one data serie." */
	MFilesWidgetChartsNoSerie = "MFiles_Widget_Charts_No_Serie",

	/** English translation: "Which object type is the chart about?" */
	MFilesWidgetChartsObjectTypeLabel = "MFiles_Widget_Charts_Object_Type_Label",

	/** English translation: "Select the widget whose data is used in the chart." */
	MFilesWidgetChartsParentSelectionDescription = "MFiles_Widget_Charts_Parent_Selection_Description",

	/** English translation: "On which property should the widget apply the operation?" */
	MFilesWidgetChartsPropertyLabel = "MFiles_Widget_Charts_Property_Label",

	/** English translation: "Specify the data that is shown in your chart." */
	MFilesWidgetChartsSeriesDescription = "MFiles_Widget_Charts_Series_Description",

	/** English translation: "Show also dates that don't have a value" */
	MFilesWidgetChartsShowDatesWithoutValue = "MFiles_Widget_Charts_Show_Dates_Without_Value",

	/** English translation: "Show grid" */
	MFilesWidgetChartsShowGrid = "MFiles_Widget_Charts_Show_Grid",

	/** English translation: "Show labels" */
	MFilesWidgetChartsShowLabels = "MFiles_Widget_Charts_Show_Labels",

	/** English translation: "Use  horizontal bars" */
	MFilesWidgetChartsUseHorizontalBars = "MFiles_Widget_Charts_Use_Horizontal_Bars",

	/** English translation: "Use stacked bars" */
	MFilesWidgetChartsUseStackedBars = "MFiles_Widget_Charts_Use_Stacked_Bars",

	/** English translation: "The object "{0}" is currently in read-only mode." */
	MFilesWidgetCheckoutReadOnlyModalChoice = "MFiles_Widget_Checkout_Read_Only_Modal_Choice",

	/** English translation: "M-Files widget configuration" */
	MFilesWidgetConfiguration = "MFiles_Widget_Configuration",

	/** English translation: "Add to allowed extensions" */
	MFilesWidgetConfigAddAllowedExtensions = "MFiles_Widget_Config_Add_Allowed_Extensions",

	/** English translation: "Add filter" */
	MFilesWidgetConfigAddFilter = "MFiles_Widget_Config_Add_Filter",

	/** English translation: "Add property" */
	MFilesWidgetConfigAddFilterableProperty = "MFiles_Widget_Config_Add_Filterable_Property",

	/** English translation: "Add the upload metadata" */
	MFilesWidgetConfigAddUploadMeta = "MFiles_Widget_Config_Add_Upload_Meta",

	/** English translation: "Add columns from M-Files views to the widget display" */
	MFilesWidgetConfigAddViewColumns = "MFiles_Widget_Config_Add_View_Columns",

	/** English translation: "Allowed extensions" */
	MFilesWidgetConfigAllowedExtensions = "MFiles_Widget_Config_Allowed_Extensions",

	/** English translation: "Adding files to objects enabled" */
	MFilesWidgetConfigAllowAddFileToExistingObject = "MFiles_Widget_Config_Allow_Add_File_To_Existing_Object",

	/** English translation: "If this is enabled, the users can add files to existing objects." */
	MFilesWidgetConfigAllowAddFileToExistingObjectInfo = "MFiles_Widget_Config_Allow_Add_File_To_Existing_Object_Info",

	/** English translation: "Creating objects enabled" */
	MFilesWidgetConfigAllowCreateNewObjectWithFile = "MFiles_Widget_Config_Allow_Create_New_Object_With_File",

	/** English translation: "If this is enabled, the users can create new objects." */
	MFilesWidgetConfigAllowCreateNewObjectWithFileInfo = "MFiles_Widget_Config_Allow_Create_New_Object_With_File_Info",

	/** English translation: "Downloading and printing files enabled" */
	MFilesWidgetConfigAllowDownload = "MFiles_Widget_Config_Allow_Download",

	/** English translation: "Allow CSV export" */
	MFilesWidgetConfigAllowExportCSV = "MFiles_Widget_Config_Allow_Export_CSV",

	/** English translation: "Saving annotations enabled" */
	MFilesWidgetConfigAllowFileAnnotation = "MFiles_Widget_Config_Allow_File_Annotation",

	/** English translation: "If saving annotations is enabled, the users can add annotations to documents and save them as new documents." */
	MFilesWidgetConfigAllowFileAnnotationInfo = "MFiles_Widget_Config_Allow_File_Annotation_Info",

	/** English translation: "Automatically open first-level location" */
	MFilesWidgetConfigAllowRootViewAutoNavigate = "MFiles_Widget_Config_Allow_Root_View_Auto_Navigate",

	/** English translation: "Editing files enabled" */
	MFilesWidgetConfigAllowVersioning = "MFiles_Widget_Config_Allow_Versioning",

	/** English translation: "If editing files is enabled, the users can update the content in existing files and edit files in Office for the web. The edited file is saved as a new file version." */
	MFilesWidgetConfigAllowVersioninginfo = "MFiles_Widget_Config_Allow_Versioning_info",

	/** English translation: "Show object metadata in the widget" */
	MFilesWidgetConfigAllowViewProperties = "MFiles_Widget_Config_Allow_View_Properties",

	/** English translation: "Column" */
	MFilesWidgetConfigColumn = "MFiles_Widget_Config_Column",

	/** English translation: "This property has an automatic value and can't be edited" */
	MFilesWidgetConfigColumnAutomaticPropertyCannotBeEdited = "MFiles_Widget_Config_Column_Automatic_Property_Cannot_Be_Edited",

	/** English translation: "Available only when property is shown" */
	MFilesWidgetConfigColumnNotAvailableInfo = "MFiles_Widget_Config_Column_Not_Available_Info",

	/** English translation: "You can't edit this property." */
	MFilesWidgetConfigColumnPropertyCannotBeEdited = "MFiles_Widget_Config_Column_Property_Cannot_Be_Edited",

	/** English translation: "Copy filters to Metadata query tab" */
	MFilesWidgetConfigCopyQuery = "MFiles_Widget_Config_Copy_Query",

	/** English translation: "Copy filters to Upload tab" */
	MFilesWidgetConfigCopyUpload = "MFiles_Widget_Config_Copy_Upload",

	/** English translation: "Show activities to all internal users" */
	MFilesWidgetConfigDisplayActivity = "MFiles_Widget_Config_Display_Activity",

	/** English translation: "When this option is enabled, all internal hub users can see and use the Activities button. When disabled, only hub admins can see and use the button." */
	MFilesWidgetConfigDisplayActivityInfo = "MFiles_Widget_Config_Display_Activity_Info",

	/** English translation: "Show in listing" */
	MFilesWidgetConfigDisplayAsColumn = "MFiles_Widget_Config_Display_As_Column",

	/** English translation: "Can be edited" */
	MFilesWidgetConfigEditable = "MFiles_Widget_Config_Editable",

	/** English translation: "Use allowed extensions list" */
	MFilesWidgetConfigEnableAllowedExtensions = "MFiles_Widget_Config_Enable_Allowed_Extensions",

	/** English translation: "If the allowed extensions list is used, the users can add or edit files with allowed file extensions only." */
	MFilesWidgetConfigEnableAllowedExtensionsInfo = "MFiles_Widget_Config_Enable_Allowed_Extensions_Info",

	/** English translation: "You must set allowed files at least at 1." */
	MFilesWidgetConfigErrorMinAllowedFiles = "MFiles_Widget_Config_Error_Min_Allowed_Files",

	/** English translation: "You must configure at least one class." */
	MFilesWidgetConfigErrorMinClassConfig = "MFiles_Widget_Config_Error_Min_Class_Config",

	/** English translation: "Extension" */
	MFilesWidgetConfigExtensionLabel = "MFiles_Widget_Config_Extension_Label",

	/** English translation: "The extension must start with a period. For example: .pdf" */
	MFilesWidgetConfigFileExtensionMustStartWithPeriod = "MFiles_Widget_Config_File_Extension_Must_Start_With_Period",

	/** English translation: "Filter:" */
	MFilesWidgetConfigFilter = "MFiles_Widget_Config_Filter",

	/** English translation: "Group by: (optional)" */
	MFilesWidgetConfigGroupBy = "MFiles_Widget_Config_Group_By",

	/** English translation: "Invalid fields configuration" */
	MFilesWidgetConfigInvalidField = "MFiles_Widget_Config_Invalid_Field",

	/** English translation: "Invalid relationship filter configuration" */
	MFilesWidgetConfigInvalidRelationshipFilterConfiguration = "MFiles_Widget_Config_Invalid_Relationship_Filter_Configuration",

	/** English translation: "Invalid view filter index" */
	MFilesWidgetConfigInvalidViewFilterIndex = "MFiles_Widget_Config_Invalid_View_Filter_Index",

	/** English translation: "Filter metadata" */
	MFilesWidgetConfigMetadataDisplayFilters = "MFiles_Widget_Config_Metadata_Display_Filters",

	/** English translation: "Show all properties" */
	MFilesWidgetConfigMetadataDisplayFilterAll = "MFiles_Widget_Config_Metadata_Display_Filter_All",

	/** English translation: "Only show my selections in "Can be edited"" */
	MFilesWidgetConfigMetadataDisplayFilterEdited = "MFiles_Widget_Config_Metadata_Display_Filter_Edited",

	/** English translation: "Only show my selections in "Show in listing"" */
	MFilesWidgetConfigMetadataDisplayFilterListing = "MFiles_Widget_Config_Metadata_Display_Filter_Listing",

	/** English translation: "Only show my selections in "Show in properties"" */
	MFilesWidgetConfigMetadataDisplayFilterProperties = "MFiles_Widget_Config_Metadata_Display_Filter_Properties",

	/** English translation: "Metadata query" */
	MFilesWidgetConfigMetaQuery = "MFiles_Widget_Config_Meta_Query",

	/** English translation: "Use these values for the next file" */
	MFilesWidgetConfigMetaUploadUseMetadataAsDefault = "MFiles_Widget_Config_Meta_Upload_Use_Metadata_As_Default",

	/** English translation: "When this option is enabled and you click Upload, the metadata that you select for this file is prefilled for the next one. If you click Upload all, the selected metadata is used for all the files that you upload now." */
	MFilesWidgetConfigMetaUploadUseMetadataAsDefaultInfo = "MFiles_Widget_Config_Meta_Upload_Use_Metadata_As_Default_Info",

	/** English translation: "Document metadata" */
	MFilesWidgetConfigMetaView = "MFiles_Widget_Config_Meta_View",

	/** English translation: "Relationships filtering" */
	MFilesWidgetConfigObjectTypeRelationshipFilter = "MFiles_Widget_Config_Object_Type_Relationship_Filter",

	/** English translation: "Hide all object relationships" */
	MFilesWidgetConfigObjectTypeRelationshipFilterHideAll = "MFiles_Widget_Config_Object_Type_Relationship_Filter_Hide_All",

	/** English translation: "Show relationships only for these object types" */
	MFilesWidgetConfigObjectTypeRelationshipFilterOptIn = "MFiles_Widget_Config_Object_Type_Relationship_Filter_Opt_In",

	/** English translation: "Hide relationships for these object types" */
	MFilesWidgetConfigObjectTypeRelationshipFilterOptOut = "MFiles_Widget_Config_Object_Type_Relationship_Filter_Opt_Out",

	/** English translation: "Show all object relationships" */
	MFilesWidgetConfigObjectTypeRelationshipFilterShowAll = "MFiles_Widget_Config_Object_Type_Relationship_Filter_Show_All",

	/** English translation: "You must use the same operator for the view filter." */
	MFilesWidgetConfigOverlappingViewfilter = "MFiles_Widget_Config_Overlapping_View_filter",

	/** English translation: "You changed the shown object type. The current object type filters are configured for the previously shown object type. Do you want to reset the filters?" */
	MFilesWidgetConfigPromptResetFilters = "MFiles_Widget_Config_Prompt_Reset_Filters",

	/** English translation: "Metadata count" */
	MFilesWidgetConfigPropertiesCount = "MFiles_Widget_Config_Properties_Count",

	/** English translation: "Property shown in parent" */
	MFilesWidgetConfigPropertyDisplayedInParent = "MFiles_Widget_Config_Property_Displayed_In_Parent",

	/** English translation: "Show in properties" */
	MFilesWidgetConfigReadableProperty = "MFiles_Widget_Config_Readable_Property",

	/** English translation: "Start view name" */
	MFilesWidgetConfigRootViewName = "MFiles_Widget_Config_Root_View_Name",

	/** English translation: "Selected properties" */
	MFilesWidgetConfigSelectedProperties = "MFiles_Widget_Config_Selected_Properties",

	/** English translation: "Select object type" */
	MFilesWidgetConfigSelectObjType = "MFiles_Widget_Config_Select_Obj_Type",

	/** English translation: "Select property" */
	MFilesWidgetConfigSelectProperty = "MFiles_Widget_Config_Select_Property",

	/** English translation: "Select the starting view" */
	MFilesWidgetConfigSelectRootView = "MFiles_Widget_Config_Select_Root_View",

	/** English translation: "Filter applied on:" */
	MFilesWidgetConfigSelectType = "MFiles_Widget_Config_Select_Type",

	/** English translation: "Show files of objects" */
	MFilesWidgetConfigShowEmbeddedFiles = "MFiles_Widget_Config_Show_Embedded_Files",

	/** English translation: "When this option is enabled, the files of the M-Files objects are shown in the listing area." */
	MFilesWidgetConfigShowEmbeddedFilesInfo = "MFiles_Widget_Config_Show_Embedded_Files_Info",

	/** English translation: "Configuration" */
	MFilesWidgetConfigTabConfiguration = "MFiles_Widget_Config_Tab_Configuration",

	/** English translation: "Filtering settings" */
	MFilesWidgetConfigTabFilterableProperties = "MFiles_Widget_Config_Tab_Filterable_Properties",

	/** English translation: "Metadata display" */
	MFilesWidgetConfigTabMetadata = "MFiles_Widget_Config_Tab_Metadata",

	/** English translation: "Column order" */
	MFilesWidgetConfigTabMetadataColumnOrder = "MFiles_Widget_Config_Tab_Metadata_Column_Order",

	/** English translation: "Order of metadata card properties" */
	MFilesWidgetConfigTabMetadataMetadatacardOrder = "MFiles_Widget_Config_Tab_Metadata_Metadatacard_Order",

	/** English translation: "Select metadata" */
	MFilesWidgetConfigTabMetadataSelect = "MFiles_Widget_Config_Tab_Metadata_Select",

	/** English translation: "Sort metadata" */
	MFilesWidgetConfigTabMetadataSort = "MFiles_Widget_Config_Tab_Metadata_Sort",

	/** English translation: "Metadata query" */
	MFilesWidgetConfigTabQuery = "MFiles_Widget_Config_Tab_Query",

	/** English translation: "Uploads" */
	MFilesWidgetConfigTabUpload = "MFiles_Widget_Config_Tab_Upload",

	/** English translation: "Options" */
	MFilesWidgetConfigTabUploadFile = "MFiles_Widget_Config_Tab_Upload_File",

	/** English translation: "Metadata" */
	MFilesWidgetConfigTabUploadMetadata = "MFiles_Widget_Config_Tab_Upload_Metadata",

	/** English translation: "This metadata is automatically given to all uploaded files" */
	MFilesWidgetConfigTabUploadMetadataDesc = "MFiles_Widget_Config_Tab_Upload_Metadata_Desc",

	/** English translation: "View filter" */
	MFilesWidgetConfigTabViewFilter = "MFiles_Widget_Config_Tab_View_Filter",

	/** English translation: "View selection" */
	MFilesWidgetConfigTabViewSelection = "MFiles_Widget_Config_Tab_View_Selection",

	/** English translation: "Visual" */
	MFilesWidgetConfigTabVisual = "MFiles_Widget_Config_Tab_Visual",

	/** English translation: "This specifies how the content is saved when a user uploads many items at once" */
	MFilesWidgetConfigUploadFileOptions = "MFiles_Widget_Config_Upload_File_Options",

	/** English translation: "Save each item as a single-file document" */
	MFilesWidgetConfigUploadFileOptionsDocument = "MFiles_Widget_Config_Upload_File_Options_Document",

	/** English translation: "Save each item as a single-file document and add them to a document collection" */
	MFilesWidgetConfigUploadFileOptionsDocumentCollection = "MFiles_Widget_Config_Upload_File_Options_Document_Collection",

	/** English translation: "Save all items to a multi-file document" */
	MFilesWidgetConfigUploadFileOptionsMultiFile = "MFiles_Widget_Config_Upload_File_Options_Multi_File",

	/** English translation: "The property value is automatically defined in M-Files." */
	MFilesWidgetConfigValueIsAutomatic = "MFiles_Widget_Config_Value_Is_Automatic",

	/** English translation: "Index" */
	MFilesWidgetConfigViewFilterIndex = "MFiles_Widget_Config_View_Filter_Index",

	/** English translation: "No filter configured. WARNING. All objects from the vault will be shown." */
	MFilesWidgetConfigWarningEmpty = "MFiles_Widget_Config_Warning_Empty",

	/** English translation: "Identical properties with different values detected between view and upload metadata. Save the configuration anyway?" */
	MFilesWidgetConfigWarningPropertyDiff = "MFiles_Widget_Config_Warning_Property_Diff",

	/** English translation: "Creates objects to an M-Files vault" */
	MFilesWidgetCreatorDescription = "MFiles_Widget_Creator_Description",

	/** English translation: "Do you want to check it out so that you can edit it?" */
	MFilesWidgetDoYouWantToCheckoutToEdit = "MFiles_Widget_Do_You_Want_To_Checkout_To_Edit",

	/** English translation: "Shows the specified contents of an M-Files vault" */
	MFilesWidgetExplorerDescription = "MFiles_Widget_Explorer_Description",

	/** English translation: "Sorry, M-Files Hubshare can't show the widget content. If you know who added the widget, please ask them to review its configuration." */
	MFilesWidgetExtensionConfigurationNotFound = "MFiles_Widget_Extension_Configuration_Not_Found",

	/** English translation: "Apply Filters" */
	MFilesWidgetFilterMetadataApplyFilters = "MFiles_Widget_Filter_Metadata_Apply_Filters",

	/** English translation: "Save as a new filter" */
	MFilesWidgetFilterMetadataCreateNewFilter = "MFiles_Widget_Filter_Metadata_Create_New_Filter",

	/** English translation: "Save as a new filter" */
	MFilesWidgetFilterMetadataCreateNewFilterConfiguration = "MFiles_Widget_Filter_Metadata_Create_New_Filter_Configuration",

	/** English translation: "Your filter conditions are saved as a new filter." */
	MFilesWidgetFilterMetadataDoYouWantToCreateNewFilter = "MFiles_Widget_Filter_Metadata_Do_You_Want_To_Create_New_Filter",

	/** English translation: "Your filter conditions are saved to the selected filter." */
	MFilesWidgetFilterMetadataDoYouWantToUpdateYourFilter = "MFiles_Widget_Filter_Metadata_Do_You_Want_To_Update_Your_Filter",

	/** English translation: "Edit filter" */
	MFilesWidgetFilterMetadataRenameFilterConfiguration = "MFiles_Widget_Filter_Metadata_Rename_Filter_Configuration",

	/** English translation: "Reset" */
	MFilesWidgetFilterMetadataResetAllFilters = "MFiles_Widget_Filter_Metadata_Reset_All_Filters",

	/** English translation: "Save filter" */
	MFilesWidgetFilterMetadataSaveCurrentFilters = "MFiles_Widget_Filter_Metadata_Save_Current_Filters",

	/** English translation: "Save to the selected filter" */
	MFilesWidgetFilterMetadataUpdateFilter = "MFiles_Widget_Filter_Metadata_Update_Filter",

	/** English translation: "Save filter" */
	MFilesWidgetFilterMetadataUpdateFilterConfiguration = "MFiles_Widget_Filter_Metadata_Update_Filter_Configuration",

	/** English translation: "No result." */
	MFilesWidgetNoResult = "MFiles_Widget_No_Result",

	/** English translation: "Shows the specified contents of an M-Files view" */
	MFilesWidgetViewsDescription = "MFiles_Widget_Views_Description",

	/** English translation: "Widget configuration" */
	ModalAddWidgetBreadcrumbStepConfigurationData = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationData",

	/** English translation: "Chart configuration" */
	ModalAddWidgetBreadcrumbStepConfigurationDataChart = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationData_Chart",

	/** English translation: "Content management" */
	ModalAddWidgetBreadcrumbStepConfigurationDataCustomContent = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationData_CustomContent",

	/** English translation: "Shown documents" */
	ModalAddWidgetBreadcrumbStepConfigurationDataDocuments = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationData_Documents",

	/** English translation: "Shown users" */
	ModalAddWidgetBreadcrumbStepConfigurationDataPeople = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationData_People",

	/** English translation: "Link management" */
	ModalAddWidgetBreadcrumbStepConfigurationDataUsefulLinks = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationData_UsefulLinks",

	/** English translation: "Widget selection" */
	ModalAddWidgetBreadcrumbStepConfigurationKind = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationKind",

	/** English translation: "Widget rights" */
	ModalAddWidgetBreadcrumbStepConfigurationRights = "Modal_Add_Widget_Breadcrumb_Step_ConfigurationRights",

	/** English translation: "Select a resource" */
	ModalResourceSelectorTitle = "Modal_ResourceSelector_Title",

	/** English translation: "Some selected users currently use Hubshare accounts through API with the following application(s)." */
	ModalRevokeApiAuthMainText = "Modal_Revoke_Api_Auth_Main_Text",

	/** English translation: "Selected users are inactive. You can close this window." */
	ModalRevokeApiAuthNoClient = "Modal_Revoke_Api_Auth_No_Client",

	/** English translation: "If you want to force users to configure the 2FA immediately, disconnect them from the application(s).
If not, they will have to configure their 2FA at their next login." */
	ModalRevokeApiAuthSecondText = "Modal_Revoke_Api_Auth_Second_Text",

	/** English translation: "Revoke current API authentication" */
	ModalRevokeApiAuthTitle = "Modal_Revoke_Api_Auth_Title",

	/** English translation: "Custom field selection" */
	ModalSelectCustomFieldTitle = "Modal_Select_Custom_Field_Title",

	/** English translation: "File module is disabled." */
	ModuleFileDisabled = "Module_File_Disabled",

	/** English translation: "Color of module title" */
	ModuleTitleColour = "Module_Title_Colour",

	/** English translation: "My hubs" */
	MyHubs = "My_Hubs",

	/** English translation: "The hub and all its contents will be permanently deleted. Are you sure you want to delete this hub?" */
	MyHubsConfirmDeleteHub = "My_Hubs_Confirm_Delete_Hub",

	/** English translation: "Create new hub" */
	MyHubsCreateNewHub = "My_Hubs_Create_New_Hub",

	/** English translation: "Show as list" */
	MyHubsDisplayList = "My_Hubs_Display_List",

	/** English translation: "Filters" */
	MyHubsFilters = "My_Hubs_Filters",

	/** English translation: "All" */
	MyHubsFilterAll = "My_Hubs_Filter_All",

	/** English translation: "Archived" */
	MyHubsFilterArchived = "My_Hubs_Filter_Archived",

	/** English translation: "Favorites" */
	MyHubsFilterFavorites = "My_Hubs_Filter_Favorites",

	/** English translation: "My hubs" */
	MyHubsFilterMyHubs = "My_Hubs_Filter_My_Hubs",

	/** English translation: "Shared to me" */
	MyHubsFilterSharedToMe = "My_Hubs_Filter_Shared_To_Me",

	/** English translation: "Hub creation in progress ..." */
	MyHubsInCreation = "My_Hubs_In_Creation",

	/** English translation: "Manage custom fields" */
	MyHubsManageCustomFields = "My_Hubs_Manage_Custom_Fields",

	/** English translation: "Manage templates" */
	MyHubsManageTemplates = "My_Hubs_Manage_Templates",

	/** English translation: "The hubs that you create or are invited to are shown here." */
	MyHubsNoDataDescription = "My_Hubs_No_Data_Description",

	/** English translation: "Open in new tab" */
	MyHubsOpenNewTab = "My_Hubs_Open_New_Tab",

	/** English translation: "Show details" */
	MyHubsShowDetails = "My_Hubs_Show_Details",

	/** English translation: "User count" */
	MyHubsUserCount = "My_Hubs_User_Count",

	/** English translation: "Notification center" */
	NotificationsNotificationCenter = "Notifications_Notification_Center",

	/** English translation: "added a new comment for" */
	NotificationCommentCreation = "Notification_Comment_Creation",

	/** English translation: "mentioned you in a comment for" */
	NotificationCommentMention = "Notification_Comment_Mention",

	/** English translation: "<b>{0}</b> sent you an invitation to join their collaboration space in M-Files Hubshare." */
	NotificationCompanyInvitationInformation = "Notification_CompanyInvitation_Information",

	/** English translation: "Remove" */
	NotificationDelete = "Notification_Delete",

	/** English translation: "No new notifications" */
	NotificationEmpty = "Notification_Empty",

	/** English translation: "Mark as read" */
	NotificationMarkAsRead = "Notification_Mark_As_Read",

	/** English translation: "An error occurred: " */
	NotificationMessageActiveDirectoryError = "Notification_Message_Active_Directory_Error",

	/** English translation: "You must define at least one recipient." */
	NotificationMessageActiveDirectoryErrorRecipients = "Notification_Message_Active_Directory_Error_Recipients",

	/** English translation: "There is at least one empty email address." */
	NotificationMessageActiveDirectoryErrorRecipientsEmpty = "Notification_Message_Active_Directory_Error_Recipients_Empty",

	/** English translation: "The refresh rate must be a positive number." */
	NotificationMessageActiveDirectoryErrorRefreshRate = "Notification_Message_Active_Directory_Error_Refresh_Rate",

	/** English translation: "You must define the server address." */
	NotificationMessageActiveDirectoryErrorServer = "Notification_Message_Active_Directory_Error_Server",

	/** English translation: "You must define the users group parameter." */
	NotificationMessageActiveDirectoryErrorUser = "Notification_Message_Active_Directory_Error_User",

	/** English translation: "Success! You have updated the active directory configuration." */
	NotificationMessageActiveDirectorySaveSuccess = "Notification_Message_Active_Directory_Save_Success",

	/** English translation: "You have synchronized the active directory." */
	NotificationMessageActiveDirectorySyncSuccess = "Notification_Message_Active_Directory_Sync_Success",

	/** English translation: "An error occurred." */
	NotificationMessageError = "Notification_Message_Error",

	/** English translation: "Success" */
	NotificationMessageSuccess = "Notification_Message_Success",

	/** English translation: "Something went wrong. {0}" */
	NotificationMFilesDownloadFilesContentError = "Notification_MFilesDownloadFilesContent_Error",

	/** English translation: "Creating the ZIP file." */
	NotificationMFilesDownloadFilesContentInProgress = "Notification_MFilesDownloadFilesContent_InProgress",

	/** English translation: "Waiting..." */
	NotificationMFilesDownloadFilesContentPending = "Notification_MFilesDownloadFilesContent_Pending",

	/** English translation: "ZIP file created." */
	NotificationMFilesDownloadFilesContentSuccess = "Notification_MFilesDownloadFilesContent_Success",

	/** English translation: "Something went wrong. {0}" */
	NotificationMFilesDownloadViewContentError = "Notification_MFilesDownloadViewContent_Error",

	/** English translation: "Creating the ZIP file." */
	NotificationMFilesDownloadViewContentInProgress = "Notification_MFilesDownloadViewContent_InProgress",

	/** English translation: "Waiting..." */
	NotificationMFilesDownloadViewContentPending = "Notification_MFilesDownloadViewContent_Pending",

	/** English translation: "ZIP created." */
	NotificationMFilesDownloadViewContentSuccess = "Notification_MFilesDownloadViewContent_Success",

	/** English translation: "Something went wrong when {0} was copied to {1}." */
	NotificationResourceCopyError = "Notification_ResourceCopy_Error",

	/** English translation: "The folder is being copied." */
	NotificationResourceCopyInProgress = "Notification_ResourceCopy_InProgress",

	/** English translation: "The folder has been copied to {0}." */
	NotificationResourceCopySuccess = "Notification_ResourceCopy_Success",

	/** English translation: "Resource copy" */
	NotificationResourceCopyTitle = "Notification_ResourceCopy_Title",

	/** English translation: "An error occurred while deleting {0}." */
	NotificationResourceDeleteError = "Notification_ResourceDelete_Error",

	/** English translation: "The folder is being deleted." */
	NotificationResourceDeleteInProgress = "Notification_ResourceDelete_InProgress",

	/** English translation: "The folder "{0}" has been deleted." */
	NotificationResourceDeleteSuccess = "Notification_ResourceDelete_Success",

	/** English translation: "Resource deletion" */
	NotificationResourceDeleteTitle = "Notification_ResourceDelete_Title",

	/** English translation: "An error occurred while moving {0} to {1}." */
	NotificationResourceMoveError = "Notification_ResourceMove_Error",

	/** English translation: "The folder is being moved." */
	NotificationResourceMoveInProgress = "Notification_ResourceMove_InProgress",

	/** English translation: "The folder has been moved to {0}." */
	NotificationResourceMoveSuccess = "Notification_ResourceMove_Success",

	/** English translation: "Resource move" */
	NotificationResourceMoveTitle = "Notification_ResourceMove_Title",

	/** English translation: "Error occured during zip creation." */
	NotificationResourceZipError = "Notification_ResourceZip_Error",

	/** English translation: "In progress" */
	NotificationResourceZipInProgress = "Notification_ResourceZip_InProgress",

	/** English translation: "Pending" */
	NotificationResourceZipPending = "Notification_ResourceZip_Pending",

	/** English translation: "Success <a href="{0}">Click here to download</a>" */
	NotificationResourceZipSuccess = "Notification_ResourceZip_Success",

	/** English translation: "Access denied" */
	NotificationResourceDetailAccessDenied = "Notification_Resource_Detail_AccessDenied",

	/** English translation: "This folder contains items you don't have sufficient rights for." */
	NotificationResourceDetailChildNotAccessible = "Notification_Resource_Detail_ChildNotAccessible",

	/** English translation: "The operation was cancelled." */
	NotificationResourceDetailDiscarded = "Notification_Resource_Detail_Discarded",

	/** English translation: "There isn't enough storage space." */
	NotificationResourceDetailInsufficientStorageSpace = "Notification_Resource_Detail_InsufficientStorageSpace",

	/** English translation: "The destination is not valid." */
	NotificationResourceDetailInvalidMove = "Notification_Resource_Detail_InvalidMove",

	/** English translation: "The resource name is not valid." */
	NotificationResourceDetailInvalidName = "Notification_Resource_Detail_InvalidName",

	/** English translation: "The resource is checked out." */
	NotificationResourceDetailLocked = "Notification_Resource_Detail_Locked",

	/** English translation: "There is a conflict name with the destination." */
	NotificationResourceDetailNameConflict = "Notification_Resource_Detail_NameConflict",

	/** English translation: "The resource can't be found." */
	NotificationResourceDetailNotFound = "Notification_Resource_Detail_NotFound",

	/** English translation: "The template "{0}" into a new {1} couldn't be copied." */
	NotificationRoomCopyError = "Notification_RoomCopy_Error",

	/** English translation: "The {0} "{1}" is now copied from template {2}." */
	NotificationRoomCopySuccess = "Notification_RoomCopy_Success",

	/** English translation: "Something went wrong." */
	NotificationRoomExportError = "Notification_RoomExport_Error",

	/** English translation: "List of export issues" */
	NotificationRoomExportErrorModalTitle = "Notification_RoomExport_Error_Modal_Title",

	/** English translation: "Exporting" */
	NotificationRoomExportInProgress = "Notification_RoomExport_InProgress",

	/** English translation: "Waiting" */
	NotificationRoomExportPending = "Notification_RoomExport_Pending",

	/** English translation: "Success! <a href="{0}">Download the file</a>" */
	NotificationRoomExportSuccess = "Notification_RoomExport_Success",

	/** English translation: "Something went wrong." */
	NotificationRoomImportError = "Notification_RoomImport_Error",

	/** English translation: "List of import issues" */
	NotificationRoomImportErrorModalTitle = "Notification_RoomImport_Error_Modal_Title",

	/** English translation: "Importing" */
	NotificationRoomImportInProgress = "Notification_RoomImport_InProgress",

	/** English translation: "Waiting" */
	NotificationRoomImportPending = "Notification_RoomImport_Pending",

	/** English translation: "<a href="{0}">Hub</a> imported" */
	NotificationRoomImportSuccess = "Notification_RoomImport_Success",

	/** English translation: "Seen" */
	NotificationSeen = "Notification_Seen",

	/** English translation: "Invitation received" */
	NotificationTitleCompanyInvitationInformation = "Notification_Title_CompanyInvitation_Information",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadFilesContentError = "Notification_Title_MFilesDownloadFilesContent_Error",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadFilesContentInProgress = "Notification_Title_MFilesDownloadFilesContent_InProgress",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadFilesContentPending = "Notification_Title_MFilesDownloadFilesContent_Pending",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadFilesContentSuccess = "Notification_Title_MFilesDownloadFilesContent_Success",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadViewContentError = "Notification_Title_MFilesDownloadViewContent_Error",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadViewContentInProgress = "Notification_Title_MFilesDownloadViewContent_InProgress",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadViewContentPending = "Notification_Title_MFilesDownloadViewContent_Pending",

	/** English translation: "Download from widget "{0}"" */
	NotificationTitleMFilesDownloadViewContentSuccess = "Notification_Title_MFilesDownloadViewContent_Success",

	/** English translation: "Updating M-Files icons for "{0}"" */
	NotificationTitleMFilesRefreshIconCacheError = "Notification_Title_MFilesRefreshIconCache_Error",

	/** English translation: "Updating M-Files icons for "{0}"" */
	NotificationTitleMFilesRefreshIconCacheInProgress = "Notification_Title_MFilesRefreshIconCache_InProgress",

	/** English translation: "Updating M-Files icons for "{0}"" */
	NotificationTitleMFilesRefreshIconCachePending = "Notification_Title_MFilesRefreshIconCache_Pending",

	/** English translation: "Updating M-Files icons for "{0}"" */
	NotificationTitleMFilesRefreshIconCacheSuccess = "Notification_Title_MFilesRefreshIconCache_Success",

	/** English translation: "Copying "{0}"" */
	NotificationTitleResourceCopyError = "Notification_Title_ResourceCopy_Error",

	/** English translation: "Copying "{0}"" */
	NotificationTitleResourceCopyInProgress = "Notification_Title_ResourceCopy_InProgress",

	/** English translation: "Copying "{0}"" */
	NotificationTitleResourceCopyPending = "Notification_Title_ResourceCopy_Pending",

	/** English translation: "Copy of "{0}"" */
	NotificationTitleResourceCopySuccess = "Notification_Title_ResourceCopy_Success",

	/** English translation: "Deleting "{0}"" */
	NotificationTitleResourceDeleteError = "Notification_Title_ResourceDelete_Error",

	/** English translation: "Deleting "{0}"" */
	NotificationTitleResourceDeleteInProgress = "Notification_Title_ResourceDelete_InProgress",

	/** English translation: "Deleting "{0}"" */
	NotificationTitleResourceDeletePending = "Notification_Title_ResourceDelete_Pending",

	/** English translation: "Folder deletion" */
	NotificationTitleResourceDeleteSuccess = "Notification_Title_ResourceDelete_Success",

	/** English translation: "Moving "{0}"" */
	NotificationTitleResourceMoveError = "Notification_Title_ResourceMove_Error",

	/** English translation: "Moving "{0}"" */
	NotificationTitleResourceMoveInProgress = "Notification_Title_ResourceMove_InProgress",

	/** English translation: "Moving "{0}"" */
	NotificationTitleResourceMovePending = "Notification_Title_ResourceMove_Pending",

	/** English translation: "Moving "{0}"" */
	NotificationTitleResourceMoveSuccess = "Notification_Title_ResourceMove_Success",

	/** English translation: "ZIP creation: {0}" */
	NotificationTitleResourceZipError = "Notification_Title_ResourceZip_Error",

	/** English translation: "ZIP creation: {0}" */
	NotificationTitleResourceZipInProgress = "Notification_Title_ResourceZip_InProgress",

	/** English translation: "ZIP creation: {0}" */
	NotificationTitleResourceZipPending = "Notification_Title_ResourceZip_Pending",

	/** English translation: "ZIP creation: {0}" */
	NotificationTitleResourceZipSuccess = "Notification_Title_ResourceZip_Success",

	/** English translation: "Creating a new {0} from template {1}." */
	NotificationTitleRoomCopyError = "Notification_Title_RoomCopy_Error",

	/** English translation: "Creating a new {0} from template {1}." */
	NotificationTitleRoomCopyInProgress = "Notification_Title_RoomCopy_InProgress",

	/** English translation: "Creating a new {0} from template {1}." */
	NotificationTitleRoomCopyPending = "Notification_Title_RoomCopy_Pending",

	/** English translation: "Creating a new {0} from template {1}." */
	NotificationTitleRoomCopySuccess = "Notification_Title_RoomCopy_Success",

	/** English translation: "Hub configurations export: {0}" */
	NotificationTitleRoomExportError = "Notification_Title_RoomExport_Error",

	/** English translation: "Hub configurations export: {0}" */
	NotificationTitleRoomExportInProgress = "Notification_Title_RoomExport_InProgress",

	/** English translation: "Hub configurations export: {0}" */
	NotificationTitleRoomExportPending = "Notification_Title_RoomExport_Pending",

	/** English translation: "Hub configurations export: {0}" */
	NotificationTitleRoomExportSuccess = "Notification_Title_RoomExport_Success",

	/** English translation: "Hub configurations import: {0}" */
	NotificationTitleRoomImportError = "Notification_Title_RoomImport_Error",

	/** English translation: "Hub configurations import: {0}" */
	NotificationTitleRoomImportInProgress = "Notification_Title_RoomImport_InProgress",

	/** English translation: "Hub configurations import: {0}" */
	NotificationTitleRoomImportPending = "Notification_Title_RoomImport_Pending",

	/** English translation: "Hub configurations import: {0}" */
	NotificationTitleRoomImportSuccess = "Notification_Title_RoomImport_Success",

	/** English translation: "Deleting user "{0}"" */
	NotificationTitleUserDeleteError = "Notification_Title_UserDelete_Error",

	/** English translation: "Deleting user "{0}"" */
	NotificationTitleUserDeleteInProgress = "Notification_Title_UserDelete_InProgress",

	/** English translation: "Deleting user "{0}"" */
	NotificationTitleUserDeletePending = "Notification_Title_UserDelete_Pending",

	/** English translation: "User "{0}" was deleted" */
	NotificationTitleUserDeleteSuccess = "Notification_Title_UserDelete_Success",

	/** English translation: "Disabling user "{0}"" */
	NotificationTitleUserDisableError = "Notification_Title_UserDisable_Error",

	/** English translation: "Disabling user "{0}"" */
	NotificationTitleUserDisableInProgress = "Notification_Title_UserDisable_InProgress",

	/** English translation: "Disabling user "{0}"" */
	NotificationTitleUserDisablePending = "Notification_Title_UserDisable_Pending",

	/** English translation: "User "{0}" was disabled" */
	NotificationTitleUserDisableSuccess = "Notification_Title_UserDisable_Success",

	/** English translation: "Created a new topic on" */
	NotificationTopicCreation = "Notification_Topic_Creation",

	/** English translation: "talks about an event on" */
	NotificationTopicEventCreation = "Notification_Topic_Event_Creation",

	/** English translation: "mentioned you in a topic on" */
	NotificationTopicMention = "Notification_Topic_Mention",

	/** English translation: "talks about a file or folder on" */
	NotificationTopicResourceCreation = "Notification_Topic_Resource_Creation",

	/** English translation: "talks about a task on" */
	NotificationTopicTaskCreation = "Notification_Topic_Task_Creation",

	/** English translation: "The user "{0}" couldn't be deleted. The user is now disabled, and their hubs, files, and hub templates were not deleted." */
	NotificationUserDeleteError = "Notification_UserDelete_Error",

	/** English translation: "The user is being deleted." */
	NotificationUserDeleteInProgress = "Notification_UserDelete_InProgress",

	/** English translation: "The user will be deleted soon." */
	NotificationUserDeletePending = "Notification_UserDelete_Pending",

	/** English translation: "The user "{0}" is now deleted." */
	NotificationUserDeleteSuccess = "Notification_UserDelete_Success",

	/** English translation: "The user "{0}" was disabled, but the user's hubs, files, and hub templates were not deleted." */
	NotificationUserDisableError = "Notification_UserDisable_Error",

	/** English translation: "The user is being disabled." */
	NotificationUserDisableInProgress = "Notification_UserDisable_InProgress",

	/** English translation: "The user will be disabled soon." */
	NotificationUserDisablePending = "Notification_UserDisable_Pending",

	/** English translation: "The user "{0}" is now disabled." */
	NotificationUserDisableSuccess = "Notification_UserDisable_Success",

	/** English translation: "You have no service providers linked to your account." */
	NoProvidersLinked = "No_Providers_Linked",

	/** English translation: "Options" */
	Options = "Options",

	/** English translation: "Are you sure you want to transfer the ownership of the hub template from {0} to {1} ?" */
	OwnershipConfirmHubTemplateTransfer = "Ownership_Confirm_Hub_Template_Transfer",

	/** English translation: "Are you sure you want to transfer the ownership of the hub from {0} to {1} ?" */
	OwnershipConfirmHubTransfer = "Ownership_Confirm_Hub_Transfer",

	/** English translation: "Are you sure you want to give the organization ownership to {0}?" */
	OwnershipConfirmRootHubTransfer = "Ownership_Confirm_Root_Hub_Transfer",

	/** English translation: "Are you sure you want to process these operations from {0} to {1}  :  {2} ?" */
	OwnershipConfirmUserResourcesTransfer = "Ownership_Confirm_User_Resources_Transfer",

	/** English translation: "Destination user" */
	OwnershipDestinationUser = "Ownership_Destination_User",

	/** English translation: "Disable the source user account after the transfer" */
	OwnershipDisableUser = "Ownership_Disable_User",

	/** English translation: "Hub to transfer" */
	OwnershipSelectHub = "Ownership_Select_Hub",

	/** English translation: "New hub owner" */
	OwnershipSelectHubReceiver = "Ownership_Select_Hub_Receiver",

	/** English translation: "Source user" */
	OwnershipSourceUser = "Ownership_Source_User",

	/** English translation: "The source user is already disabled." */
	OwnershipTransferAlreadyDisabled = "Ownership_Transfer_Already_Disabled",

	/** English translation: "An error occured. These resources were not moved" */
	OwnershipTransferErrorOccurred = "Ownership_Transfer_Error_Occurred",

	/** English translation: "Reason" */
	OwnershipTransferErrorReason = "Ownership_Transfer_Error_Reason",

	/** English translation: "Transfer a hub" */
	OwnershipTransferHub = "Ownership_Transfer_Hub",

	/** English translation: "Transfer hubs" */
	OwnershipTransferHubs = "Ownership_Transfer_Hubs",

	/** English translation: "You can't transfer hubs to a user who is not hub creator." */
	OwnershipTransferHubDisabled = "Ownership_Transfer_Hub_Disabled",

	/** English translation: "This option lets you transfer the ownership of a hub to another user." */
	OwnershipTransferHubLabel = "Ownership_Transfer_Hub_Label",

	/** English translation: "Transfer hub templates" */
	OwnershipTransferHubTemplates = "Ownership_Transfer_Hub_Templates",

	/** English translation: "You can't transfer hub templates to a user who is not a hub template creator." */
	OwnershipTransferHubTemplatesDisabled = "Ownership_Transfer_Hub_Templates_Disabled",

	/** English translation: "Transfer My documents" */
	OwnershipTransferMyDocuments = "Ownership_Transfer_My_Documents",

	/** English translation: "Transfer Outbox" */
	OwnershipTransferOutbox = "Ownership_Transfer_Outbox",

	/** English translation: "You can't transfer an outbox to a user who doesn't have an outbox folder." */
	OwnershipTransferOutboxDisabled = "Ownership_Transfer_Outbox_Disabled",

	/** English translation: "Change organization ownership" */
	OwnershipTransferRootHub = "Ownership_Transfer_Root_Hub",

	/** English translation: "You will transfer the ownership of your organization to another user." */
	OwnershipTransferRootHubLabel = "Ownership_Transfer_Root_Hub_Label",

	/** English translation: "Change this information carefully. You will have to confirm your changes when you click to confirm." */
	OwnershipTransferSublabel = "Ownership_Transfer_Sublabel",

	/** English translation: "These resources were successfully transferred" */
	OwnershipTransferSuccesses = "Ownership_Transfer_Successes",

	/** English translation: "Transfer user data" */
	OwnershipTransferUserResources = "Ownership_Transfer_User_Resources",

	/** English translation: "This option lets you transfer all data between users." */
	OwnershipTransferUserResourcesLabel = "Ownership_Transfer_User_Resources_Label",

	/** English translation: "Administration guide" */
	PageFooterAdministrationGuide = "Page_Footer_Administration_Guide",

	/** English translation: "User guide" */
	PageFooterAssistance = "Page_Footer_Assistance",

	/** English translation: "Confidentiality and utilization policy" */
	PageFooterConfidentiality = "Page_Footer_Confidentiality",

	/** English translation: "Admin guide" */
	PageFooterUserAdminGuide = "Page_Footer_User_Admin_Guide",

	/** English translation: "Password" */
	Password = "Password",

	/** English translation: "Invalid password" */
	PasswordConfirmError = "Password_Confirm_Error",

	/** English translation: "Please enter your password." */
	PasswordConfirmLabel = "Password_Confirm_Label",

	/** English translation: "Current password" */
	PasswordCurrentPassword = "Password_Current_Password",

	/** English translation: "If the entered address is associated with a known user, an email is sent with instructions on how to reset the password." */
	PasswordForgotEmailConfirm = "Password_Forgot_Email_Confirm",

	/** English translation: "Reset your password" */
	PasswordInitialize = "Password_Initialize",

	/** English translation: "New password" */
	PasswordNewPassword = "Password_New_Password",

	/** English translation: "The password contains a forbidden word." */
	PasswordRequirementBlacklistOnly = "Password_Requirement_Blacklist_Only",

	/** English translation: "The password contains a blacklist word {0}." */
	PasswordRequirementBlacklistWithDetails = "Password_Requirement_Blacklist_With_Details",

	/** English translation: "At least 8 characters long" */
	PasswordRequirementNbCharacters = "Password_Requirement_NbCharacters",

	/** English translation: "Contains numbers" */
	PasswordRequirementNumbers = "Password_Requirement_Numbers",

	/** English translation: "Contains special characters" */
	PasswordRequirementSpecialCharacters = "Password_Requirement_Special_Characters",

	/** English translation: "Contains uppercase and lowercase letters" */
	PasswordRequirementUpLowCase = "Password_Requirement_UpLowCase",

	/** English translation: "Password strength" */
	PasswordStrength = "Password_Strength",

	/** English translation: "Paste as text" */
	PasteAsText = "Paste_As_Text",

	/** English translation: "Add contact" */
	PeopleAddContact = "People_Add_Contact",

	/** English translation: "Create new group" */
	PeopleCreateGroup = "People_Create_Group",

	/** English translation: "Delete contact" */
	PeopleDeleteUser = "People_Delete_User",

	/** English translation: "Are you sure you want to delete this person?" */
	PeopleDeleteUserConfirmMessage = "People_Delete_User_Confirm_Message",

	/** English translation: "The contact couldn't be deleted." */
	PeopleDeleteUserError = "People_Delete_User_Error",

	/** English translation: "The contact was deleted." */
	PeopleDeleteUserSuccess = "People_Delete_User_Success",

	/** English translation: "Manage groups" */
	PeopleManageGroup = "People_Manage_Group",

	/** English translation: "The users couldn't be added." */
	PeopleModalAddUserErrorAdd = "People_Modal_Add_User_Error_Add",

	/** English translation: "Please select at least one user." */
	PeopleModalAddUserErrorNoUser = "People_Modal_Add_User_Error_No_User",

	/** English translation: "All selected users are already in the selected group." */
	PeopleModalAddUserErrorNoUserToAdd = "People_Modal_Add_User_Error_No_User_To_Add",

	/** English translation: "Please select at least one group." */
	PeopleModalAddUserErrorSelectOneGroup = "People_Modal_Add_User_Error_Select_One_Group",

	/** English translation: "Select a group to add contacts" */
	PeopleModalAddUserSelectGroup = "People_Modal_Add_User_Select_Group",

	/** English translation: "Select the contacts that you want to add" */
	PeopleModalAddUserSelectUser = "People_Modal_Add_User_Select_User",

	/** English translation: "The user was added." */
	PeopleModalAddUserSuccessAdd = "People_Modal_Add_User_Success_Add",

	/** English translation: "The users were added." */
	PeopleModalAddUserSuccessAddPlural = "People_Modal_Add_User_Success_Add_Plural",

	/** English translation: "The group couldn't be added." */
	PeopleModalManageGroupErrorAdd = "People_Modal_Manage_Group_Error_Add",

	/** English translation: "The group couldn't be deleted." */
	PeopleModalManageGroupErrorDelete = "People_Modal_Manage_Group_Error_Delete",

	/** English translation: "The group couldn't be edited." */
	PeopleModalManageGroupErrorEdit = "People_Modal_Manage_Group_Error_Edit",

	/** English translation: "Please select a parent group." */
	PeopleModalManageGroupErrorSelectGroupToAdd = "People_Modal_Manage_Group_Error_Select_Group_To_Add",

	/** English translation: "Please select the group you want to delete." */
	PeopleModalManageGroupErrorSelectGroupToDelete = "People_Modal_Manage_Group_Error_Select_Group_To_Delete",

	/** English translation: "Name of the group" */
	PeopleModalManageGroupGroupName = "People_Modal_Manage_Group_Group_Name",

	/** English translation: "The group was added." */
	PeopleModalManageGroupSuccessAdd = "People_Modal_Manage_Group_Success_Add",

	/** English translation: "The group was deleted." */
	PeopleModalManageGroupSuccessDelete = "People_Modal_Manage_Group_Success_Delete",

	/** English translation: "The group was modified." */
	PeopleModalManageGroupSuccessEdit = "People_Modal_Manage_Group_Success_Edit",

	/** English translation: "Create group" */
	PeopleModalManageGroupTitleCreate = "People_Modal_Manage_Group_Title_Create",

	/** English translation: "Manage group" */
	PeopleModalManageGroupTitleManage = "People_Modal_Manage_Group_Title_Manage",

	/** English translation: "The contact couldn't be moved." */
	PeopleModalMoveUserError = "People_Modal_Move_User_Error",

	/** English translation: "Select a group to move a contact" */
	PeopleModalMoveUserSelectGroup = "People_Modal_Move_User_Select_Group",

	/** English translation: "The contact was moved." */
	PeopleModalMoveUserSuccess = "People_Modal_Move_User_Success",

	/** English translation: "Move contact" */
	PeopleModalMoveUserTitle = "People_Modal_Move_User_Title",

	/** English translation: "Select the group you want to edit or delete" */
	PeopleModelManageGroupSelectGroup = "People_Model_Manage_Group_Select_Group",

	/** English translation: "Select parent group" */
	PeopleModelManageGroupSelectParentGroup = "People_Model_Manage_Group_Select_Parent_Group",

	/** English translation: "More information" */
	PeopleMoreInfo = "People_More_Info",

	/** English translation: "No groups" */
	PeopleNoGroup = "People_No_Group",

	/** English translation: "There are no users in this group." */
	PeopleNoPeople = "People_No_People",

	/** English translation: "User's groups" */
	PrefilledValueGroups = "Prefilled_Value_Groups",

	/** English translation: "Hub name" */
	PrefilledValueHubName = "Prefilled_Value_Hub_Name",

	/** English translation: "User's IP address" */
	PrefilledValueLoggedInUserIp = "Prefilled_Value_Logged_In_User_Ip",

	/** English translation: "Hub unique identifier" */
	PrefilledValueRoomUniqueIdentifier = "Prefilled_Value_Room_Unique_Identifier",

	/** English translation: "User email" */
	PrefilledValueUserEmail = "Prefilled_Value_User_Email",

	/** English translation: "Full name of the user" */
	PrefilledValueUserFullName = "Prefilled_Value_User_Full_Name",

	/** English translation: "Full name and email of the user" */
	PrefilledValueUserFullNameAndEmail = "Prefilled_Value_User_Full_Name_And_Email",

	/** English translation: "Widget name" */
	PrefilledValueWidgetName = "Prefilled_Value_Widget_Name",

	/** English translation: "M-Files Hubshare" */
	ProductName = "ProductName",

	/** English translation: "Please make a note that the information in your account profile is shown to all organizations that you have joined." */
	ProfileInformationsInfo = "Profile_Informations_Info",

	/** English translation: "When you have unlinked this account, you can't use the associated service provider anymore. Are you sure you want to unlink this account?" */
	ProviderUnlinkMessage = "Provider_Unlink_Message",

	/** English translation: "Enable" */
	PublicHubRegistry = "Public_Hub_Registry",

	/** English translation: ": " */
	PunctuationColon = "Punctuation_Colon",

	/** English translation: " ..." */
	PunctuationEllipsis = "Punctuation_Ellipsis",

	/** English translation: "! " */
	PunctuationExclamationMark = "Punctuation_Exclamation_Mark",

	/** English translation: "Redirecting" */
	RedirectionInProgress = "RedirectionInProgress",

	/** English translation: "Edit response" */
	RelativeFormAddDetail = "Relative_Form_Add_Detail",

	/** English translation: "Remove custom field" */
	RemoveCustomField = "Remove_Custom_Field",

	/** English translation: "Reset field" */
	ResetField = "Reset_Field",

	/** English translation: "Consulted resources" */
	ResourcesConsulted = "Resources_Consulted",

	/** English translation: "Created resources" */
	ResourcesCreated = "Resources_Created",

	/** English translation: "Deleted resources" */
	ResourcesDeleted = "Resources_Deleted",

	/** English translation: "Modified resources" */
	ResourcesModified = "Resources_Modified",

	/** English translation: "To see the shared documents, you must first accept the invitation from {0}." */
	ResourcesSharesCompanyInvitation = "Resources_Shares_Company_Invitation",

	/** English translation: "Enter the password that you have received by email." */
	ResourcesSharesFillPassword = "Resources_Shares_Fill_Password",

	/** English translation: "Passcode" */
	ResourcesSharesPassword = "Resources_Shares_Password",

	/** English translation: "Share with passcode" */
	ResourcesSharesPasswordTitle = "Resources_Shares_PasswordTitle",

	/** English translation: "Log in with your account if you have one" */
	ResourcesSharesUseLogin = "Resources_Shares_Use_Login",

	/** English translation: "Activities on resources" */
	ResourceActivities = "Resource_Activities",

	/** English translation: "Checked out" */
	ResourceCheckedOut = "Resource_Checked_Out",

	/** English translation: "Add files" */
	ResourceExplorerAddFiles = "Resource_Explorer_Add_Files",

	/** English translation: "Add and extract ZIP" */
	ResourceExplorerAddZipExtract = "Resource_Explorer_Add_Zip_Extract",

	/** English translation: "And all its content" */
	ResourceExplorerAndAllItsContent = "Resource_Explorer_And_All_Its_Content",

	/** English translation: "The files and folders in this recycle bin are automatically deleted after {0} days of retention." */
	ResourceExplorerBinRetentionInfo = "Resource_Explorer_Bin_Retention_Info",

	/** English translation: "Create folder" */
	ResourceExplorerCreateFolder = "Resource_Explorer_Create_Folder",

	/** English translation: "Create new folder" */
	ResourceExplorerCreateNewFolder = "Resource_Explorer_Create_New_Folder",

	/** English translation: "Creation date" */
	ResourceExplorerCreationDate = "Resource_Explorer_Creation_Date",

	/** English translation: "Do you want to delete these items?" */
	ResourceExplorerDeleteResourcesConfirm = "Resource_Explorer_Delete_Resources_Confirm",

	/** English translation: "Do you want to delete this item?" */
	ResourceExplorerDeleteResourceConfirm = "Resource_Explorer_Delete_Resource_Confirm",

	/** English translation: "Show as grid" */
	ResourceExplorerDisplayAsAGrid = "Resource_Explorer_Display_As_A_Grid",

	/** English translation: "Show as list" */
	ResourceExplorerDisplayAsAList = "Resource_Explorer_Display_As_A_List",

	/** English translation: "Show file index" */
	ResourceExplorerDisplayFileIndex = "Resource_Explorer_Display_File_Index",

	/** English translation: "Download and check out" */
	ResourceExplorerDownloadAndCheckOut = "Resource_Explorer_Download_And_Check_Out",

	/** English translation: "Do you want to permanently delete the files and folders in the recycle bin?" */
	ResourceExplorerEmptyBinConfirm = "Resource_Explorer_Empty_Bin_Confirm",

	/** English translation: "This folder is empty." */
	ResourceExplorerEmptyFolder = "Resource_Explorer_Empty_Folder",

	/** English translation: "Empty recycle bin" */
	ResourceExplorerEmptyRecycleBin = "Resource_Explorer_Empty_RecycleBin",

	/** English translation: "Do you want to permanently delete these documents?" */
	ResourceExplorerEraseDocumentsConfirm = "Resource_Explorer_Erase_Documents_Confirm",

	/** English translation: "Do you want to permanently delete this file?" */
	ResourceExplorerEraseFileConfirm = "Resource_Explorer_Erase_File_Confirm",

	/** English translation: "Do you want to permanently delete this folder?" */
	ResourceExplorerEraseFolderConfirm = "Resource_Explorer_Erase_Folder_Confirm",

	/** English translation: "Favorites" */
	ResourceExplorerFavourites = "Resource_Explorer_Favourites",

	/** English translation: "Latest deletion" */
	ResourceExplorerLatestDeletion = "Resource_Explorer_Latest_Deletion",

	/** English translation: "Latest update" */
	ResourceExplorerLatestUpdate = "Resource_Explorer_Latest_Update",

	/** English translation: "Manage user rights" */
	ResourceExplorerManageUserRights = "Resource_Explorer_Manage_User_Rights",

	/** English translation: "Mark as favorite" */
	ResourceExplorerMarkAsFavourite = "Resource_Explorer_Mark_As_Favourite",

	/** English translation: "You don't have rights to access this module." */
	ResourceExplorerNoRights = "Resource_Explorer_No_Rights",

	/** English translation: "Number of comments" */
	ResourceExplorerNumberOfComments = "Resource_Explorer_Number_Of_Comments",

	/** English translation: "Number of files in the current directory" */
	ResourceExplorerNumberOfFilesInCurrentDirectory = "Resource_Explorer_Number_Of_Files_In_Current_Directory",

	/** English translation: "Number of folders in the current directory" */
	ResourceExplorerNumberOfFoldersInCurrentDirectory = "Resource_Explorer_Number_Of_Folders_In_Current_Directory",

	/** English translation: "Number of users who can access the current directory" */
	ResourceExplorerNumberOfUsersCurrentDirectoryAccess = "Resource_Explorer_Number_Of_Users_Current_Directory_Access",

	/** English translation: "Oldest deletion" */
	ResourceExplorerOldestDeletion = "Resource_Explorer_Oldest_Deletion",

	/** English translation: "Oldest update" */
	ResourceExplorerOldestUpdate = "Resource_Explorer_Oldest_Update",

	/** English translation: "Open file index" */
	ResourceExplorerOpenFileIndex = "Resource_Explorer_Open_File_Index",

	/** English translation: "Do you want to move these documents to the recycle bin?" */
	ResourceExplorerRecycleDocumentsConfirm = "Resource_Explorer_Recycle_Documents_Confirm",

	/** English translation: "Do you want to move this file to the recycle bin?" */
	ResourceExplorerRecycleFileConfirm = "Resource_Explorer_Recycle_File_Confirm",

	/** English translation: "Do you want to move this folder to the recycle bin?" */
	ResourceExplorerRecycleFolderConfirm = "Resource_Explorer_Recycle_Folder_Confirm",

	/** English translation: "Reindex this folder" */
	ResourceExplorerReindexFileIndex = "Resource_Explorer_Reindex_File_Index",

	/** English translation: "Remove from favorites" */
	ResourceExplorerRemoveFromFavourites = "Resource_Explorer_Remove_From_Favourites",

	/** English translation: "Do you want to restore these documents?" */
	ResourceExplorerRestoreDocumentsConfirm = "Resource_Explorer_Restore_Documents_Confirm",

	/** English translation: "Do you want to restore this file?" */
	ResourceExplorerRestoreFileConfirm = "Resource_Explorer_Restore_File_Confirm",

	/** English translation: "Do you want to restore this folder?" */
	ResourceExplorerRestoreFolderConfirm = "Resource_Explorer_Restore_Folder_Confirm",

	/** English translation: "Creation rights are needed for the destination folder." */
	ResourceExplorerRightsOnMove = "Resource_Explorer_Rights_On_Move",

	/** English translation: "Recycle bin" */
	ResourceExplorerRoomBin = "Resource_Explorer_RoomBin",

	/** English translation: "Selected destination" */
	ResourceExplorerSelectedDestination = "Resource_Explorer_Selected_Destination",

	/** English translation: "Select new destination" */
	ResourceExplorerSelectNewDestination = "Resource_Explorer_Select_New_Destination",

	/** English translation: "Do you want to unsubscribe from these shares?" */
	ResourceExplorerUnsubscribeDocumentsConfirm = "Resource_Explorer_Unsubscribe_Documents_Confirm",

	/** English translation: "Upload folder" */
	ResourceExplorerUploadFolder = "Resource_Explorer_Upload_Folder",

	/** English translation: "Select ZIP file" */
	ResourceExplorerUploadSelectZip = "Resource_Explorer_Upload_Select_Zip",

	/** English translation: "You can upload a folder and keep its structure, for example all its subfolders and files, with one upload. All you have to do is make a ZIP out of your folder and select the ZIP with the button below. The system will extract the ZIP file to your current Hubshare folder, and keep the same hierarchy.This can take some time. You will receive a notification email when the process is completed." */
	ResourceExplorerUploadZip = "Resource_Explorer_Upload_Zip",

	/** English translation: "This can take some time. You will receive a notification email when the process is completed." */
	ResourceExplorerUploadZipProcessTakeTime = "Resource_Explorer_Upload_Zip_ProcessTakeTime",

	/** English translation: "Recycle bin" */
	ResourceExplorerUserBin = "Resource_Explorer_UserBin",

	/** English translation: "View in new window" */
	ResourceExplorerViewInNewWindow = "Resource_Explorer_View_In_New_Window",

	/** English translation: "Please rename the selected file to add it to your selection" */
	ResourceFileSelectionContainsDuplicatedNames = "Resource_File_Selection_Contains_Duplicated_Names",

	/** English translation: "Please rename the selected folder to add it to your selection" */
	ResourceFolderSelectionContainsDuplicatedNames = "Resource_Folder_Selection_Contains_Duplicated_Names",

	/** English translation: "Hosted by" */
	ResourceHostedBy = "Resource_Hosted_By",

	/** English translation: "The resource couldn't be found." */
	ResourceNotFound = "Resource_Not_Found",

	/** English translation: "Sorry but the selected items can't have the same name." */
	ResourcePleaseRenameItBeforeAddingItToSelection = "Resource_Please_Rename_It_Before_Adding_It_To_Selection",

	/** English translation: "Shared by me" */
	ResourceSharedByMe = "Resource_Shared_By_Me",

	/** English translation: "Shared to me" */
	ResourceSharedToMe = "Resource_Shared_To_Me",

	/** English translation: "Latest creation date" */
	ResourceSortLatestCreationDate = "Resource_Sort_Latest_Creation_Date",

	/** English translation: "Oldest creation date" */
	ResourceSortOldestCreationDate = "Resource_Sort_Oldest_Creation_Date",

	/** English translation: "Updated by" */
	ResourceUpdatedBy = "Resource_Updated_By",

	/** English translation: "Show search bar and icon" */
	RoomShowSearch = "Room_Show_Search",

	/** English translation: "The group or subgroup is linked to at least one widget. If you delete this group or subgroup, the related widgets are also deleted." */
	RoomTeamLinkedToWidget = "Room_Team_Linked_To_Widget",

	/** English translation: "Show only selected hubs" */
	RoomTreeViewHideNotChecked = "Room_Tree_View_Hide_Not_Checked",

	/** English translation: "Save and go to my hub template" */
	S = "S",

	/** English translation: "The search couldn't be completed." */
	SearchError = "Search_Error",

	/** English translation: "The search is invalid." */
	SearchInvalidSearch = "Search_Invalid_Search",

	/** English translation: "The search was stopped." */
	SearchStopped = "Search_Stopped",

	/** English translation: "The search word must contain at least 3 characters." */
	SearchValueToSmall = "Search_Value_To_Small",

	/** English translation: "Please confirm that you want to delete this user rights template." */
	SecurityTemplateConfirmDelete = "Security_Template_Confirm_Delete",

	/** English translation: "You must select a custom field." */
	SelectCustomFieldNoSelection = "Select_Custom_Field_No_Selection",

	/** English translation: "Send new invitation email" */
	SendNewEmail = "SendNewEmail",

	/** English translation: "Service providers" */
	ServiceProviders = "Service_Providers",

	/** English translation: "Applications can be granted access to your Hubshare account. You can see and edit the permissions of the connected applications. " */
	ServiceProvidersDescription = "Service_Providers_Description",

	/** English translation: "Disable emails when there are no changes" */
	SettingsActiveDirectoryDisableEmail = "Settings_Active_Directory_Disable_Email",

	/** English translation: "Active Directory group" */
	SettingsActiveDirectoryGroup = "Settings_Active_Directory_Group",

	/** English translation: "No syncs" */
	SettingsActiveDirectoryNoSyncDate = "Settings_Active_Directory_No_Sync_Date",

	/** English translation: "Activity" */
	SettingsActivity = "Settings_Activity",

	/** English translation: "See your activities." */
	SettingsActivityDescription = "Settings_Activity_Description",

	/** English translation: "See the activities of all users." */
	SettingsActivityDescriptionAdmin = "Settings_Activity_Description_Admin",

	/** English translation: "If you don't add the user to a group, you have to manually give module access rights to the user." */
	SettingsAddUserGroupInformation = "Settings_Add_User_Group_Information",

	/** English translation: "Administration" */
	SettingsAdministration = "Settings_Administration",

	/** English translation: "Manage organization-level settings." */
	SettingsAdministrationDescription = "Settings_Administration_Description",

	/** English translation: "All" */
	SettingsAll = "Settings_All",

	/** English translation: "Audit trail" */
	SettingsAuditTrail = "Settings_Audit_Trail",

	/** English translation: "This group is bound with an Active Directory group and can't be deleted. To delete this group, remove the group binding in Active Directory synchronization settings." */
	SettingsCantDeleteADGroup = "Settings_Cant_Delete_AD_Group",

	/** English translation: "You can't add or remove members from a Hubshare group if the group is bound to an Active Directory group." */
	SettingsCantEditADGroupMembers = "Settings_Cant_Edit_AD_Group_Members",

	/** English translation: "Can request document signatures" */
	SettingsCanRequestDocumentSignature = "Settings_Can_Request_Document_Signature",

	/** English translation: "Can use login and password" */
	SettingsCanUseLoginPassword = "Settings_Can_Use_Login_Password",

	/** English translation: "Convert to internal user" */
	SettingsConvertExternalUser = "Settings_Convert_External_User",

	/** English translation: "Create external user" */
	SettingsCreateExternalUser = "Settings_Create_External_User",

	/** English translation: "Create group" */
	SettingsCreateGroup = "Settings_Create_Group",

	/** English translation: "Create internal user" */
	SettingsCreateInternalUser = "Settings_Create_Internal_User",

	/** English translation: "Create new group" */
	SettingsCreateNewGroup = "Settings_Create_New_Group",

	/** English translation: "Add new binding rule" */
	SettingsCreateNewGroupBinding = "Settings_Create_New_Group_Binding",

	/** English translation: "Create user rights template" */
	SettingsCreateSecurity = "Settings_Create_Security",

	/** English translation: "Disk quota" */
	SettingsDiskQuota = "Settings_Disk_Quota",

	/** English translation: "Disk usage" */
	SettingsDiskUsage = "Settings_Disk_Usage",

	/** English translation: "Edit group" */
	SettingsEditGroup = "Settings_Edit_Group",

	/** English translation: "Edit user rights template" */
	SettingsEditSecurity = "Settings_Edit_Security",

	/** English translation: "Private contact" */
	SettingsExternalUserPrivate = "Settings_ExternalUser_Private",

	/** English translation: "The user account is already validated." */
	SettingsExternalUserAlreadyValidated = "Settings_External_User_Already_Validated",

	/** English translation: "Please give at least a name with description and check a right." */
	SettingsFieldsRequired = "Settings_Fields_Required",

	/** English translation: "File module" */
	SettingsFilesModule = "Settings_Files_Module",

	/** English translation: "Add user to group" */
	SettingsGroupAddUser = "Settings_Group_Add_User",

	/** English translation: "You can  synchronize your Active Directory groups to Hubshare groups. Synchronization creates accounts for the Active Directory group users and adds these users to the selected Hubshare group." */
	SettingsGroupBindingInformation = "Settings_Group_Binding_Information",

	/** English translation: "You must give a description for the group." */
	SettingsGroupDescriptionRequired = "Settings_Group_Description_Required",

	/** English translation: "This group name is already in use. Please enter another name." */
	SettingsGroupNameAlreadyExist = "Settings_Group_Name_Already_Exist",

	/** English translation: "You must give a name for the group." */
	SettingsGroupNameRequired = "Settings_Group_Name_Required",

	/** English translation: "Hubshare group" */
	SettingsHubshareGroup = "Settings_Hubshare_Group",

	/** English translation: "Hub creator" */
	SettingsHubAdmin = "Settings_Hub_Admin",

	/** English translation: "An administrator has rights in all widgets." */
	SettingsHubAdministratorAllRights = "Settings_Hub_Administrator_All_Rights",

	/** English translation: "An administrator has all rights in the project module." */
	SettingsHubAdministratorAllRightsProject = "Settings_Hub_Administrator_All_Rights_Project",

	/** English translation: "Manage widget rights" */
	SettingsHubManageWidgetRights = "Settings_Hub_Manage_Widget_Rights",

	/** English translation: "Available modules" */
	SettingsHubModulesAvailable = "Settings_Hub_Modules_Available",

	/** English translation: "Please select at least one module." */
	SettingsHubModulesSelectOneModule = "Settings_Hub_Modules_Select_One_Module",

	/** English translation: "The modules couldn't be edited." */
	SettingsHubModulesUpdateError = "Settings_Hub_Modules_Update_Error",

	/** English translation: "Please select at least one module." */
	SettingsHubModulesUpdateErrorAtLeastOneModule = "Settings_Hub_Modules_Update_Error_At_Least_One_Module",

	/** English translation: "You have successfully edited the modules configuration for this hub." */
	SettingsHubModulesUpdateSuccess = "Settings_Hub_Modules_Update_Success",

	/** English translation: "Hub template creator" */
	SettingsHubTemplateCreator = "Settings_Hub_Template_Creator",

	/** English translation: "Manage users" */
	SettingsHubUsersManageUsers = "Settings_Hub_Users_Manage_Users",

	/** English translation: "You can't delete a hub creator." */
	SettingsHubUserCantRemoveOwner = "Settings_Hub_User_Cant_Remove_Owner",

	/** English translation: "Please confirm that you want to remove this user from this hub." */
	SettingsHubUserRemoveConfirmMessage = "Settings_Hub_User_Remove_Confirm_Message",

	/** English translation: "Please confirm that you want to remove this group from this hub." */
	SettingsHubUserRemoveGroupConfirmMessage = "Settings_Hub_User_Remove_Group_Confirm_Message",

	/** English translation: "This module is not enbaled for this user. The widget is not shown." */
	SettingsHubWidgetRightsModuleDisabledForUser = "Settings_Hub_Widget_Rights_Module_Disabled_For_User",

	/** English translation: "Configuring the import of CSV information" */
	SettingsImportCSVConfigFields = "Settings_Import_CSV_Config_Fields",

	/** English translation: "Firstname;Last name;E-mail;Disk quota;Admin;Outlook plugin;Outlook plugin settings;Groups." */
	SettingsImportCSVConfigHelp = "Settings_Import_CSV_Config_Help",

	/** English translation: "You can import users from a CSV file in the following form:" */
	SettingsImportCSVConfigHelpBeguining = "Settings_Import_CSV_Config_Help_Beguining",

	/** English translation: "The default encoding of the model is 'UNICODE (65001)' and the separator is ';'." */
	SettingsImportCSVconfigHelpDefaultConfig = "Settings_Import_CSV_config_Help_DefaultConfig",

	/** English translation: "If the first line in your file contains column names, please check the corresponding box. Required fields are last name, first name, and email. If the email address is prefilled, the user will be updated." */
	SettingsImportCSVConfigHelpEnd = "Settings_Import_CSV_Config_Help_End",

	/** English translation: "Firstname;Last name;E-mail;Groups." */
	SettingsImportCSVConfigHelpExternal = "Settings_Import_CSV_Config_Help_External",

	/** English translation: "It is possible to enter many groups. To enter many groups, separate the groups with the character "|"." */
	SettingsImportCSVconfigHelpGroups = "Settings_Import_CSV_config_Help_Groups",

	/** English translation: "Firstname;Last name;E-mail;Disk quota;Admin;Outlook plugin;Outlook plugin settings;Hub creator;Groups." */
	SettingsImportCSVConfigHelpWithCreator = "Settings_Import_CSV_Config_Help_With_Creator",

	/** English translation: "Firstname;Last name;E-mail;Disk quota;Admin;Outlook plugin;Outlook plugin settings;Hub creator;Hub template creator;Groups." */
	SettingsImportCSVConfigHelpWithTemplate = "Settings_Import_CSV_Config_Help_With_Template",

	/** English translation: "Yes/ no fields must be 0 or 1 in the CSV file.The default setting is 0." */
	SettingsImportCSVConfigHelpYesNo = "Settings_Import_CSV_Config_Help_YesNo",

	/** English translation: "Encoding" */
	SettingsImportCSVEncoding = "Settings_Import_CSV_Encoding",

	/** English translation: "Import users from CSV file" */
	SettingsImportCSVFields = "Settings_Import_CSV_Fields",

	/** English translation: "Import CSV file" */
	SettingsImportCSVFile = "Settings_Import_CSV_File",

	/** English translation: "Select CSV file" */
	SettingsImportCSVFileName = "Settings_Import_CSV_File_Name",

	/** English translation: "First line contains column names" */
	SettingsImportCSVFirstLine = "Settings_Import_CSV_First_Line",

	/** English translation: "The CSV selected to a different number of columns in the model. Make sure that you are using the right CSV file." */
	SettingsImportCSVModelError = "Settings_Import_CSV_Model_Error",

	/** English translation: "CSV template" */
	SettingsImportCSVSample = "Settings_Import_CSV_Sample",

	/** English translation: "Separator" */
	SettingsImportCSVSeparator = "Settings_Import_CSV_Separator",

	/** English translation: "The CVS file is now imported." */
	SettingsImportCSVSuccess = "Settings_Import_CSV_Success",

	/** English translation: "M-Files integration" */
	SettingsMFilesIntegration = "Settings_MFiles_Integration",

	/** English translation: "If you create configuration here it will be available in all hubs. It is highly recommended that you create your configuration directly in your hub templates or hubs. Go to your hub template or hub, click Configuration and style, then M-Files integration." */
	SettingsMFilesIntegrationAlert = "Settings_MFiles_Integration_Alert",

	/** English translation: "No user rights templates" */
	SettingsNoSecurityTemplates = "Settings_No_Security_Templates",

	/** English translation: "Reset password with email" */
	SettingsResetPassword = "Settings_Reset_Password",

	/** English translation: "Do you want to reset password for user {0}?" */
	SettingsResetPasswordMessage = "Settings_Reset_Password_Message",

	/** English translation: "Right inherited from group" */
	SettingsRightInheritedGroup = "Settings_Right_Inherited_Group",

	/** English translation: "Model name" */
	SettingsSecurityModelName = "Settings_Security_Model_Name",

	/** English translation: "Select origin folder" */
	SettingsSelectOriginFolder = "Settings_Select_Origin_Folder",

	/** English translation: "The group is now created." */
	SettingsSuccessCreatedGroup = "Settings_Success_Created_Group",

	/** English translation: "The user rights template is now created." */
	SettingsSuccessCreatedSecurity = "Settings_Success_Created_Security",

	/** English translation: "The group is now deleted." */
	SettingsSuccessDeleteGroup = "Settings_Success_Delete_Group",

	/** English translation: "The user rights template is now deleted." */
	SettingsSuccessDeleteSecurity = "Settings_Success_Delete_Security",

	/** English translation: "Your changes to the group are now saved." */
	SettingsSuccessEditedGroup = "Settings_Success_Edited_Group",

	/** English translation: "Your changes to the user rights template are now saved." */
	SettingsSuccessEditedSecurity = "Settings_Success_Edited_Security",

	/** English translation: "Template file" */
	SettingsTemplateFile = "Settings_Template_File",

	/** English translation: "Transfer hub or user data" */
	SettingsTransfer = "Settings_Transfer",

	/** English translation: "Disable your two-factor authentication configuration" */
	SettingsUser2FADisableMessage = "Settings_User_2FA_Disable_Message",

	/** English translation: "Reset your two-factor authentication configuration" */
	SettingsUser2FAResetMessage = "Settings_User_2FA_Reset_Message",

	/** English translation: "User account" */
	SettingsUserAccount = "Settings_User_Account",

	/** English translation: "User accounts" */
	SettingsUserAccounts = "Settings_User_Accounts",

	/** English translation: "Manage the settings that are related to your user account." */
	SettingsUserAccountDescription = "Settings_User_Account_Description",

	/** English translation: "Users with admin rights automatically have hub creator rights as well." */
	SettingsUserCompanyManagerForceHubCreatorRight = "Settings_User_Company_Manager_Force_Hub_Creator_Right",

	/** English translation: "Organization manager required to edit user profile" */
	SettingsUserCompanyManagerRequired = "Settings_User_Company_Manager_Required",

	/** English translation: "The selected user will be permanently deleted. The content that the user has created to M-Files Hubshare and M-Files is not deleted.<br /><br />Do you want to delete this user?" */
	SettingsUserDeleteExternalConfirm = "Settings_User_Delete_External_Confirm",

	/** English translation: "The selected user and all content they have created will be <strong>permanently deleted</strong> from M-Files Hubshare. For example, their hubs, hub templates, and content in the Files module.The content they have added to M-Files with the M-Files widgets will not be deleted.<br /><br />Before you delete the user, you can transfer their M-Files Hubshare content to other users. For instructions, refer to <a href="https://userguide.m-files.com/user-guide/hubshare-admin/latest/eng/hsadmin_transferring_data_between_users.html#transferring_data" target="_blank">Transferring Data Between Users</a> in the M-Files Hubshare admin guide.<br /><br />Do you want to delete this user?" */
	SettingsUserDeleteInternalConfirm = "Settings_User_Delete_Internal_Confirm",

	/** English translation: "The selected users will be permanently deleted. The content that the users have created to M-Files Hubshare and M-Files is not deleted.<br /><br />Do you want to delete these users?" */
	SettingsUserDeleteMultipleExternalConfirm = "Settings_User_Delete_Multiple_External_Confirm",

	/** English translation: "The selected users and all content they have created will be <strong>permanently deleted</strong> from M-Files Hubshare. For example, their hubs, hub templates, and content in the Files module.The content they have added to M-Files with the M-Files widgets will not be deleted.<br /><br />Before you delete the users, you can transfer their M-Files Hubshare content to other users. For instructions, refer to <a href="https://userguide.m-files.com/user-guide/hubshare-admin/latest/eng/hsadmin_transferring_data_between_users.html#transferring_data" target="_blank">Transferring Data Between Users</a> in the M-Files Hubshare admin guide.<br /><br />Do you want to delete these users?" */
	SettingsUserDeleteMultipleInternalConfirm = "Settings_User_Delete_Multiple_Internal_Confirm",

	/** English translation: "Do you want to disable this user?" */
	SettingsUserDisableConfirm = "Settings_User_Disable_Confirm",

	/** English translation: "Disable user" */
	SettingsUserDisableConfirmTitle = "Settings_User_Disable_Confirm_Title",

	/** English translation: "Delete items. When you delete items, all the items that the user has created to M-Files Hubshare are permanently deleted. For example, their hubs, hub templates, and content in the Files module. The content they have added to M-Files with the M-Files widgets will not be deleted. This operation frees disk space." */
	SettingsUserDisableDeleteFiles = "Settings_User_Disable_Delete_Files",

	/** English translation: "Do you want to disable these users?" */
	SettingsUserDisableMultipleConfirm = "Settings_User_Disable_Multiple_Confirm",

	/** English translation: "Do you want to enable this user?" */
	SettingsUserEnableConfirm = "Settings_User_Enable_Confirm",

	/** English translation: "Enable user" */
	SettingsUserEnableConfirmTitle = "Settings_User_Enable_Confirm_Title",

	/** English translation: "Do you want to enable these users?" */
	SettingsUserEnableMultipleConfirm = "Settings_User_Enable_Multiple_Confirm",

	/** English translation: "User groups" */
	SettingsUserGroups = "Settings_User_Groups",

	/** English translation: "User rights templates" */
	SettingsUserRightsTemplates = "Settings_User_Rights_Templates",

	/** English translation: "Set the default folder in File module" */
	SettingsUserSetRootFolder = "Settings_User_Set_Root_Folder",

	/** English translation: "Are you sure you want to unlock user "{0}"?" */
	SettingsUserUnlockConfirmMsg = "Settings_User_Unlock_Confirm_Msg",

	/** English translation: "Unlock user" */
	SettingsUserUnlockConfirmTitle = "Settings_User_Unlock_Confirm_Title",

	/** English translation: "Waiting" */
	SettingsWaiting = "Settings_Waiting",

	/** English translation: "Choose parent hub" */
	SettingHubParentHubModalTitle = "Setting_Hub_Parent_Hub_Modal_Title",

	/** English translation: "%" */
	SharedPercentage = "SharedPercentage",

	/** English translation: "About" */
	SharedAbout = "Shared_About",

	/** English translation: "Accept" */
	SharedAccept = "Shared_Accept",

	/** English translation: "Access" */
	SharedAccess = "Shared_Access",

	/** English translation: "Show in Reporting" */
	SharedAccessCompanyTable = "Shared_Access_Company_Table",

	/** English translation: "Action" */
	SharedAction = "Shared_Action",

	/** English translation: "Actions" */
	SharedActions = "Shared_Actions",

	/** English translation: "Activate" */
	SharedActivate = "Shared_Activate",

	/** English translation: "Active" */
	SharedActive = "Shared_Active",

	/** English translation: "Active Directory" */
	SharedActiveDirectory = "Shared_ActiveDirectory",

	/** English translation: "Active shares" */
	SharedActives = "Shared_Actives",

	/** English translation: "hubshare.admin@yourorganization.com" */
	SharedActiveDirectoryEmailPlaceholder = "Shared_Active_Directory_Email_Placeholder",

	/** English translation: "Activities" */
	SharedActivities = "Shared_Activities",

	/** English translation: "Add" */
	SharedAdd = "Shared_Add",

	/** English translation: "Address" */
	SharedAddress = "Shared_Address",

	/** English translation: "Add at least one {0}" */
	SharedAddAtLeastOne = "Shared_Add_At_Least_One",

	/** English translation: "Add a color" */
	SharedAddAColor = "Shared_Add_A_Color",

	/** English translation: "Add board" */
	SharedAddBoard = "Shared_Add_Board",

	/** English translation: "Add category" */
	SharedAddCategory = "Shared_Add_Category",

	/** English translation: "Add custom field" */
	SharedAddCustomfield = "Shared_Add_Customfield",

	/** English translation: "Add existing users" */
	SharedAddExistingUsers = "Shared_Add_Existing_Users",

	/** English translation: "Create and add external user" */
	SharedAddExternalUser = "Shared_Add_External_User",

	/** English translation: "Add File" */
	SharedAddFile = "Shared_Add_File",

	/** English translation: "Enter {0}" */
	SharedAddItemThenPressEnter = "Shared_Add_Item_Then_Press_Enter",

	/** English translation: "Add new comment" */
	SharedAddNewComment = "Shared_Add_New_Comment",

	/** English translation: "Add subtask" */
	SharedAddNewSubTask = "Shared_Add_New_Sub_Task",

	/** English translation: "Add response" */
	SharedAddResponse = "Shared_Add_Response",

	/** English translation: "Add subject" */
	SharedAddSubject = "Shared_Add_Subject",

	/** English translation: "Add tag" */
	SharedAddTag = "Shared_Add_Tag",

	/** English translation: "Add tag group" */
	SharedAddTagGroup = "Shared_Add_Tag_Group",

	/** English translation: "Add type" */
	SharedAddType = "Shared_Add_Type",

	/** English translation: "Add users" */
	SharedAddUsers = "Shared_Add_Users",

	/** English translation: "Press Enter to add {0}" */
	SharedAddYourItemPressEnter = "Shared_Add_Your_Item_Press_Enter",

	/** English translation: "Admin" */
	SharedAdmin = "Shared_Admin",

	/** English translation: "Administrator" */
	SharedAdministrator = "Shared_Administrator",

	/** English translation: "Administrators" */
	SharedAdministrators = "Shared_Administrators",

	/** English translation: "Advanced search" */
	SharedAdvancedSearch = "Shared_AdvancedSearch",

	/** English translation: "Allow searching in file content" */
	SharedAdvancedSearchFileContent = "Shared_AdvancedSearchFileContent",

	/** English translation: "All" */
	SharedAll = "Shared_All",

	/** English translation: "Allow" */
	SharedAllow = "Shared_Allow",

	/** English translation: "Allowed" */
	SharedAllowed = "Shared_Allowed",

	/** English translation: "All categories" */
	SharedAllCategories = "Shared_All_Categories",

	/** English translation: "All day" */
	SharedAllDay = "Shared_All_Day",

	/** English translation: "All hubs" */
	SharedAllHubs = "Shared_All_Hubs",

	/** English translation: "All hub users" */
	SharedAllHubUsers = "Shared_All_Hub_Users",

	/** English translation: "All subjects" */
	SharedAllSubjects = "Shared_All_Subjects",

	/** English translation: "All tasks" */
	SharedAllTasks = "Shared_All_Tasks",

	/** English translation: "All users" */
	SharedAllUsers = "Shared_All_Users",

	/** English translation: "and" */
	SharedAnd = "Shared_And",

	/** English translation: "Annotations" */
	SharedAnnotations = "Shared_Annotations",

	/** English translation: "Anyone" */
	SharedAnyone = "Shared_Anyone",

	/** English translation: "API tokens" */
	SharedApiTokens = "Shared_Api_Tokens",

	/** English translation: "Application" */
	SharedApplication = "Shared_Application",

	/** English translation: "Apply" */
	SharedApply = "Shared_Apply",

	/** English translation: "Apply to sub-elements" */
	SharedApplyToChildren = "Shared_Apply_To_Children",

	/** English translation: "Archive" */
	SharedArchive = "Shared_Archive",

	/** English translation: "Ask hub access" */
	SharedAskHubAccess = "Shared_Ask_Hub_Access",

	/** English translation: "Assignment" */
	SharedAssignment = "Shared_Assignment",

	/** English translation: "Assign myself" */
	SharedAssignMyself = "Shared_Assign_Myself",

	/** English translation: "Attributes" */
	SharedAttributes = "Shared_Attributes",

	/** English translation: "The credentials are invalid." */
	SharedAuthenticationFailed = "Shared_Authentication_Failed",

	/** English translation: "Default configuration is "Negotiate" with "Signing" and "Sealing"." */
	SharedAuthenticationInfo = "Shared_Authentication_Info",

	/** English translation: "Automatic" */
	SharedAutomatic = "Shared_Automatic",

	/** English translation: "The value in "Time of inactivity for notification" must be less than the value in "Time of inactivity for account disabling"." */
	SharedAutoDisablingMailError = "Shared_Auto_Disabling_Mail_Error",

	/** English translation: "Time of inactivity for account disabling" */
	SharedAutoDisablingMailIdleTimeDisabling = "Shared_Auto_Disabling_Mail_IdleTime_Disabling",

	/** English translation: "Time of inactivity for notification" */
	SharedAutoDisablingMailIdleTimeReceiving = "Shared_Auto_Disabling_Mail_Idle_Time_Receiving",

	/** English translation: "Send email notification about account disabling" */
	SharedAutoDisablingMailNotification = "Shared_Auto_Disabling_Mail_Notification",

	/** English translation: "Automatically disable inactive external accounts" */
	SharedAutoDisablingUser = "Shared_Auto_Disabling_User",

	/** English translation: "Available space" */
	SharedAvailableSpace = "Shared_Available_Space",

	/** English translation: "Back" */
	SharedBack = "Shared_Back",

	/** English translation: "Background color" */
	SharedBackgroundColor = "Shared_Background_Color",

	/** English translation: "Blog" */
	SharedBlog = "Shared_Blog",

	/** English translation: "Blog subjects" */
	SharedBlogSubjects = "Shared_Blog_Subjects",

	/** English translation: "Board number" */
	SharedBoardsNumber = "Shared_Boards_Number",

	/** English translation: "Browse" */
	SharedBrowse = "Shared_Browse",

	/** English translation: "By" */
	SharedBy = "Shared_By",

	/** English translation: "Shared by me" */
	SharedByMe = "Shared_By_Me",

	/** English translation: "Calendar" */
	SharedCalendar = "Shared_Calendar",

	/** English translation: "Cancel" */
	SharedCancel = "Shared_Cancel",

	/** English translation: "Canceled" */
	SharedCanceled = "Shared_Canceled",

	/** English translation: "Cancel all" */
	SharedCancelAll = "Shared_Cancel_All",

	/** English translation: "Categories number" */
	SharedCategoriesNumber = "Shared_Categories_Number",

	/** English translation: "Category" */
	SharedCategory = "Shared_Category",

	/** English translation: "Check" */
	SharedCheck = "Shared_Check",

	/** English translation: "Check in or check out" */
	SharedCheckInCheckOut = "Shared_CheckInCheckOut",

	/** English translation: "Checking" */
	SharedChecking = "Shared_Checking",

	/** English translation: "Check-in selected" */
	SharedCheckInSelected = "Shared_Check_In_Selected",

	/** English translation: "Both (default public)" */
	SharedChooseTwoDefault = "Shared_ChooseTwoDefault",

	/** English translation: "Choose available languages" */
	SharedChooseAvailableLanguages = "Shared_Choose_Available_Languages",

	/** English translation: "Choose profile picture" */
	SharedChooseProfilePicture = "Shared_Choose_Profile_Picture",

	/** English translation: "City" */
	SharedCity = "Shared_City",

	/** English translation: "Title" */
	SharedCivility = "Shared_Civility",

	/** English translation: "Clear all" */
	SharedClear = "Shared_Clear",

	/** English translation: "Clear all" */
	SharedClearAll = "Shared_Clear_All",

	/** English translation: "Close" */
	SharedClose = "Shared_Close",

	/** English translation: "Closed" */
	SharedClosed = "Shared_Closed",

	/** English translation: "Collapse all" */
	SharedCollapseAll = "Shared_Collapse_All",

	/** English translation: "Color" */
	SharedColor = "Shared_Color",

	/** English translation: "You can't change this column." */
	SharedColumnCannotBeChanged = "Shared_Column_Cannot_Be_Changed",

	/** English translation: "The column is not shown in the preview." */
	SharedColumnCannotBeView = "Shared_Column_Cannot_Be_View",

	/** English translation: "The column can't have a condition." */
	SharedColumnCannotHaveCondition = "Shared_Column_Cannot_Have_Condition",

	/** English translation: "Coming soon" */
	SharedComingSoon = "Shared_Coming_Soon",

	/** English translation: "Comment" */
	SharedComment = "Shared_Comment",

	/** English translation: "Comments" */
	SharedComments = "Shared_Comments",

	/** English translation: "The comment is now deleted." */
	SharedCommentDeleteSuccess = "Shared_Comment_Delete_Success",

	/** English translation: "The comment doesn't have any content." */
	SharedCommentEmpty = "Shared_Comment_Empty",

	/** English translation: "Organizations" */
	SharedCompanies = "Shared_Companies",

	/** English translation: "External organizations" */
	SharedCompaniesExternal = "Shared_Companies_External",

	/** English translation: "Internal organization" */
	SharedCompaniesInternal = "Shared_Companies_Internal",

	/** English translation: "Organization" */
	SharedCompany = "Shared_Company",

	/** English translation: "External organization users" */
	SharedCompanyExternal = "Shared_Company_External",

	/** English translation: "Internal organization users" */
	SharedCompanyInternal = "Shared_Company_Internal",

	/** English translation: "Organization-level settings" */
	SharedCompanySettings = "Shared_Company_Settings",

	/** English translation: "Organization's storage" */
	SharedCompanyStorage = "Shared_Company_Storage",

	/** English translation: "Organization users" */
	SharedCompanyUsers = "Shared_Company_Users",

	/** English translation: "Condition" */
	SharedCondition = "Shared_Condition",

	/** English translation: "Configuration" */
	SharedConfiguration = "Shared_Configuration",

	/** English translation: "Configure" */
	SharedConfigure = "Shared_Configure",

	/** English translation: "The configuration is now saved." */
	SharedConfigSaved = "Shared_Config_Saved",

	/** English translation: "Confirm" */
	SharedConfirm = "Shared_Confirm",

	/** English translation: "Confirmation" */
	SharedConfirmation = "Shared_Confirmation",

	/** English translation: "Your changes are not saved. Are you sure you want to close this window?" */
	SharedConfirmCloseChangesLost = "Shared_Confirm_Close_Changes_Lost",

	/** English translation: "Confirm configuration" */
	SharedConfirmConfiguration = "Shared_Confirm_Configuration",

	/** English translation: "When you convert the hub to a hub template, the hub is deleted. This can't be undone. Do you want to continue?" */
	SharedConfirmConvertHubTemplateMessage = "Shared_Confirm_Convert_Hub_Template_Message",

	/** English translation: "Convert to template" */
	SharedConfirmConvertHubTemplateTitle = "Shared_Confirm_Convert_Hub_Template_Title",

	/** English translation: "Are you sure you want to create this external user?" */
	SharedConfirmCreateExternalUser = "Shared_Confirm_Create_External_User",

	/** English translation: "You are creating one or more shares.Here you can write a message that will be shown in the email." */
	SharedConfirmCreateShare = "Shared_Confirm_Create_Share",

	/** English translation: "Do you want to save your changes?" */
	SharedConfirmSaveAllModifications = "Shared_Confirm_Save_All_Modifications",

	/** English translation: "contains" */
	SharedContains = "Shared_Contains",

	/** English translation: "Convert" */
	SharedConvert = "Shared_Convert",

	/** English translation: "Hub converted to template" */
	SharedConvertHubTemplateSuccess = "Shared_Convert_Hub_Template_Success",

	/** English translation: "Copy" */
	SharedCopy = "Shared_Copy",

	/** English translation: "Copy" */
	SharedCopyClipboard = "Shared_Copy_Clipboard",

	/** English translation: "The item couldn't be copied." */
	SharedCopyClipboardError = "Shared_Copy_Clipboard_Error",

	/** English translation: "Copy link" */
	SharedCopyLink = "Shared_Copy_Link",

	/** English translation: "Copy path" */
	SharedCopyPath = "Shared_Copy_Path",

	/** English translation: "Copy" */
	SharedCopyToClipboard = "Shared_Copy_To_Clipboard",

	/** English translation: "Country" */
	SharedCountry = "Shared_Country",

	/** English translation: "Contact number" */
	SharedCountContact = "Shared_Count_Contact",

	/** English translation: "Subgroup number" */
	SharedCountSubGroup = "Shared_Count_SubGroup",

	/** English translation: "Create" */
	SharedCreate = "Shared_Create",

	/** English translation: "Created" */
	SharedCreated = "Shared_Created",

	/** English translation: "Created by {0}" */
	SharedCreatedBy = "Shared_Created_By",

	/** English translation: "Created on {0}" */
	SharedCreatedOn = "Shared_Created_On",

	/** English translation: "Create account" */
	SharedCreateAnAccount = "Shared_Create_An_Account",

	/** English translation: "Create board" */
	SharedCreateBoard = "Shared_Create_Board",

	/** English translation: "Create category" */
	SharedCreateCategory = "Shared_Create_Category",

	/** English translation: "Create external user" */
	SharedCreateExternalUser = "Shared_Create_External_User",

	/** English translation: "Create hub-specific group" */
	SharedCreateHubGroup = "Shared_Create_Hub_Group",

	/** English translation: "Create internal user" */
	SharedCreateInternalUser = "Shared_Create_Internal_User",

	/** English translation: "Create type" */
	SharedCreateKind = "Shared_Create_Kind",

	/** English translation: "Create tag" */
	SharedCreateTag = "Shared_Create_Tag",

	/** English translation: "Create tag group" */
	SharedCreateTagGroup = "Shared_Create_Tag_Group",

	/** English translation: "Create template" */
	SharedCreateTemplate = "Shared_Create_Template",

	/** English translation: "Create new token" */
	SharedCreateToken = "Shared_Create_Token",

	/** English translation: "Create user" */
	SharedCreateUser = "Shared_Create_User",

	/** English translation: "Creation" */
	SharedCreation = "Shared_Creation",

	/** English translation: "Creator" */
	SharedCreator = "Shared_Creator",

	/** English translation: "creator" */
	SharedCreatorMin = "Shared_Creator_Min",

	/** English translation: "Creator widget" */
	SharedCreatorWidget = "Shared_Creator_Widget",

	/** English translation: "CSV export" */
	SharedCSVExport = "Shared_CSV_Export",

	/** English translation: "Current hub" */
	SharedCurrentHub = "Shared_Current_Hub",

	/** English translation: "Custom field" */
	SharedCustomField = "Shared_Custom_Field",

	/** English translation: "Custom field number" */
	SharedCustomFieldsNumber = "Shared_Custom_Fields_Number",

	/** English translation: "Custom pages" */
	SharedCustomPages = "Shared_Custom_Pages",

	/** English translation: "My task" */
	SharedCustomTaskModuleTitle = "Shared_Custom_Task_Module_Title",

	/** English translation: "Dark text" */
	SharedDarkText = "Shared_Dark_Text",

	/** English translation: "Dashboard" */
	SharedDashboard = "Shared_Dashboard",

	/** English translation: "Page" */
	SharedDashboardPage = "Shared_DashboardPage",

	/** English translation: "Date" */
	SharedDate = "Shared_Date",

	/** English translation: "Dates" */
	SharedDates = "Shared_Dates",

	/** English translation: "The dates are not the same." */
	SharedDatesAreDifferent = "Shared_Dates_Are_Different",

	/** English translation: "Please give valid dates." */
	SharedDatesMustBeValid = "Shared_Dates_Must_Be_Valid",

	/** English translation: "Date and time" */
	SharedDatetime = "Shared_Datetime",

	/** English translation: "{0} {1} ago" */
	SharedDateAgo = "Shared_Date_Ago",

	/** English translation: "Less than a minute ago" */
	SharedDateClose = "Shared_Date_Close",

	/** English translation: "< 1 min" */
	SharedDateCloseCompressed = "Shared_Date_Close_Compressed",

	/** English translation: "Created" */
	SharedDateCreated = "Shared_Date_Created",

	/** English translation: "Day" */
	SharedDateDay = "Shared_Date_Day",

	/** English translation: "Days" */
	SharedDateDays = "Shared_Date_Days",

	/** English translation: "d" */
	SharedDateDayCompressed = "Shared_Date_Day_Compressed",

	/** English translation: "Date deleted" */
	SharedDateDeleted = "Shared_Date_Deleted",

	/** English translation: "From" */
	SharedDateFrom = "Shared_Date_From",

	/** English translation: "Hour" */
	SharedDateHour = "Shared_Date_Hour",

	/** English translation: "Hours" */
	SharedDateHours = "Shared_Date_Hours",

	/** English translation: "h" */
	SharedDateHourCompressed = "Shared_Date_Hour_Compressed",

	/** English translation: "{0} {1} left" */
	SharedDateLeft = "Shared_Date_Left",

	/** English translation: "Minute" */
	SharedDateMinute = "Shared_Date_Minute",

	/** English translation: "Minutes" */
	SharedDateMinutes = "Shared_Date_Minutes",

	/** English translation: "min" */
	SharedDateMinuteCompressed = "Shared_Date_Minute_Compressed",

	/** English translation: "Month" */
	SharedDateMonth = "Shared_Date_Month",

	/** English translation: "Months" */
	SharedDateMonths = "Shared_Date_Months",

	/** English translation: "mo" */
	SharedDateMonthCompressed = "Shared_Date_Month_Compressed",

	/** English translation: "On" */
	SharedDateOn = "Shared_Date_On",

	/** English translation: "Quarter" */
	SharedDateQuarter = "Shared_Date_Quarter",

	/** English translation: "More than {0} year ago" */
	SharedDateSinceYear = "Shared_Date_Since_Year",

	/** English translation: "More than {0} years ago" */
	SharedDateSinceYears = "Shared_Date_Since_Years",

	/** English translation: "To" */
	SharedDateTo = "Shared_Date_To",

	/** English translation: "Date updated" */
	SharedDateUpdated = "Shared_Date_Updated",

	/** English translation: "Week" */
	SharedDateWeek = "Shared_Date_Week",

	/** English translation: "Weeks" */
	SharedDateWeeks = "Shared_Date_Weeks",

	/** English translation: "wk" */
	SharedDateWeekCompressed = "Shared_Date_Week_Compressed",

	/** English translation: "Date and time" */
	SharedDateWithHours = "Shared_Date_With_Hours",

	/** English translation: "Year" */
	SharedDateYear = "Shared_Date_Year",

	/** English translation: "Years" */
	SharedDateYears = "Shared_Date_Years",

	/** English translation: "yr" */
	SharedDateYearCompressed = "Shared_Date_Year_Compressed",

	/** English translation: "Day" */
	SharedDay = "Shared_Day",

	/** English translation: "{0} days ago" */
	SharedDaysAgo = "Shared_Days_Ago",

	/** English translation: "{0} days left" */
	SharedDaysLeft = "Shared_Days_Left",

	/** English translation: "{0} day ago" */
	SharedDayAgo = "Shared_Day_Ago",

	/** English translation: "{0} day left" */
	SharedDayLeft = "Shared_Day_Left",

	/** English translation: "Default" */
	SharedDefault = "Shared_Default",

	/** English translation: "Default settings for Outlook plugin" */
	SharedDefaultParamOutlookPlugin = "Shared_DefaultParamOutlookPlugin",

	/** English translation: "Default board" */
	SharedDefaultBoard = "Shared_Default_Board",

	/** English translation: "Default groups" */
	SharedDefaultGroups = "Shared_Default_Groups",

	/** English translation: "Default sort order" */
	SharedDefaultSortOrder = "Shared_Default_Sort_Order",

	/** English translation: "Delete" */
	SharedDelete = "Shared_Delete",

	/** English translation: "Deleted" */
	SharedDeleted = "Shared_Deleted",

	/** English translation: "Delete category" */
	SharedDeleteCategory = "Shared_Delete_Category",

	/** English translation: "Are you sure you want to delete this comment?" */
	SharedDeleteCommentConfirm = "Shared_Delete_Comment_Confirm",

	/** English translation: "Delete selected" */
	SharedDeleteSelected = "Shared_Delete_Selected",

	/** English translation: "Are you sure you want to delete this subject and all related comments?" */
	SharedDeleteSubjectConfirm = "Shared_Delete_Subject_Confirm",

	/** English translation: "Delete template" */
	SharedDeleteTemplate = "Shared_Delete_Template",

	/** English translation: "Delete type" */
	SharedDeleteType = "Shared_Delete_Type",

	/** English translation: "Are you sure you want to delete this type?" */
	SharedDeleteTypeConfirm = "Shared_Delete_Type_Confirm",

	/** English translation: "Deleting" */
	SharedDeleting = "Shared_Deleting",

	/** English translation: "Deny" */
	SharedDeny = "Shared_Deny",

	/** English translation: "Description" */
	SharedDescription = "Shared_Description",

	/** English translation: "Deselect" */
	SharedDeselect = "Shared_Deselect",

	/** English translation: "Detail" */
	SharedDetail = "Shared_Detail",

	/** English translation: "Details" */
	SharedDetails = "Shared_Details",

	/** English translation: "Device manager" */
	SharedDeviceManager = "Shared_Device_Manager",

	/** English translation: "is not" */
	SharedDifferentThan = "Shared_Different_Than",

	/** English translation: "Disable" */
	SharedDisable = "Shared_Disable",

	/** English translation: "Disabled" */
	SharedDisabled = "Shared_Disabled",

	/** English translation: "Disabled" */
	SharedDisabledFemale = "Shared_Disabled_Female",

	/** English translation: "Disabling" */
	SharedDisabling = "Shared_Disabling",

	/** English translation: "The value must be between 1 and 99." */
	SharedDiskUsageIsNull = "Shared_Disk_Usage_Is_Null",

	/** English translation: "Show" */
	SharedDisplay = "Shared_Display",

	/** English translation: "Show all to board" */
	SharedDisplayAllToBoard = "Shared_Display_All_To_Board",

	/** English translation: "Show all in edit view" */
	SharedDisplayAllToEdit = "Shared_Display_All_To_Edit",

	/** English translation: "Show all in preview" */
	SharedDisplayAllToView = "Shared_Display_All_To_View",

	/** English translation: "Add a placeholder for the organization name" */
	SharedDisplayCompanyName = "Shared_Display_Company_Name",

	/** English translation: "Show open and close columns" */
	SharedDisplayDefaultColumnsOpenClose = "Shared_Display_Default_Columns_Open_Close",

	/** English translation: "Add a placeholder for the date" */
	SharedDisplayTheDate = "Shared_Display_The_Date",

	/** English translation: "Add a placeholder for the date and time" */
	SharedDisplayTheDateWithHours = "Shared_Display_The_Date_With_Hours",

	/** English translation: "Add a placeholder for the hub name" */
	SharedDisplayTheHubName = "Shared_Display_The_Hub_Name",

	/** English translation: "Add a placeholder for the name of the user" */
	SharedDisplayUserName = "Shared_Display_User_Name",

	/** English translation: "does not start with" */
	SharedDoesNotStartWith = "Shared_Does_Not_Start_With",

	/** English translation: "Domain name" */
	SharedDomainName = "Shared_Domain_Name",

	/** English translation: "Download" */
	SharedDownload = "Shared_Download",

	/** English translation: "Downloads" */
	SharedDownloads = "Shared_Downloads",

	/** English translation: "You have selected too many files for downloading.The maximum file size is {0}." */
	SharedDownloadTooBigSize = "Shared_Download_Too_Big_Size",

	/** English translation: "Don't show this again, I'll remember" */
	SharedDoNotShowAgain = "Shared_Do_Not_Show_Again",

	/** English translation: "Drop your files here" */
	SharedDropFilesHere = "Shared_Drop_Files_Here",

	/** English translation: "Due date" */
	SharedDueDate = "Shared_Due_Date",

	/** English translation: "Duration" */
	SharedDuration = "Shared_Duration",

	/** English translation: "Edit" */
	SharedEdit = "Shared_Edit",

	/** English translation: "Edit board" */
	SharedEditBoard = "Shared_Edit_Board",

	/** English translation: "Edit category" */
	SharedEditCategory = "Shared_Edit_Category",

	/** English translation: "Edit HTML" */
	SharedEditHTML = "Shared_Edit_HTML",

	/** English translation: "Edit mode" */
	SharedEditMode = "Shared_Edit_Mode",

	/** English translation: "Edit tag" */
	SharedEditTag = "Shared_Edit_Tag",

	/** English translation: "Edit tag group" */
	SharedEditTagGroup = "Shared_Edit_Tag_Group",

	/** English translation: "Edit type" */
	SharedEditType = "Shared_Edit_Type",

	/** English translation: "Edit user" */
	SharedEditUser = "Shared_Edit_User",

	/** English translation: "Electronic document signing" */
	SharedElectronicDocumentSigning = "Shared_ElectronicDocumentSigning",

	/** English translation: "Email" */
	SharedEmail = "Shared_Email",

	/** English translation: "Email confirmation" */
	SharedEmailConfirmation = "Shared_Email_Confirmation",

	/** English translation: "Empty" */
	SharedEmpty = "Shared_Empty",

	/** English translation: "Enable" */
	SharedEnable = "Shared_Enable",

	/** English translation: "Enabled" */
	SharedEnabled = "Shared_Enabled",

	/** English translation: "End" */
	SharedEnd = "Shared_End",

	/** English translation: "End date" */
	SharedEndDate = "Shared_End_Date",

	/** English translation: "Expand" */
	SharedEnlarge = "Shared_Enlarge",

	/** English translation: "is" */
	SharedEqual = "Shared_Equal",

	/** English translation: "Errors" */
	SharedErrors = "Shared_Errors",

	/** English translation: "You can't disable yourself." */
	SharedErrorCanNotDisableYourself = "Shared_Error_Can_Not_Disable_Yourself",

	/** English translation: "Incorrect file format" */
	SharedErrorInvalidFileFormat = "Shared_Error_Invalid_File_Format",

	/** English translation: "An error occurred. Please try again." */
	SharedErrorOccured = "Shared_Error_Occured",

	/** English translation: "The name already exists." */
	SharedErrorPathNameAlreadyExists = "Shared_Error_Path_Name_Already_Exists",

	/** English translation: "Event assigned to me" */
	SharedEventAssignement = "Shared_Event_Assignement",

	/** English translation: "Event assigned to me deleted" */
	SharedEventDeletion = "Shared_Event_Deletion",

	/** English translation: "Event unassigned from me" */
	SharedEventUnassignement = "Shared_Event_Unassignement",

	/** English translation: "Event assigned to me updated" */
	SharedEventUpdate = "Shared_Event_Update",

	/** English translation: "Every" */
	SharedEvery = "Shared_Every",

	/** English translation: "Everything" */
	SharedEverything = "Shared_Everything",

	/** English translation: "Existing tokens" */
	SharedExistingTokens = "Shared_Existing_Tokens",

	/** English translation: "Expand all" */
	SharedExpandAll = "Shared_Expand_All",

	/** English translation: "Expiration date" */
	SharedExpirationDate = "Shared_ExpirationDate",

	/** English translation: "Explorer widget" */
	SharedExplorerWidget = "Shared_Explorer_Widget",

	/** English translation: "Export" */
	SharedExport = "Shared_Export",

	/** English translation: "Exporting" */
	SharedExporting = "Shared_Exporting",

	/** English translation: "Export hub configurations" */
	SharedExportHub = "Shared_Export_Hub",

	/** English translation: "Extension" */
	SharedExtension = "Shared_Extension",

	/** English translation: "Extensions" */
	SharedExtensions = "Shared_Extensions",

	/** English translation: "External user" */
	SharedExternalUser = "Shared_External_User",

	/** English translation: "External users" */
	SharedExternalUsers = "Shared_External_Users",

	/** English translation: "Enabled external users" */
	SharedExternalUserActive = "Shared_External_User_Active",

	/** English translation: "External user licenses" */
	SharedExternalUserLicences = "Shared_External_User_Licences",

	/** English translation: "Extract" */
	SharedExtract = "Shared_Extract",

	/** English translation: "Favorites" */
	SharedFavorites = "Shared_Favorites",

	/** English translation: "Fax number" */
	SharedFaxNumber = "Shared_Fax_Number",

	/** English translation: "Feature not available" */
	SharedFeatureNotAvailable = "Shared_Feature_Not_Available",

	/** English translation: "File" */
	SharedFile = "Shared_File",

	/** English translation: "Files" */
	SharedFiles = "Shared_Files",

	/** English translation: "Permanent deletion" */
	SharedFileDelete = "Shared_File_Delete",

	/** English translation: "Invalid type" */
	SharedFileInvalidType = "Shared_File_Invalid_Type",

	/** English translation: "Deletion" */
	SharedFileRecycle = "Shared_File_Recycle",

	/** English translation: "Restoration" */
	SharedFileRestore = "Shared_File_Restore",

	/** English translation: "Too large" */
	SharedFileSizeTooLarge = "Shared_File_Size_Too_Large",

	/** English translation: "File types" */
	SharedFileTypes = "Shared_File_Types",

	/** English translation: "File viewer" */
	SharedFileViewer = "Shared_File_Viewer",

	/** English translation: "Filter" */
	SharedFilter = "Shared_Filter",

	/** English translation: "Finished" */
	SharedFinished = "Shared_Finished",

	/** English translation: "First name" */
	SharedFirstname = "Shared_Firstname",

	/** English translation: "First name (ascending)" */
	SharedFirstNameAsc = "Shared_First_Name_Asc",

	/** English translation: "First name (descending)" */
	SharedFirstNameDesc = "Shared_First_Name_Desc",

	/** English translation: "Folder" */
	SharedFolder = "Shared_Folder",

	/** English translation: "Folders" */
	SharedFolders = "Shared_Folders",

	/** English translation: "Permanent deletion" */
	SharedFolderDelete = "Shared_Folder_Delete",

	/** English translation: "Deletion" */
	SharedFolderRecycle = "Shared_Folder_Recycle",

	/** English translation: "Restoration" */
	SharedFolderRestore = "Shared_Folder_Restore",

	/** English translation: "Font" */
	SharedFont = "Shared_Font",

	/** English translation: "Forced" */
	SharedForced = "Shared_Forced",

	/** English translation: "Forms" */
	SharedForms = "Shared_Forms",

	/** English translation: "A form response was sent." */
	SharedFormsResponseSend = "Shared_Forms_Response_Send",

	/** English translation: "A form response was updated" */
	SharedFormsResponseUpdate = "Shared_Forms_Response_Update",

	/** English translation: "The form is invalid. Please see the required fields." */
	SharedFormInvalid = "Shared_Form_Invalid",

	/** English translation: "For Hubshare" */
	SharedForDocstore = "Shared_For_Docstore",

	/** English translation: "For hubs" */
	SharedForHubs = "Shared_For_Hubs",

	/** English translation: "Free" */
	SharedFree = "Shared_Free",

	/** English translation: "Full screen" */
	SharedFullScreen = "Shared_Full_Screen",

	/** English translation: "Gender" */
	SharedGender = "Shared_Gender",

	/** English translation: "Female" */
	SharedGenderFemale = "Shared_Gender_Female",

	/** English translation: "Male" */
	SharedGenderMale = "Shared_Gender_Male",

	/** English translation: "General" */
	SharedGeneral = "Shared_General",

	/** English translation: "General configuration" */
	SharedGeneralConfiguration = "Shared_General_Configuration",

	/** English translation: "Generate" */
	SharedGenerate = "Shared_Generate",

	/** English translation: "Generated" */
	SharedGenerated = "Shared_Generated",

	/** English translation: "Generate" */
	SharedGenerateToken = "Shared_Generate_Token",

	/** English translation: "Go back" */
	SharedGoBack = "Shared_Go_Back",

	/** English translation: "Go home" */
	SharedGoHome = "Shared_Go_Home",

	/** English translation: "is greater than" */
	SharedGreaterThan = "Shared_Greater_Than",

	/** English translation: "is greater than or same as" */
	SharedGreaterThanOrEquals = "Shared_Greater_Than_Or_Equals",

	/** English translation: "Group" */
	SharedGroup = "Shared_Group",

	/** English translation: "Groups" */
	SharedGroups = "Shared_Groups",

	/** English translation: "Group count" */
	SharedGroupsNumber = "Shared_Groups_Number",

	/** English translation: "CN=HubshareAdmin,OU=Groups,DC=corp,DC=dom,DC=com" */
	SharedGroupPlaceHolderAdmin = "Shared_Group_PlaceHolder_Admin",

	/** English translation: "CN=HubshareHubCreator,OU=Groups,DC=corp,DC=dom,DC=com" */
	SharedGroupPlaceHolderHubCreator = "Shared_Group_PlaceHolder_HubCreator",

	/** English translation: "CN=HubshareHubTemplateCreator,OU=Groups,DC=corp,DC=dom,DC=com" */
	SharedGroupPlaceHolderHubTemplateCreator = "Shared_Group_PlaceHolder_HubTemplateCreator",

	/** English translation: "CN=HubshareOutlookPlugin,OU=Groups,DC=corp,DC=dom,DC=com" */
	SharedGroupPlaceHolderOutlookPlugin = "Shared_Group_PlaceHolder_OutlookPlugin",

	/** English translation: "CN=HubshareOutlookPluginAdmin,OU=Groups,DC=corp,DC=dom,DC=com" */
	SharedGroupPlaceHolderOutlookPluginAdmin = "Shared_Group_PlaceHolder_OutlookPluginAdmin",

	/** English translation: "CN=HubshareUsers,OU=Groups,DC=corp,DC=dom,DC=com" */
	SharedGroupPlaceHolderUser = "Shared_Group_PlaceHolder_User",

	/** English translation: "Group profile" */
	SharedGroupProfile = "Shared_Group_Profile",

	/** English translation: "Delete group" */
	SharedGroupRemoveConfirmTitle = "Shared_Group_Remove_Confirm_Title",

	/** English translation: "Help" */
	SharedHelp = "Shared_Help",

	/** English translation: "Hide all in board" */
	SharedHideAllToBoard = "Shared_Hide_All_To_Board",

	/** English translation: "Hide all in edit view" */
	SharedHideAllToEdit = "Shared_Hide_All_To_Edit",

	/** English translation: "Hide all in preview" */
	SharedHideAllToView = "Shared_Hide_All_To_View",

	/** English translation: "Home" */
	SharedHome = "Shared_Home",

	/** English translation: "Home folder of {0}" */
	SharedHomeFolderOf = "Shared_Home_Folder_Of",

	/** English translation: "{0} hours ago" */
	SharedHoursAgo = "Shared_Hours_Ago",

	/** English translation: "{0} hours left" */
	SharedHoursLeft = "Shared_Hours_Left",

	/** English translation: "{0} hour ago" */
	SharedHourAgo = "Shared_Hour_Ago",

	/** English translation: "{0} hour left" */
	SharedHourLeft = "Shared_Hour_Left",

	/** English translation: "Hub" */
	SharedHub = "Shared_Hub",

	/** English translation: "Hub-specific group" */
	SharedHubGroup = "Shared_HubGroup",

	/** English translation: "Hubs" */
	SharedHubs = "Shared_Hubs",

	/** English translation: "Hub activity" */
	SharedHubActivities = "Shared_Hub_Activities",

	/** English translation: "Number of times the hub was accessed" */
	SharedHubActivitiesConnectionPerMonth = "Shared_Hub_Activities_Connection_Per_Month",

	/** English translation: "Hub" */
	SharedHubActivitiesGeneralInformation = "Shared_Hub_Activities_General_Information",

	/** English translation: "Accesses" */
	SharedHubActivitiesUsageStatistics = "Shared_Hub_Activities_Usage_Statistics",

	/** English translation: "(in last 12 months)" */
	SharedHubActivitiesUsageStatisticsInfo = "Shared_Hub_Activities_Usage_Statistics_Info",

	/** English translation: "Users" */
	SharedHubActivitiesUsersInformation = "Shared_Hub_Activities_Users_Information",

	/** English translation: "Hub designer" */
	SharedHubDesigner = "Shared_Hub_Designer",

	/** English translation: "External hub users" */
	SharedHubExternal = "Shared_Hub_External",

	/** English translation: "Internal hub users" */
	SharedHubInternal = "Shared_Hub_Internal",

	/** English translation: "Hub size" */
	SharedHubSize = "Shared_Hub_Size",

	/** English translation: "Active" */
	SharedHubStatusActive = "Shared_Hub_Status_Active",

	/** English translation: "Design" */
	SharedHubStatusDesign = "Shared_Hub_Status_Design",

	/** English translation: "Inactive" */
	SharedHubStatusInactive = "Shared_Hub_Status_Inactive",

	/** English translation: "Wizard" */
	SharedHubStatusWizard = "Shared_Hub_Status_Wizard",

	/** English translation: "Hub template" */
	SharedHubTemplate = "Shared_Hub_Template",

	/** English translation: "Hub templates" */
	SharedHubTemplates = "Shared_Hub_Templates",

	/** English translation: "Icon" */
	SharedIcon = "Shared_Icon",

	/** English translation: "Identifier" */
	SharedIdentifier = "Shared_Identifier",

	/** English translation: "Image" */
	SharedImage = "Shared_Image",

	/** English translation: "Import" */
	SharedImport = "Shared_Import",

	/** English translation: "Importing" */
	SharedImporting = "Shared_Importing",

	/** English translation: "Watermark configuration ({0})" */
	SharedImportExportWatermarkConfiguration = "Shared_Import_Export_Watermark_Configuration",

	/** English translation: "Import hub configurations" */
	SharedImportHub = "Shared_Import_Hub",

	/** English translation: "Industry" */
	SharedIndustry = "Shared_Industry",

	/** English translation: "No information available" */
	SharedInformationNotFound = "Shared_Information_Not_Found",

	/** English translation: "M-Files Hubshare license" */
	SharedInformationOnYourMFilesHubshareLicense = "Shared_Information_On_Your_MFiles_Hubshare_License",

	/** English translation: "Outlook plugin license" */
	SharedInformationOnYourOutlookPluginLicense = "Shared_Information_On_Your_Outlook_Plugin_License",

	/** English translation: "Insert" */
	SharedInsert = "Shared_Insert",

	/** English translation: "There isn't enough storage space." */
	SharedInsufficientStorageSpace = "Shared_Insufficient_Storage_Space",

	/** English translation: "Internal user" */
	SharedInternalUser = "Shared_Internal_User",

	/** English translation: "Internal users" */
	SharedInternalUsers = "Shared_Internal_Users",

	/** English translation: "Enabled internal users" */
	SharedInternalUserActive = "Shared_Internal_User_Active",

	/** English translation: "Internal user licenses" */
	SharedInternalUserLicenses = "Shared_Internal_User_Licenses",

	/** English translation: "Interval" */
	SharedInterval = "Shared_Interval",

	/** English translation: "Invalid email" */
	SharedInvalidEmail = "Shared_Invalid_Email",

	/** English translation: "Invalid file" */
	SharedInvalidFile = "Shared_Invalid_File",

	/** English translation: "The parameters are invalid." */
	SharedInvalidParameters = "Shared_Invalid_Parameters",

	/** English translation: "is after" */
	SharedIsAfter = "Shared_Is_After",

	/** English translation: "is before" */
	SharedIsBefore = "Shared_Is_Before",

	/** English translation: "is between" */
	SharedIsBetween = "Shared_Is_Between",

	/** English translation: "is empty" */
	SharedIsEmpty = "Shared_Is_Empty",

	/** English translation: "is" */
	SharedIsExactly = "Shared_Is_Exactly",

	/** English translation: "is not empty" */
	SharedIsNotEmpty = "Shared_Is_Not_Empty",

	/** English translation: "is not" */
	SharedIsNotExactly = "Shared_Is_Not_Exactly",

	/** English translation: "The {0} has already been added." */
	SharedItemAlreadyAdded = "Shared_Item_Already_Added",

	/** English translation: "I already have an account." */
	SharedIAlreadyHaveAnAccount = "Shared_I_Already_Have_An_Account",

	/** English translation: "Job title" */
	SharedJobTitle = "Shared_Job_Title",

	/** English translation: "Kanban" */
	SharedKanbanMode = "Shared_Kanban_Mode",

	/** English translation: "Keep" */
	SharedKeep = "Shared_Keep",

	/** English translation: "Landing page" */
	SharedLandingPage = "Shared_Landing_Page",

	/** English translation: "Language" */
	SharedLanguage = "Shared_Language",

	/** English translation: "English (United States)" */
	SharedLanguageName = "Shared_LanguageName",

	/** English translation: "Languages" */
	SharedLanguages = "Shared_Languages",

	/** English translation: "Last name" */
	SharedLastname = "Shared_Lastname",

	/** English translation: "Last comment" */
	SharedLastComment = "Shared_Last_Comment",

	/** English translation: "Last edited" */
	SharedLastModified = "Shared_Last_Modified",

	/** English translation: "Last name (ascending)" */
	SharedLastNameAsc = "Shared_Last_Name_Asc",

	/** English translation: "Last name (descending)" */
	SharedLastNameDesc = "Shared_Last_Name_Desc",

	/** English translation: "Last synchronization" */
	SharedLastSynchronisation = "Shared_Last_Synchronisation",

	/** English translation: "Last update" */
	SharedLastUpdate = "Shared_Last_Update",

	/** English translation: "Leave" */
	SharedLeave = "Shared_Leave",

	/** English translation: "Less details" */
	SharedLessDetails = "Shared_Less_Details",

	/** English translation: "is less than" */
	SharedLessThan = "Shared_Less_Than",

	/** English translation: "is less than or same as" */
	SharedLessThanOrEquals = "Shared_Less_Than_Or_Equals",

	/** English translation: "Light text" */
	SharedLightText = "Shared_Light_Text",

	/** English translation: "Limitations" */
	SharedLimitations = "Shared_Limitations",

	/** English translation: "Link" */
	SharedLink = "Shared_Link",

	/** English translation: "Link copied" */
	SharedLinkCopiedToClipboard = "Shared_Link_Copied_To_Clipboard",

	/** English translation: "The download link has expired. Please select and download the content again." */
	SharedLinkExpired = "Shared_Link_Expired",

	/** English translation: "List" */
	SharedList = "Shared_List",

	/** English translation: "Loading" */
	SharedLoading = "Shared_Loading",

	/** English translation: "The data couldn't be loaded." */
	SharedLoadingError = "Shared_Loading_Error",

	/** English translation: "Location" */
	SharedLocation = "Shared_Location",

	/** English translation: "Locked" */
	SharedLocked = "Shared_Locked",

	/** English translation: "The file is edited in Office for the web." */
	SharedLockedInOffice = "Shared_Locked_In_Office",

	/** English translation: "Locked out" */
	SharedLockedOut = "Shared_Locked_Out",

	/** English translation: "You are logged in as" */
	SharedLoggedInAs = "Shared_Logged_In_As",

	/** English translation: "Sign in" */
	SharedLogin = "Shared_Login",

	/** English translation: "Sign in" */
	SharedLoginTitle = "Shared_Login_Title",

	/** English translation: "Logout" */
	SharedLogout = "Shared_Logout",

	/** English translation: "Manage" */
	SharedManage = "Shared_Manage",

	/** English translation: "Manage default settings" */
	SharedManageDefaultSettings = "Shared_ManageDefaultSettings",

	/** English translation: "Manage sync" */
	SharedManageSync = "Shared_ManageSync",

	/** English translation: "Manage users" */
	SharedManageUsers = "Shared_ManageUsers",

	/** English translation: "Manage boards" */
	SharedManageBoard = "Shared_Manage_Board",

	/** English translation: "Manage categories" */
	SharedManageCategories = "Shared_Manage_Categories",

	/** English translation: "Manage module rights" */
	SharedManageModulesRights = "Shared_Manage_Modules_Rights",

	/** English translation: "Manage rights" */
	SharedManageRights = "Shared_Manage_Rights",

	/** English translation: "Manage tags" */
	SharedManageTag = "Shared_Manage_Tag",

	/** English translation: "Manage tag groups" */
	SharedManageTagGroup = "Shared_Manage_Tag_Group",

	/** English translation: "Manage types" */
	SharedManageTypes = "Shared_Manage_Types",

	/** English translation: "Manage widget rights" */
	SharedManageWidgetsRights = "Shared_Manage_Widgets_Rights",

	/** English translation: "Max" */
	SharedMax = "Shared_Max",

	/** English translation: "Maximum length:" */
	SharedMaxLength = "Shared_Max_Length",

	/** English translation: "Median" */
	SharedMedian = "Shared_Median",

	/** English translation: "Mention" */
	SharedMention = "Shared_Mention",

	/** English translation: "Message" */
	SharedMessage = "Shared_Message",

	/** English translation: "Messages" */
	SharedMessages = "Shared_Messages",

	/** English translation: "There is no content in the message." */
	SharedMessageEmpty = "Shared_Message_Empty",

	/** English translation: "Metrics" */
	SharedMetricsMode = "Shared_Metrics_Mode",

	/** English translation: "M-Files Charts" */
	SharedMFilesCharts = "Shared_MFiles_Charts",

	/** English translation: "M-Files Creator" */
	SharedMFilesCreator = "Shared_MFiles_Creator",

	/** English translation: "M-Files Explorer" */
	SharedMFilesExplorer = "Shared_MFiles_Explorer",

	/** English translation: "M-Files user" */
	SharedMFilesUser = "Shared_MFiles_User",

	/** English translation: "M-Files Views" */
	SharedMFilesViews = "Shared_MFiles_Views",

	/** English translation: "MIME" */
	SharedMime = "Shared_Mime",

	/** English translation: "Min" */
	SharedMin = "Shared_Min",

	/** English translation: "Minimize" */
	SharedMinimize = "Shared_Minimize",

	/** English translation: "minutes" */
	SharedMinutes = "Shared_Minutes",

	/** English translation: "{0} minutes ago" */
	SharedMinutesAgo = "Shared_Minutes_Ago",

	/** English translation: "{0} minutes left" */
	SharedMinutesLeft = "Shared_Minutes_Left",

	/** English translation: "{0} minute ago" */
	SharedMinuteAgo = "Shared_Minute_Ago",

	/** English translation: "{0} minute left" */
	SharedMinuteLeft = "Shared_Minute_Left",

	/** English translation: "Mobile phone number" */
	SharedMobilePhoneNumber = "Shared_Mobile_Phone_Number",

	/** English translation: "Edit" */
	SharedModify = "Shared_Modify",

	/** English translation: "Modules" */
	SharedModules = "Shared_Modules",

	/** English translation: "Module descriptions" */
	SharedModulesDescriptions = "Shared_ModulesDescriptions",

	/** English translation: "Module rights" */
	SharedModulesRights = "Shared_ModulesRights",

	/** English translation: "Module/page/widget" */
	SharedModulePage = "Shared_Module_Page",

	/** English translation: "Month" */
	SharedMonth = "Shared_Month",

	/** English translation: "{0} months ago" */
	SharedMonthsAgo = "Shared_Months_Ago",

	/** English translation: "{0} months left" */
	SharedMonthsLeft = "Shared_Months_Left",

	/** English translation: "{0} month ago" */
	SharedMonthAgo = "Shared_Month_Ago",

	/** English translation: "{0} month left" */
	SharedMonthLeft = "Shared_Month_Left",

	/** English translation: "More details" */
	SharedMoreDetails = "Shared_More_Details",

	/** English translation: "Move" */
	SharedMove = "Shared_Move",

	/** English translation: "Move down" */
	SharedMoveDown = "Shared_Move_Down",

	/** English translation: "Move up" */
	SharedMoveUp = "Shared_Move_Up",

	/** English translation: "My documents" */
	SharedMyDocuments = "Shared_MyDocuments",

	/** English translation: "My hubs" */
	SharedMyHub = "Shared_My_Hub",

	/** English translation: "My profile" */
	SharedMyProfile = "Shared_My_Profile",

	/** English translation: "My settings" */
	SharedMySettings = "Shared_My_Settings",

	/** English translation: "My tasks" */
	SharedMyTasksMode = "Shared_My_Tasks_Mode",

	/** English translation: "Name" */
	SharedName = "Shared_Name",

	/** English translation: "Navigate to this hub" */
	SharedNavigateToHub = "Shared_Navigate_To_Hub",

	/** English translation: "New" */
	SharedNew = "Shared_New",

	/** English translation: "New comment" */
	SharedNewComment = "Shared_New_Comment",

	/** English translation: "New name" */
	SharedNewName = "Shared_New_Name",

	/** English translation: "New reply" */
	SharedNewReply = "Shared_New_Reply",

	/** English translation: "New subject" */
	SharedNewSubject = "Shared_New_Subject",

	/** English translation: "New subject title" */
	SharedNewSubjectTitle = "Shared_New_Subject_Title",

	/** English translation: "New window" */
	SharedNewWindow = "Shared_New_Window",

	/** English translation: "Next" */
	SharedNext = "Shared_Next",

	/** English translation: "Next step" */
	SharedNextStep = "Shared_Next_Step",

	/** English translation: "No" */
	SharedNo = "Shared_No",

	/** English translation: "Nobody" */
	SharedNobody = "Shared_Nobody",

	/** English translation: "None" */
	SharedNone = "Shared_None",

	/** English translation: "None" */
	SharedNoneFeminine = "Shared_None_Feminine",

	/** English translation: "Nothing was found." */
	SharedNothingFound = "Shared_Nothing_Found",

	/** English translation: "Notification" */
	SharedNotification = "Shared_Notification",

	/** English translation: "Daily" */
	SharedNotificationFrequencyDaily = "Shared_Notification_Frequency_Daily",

	/** English translation: "Monthly" */
	SharedNotificationFrequencyMonthly = "Shared_Notification_Frequency_Monthly",

	/** English translation: "Now" */
	SharedNotificationFrequencyNow = "Shared_Notification_Frequency_Now",

	/** English translation: "Off" */
	SharedNotificationFrequencyOff = "Shared_Notification_Frequency_Off",

	/** English translation: "Weekly" */
	SharedNotificationFrequencyWeekly = "Shared_Notification_Frequency_Weekly",

	/** English translation: "Not administrator" */
	SharedNotAdministrator = "Shared_Not_Administrator",

	/** English translation: "Not administrators" */
	SharedNotAdministrators = "Shared_Not_Administrators",

	/** English translation: "does not contain" */
	SharedNotContains = "Shared_Not_Contains",

	/** English translation: "Not forced" */
	SharedNotForced = "Shared_Not_Forced",

	/** English translation: "Not set" */
	SharedNotSet = "Shared_Not_Set",

	/** English translation: "No attachment" */
	SharedNoAttachment = "Shared_No_Attachment",

	/** English translation: "No board" */
	SharedNoBoard = "Shared_No_Board",

	/** English translation: "No category" */
	SharedNoCategory = "Shared_No_Category",

	/** English translation: "No comment" */
	SharedNoComment = "Shared_No_Comment",

	/** English translation: "No contacts" */
	SharedNoContacts = "Shared_No_Contacts",

	/** English translation: "No data" */
	SharedNoData = "Shared_No_Data",

	/** English translation: "No description" */
	SharedNoDescription = "Shared_No_Description",

	/** English translation: "No documents" */
	SharedNoDocuments = "Shared_No_Documents",

	/** English translation: "No element" */
	SharedNoElement = "Shared_No_Element",

	/** English translation: "No file" */
	SharedNoFile = "Shared_No_File",

	/** English translation: "No file found" */
	SharedNoFileFound = "Shared_No_File_Found",

	/** English translation: "There are no filters configured for this widget. Please ask your hub admin to configure them in the widget settings." */
	SharedNoFilterableProperties = "Shared_No_Filterable_Properties",

	/** English translation: "No folder" */
	SharedNoFolder = "Shared_No_Folder",

	/** English translation: "No hubs" */
	SharedNoHub = "Shared_No_Hub",

	/** English translation: "No records" */
	SharedNoRecords = "Shared_No_Records",

	/** English translation: "No results" */
	SharedNoResult = "Shared_No_Result",

	/** English translation: "No selection" */
	SharedNoSelection = "Shared_No_Selection",

	/** English translation: "No tag" */
	SharedNoTag = "Shared_No_Tag",

	/** English translation: "No tag group" */
	SharedNoTagGroup = "Shared_No_TagGroup",

	/** English translation: "Please create a watermark template" */
	SharedNoTemplatePleaseAdd = "Shared_No_Template_Please_Add",

	/** English translation: "No token was found." */
	SharedNoTokenFound = "Shared_No_Token_Found",

	/** English translation: "No topic" */
	SharedNoTopic = "Shared_No_Topic",

	/** English translation: "No users assigned" */
	SharedNoUsersAssigned = "Shared_No_Users_Assigned",

	/** English translation: "No values" */
	SharedNoValue = "Shared_No_Value",

	/** English translation: "Number of times the hub was accessed (last 30 days)" */
	SharedNumberOfConnectionMonth = "Shared_Number_Of_Connection_Month",

	/** English translation: "Number of times the hub was accessed (last 7 days)" */
	SharedNumberOfConnectionWeek = "Shared_Number_Of_Connection_Week",

	/** English translation: "n/a" */
	SharedNA = "Shared_N_A",

	/** English translation: "Office" */
	SharedOffice = "Shared_Office",

	/** English translation: "Office desktop plugin" */
	SharedOfficePlugin = "Shared_Office_Plugin",

	/** English translation: "Sign up for Office 365 at" */
	SharedOfficePromotional = "Shared_Office_Promotional",

	/** English translation: "OK" */
	SharedOk = "Shared_Ok",

	/** English translation: "Please enter at least one value." */
	SharedOneValueRequired = "Shared_One_Value_Required",

	/** English translation: "Only letters and numbers are allowed." */
	SharedOnlyLettersDigitsAllowed = "Shared_Only_Letters_Digits_Allowed",

	/** English translation: "Open" */
	SharedOpen = "Shared_Open",

	/** English translation: "Opened" */
	SharedOpened = "Shared_Opened",

	/** English translation: "Open in browser" */
	SharedOpenInBrowser = "Shared_Open_In_Browser",

	/** English translation: "Open link" */
	SharedOpenLink = "Shared_Open_Link",

	/** English translation: "Open user guide" */
	SharedOpenUserGuide = "Shared_Open_User_Guide",

	/** English translation: "Operation" */
	SharedOperation = "Shared_Operation",

	/** English translation: "Options" */
	SharedOptions = "Shared_Options",

	/** English translation: "Or" */
	SharedOr = "Shared_Or",

	/** English translation: "Other" */
	SharedOther = "Shared_Other",

	/** English translation: "Outbox" */
	SharedOutbox = "Shared_Outbox",

	/** English translation: "Outlook plugin admin" */
	SharedOutlookPluginAdmin = "Shared_Outlook_Plugin_Admin",

	/** English translation: "Owned by" */
	SharedOwnedBy = "Shared_Owned_By",

	/** English translation: "Owner" */
	SharedOwner = "Shared_Owner",

	/** English translation: "Page not found" */
	SharedPageNotFound = "Shared_Page_Not_Found",

	/** English translation: "Parameters" */
	SharedParameters = "Shared_Parameters",

	/** English translation: "Parent" */
	SharedParent = "Shared_Parent",

	/** English translation: "Password" */
	SharedPassword = "Shared_Password",

	/** English translation: "For password protected attachments:" */
	SharedPasswordProtectedAttachments = "Shared_PasswordProtectedAttachments",

	/** English translation: "Password (anonymous access)" */
	SharedPasswordAnonymous = "Shared_Password_Anonymous",

	/** English translation: "Confirm password " */
	SharedPasswordConfirm = "Shared_Password_Confirm",

	/** English translation: "Password copied" */
	SharedPasswordCopiedToClipboard = "Shared_Password_Copied_To_Clipboard",

	/** English translation: "Your password has expired." */
	SharedPasswordExpired = "Shared_Password_Expired",

	/** English translation: "We send unique passwords to the emails given in this section.With these passwords, we know when someone opens the share." */
	SharedPasswordSharedExplanation = "Shared_Password_Shared_Explanation",

	/** English translation: "Path" */
	SharedPath = "Shared_Path",

	/** English translation: "Pending" */
	SharedPending = "Shared_Pending",

	/** English translation: "People" */
	SharedPeople = "Shared_People",

	/** English translation: "Delete permanently" */
	SharedPermanentDelete = "Shared_Permanent_Delete",

	/** English translation: "Personal" */
	SharedPersonal = "Shared_Personal",

	/** English translation: "Phone number" */
	SharedPhoneNumber = "Shared_Phone_Number",

	/** English translation: "Picture" */
	SharedPicture = "Shared_Picture",

	/** English translation: "Please select a resource." */
	SharedPleaseSelectAResource = "Shared_Please_Select_A_Resource",

	/** English translation: "Select a watermark template" */
	SharedPleaseSelectATemplate = "Shared_Please_Select_A_Template",

	/** English translation: "Preview" */
	SharedPreview = "Shared_Preview",

	/** English translation: "Previous" */
	SharedPrevious = "Shared_Previous",

	/** English translation: "Print" */
	SharedPrint = "Shared_Print",

	/** English translation: "Processing" */
	SharedProcessing = "Shared_Processing",

	/** English translation: "Profile edited" */
	SharedProfileEdited = "Shared_Profile_Edited",

	/** English translation: "Progress" */
	SharedProgress = "Shared_Progress",

	/** English translation: "Project" */
	SharedProject = "Shared_Project",

	/** English translation: "Project (hub admin)" */
	SharedProjetAdmin = "Shared_Projet_Admin",

	/** English translation: "Properties" */
	SharedProperties = "Shared_Properties",

	/** English translation: "Protect attachments with password" */
	SharedProtectAttachmentsPassword = "Shared_ProtectAttachmentsPassword",

	/** English translation: "Leave edit mode" */
	SharedQuitEditMode = "Shared_Quit_Edit_Mode",

	/** English translation: "Read less" */
	SharedReadLess = "Shared_Read_Less",

	/** English translation: "Read more" */
	SharedReadMore = "Shared_Read_More",

	/** English translation: "Read-only" */
	SharedReadOnly = "Shared_Read_Only",

	/** English translation: "Reason" */
	SharedReason = "Shared_Reason",

	/** English translation: "Recents" */
	SharedRecents = "Shared_Recents",

	/** English translation: "Recent activity" */
	SharedRecentActivity = "Shared_Recent_Activity",

	/** English translation: "Delete" */
	SharedRecycle = "Shared_Recycle",

	/** English translation: "by" */
	SharedRecycledBy = "Shared_Recycled_By",

	/** English translation: "from" */
	SharedRecycledFrom = "Shared_Recycled_From",

	/** English translation: "Recycled on" */
	SharedRecycledOn = "Shared_Recycled_On",

	/** English translation: "Redirection email for organization-level notifications" */
	SharedRedirectEmail = "Shared_Redirect_Email",

	/** English translation: "Enter an email address to redirect M-Files Hubshare notifications. A copy of the redirected emails is not sent to the organizationowner. After the organizationowner has activated their account, the redirection email can't be changed. However, the organizationowner can remove the redirection. If the organizationowner already has an M-Files Hubshare account, this setting can't be used." */
	SharedRedirectEmailInputTooltip = "Shared_Redirect_Email_Input_Tooltip",

	/** English translation: "Refresh" */
	SharedRefresh = "Shared_Refresh",

	/** English translation: "Refresh rate" */
	SharedRefreshRate = "Shared_Refresh_Rate",

	/** English translation: "When you set the refresh rate to 0, the automatic synchronization is disabled." */
	SharedRefreshRateInfo = "Shared_Refresh_Rate_Info",

	/** English translation: "Refuse" */
	SharedRefuse = "Shared_Refuse",

	/** English translation: "Register" */
	SharedRegister = "Shared_Register",

	/** English translation: "Remove" */
	SharedRemove = "Shared_Remove",

	/** English translation: "Remove admin rights" */
	SharedRemoveAdminRights = "Shared_Remove_Admin_Rights",

	/** English translation: "This will remove administrative rights from the user {0}. Do you want to continue?" */
	SharedRemoveAdminRightsConfirm = "Shared_Remove_Admin_Rights_Confirm",

	/** English translation: "This will remove administrative rights from the user group {0}. Do you want to continue?" */
	SharedRemoveGroupAdminRightsConfirm = "Shared_Remove_Group_Admin_Rights_Confirm",

	/** English translation: "Stop redirecting" */
	SharedRemoveRedirectEmail = "Shared_Remove_Redirect_Email",

	/** English translation: "Rename" */
	SharedRename = "Shared_Rename",

	/** English translation: "Replace" */
	SharedReplace = "Shared_Replace",

	/** English translation: "Reporting" */
	SharedReporting = "Shared_Reporting",

	/** English translation: "(required)" */
	SharedRequired = "Shared_Required",

	/** English translation: "Fields marked with an asterisk are required." */
	SharedRequiredFields = "Shared_RequiredFields",

	/** English translation: "Required field" */
	SharedRequiredField = "Shared_Required_Field",

	/** English translation: "Required space" */
	SharedRequiredSpace = "Shared_Required_Space",

	/** English translation: "Reset" */
	SharedReset = "Shared_Reset",

	/** English translation: "Reset email adress" */
	SharedResetEmailAdress = "Shared_Reset_Email_Adress",

	/** English translation: "If you reset this form, all changes will be lost. Are you sure you want to reset this form?" */
	SharedResetMessage = "Shared_Reset_Message",

	/** English translation: "Resource" */
	SharedResource = "Shared_Resource",

	/** English translation: "Resources" */
	SharedResources = "Shared_Resources",

	/** English translation: "Do you want to check in these files?" */
	SharedResourcesCheckInConfirm = "Shared_Resources_Check_In_Confirm",

	/** English translation: "Do you want to check out these files?" */
	SharedResourcesCheckOutConfirm = "Shared_Resources_Check_Out_Confirm",

	/** English translation: "Checked in" */
	SharedResourceCheckedIn = "Shared_Resource_Checked_In",

	/** English translation: "Checked out by" */
	SharedResourceCheckedOutBy = "Shared_Resource_Checked_Out_By",

	/** English translation: "Number of checked-out files" */
	SharedResourceCheckedOutNumber = "Shared_Resource_Checked_Out_Number",

	/** English translation: "Checked out on" */
	SharedResourceCheckedOutOn = "Shared_Resource_Checked_Out_On",

	/** English translation: "Check in" */
	SharedResourceCheckIn = "Shared_Resource_Check_In",

	/** English translation: "Do you want to check in this file?" */
	SharedResourceCheckInConfirm = "Shared_Resource_Check_In_Confirm",

	/** English translation: "The file "{0}" : {1} can't be checked in." */
	SharedResourceCheckInError = "Shared_Resource_Check_In_Error",

	/** English translation: "Check out" */
	SharedResourceCheckOut = "Shared_Resource_Check_Out",

	/** English translation: "Do you want to check out this file?" */
	SharedResourceCheckOutConfirm = "Shared_Resource_Check_Out_Confirm",

	/** English translation: "The file "{0}" : {1} can't be checked out." */
	SharedResourceCheckOutError = "Shared_Resource_Check_Out_Error",

	/** English translation: "Created by" */
	SharedResourceCreatedBy = "Shared_Resource_Created_By",

	/** English translation: "The resource no longer exists." */
	SharedResourceDeleted = "Shared_Resource_Deleted",

	/** English translation: "File checked in" */
	SharedResourceFileCheckedIn = "Shared_Resource_File_Checked_In",

	/** English translation: "File checked out" */
	SharedResourceFileCheckedOut = "Shared_Resource_File_Checked_Out",

	/** English translation: "File name" */
	SharedResourceFileName = "Shared_Resource_File_Name",

	/** English translation: "No checked-out files" */
	SharedResourceNoCheckedOutFiles = "Shared_Resource_No_Checked_Out_Files",

	/** English translation: "Undo checkout" */
	SharedResourceUndoCheckOut = "Shared_Resource_Undo_Check_Out",

	/** English translation: "Restore" */
	SharedRestore = "Shared_Restore",

	/** English translation: "Restore to" */
	SharedRestoreTo = "Shared_Restore_To",

	/** English translation: "Results" */
	SharedResults = "Shared_Results",

	/** English translation: "Return to Hubshare" */
	SharedReturnToDocstore = "Shared_Return_To_Docstore",

	/** English translation: "Rights" */
	SharedRights = "Shared_Rights",

	/** English translation: "Delete" */
	SharedRightDeletion = "Shared_Right_Deletion",

	/** English translation: "Download" */
	SharedRightDownload = "Shared_Right_Download",

	/** English translation: "Move" */
	SharedRightMove = "Shared_Right_Move",

	/** English translation: "Print" */
	SharedRightPrint = "Shared_Right_Print",

	/** English translation: "Read" */
	SharedRightRead = "Shared_Right_Read",

	/** English translation: "Rename" */
	SharedRightRename = "Shared_Right_Rename",

	/** English translation: "Update" */
	SharedRightUpdate = "Shared_Right_Update",

	/** English translation: "Root" */
	SharedRoot = "Shared_Root",

	/** English translation: "Save" */
	SharedSave = "Shared_Save",

	/** English translation: "The configuration was saved." */
	SharedSavedConfiguration = "Shared_Saved_Configuration",

	/** English translation: "Save and close" */
	SharedSaveAndClose = "Shared_Save_And_Close",

	/** English translation: "Save and continue" */
	SharedSaveAndContinue = "Shared_Save_And_Continue",

	/** English translation: "Save and finish" */
	SharedSaveAndFinish = "Shared_Save_And_Finish",

	/** English translation: "The data couldn't be saved." */
	SharedSaveError = "Shared_Save_Error",

	/** English translation: "Search" */
	SharedSearch = "Shared_Search",

	/** English translation: "Search" */
	SharedSearchKind = "Shared_Search_Kind",

	/** English translation: "Security" */
	SharedSecurity = "Shared_Security",

	/** English translation: "See details" */
	SharedSeeDetails = "Shared_See_Details",

	/** English translation: "See more" */
	SharedSeeMore = "Shared_See_More",

	/** English translation: "Selected groups" */
	SharedSelectedGroups = "Shared_Selected_Groups",

	/** English translation: "Selected users" */
	SharedSelectedUsers = "Shared_Selected_Users",

	/** English translation: "Selection" */
	SharedSelection = "Shared_Selection",

	/** English translation: "Your selection is empty" */
	SharedSelectionEmpty = "Shared_Selection_Empty",

	/** English translation: "Select all" */
	SharedSelectAll = "Shared_Select_All",

	/** English translation: "Select a file" */
	SharedSelectAFile = "Shared_Select_A_File",

	/** English translation: "Select a template" */
	SharedSelectATemplate = "Shared_Select_A_Template",

	/** English translation: "Select organization" */
	SharedSelectCompany = "Shared_Select_Company",

	/** English translation: "Select element" */
	SharedSelectElement = "Shared_Select_Element",

	/** English translation: "Select groups" */
	SharedSelectGroups = "Shared_Select_Groups",

	/** English translation: "Please select only one file." */
	SharedSelectOnlyOneFile = "Shared_Select_Only_One_File",

	/** English translation: "Select saved filter" */
	SharedSelectSavedFilter = "Shared_Select_Saved_Filter",

	/** English translation: "Select source widget" */
	SharedSelectSourceWidget = "Shared_Select_Source_Widget",

	/** English translation: "Select status" */
	SharedSelectStatus = "Shared_Select_Status",

	/** English translation: "Select time zone" */
	SharedSelectTimezone = "Shared_Select_Timezone",

	/** English translation: "Select type" */
	SharedSelectType = "Shared_Select_Type",

	/** English translation: "Select user" */
	SharedSelectUser = "Shared_Select_User",

	/** English translation: "Select users" */
	SharedSelectUsers = "Shared_Select_Users",

	/** English translation: "Add users and groups" */
	SharedSelectUserOrGroup = "Shared_Select_User_Or_Group",

	/** English translation: "Select a widget" */
	SharedSelectWidget = "Shared_Select_Widget",

	/** English translation: "Send" */
	SharedSend = "Shared_Send",

	/** English translation: "Send attachments automatically with Hubshare" */
	SharedSendAttachmentsHubshare = "Shared_SendAttachmentsHubshare",

	/** English translation: "Send all" */
	SharedSendAll = "Shared_Send_All",

	/** English translation: "Send email" */
	SharedSendEmail = "Shared_Send_Email",

	/** English translation: "Sent to" */
	SharedSentTo = "Shared_Sent_To",

	/** English translation: "Server" */
	SharedServer = "Shared_Server",

	/** English translation: "Settings" */
	SharedSettings = "Shared_Settings",

	/** English translation: "Editing the parent hub might delete the widgets linked to the parent hub. Are you sure you want to continue?" */
	SharedSettingHubParentModalConfirmMessage = "Shared_Setting_Hub_Parent_Modal_Confirm_Message",

	/** English translation: "Set as admin" */
	SharedSetAsAdmin = "Shared_Set_As_Admin",

	/** English translation: "This will cause the user {0} to have full rights in the hub. Do you want to make this user an administrator?" */
	SharedSetAsAdminConfirm = "Shared_Set_As_Admin_Confirm",

	/** English translation: "Set as default" */
	SharedSetAsDefault = "Shared_Set_As_Default",

	/** English translation: "Make this a hub-specific group" */
	SharedSetAsHubGroup = "Shared_Set_As_HubGroup",

	/** English translation: "This will cause the internal members of the group {0} to have full rights in the hub. Do you want to give them full rights?" */
	SharedSetGroupAsAdminConfirm = "Shared_Set_Group_As_Admin_Confirm",

	/** English translation: "Shares" */
	SharedShares = "Shared_Shares",

	/** English translation: "Share with link" */
	SharedShareWithLink = "Shared_Share_With_Link",

	/** English translation: "The short name is already in use." */
	SharedShortNameAlreadyUsed = "Shared_Short_Name_Already_Used",

	/** English translation: "Show more" */
	SharedShowMore = "Shared_Show_More",

	/** English translation: "Shrink" */
	SharedShrink = "Shared_Shrink",

	/** English translation: "Signatures" */
	SharedSignatures = "Shared_Signatures",

	/** English translation: "Size" */
	SharedSize = "Shared_Size",

	/** English translation: "Social" */
	SharedSocial = "Shared_Social",

	/** English translation: "Sort by" */
	SharedSortBy = "Shared_Sort_By",

	/** English translation: "Date created (ascending)" */
	SharedSortCreationDateAscending = "Shared_Sort_Creation_Date_Ascending",

	/** English translation: "Date created (descending)" */
	SharedSortCreationDateDescending = "Shared_Sort_Creation_Date_Descending",

	/** English translation: "Index (ascending)" */
	SharedSortIndexAscending = "Shared_Sort_Index_Ascending",

	/** English translation: "Index (descending)" */
	SharedSortIndexDescending = "Shared_Sort_Index_Descending",

	/** English translation: "Latest end date" */
	SharedSortLatestEndDate = "Shared_Sort_Latest_End_Date",

	/** English translation: "Latest start date" */
	SharedSortLatestStartDate = "Shared_Sort_Latest_Start_Date",

	/** English translation: "Name (ascending)" */
	SharedSortNameAscending = "Shared_Sort_Name_Ascending",

	/** English translation: "Name (descending)" */
	SharedSortNameDescending = "Shared_Sort_Name_Descending",

	/** English translation: "Oldest end date" */
	SharedSortOldestEndDate = "Shared_Sort_Oldest_End_Date",

	/** English translation: "Oldest start date" */
	SharedSortOldestStartDate = "Shared_Sort_Oldest_Start_Date",

	/** English translation: "Sort options" */
	SharedSortOptions = "Shared_Sort_Options",

	/** English translation: "Date modified (ascending)" */
	SharedSortUpdateDateAscending = "Shared_Sort_Update_Date_Ascending",

	/** English translation: "Date modified (descending)" */
	SharedSortUpdateDateDescending = "Shared_Sort_Update_Date_Descending",

	/** English translation: "Source widget" */
	SharedSourceWidget = "Shared_Source_Widget",

	/** English translation: "Start" */
	SharedStart = "Shared_Start",

	/** English translation: "Starting" */
	SharedStarting = "Shared_Starting",

	/** English translation: "Start date" */
	SharedStartDate = "Shared_Start_Date",

	/** English translation: "starts with" */
	SharedStartWith = "Shared_Start_With",

	/** English translation: "State" */
	SharedState = "Shared_State",

	/** English translation: "Status" */
	SharedStatus = "Shared_Status",

	/** English translation: "Step" */
	SharedStep = "Shared_Step",

	/** English translation: "Subjects" */
	SharedSubjects = "Shared_Subjects",

	/** English translation: "Subject deleted successfully." */
	SharedSubjectDeleteSuccess = "Shared_Subject_Delete_Success",

	/** English translation: "Subject title is empty." */
	SharedSubjectEmpty = "Shared_Subject_Empty",

	/** English translation: "Summary" */
	SharedSummary = "Shared_Summary",

	/** English translation: "Synchronise" */
	SharedSynchronise = "Shared_Synchronise",

	/** English translation: "Table" */
	SharedTableMode = "Shared_Table_Mode",

	/** English translation: "Number of tag groups" */
	SharedTagGroupsNumber = "Shared_TagGroups_Number",

	/** English translation: "Tags" */
	SharedTags = "Shared_Tags",

	/** English translation: "Tag number" */
	SharedTagsNumber = "Shared_Tags_Number",

	/** English translation: "Tag group" */
	SharedTagGroup = "Shared_Tag_Group",

	/** English translation: "Talks" */
	SharedTalks = "Shared_Talks",

	/** English translation: "Task creation" */
	SharedTaskAdminCreation = "Shared_Task_Admin_Creation",

	/** English translation: "Task deletion" */
	SharedTaskAdminDeletion = "Shared_Task_Admin_Deletion",

	/** English translation: "Task update" */
	SharedTaskAdminUpdate = "Shared_Task_Admin_Update",

	/** English translation: "Task assigned to me" */
	SharedTaskAssignement = "Shared_Task_Assignement",

	/** English translation: "Task unassigned from me" */
	SharedTaskUnassignement = "Shared_Task_Unassignement",

	/** English translation: "Task created by me updated" */
	SharedTaskUserCreation = "Shared_Task_User_Creation",

	/** English translation: "Task assigned to me deleted" */
	SharedTaskUserDeletion = "Shared_Task_User_Deletion",

	/** English translation: "Task assigned to me updated" */
	SharedTaskUserUpdate = "Shared_Task_User_Update",

	/** English translation: "Template" */
	SharedTemplate = "Shared_Template",

	/** English translation: "Templates" */
	SharedTemplates = "Shared_Templates",

	/** English translation: "Template in use" */
	SharedTemplateInUse = "Shared_Template_In_Use",

	/** English translation: "Text" */
	SharedText = "Shared_Text",

	/** English translation: "Text color" */
	SharedTextColor = "Shared_Text_Color",

	/** English translation: "Timeline" */
	SharedTimelineMode = "Shared_Timeline_Mode",

	/** English translation: "Time zone" */
	SharedTimezone = "Shared_Timezone",

	/** English translation: "Title" */
	SharedTitle = "Shared_Title",

	/** English translation: "Titles" */
	SharedTitles = "Shared_Titles",

	/** English translation: "Today" */
	SharedToday = "Shared_Today",

	/** English translation: "Token" */
	SharedToken = "Shared_Token",

	/** English translation: "Topic" */
	SharedTopic = "Shared_Topic",

	/** English translation: "Topics" */
	SharedTopics = "Shared_Topics",

	/** English translation: "Created {0} by {1}" */
	SharedTopicCreatedOnBy = "Shared_Topic_Created_On_By",

	/** English translation: "Total" */
	SharedTotal = "Shared_Total",

	/** English translation: "Total hub administrator" */
	SharedTotalHubAdministrator = "Shared_TotalHubAdministrator",

	/** English translation: "Hub creators" */
	SharedTotalHubCreators = "Shared_TotalHubCreators",

	/** English translation: "Hubs" */
	SharedTotalHubs = "Shared_TotalHubs",

	/** English translation: "Hub template creators" */
	SharedTotalHubTemplateCreators = "Shared_TotalHubTemplateCreators",

	/** English translation: "Storage space" */
	SharedTotalStorageSpaceUsed = "Shared_TotalStorageSpaceUsed",

	/** English translation: "Total licenses used" */
	SharedTotalUsedLicense = "Shared_TotalUsedLicense",

	/** English translation: "User licenses" */
	SharedTotalUserLicense = "Shared_TotalUserLicense",

	/** English translation: "Comment" */
	SharedToComment = "Shared_To_Comment",

	/** English translation: "Force" */
	SharedToForce = "Shared_To_Force",

	/** English translation: "Shared to me" */
	SharedToMe = "Shared_To_Me",

	/** English translation: "Reply" */
	SharedToReply = "Shared_To_Reply",

	/** English translation: "Sort" */
	SharedToSort = "Shared_To_Sort",

	/** English translation: "Tutorial" */
	SharedTutorial = "Shared_Tutorial",

	/** English translation: "Type" */
	SharedType = "Shared_Type",

	/** English translation: "Number of types" */
	SharedTypesNumber = "Shared_Types_Number",

	/** English translation: "Please type a character" */
	SharedTypeACharacter = "Shared_Type_A_Character",

	/** English translation: "Unassignment" */
	SharedUnassignment = "Shared_Unassignment",

	/** English translation: "Unit {0}" */
	SharedUnit = "Shared_Unit",

	/** English translation: "Unlink" */
	SharedUnlink = "Shared_Unlink",

	/** English translation: "Update" */
	SharedUpdate = "Shared_Update",

	/** English translation: "Updated" */
	SharedUpdated = "Shared_Updated",

	/** English translation: "Updated on" */
	SharedUpdatedOn = "Shared_Updated_On",

	/** English translation: "Update external user" */
	SharedUpdateExternalUser = "Shared_Update_External_User",

	/** English translation: "Update internal user" */
	SharedUpdateInternalUser = "Shared_Update_Internal_User",

	/** English translation: "Upload" */
	SharedUpload = "Shared_Upload",

	/** English translation: "Upload all" */
	SharedUploadAll = "Shared_Upload_All",

	/** English translation: "Upload file" */
	SharedUploadFile = "Shared_Upload_File",

	/** English translation: "Upload files" */
	SharedUploadFilesTitle = "Shared_Upload_Files_Title",

	/** English translation: "Upload images" */
	SharedUploadImageTitle = "Shared_Upload_Image_Title",

	/** English translation: "Upload videos" */
	SharedUploadVideoTitle = "Shared_Upload_Video_Title",

	/** English translation: "Use custom image" */
	SharedUseCustomImage = "Shared_UseCustomImage",

	/** English translation: "Used" */
	SharedUsed = "Shared_Used",

	/** English translation: "Use external accounts" */
	SharedUseExternalAccounts = "Shared_UseExternalAccounts",

	/** English translation: "Use Hubshare for files larger than (MB)" */
	SharedUseLargerThan = "Shared_UseLargerThan",

	/** English translation: "Use public accounts" */
	SharedUsePublicAccounts = "Shared_UsePublicAccounts",

	/** English translation: "User" */
	SharedUser = "Shared_User",

	/** English translation: "All users" */
	SharedUserKindAll = "Shared_UserKind_All",

	/** English translation: "External users" */
	SharedUserKindExternal = "Shared_UserKind_External",

	/** English translation: "Internal users" */
	SharedUserKindInternal = "Shared_UserKind_Internal",

	/** English translation: "No users" */
	SharedUserKindNone = "Shared_UserKind_None",

	/** English translation: "Users" */
	SharedUsers = "Shared_Users",

	/** English translation: "Users and groups" */
	SharedUsersAndGroups = "Shared_Users_And_Groups",

	/** English translation: "Number of users" */
	SharedUsersNumber = "Shared_Users_Number",

	/** English translation: "User details" */
	SharedUserDetails = "Shared_User_Details",

	/** English translation: "External users" */
	SharedUserExternal = "Shared_User_External",

	/** English translation: "Internal users" */
	SharedUserInternal = "Shared_User_Internal",

	/** English translation: "User manager" */
	SharedUserManager = "Shared_User_Manager",

	/** English translation: "Username" */
	SharedUserName = "Shared_User_Name",

	/** English translation: "User profile" */
	SharedUserProfile = "Shared_User_Profile",

	/** English translation: "User deletion" */
	SharedUserRemoveConfirmTitle = "Shared_User_Remove_Confirm_Title",

	/** English translation: "Validate" */
	SharedValidate = "Shared_Validate",

	/** English translation: "Configuration" */
	SharedVault = "Shared_Vault",

	/** English translation: "Configuration selection" */
	SharedVaultSelection = "Shared_Vault_Selection",

	/** English translation: "vCard" */
	SharedVCard = "Shared_VCard",

	/** English translation: "Version" */
	SharedVersion = "Shared_Version",

	/** English translation: "Versioning" */
	SharedVersioning = "Shared_Versioning",

	/** English translation: "View" */
	SharedView = "Shared_View",

	/** English translation: "Views widget" */
	SharedViewsWidget = "Shared_Views_Widget",

	/** English translation: "View more" */
	SharedViewMore = "Shared_View_More",

	/** English translation: "Open with Office desktop" */
	SharedViewOfficeDesktop = "Shared_View_Office_Desktop",

	/** English translation: "Open with Office for the web" */
	SharedViewOfficeOnline = "Shared_View_Office_Online",

	/** English translation: "Waiting" */
	SharedWaiting = "Shared_Waiting",

	/** English translation: "Warning" */
	SharedWarning = "Shared_Warning",

	/** English translation: "Warnings" */
	SharedWarnings = "Shared_Warnings",

	/** English translation: "Watermark template" */
	SharedWatermark = "Shared_Watermark",

	/** English translation: "Watermark templates" */
	SharedWatermarks = "Shared_Watermarks",

	/** English translation: "Web" */
	SharedWeb = "Shared_Web",

	/** English translation: "Website" */
	SharedWebSite = "Shared_Web_Site",

	/** English translation: "Week" */
	SharedWeek = "Shared_Week",

	/** English translation: "{0} weeks ago" */
	SharedWeeksAgo = "Shared_Weeks_Ago",

	/** English translation: "{0} weeks left" */
	SharedWeeksLeft = "Shared_Weeks_Left",

	/** English translation: "{0} week ago" */
	SharedWeekAgo = "Shared_Week_Ago",

	/** English translation: "{0} week left" */
	SharedWeekLeft = "Shared_Week_Left",

	/** English translation: "Widget" */
	SharedWidget = "Shared_Widget",

	/** English translation: "Widgets" */
	SharedWidgets = "Shared_Widgets",

	/** English translation: "without the condition" */
	SharedWithoutCondition = "Shared_Without_Condition",

	/** English translation: "Workflows" */
	SharedWorkflows = "Shared_Workflows",

	/** English translation: "{0} years ago" */
	SharedYearsAgo = "Shared_Years_Ago",

	/** English translation: "{0} years left" */
	SharedYearsLeft = "Shared_Years_Left",

	/** English translation: "{0} year ago" */
	SharedYearAgo = "Shared_Year_Ago",

	/** English translation: "{0} year left" */
	SharedYearLeft = "Shared_Year_Left",

	/** English translation: "Yes" */
	SharedYes = "Shared_Yes",

	/** English translation: "Your storage" */
	SharedYourStorage = "Shared_Your_Storage",

	/** English translation: "You are here" */
	SharedYouAreHere = "Shared_You_Are_Here",

	/** English translation: "Zip code" */
	SharedZipCode = "Shared_Zip_Code",

	/** English translation: "Zoom in" */
	SharedZoomIn = "Shared_Zoom_In",

	/** English translation: "Zoom out" */
	SharedZoomOut = "Shared_Zoom_Out",

	/** English translation: "Created shares" */
	SharesCreated = "Shares_Created",

	/** English translation: "Deleted shares" */
	SharesDeleted = "Shares_Deleted",

	/** English translation: "Shared with" */
	SharesWith = "Shares_With",

	/** English translation: "This share has expired." */
	ShareExpired = "Share_Expired",

	/** English translation: "This share is not accessible." */
	ShareNotAccessible = "Share_Not_Accessible",

	/** English translation: "Visible / Hidden" */
	ShareSubscription = "Share_Subscription",

	/** English translation: "Unsubscribe" */
	ShareUnsubscribe = "Share_Unsubscribe",

	/** English translation: "Show advanced settings" */
	ShowAdvancedSettings = "Show_Advanced_Settings",

	/** English translation: "New chat" */
	SocialAddThread = "Social_Add_Thread",

	/** English translation: "Message deleted" */
	SocialCommentDeleted = "Social_Comment_Deleted",

	/** English translation: "Are you sure you want to delete this chat?" */
	SocialDeleteThreadMessage = "Social_Delete_Thread_Message",

	/** English translation: "Delete chat" */
	SocialDeleteThreadTitle = "Social_Delete_Thread_Title",

	/** English translation: "Edit chat" */
	SocialEditThread = "Social_Edit_Thread",

	/** English translation: "Follow" */
	SocialFollow = "Social_Follow",

	/** English translation: "Feed" */
	SocialHubFeed = "Social_Hub_Feed",

	/** English translation: "Filter hubs" */
	SocialHubFilter = "Social_Hub_Filter",

	/** English translation: "Like" */
	SocialLike = "Social_Like",

	/** English translation: "New chat" */
	SocialNewThread = "Social_New_Thread",

	/** English translation: "Chat title" */
	SocialNewThreadPlaceholder = "Social_New_Thread_Placeholder",

	/** English translation: "Sort in ascending order" */
	SocialOrderTopicAsc = "Social_Order_Topic_Asc",

	/** English translation: "Sort in descending order" */
	SocialOrderTopicDesc = "Social_Order_Topic_Desc",

	/** English translation: "Say something" */
	SocialSaySomething = "Social_Say_Something",

	/** English translation: "See new topics" */
	SocialSeeNewTopics = "Social_See_New_Topics",

	/** English translation: "Chat" */
	SocialThread = "Social_Thread",

	/** English translation: "Administrator" */
	SocialThreadRightsAdmin = "Social_ThreadRights_Admin",

	/** English translation: "Moderator" */
	SocialThreadRightsModerator = "Social_ThreadRights_Moderator",

	/** English translation: "Writer" */
	SocialThreadRightsWriter = "Social_ThreadRights_Writer",

	/** English translation: "Chat creator" */
	SocialThreadCreator = "Social_Thread_Creator",

	/** English translation: "The chat title is empty." */
	SocialThreadNameEmpty = "Social_Thread_Name_Empty",

	/** English translation: "Chat rights" */
	SocialThreadRights = "Social_Thread_Rights",

	/** English translation: "Assertion URL" */
	SSOAssertionUrl = "SSO_Assertion_Url",

	/** English translation: "Audience restriction" */
	SSOAudienceRestriction = "SSO_Audience_Restriction",

	/** English translation: "Automatically create new users" */
	SSOAutoCreateUsers = "SSO_Auto_Create_Users",

	/** English translation: "Certificates" */
	SSOCertificates = "SSO_Certificates",

	/** English translation: "Download Hubshare certificate" */
	SSOCertificateDownload = "SSO_Certificate_Download",

	/** English translation: "Drop certificate" */
	SSOCertificateDrop = "SSO_Certificate_Drop",

	/** English translation: "Primary certificate" */
	SSOCertificatePrimary = "SSO_Certificate_Primary",

	/** English translation: "Secondary certificate" */
	SSOCertificateSecondary = "SSO_Certificate_Secondary",

	/** English translation: "Certificate file. Max: 500KB" */
	SSOCertificateUploadInformations = "SSO_Certificate_Upload_Informations",

	/** English translation: "Upload primary certificate" */
	SSOCertificateUploadPrimary = "SSO_Certificate_Upload_Primary",

	/** English translation: "Upload secondary certificate" */
	SSOCertificateUploadSecondary = "SSO_Certificate_Upload_Secondary",

	/** English translation: "Use embedded certificates" */
	SSOCertificateUseEmbedded = "SSO_Certificate_Use_Embedded",

	/** English translation: "Clock skew is allowed between the identity provider and Hubshare." */
	SSOClockSkew = "SSO_Clock_Skew",

	/** English translation: "Shortcut" */
	SSOCompanyShortcut = "SSO_Company_Shortcut",

	/** English translation: "Custom" */
	SSOCustom = "SSO_Custom",

	/** English translation: "Data encryption method" */
	SSODataEncryptionMethod = "SSO_Data_Encryption_Method",

	/** English translation: "Default groups for automatically created users" */
	SSODefaultGroups = "SSO_Default_Groups",

	/** English translation: "Digest method" */
	SSODigestMethod = "SSO_Digest_Method",

	/** English translation: "Hubshare metadata" */
	SSODocstoreMetadata = "SSO_Docstore_Metadata",

	/** English translation: "Download Hubshare metadata" */
	SSODownloadMetadata = "SSO_Download_Metadata",

	/** English translation: "Drop metadata file" */
	SSODropMetadata = "SSO_Drop_Metadata",

	/** English translation: "Enable Single sign-on" */
	SSOEnable = "SSO_Enable",

	/** English translation: "You have to connect with Single sign-on. Please use this link to connect." */
	SSOErrorSSORequired = "SSO_Error_SSO_Required",

	/** English translation: "External user login" */
	SSOExternalSignIn = "SSO_External_Sign_In",

	/** English translation: "Hide metadata" */
	SSOHideMetadata = "SSO_Hide_Metadata",

	/** English translation: "Identity protocol" */
	SSOIdentityProtocol = "SSO_Identity_Protocol",

	/** English translation: "Identity provider" */
	SSOIdentityProvider = "SSO_Identity_Provider",

	/** English translation: "Issuer" */
	SSOIssuerUrl = "SSO_Issuer_Url",

	/** English translation: "Key encryption method" */
	SSOKeyEncryptionMethod = "SSO_Key_Encryption_Method",

	/** English translation: "SSO login button preview" */
	SSOLoginButtonPreview = "SSO_Login_Button_Preview",

	/** English translation: "Logout response URL" */
	SSOLogoutResponseUrl = "SSO_Logout_Response_Url",

	/** English translation: "Manage Single sign-on" */
	SSOManage = "SSO_Manage",

	/** English translation: "Upload metadata" */
	SSOMetadataUpload = "SSO_Metadata_Upload",

	/** English translation: "The metadata is now parsed. Please go through these settings before you save your changes." */
	SSOMetadataUploaded = "SSO_Metadata_Uploaded",

	/** English translation: "SAML 2.0 metadata file (.xml) Max: 500KB" */
	SSOMetadataUploadInformations = "SSO_Metadata_Upload_Informations",

	/** English translation: "Callback URL" */
	SSOOpenIDCallbackURL = "SSO_OpenID_Callback_URL",

	/** English translation: "Client ID" */
	SSOOpenIDClientId = "SSO_OpenID_Client_Id",

	/** English translation: "Client secret" */
	SSOOpenIDClientSecret = "SSO_OpenID_Client_Secret",

	/** English translation: "Logout URL" */
	SSOOpenIDLogoutURL = "SSO_OpenID_Logout_URL",

	/** English translation: "Provider URL" */
	SSOOpenIDProviderURL = "SSO_OpenID_Provider_URL",

	/** English translation: "OpenID settings" */
	SSOOpenIDSettings = "SSO_OpenID_Settings",

	/** English translation: "Paste text here" */
	SSOPasteMetadataText = "SSO_Paste_Metadata_Text",

	/** English translation: "Do not send user invitation emails" */
	SSOPreventUserInvitationEmail = "SSO_Prevent_User_Invitation_Email",

	/** English translation: "Kind" */
	SSOProviderKind = "SSO_Provider_Kind",

	/** English translation: "Provider logo" */
	SSOProviderLogo = "SSO_Provider_Logo",

	/** English translation: "Provider name" */
	SSOProviderName = "SSO_Provider_Name",

	/** English translation: "Provider settings" */
	SSOProviderSettings = "SSO_Provider_Settings",

	/** English translation: "SAML 2.0 advanced settings" */
	SSOSAMLAdvancedSettings = "SSO_SAML_Advanced_Settings",

	/** English translation: "Paste your metadata" */
	SSOSAMLPasteMetadata = "SSO_SAML_Paste_Metadata",

	/** English translation: "SAML 2.0 settings" */
	SSOSAMLSettings = "SSO_SAML_Settings",

	/** English translation: "Upload your metadata file (.xml)" */
	SSOSAMLUploadMetadata = "SSO_SAML_Upload_Metadata",

	/** English translation: "Your changes to settings are now saved." */
	SSOSavedSuccess = "SSO_Saved_Success",

	/** English translation: "Hubshare will: " */
	SSOSecurityDocstorePart = "SSO_Security_Docstore_Part",

	/** English translation: "The identity provider will: " */
	SSOSecurityIdPPart = "SSO_Security_IdP_Part",

	/** English translation: "Sign authentication request" */
	SSOSecuritySignAuthn = "SSO_Security_Sign_Authn",

	/** English translation: "Sign logout request" */
	SSOSecuritySignLogoutRequest = "SSO_Security_Sign_Logout_Request",

	/** English translation: "Sign logout response" */
	SSOSecuritySignLogoutResponse = "SSO_Security_Sign_Logout_Response",

	/** English translation: "Encrypt the assertion" */
	SSOSecurityWantAssertionEncrypted = "SSO_Security_Want_Assertion_Encrypted",

	/** English translation: "Sign the assertion" */
	SSOSecurityWantAssertionSigned = "SSO_Security_Want_Assertion_Signed",

	/** English translation: "Sign the SAML 2.0 response" */
	SSOSecurityWantSAMLSigned = "SSO_Security_Want_SAML_Signed",

	/** English translation: "Send metadata" */
	SSOSendMetadata = "SSO_Send_Metadata",

	/** English translation: "Single sign-out URL" */
	SSOServiceLogoutUrl = "SSO_Service_Logout_Url",

	/** English translation: "Single sign-on URL" */
	SSOServiceUrl = "SSO_Service_Url",

	/** English translation: "Session timeout" */
	SSOSessionTimeout = "SSO_Session_Timeout",

	/** English translation: "Show metadata" */
	SSOShowMetadata = "SSO_Show_Metadata",

	/** English translation: "Signature and encryption" */
	SSOSignatureAndEncryption = "SSO_Signature_And_Encryption",

	/** English translation: "Signature method" */
	SSOSignatureMethod = "SSO_Signature_Method",

	/** English translation: "Signout URL" */
	SSOSignOutUrl = "SSO_SignOut_Url",

	/** English translation: "Sign in with" */
	SSOSignInWith = "SSO_Sign_In_With",

	/** English translation: "Sign in with SSO" */
	SSOSignInWithSSO = "SSO_Sign_In_With_SSO",

	/** English translation: "Can use login or password" */
	SSOSSOOnly = "SSO_SSO_Only",

	/** English translation: "The shortcut is not available." */
	SSOUrlAlreadyUsed = "SSO_Url_Already_Used",

	/** English translation: "When you personalize URLs, you can login directly with your identity provider to Hubshare or the Hubshare API." */
	SSOUrlInformations = "SSO_Url_Informations",

	/** English translation: "URL personalization" */
	SSOUrlPersonalization = "SSO_Url_Personalization",

	/** English translation: "The shortcut couldn't be verified." */
	SSOUrlVerificationError = "SSO_Url_Verification_Error",

	/** English translation: "The previous URL will be overwritten. The changes are effective after the save." */
	SSOUrlWarning = "SSO_Url_Warning",

	/** English translation: "User settings" */
	SSOUsersSettings = "SSO_Users_Settings",

	/** English translation: "Sub-hubs" */
	SubHubs = "Sub_Hubs",

	/** English translation: "Please enter a title and content for at least one language." */
	SuperAdminAtLeastOneTitleAndContent = "Super_Admin_At_Least_One_Title_And_Content",

	/** English translation: "Please enter a title for at least one language." */
	SuperAdminAtLeastOneTranslation = "Super_Admin_At_Least_One_Translation",

	/** English translation: "The contract is limited to {0} days." */
	SuperAdminCompanyContractLimitation = "Super_Admin_Company_Contract_Limitation",

	/** English translation: "The organization is now created." */
	SuperAdminCompanyCreatedSuccessfully = "Super_Admin_Company_Created_Successfully",

	/** English translation: "Creation date" */
	SuperAdminCompanyCreationDate = "Super_Admin_Company_Creation_Date",

	/** English translation: "The organization doesn't have enough quota." */
	SuperAdminCompanyNotEnoughQuota = "Super_Admin_Company_Not_Enough_Quota",

	/** English translation: "The storage is limited to {0} GB." */
	SuperAdminCompanyStorageLimitation = "Super_Admin_Company_Storage_Limitation",

	/** English translation: "The organization is now updated." */
	SuperAdminCompanyUpdatedSuccessfully = "Super_Admin_Company_Updated_Successfully",

	/** English translation: "You are authorized to create a maximum of {0} users." */
	SuperAdminCompanyUserLimitation = "Super_Admin_Company_User_Limitation",

	/** English translation: "End date" */
	SuperAdminContractEndDate = "Super_Admin_Contract_End_Date",

	/** English translation: "The administrator is now created." */
	SuperAdminCreatedSuccessfully = "Super_Admin_Created_Successfully",

	/** English translation: "Create administrator" */
	SuperAdminCreateAdministrator = "Super_Admin_Create_Administrator",

	/** English translation: "Create organization" */
	SuperAdminCreateCompany = "Super_Admin_Create_Company",

	/** English translation: "You are creating an administrator." */
	SuperAdminCreatingAnAdmin = "Super_Admin_Creating_An_Admin",

	/** English translation: "External users in organization:" */
	SuperAdminCurrentExternalLicences = "Super_Admin_Current_External_Licences",

	/** English translation: "Internal users in organization:" */
	SuperAdminCurrentInternalLicences = "Super_Admin_Current_Internal_Licences",

	/** English translation: "Users inorganization:" */
	SuperAdminCurrentLicences = "Super_Admin_Current_Licences",

	/** English translation: "Outlook licences used in organization:" */
	SuperAdminCurrentOutlookLicences = "Super_Admin_Current_Outlook_Licences",

	/** English translation: "The administrator is now deleted." */
	SuperAdminDeletedSuccessfully = "Super_Admin_Deleted_Successfully",

	/** English translation: "Deprecated" */
	SuperAdminDeprecated = "Super_Admin_Deprecated",

	/** English translation: "Are you sure you want to disable this organization?" */
	SuperAdminDisableCompanyConfirmation = "Super_Admin_Disable_Company_Confirmation",

	/** English translation: "NOTE: always verify from the customer that it is OK to delete their data. Requirement: either data has been transferred to the customer, or customer does not need the data and gives permission to fully delete it." */
	SuperAdminDisableCompanyConfirmationNote = "Super_Admin_Disable_Company_Confirmation_Note",

	/** English translation: "Disable only: Organization users can no longer login but all their files are kept." */
	SuperAdminDisableCompanyOnly = "Super_Admin_Disable_Company_Only",

	/** English translation: "Disable and delete files: Organization users can no longer login and all their files are deleted. This operation frees disk space." */
	SuperAdminDisableCompanyWithDelete = "Super_Admin_Disable_Company_With_Delete",

	/** English translation: "Are you sure you want to disable this user?" */
	SuperAdminDisableUserConfirmation = "Super_Admin_Disable_User_Confirmation",

	/** English translation: "Disable only: User can no longer login but all their files are kept." */
	SuperAdminDisableUserOnly = "Super_Admin_Disable_User_Only",

	/** English translation: "Disable and delete files: User can no longer login and all their files are deleted. This operation frees disk space." */
	SuperAdminDisableUserWithDelete = "Super_Admin_Disable_User_With_Delete",

	/** English translation: "Edit organization users" */
	SuperAdminEditCompanyUsers = "Super_Admin_Edit_Company_Users",

	/** English translation: "Add recipients" */
	SuperAdminEmailAddRecipients = "Super_Admin_Email_Add_Recipients",

	/** English translation: "The email doesn't have any content." */
	SuperAdminEmailBodyEmpty = "Super_Admin_Email_Body_Empty",

	/** English translation: "Recipient" */
	SuperAdminEmailRecipient = "Super_Admin_Email_Recipient",

	/** English translation: "Recipients" */
	SuperAdminEmailRecipients = "Super_Admin_Email_Recipients",

	/** English translation: "Please select at least one recipient." */
	SuperAdminEmailSelectOneRecipient = "Super_Admin_Email_Select_One_Recipient",

	/** English translation: "Send" */
	SuperAdminEmailSend = "Super_Admin_Email_Send",

	/** English translation: "The email was sent." */
	SuperAdminEmailSent = "Super_Admin_Email_Sent",

	/** English translation: "Subject" */
	SuperAdminEmailSubject = "Super_Admin_Email_Subject",

	/** English translation: "Write email" */
	SuperAdminEmailWriteEmail = "Super_Admin_Email_Write_Email",

	/** English translation: "This email doesn't have any content. Do you want to send it anyway?" */
	SuperAdminEmtySubject = "Super_Admin_Emty_Subject",

	/** English translation: "Enabled" */
	SuperAdminEnabled = "Super_Admin_Enabled",

	/** English translation: "Are you sure you want to enable this organization?" */
	SuperAdminEnableCompanyConfirmation = "Super_Admin_Enable_Company_Confirmation",

	/** English translation: "Are you sure you want to enable this user?" */
	SuperAdminEnableUserConfirmation = "Super_Admin_Enable_User_Confirmation",

	/** English translation: "External licenses" */
	SuperAdminExternalLicences = "Super_Admin_External_Licences",

	/** English translation: "Filter by status" */
	SuperAdminFilterByUserStatus = "Super_Admin_Filter_By_User_Status",

	/** English translation: "Filter by type" */
	SuperAdminFilterByUserType = "Super_Admin_Filter_By_User_Type",

	/** English translation: "Hub creators" */
	SuperAdminHubCreators = "Super_Admin_Hub_Creators",

	/** English translation: "Hub licenses" */
	SuperAdminHubLicences = "Super_Admin_Hub_Licences",

	/** English translation: "Hub only" */
	SuperAdminHubOnly = "Super_Admin_Hub_Only",

	/** English translation: "Internal licenses" */
	SuperAdminInternalLicences = "Super_Admin_Internal_Licences",

	/** English translation: "The item is now moved." */
	SuperAdminItemMovedSuccess = "Super_Admin_Item_Moved_Success",

	/** English translation: "Licenses" */
	SuperAdminLicences = "Super_Admin_Licences",

	/** English translation: "Current value" */
	SuperAdminLicenseCurrentValue = "Super_Admin_License_Current_Value",

	/** English translation: "New value" */
	SuperAdminLicenseNewValue = "Super_Admin_License_New_Value",

	/** English translation: "Current license is already up to date." */
	SuperAdminLicenseUpToDate = "Super_Admin_License_Up_To_Date",

	/** English translation: "Please add at least one hub license to manage hubs." */
	SuperAdminLimitationsHubsNoCreator = "Super_Admin_Limitations_Hubs_No_Creator",

	/** English translation: "There aren't enough hub licenses for existing users." */
	SuperAdminLimitationsLicenceHubReduction = "Super_Admin_Limitations_Licence_Hub_Reduction",

	/** English translation: "There aren't enough Outlook plugin licenses for existing users." */
	SuperAdminLimitationsLicenceOutlookReduction = "Super_Admin_Limitations_Licence_Outlook_Reduction",

	/** English translation: "There aren't enough licenses for existing users." */
	SuperAdminLimitationsLicenceReduction = "Super_Admin_Limitations_Licence_Reduction",

	/** English translation: "In "External user licenses", please use a number that is larger than the number of existing external users." */
	SuperAdminLimitationsLicenceReductionExternal = "Super_Admin_Limitations_Licence_Reduction_External",

	/** English translation: "The user is now locked out." */
	SuperAdminLockSuccess = "Super_Admin_Lock_Success",

	/** English translation: "Lock out user" */
	SuperAdminLockUser = "Super_Admin_Lock_User",

	/** English translation: "Are you sure you want to lock out this user?" */
	SuperAdminLockUserConfirmation = "Super_Admin_Lock_User_Confirmation",

	/** English translation: "Mode" */
	SuperAdminMode = "Super_Admin_Mode",

	/** English translation: "You can't exceed the number of user licenses." */
	SuperAdminMoreThanUserLicences = "Super_Admin_More_Than_User_Licences",

	/** English translation: "Please give a title and content for your contents." */
	SuperAdminNeedContentOrTitle = "Super_Admin_Need_Content_Or_Title",

	/** English translation: "Please set at least one hub license for the organization." */
	SuperAdminNeedSetHubLicence = "Super_Admin_Need_Set_Hub_Licence",

	/** English translation: "Please set at least one Outlook license for the organization." */
	SuperAdminNeedSetOutlookLicence = "Super_Admin_Need_Set_Outlook_Licence",

	/** English translation: "There are no available licenses." */
	SuperAdminNoAvailableLicence = "Super_Admin_No_Available_Licence",

	/** English translation: "No email" */
	SuperAdminNoEmailNotification = "Super_Admin_No_Email_Notification",

	/** English translation: "Can change settings" */
	SuperAdminOutlookCanModifySettings = "Super_Admin_Outlook_Can_Modify_Settings",

	/** English translation: "Outlook plugin" */
	SuperAdminPluginOutlook = "Super_Admin_Plugin_Outlook",

	/** English translation: "Quota" */
	SuperAdminQuota = "Super_Admin_Quota",

	/** English translation: "Are you sure you want to delete this administrator?" */
	SuperAdminRemoveUserConfirmation = "Super_Admin_Remove_User_Confirmation",

	/** English translation: "Server administrator" */
	SuperAdminRoleServerAdmin = "Super_Admin_Role_Server_Admin",

	/** English translation: "Administrator who has access to every administration section." */
	SuperAdminRoleServerAdminDesc = "Super_Admin_Role_Server_Admin_Desc",

	/** English translation: "3 characters minimum" */
	SuperAdminSearch3CharactersMin = "Super_Admin_Search_3_Characters_Min",

	/** English translation: "Search (3 characters minimum)" */
	SuperAdminSearchAtLeast3Characters = "Super_Admin_Search_At_Least_3_Characters",

	/** English translation: "Write at least 3 characters." */
	SuperAdminSearchTypeAtLeast3Characters = "Super_Admin_Search_Type_At_Least_3_Characters",

	/** English translation: "Send invitation email" */
	SuperAdminSendEmailInvitation = "Super_Admin_Send_Email_Invitation",

	/** English translation: "Storage" */
	SuperAdminStorage = "Super_Admin_Storage",

	/** English translation: "Unlock out" */
	SuperAdminUnlock = "Super_Admin_Unlock",

	/** English translation: "The user is now unlocked out." */
	SuperAdminUnlockSuccess = "Super_Admin_Unlock_Success",

	/** English translation: "Unlock out user" */
	SuperAdminUnlockUser = "Super_Admin_Unlock_User",

	/** English translation: "Are you sure you want to unlock out this user?" */
	SuperAdminUnlockUserConfirmation = "Super_Admin_Unlock_User_Confirmation",

	/** English translation: "The administrator is now updated." */
	SuperAdminUpdatedSuccessfully = "Super_Admin_Updated_Successfully",

	/** English translation: "Update license" */
	SuperAdminUpdateLicense = "Super_Admin_Update_License",

	/** English translation: "Upload a license file." */
	SuperAdminUploadLicenseFile = "Super_Admin_Upload_License_File",

	/** English translation: "Usage" */
	SuperAdminUsage = "Super_Admin_Usage",

	/** English translation: "Usage rate" */
	SuperAdminUsageRate = "Super_Admin_Usage_Rate",

	/** English translation: "The user is now disabled." */
	SuperAdminUserDisabledSuccess = "Super_Admin_User_Disabled_Success",

	/** English translation: "The user is now enabled." */
	SuperAdminUserEnabledSuccess = "Super_Admin_User_Enabled_Success",

	/** English translation: "User licenses" */
	SuperAdminUserLicenses = "Super_Admin_User_Licenses",

	/** English translation: "The organization doesn't have enough storage space." */
	SuperAdminUserQuotaChanged = "Super_Admin_User_Quota_Changed",

	/** English translation: "The user is now updated." */
	SuperAdminUserUpdatedSuccessfully = "Super_Admin_User_Updated_Successfully",

	/** English translation: "This template cannot be deleted because it has extension configurations used in linked hubs." */
	TemplateExtensionConfigurationsInUse = "Template_Extension_Configurations_In_Use",

	/** English translation: "linked hubs" */
	TemplateLinkHubs = "Template_Link_Hubs",

	/** English translation: "linked hubs" */
	TemplateLinkHubsCount = "Template_Link_Hubs_Count",

	/** English translation: "Select the hubs you want to link" */
	TemplateLinkHubsDescription = "Template_Link_Hubs_Description",

	/** English translation: "Please confirm that you want to remove this group from the template." */
	TemplateRemoveGroup = "Template_Remove_Group",

	/** English translation: "Please confirm that you want to remove this user from the template." */
	TemplateRemoveUser = "Template_Remove_User",

	/** English translation: "Template rights" */
	TemplateRights = "Template_Rights",

	/** English translation: "Can edit template" */
	TemplateRightsCanEdit = "Template_Rights_Can_Edit",

	/** English translation: "This operation removes all user rights on this template.Please confirm that you want to remove this user." */
	TemplateRightsDeleteMessage = "Template_Rights_Delete_Message",

	/** English translation: "Remote template rights" */
	TemplateRightsDeleteTitle = "Template_Rights_Delete_Title",

	/** English translation: "You can't give edit rights because the user is not a hub template creator." */
	TemplateRightsEditCreatorLimitation = "Template_Rights_Edit_Creator_Limitation",

	/** English translation: "You can't remove edit rights from the hub creator." */
	TemplateRightsEditOwnerLimitation = "Template_Rights_Edit_Owner_Limitation",

	/** English translation: "You can't remove your template rights." */
	TemplateRightsSelfRemove = "Template_Rights_Self_Remove",

	/** English translation: "Set edit right" */
	TemplateRightsSetWrite = "Template_Rights_Set_Write",

	/** English translation: "If you give this user edit rights to this template, the user will have full rights on this template, for example modules, widgets, and rights. Are you sure you want this user to have edit rights on this template?" */
	TemplateRightsSetWriteConfirm = "Template_Rights_Set_Write_Confirm",

	/** English translation: "The users in this list have the right to create new hubs from this template." */
	TemplateRightsSubtitle = "Template_Rights_Subtitle",

	/** English translation: "Remove edit right" */
	TemplateRightsUnsetWrite = "Template_Rights_Unset_Write",

	/** English translation: "If you remove editor rights from this user, the user can still create new templates or new hubs with other rights. Are you sure you want to remove editor rights from this user?" */
	TemplateRightsUnsetWriteConfirm = "Template_Rights_Unset_Write_Confirm",

	/** English translation: "This will cause the user {0} to have full rights in hubs created with this template. Do you want to make this user an administrator?" */
	TemplateSetAsAdminConfirm = "Template_Set_As_Admin_Confirm",

	/** English translation: "This will cause the internal members of the group {0} to have full rights in hubs created with this template. Do you want to give them full rights?" */
	TemplateSetGroupAsAdminConfirm = "Template_Set_Group_As_Admin_Confirm",

	/** English translation: "Show only hubs updated or created with this template" */
	TemplateShowOnlyImportRelatedHubs = "Template_Show_Only_Import_Related_Hubs",

	/** English translation: "Show only similar hubs" */
	TemplateShowOnlySimilarHubs = "Template_Show_Only_Similar_Hubs",

	/** English translation: "Show widget tasks" */
	ToDoListDisplayCurrent = "To_Do_List_Display_Current",

	/** English translation: "Show all your assigned tasks in this hub and children hubs" */
	ToDoListDisplayOverview = "To_Do_List_Display_Overview",

	/** English translation: "Transfer" */
	Transfer = "Transfer",

	/** English translation: "<video src="Content/Video/en_blog_add_content.mp4" controls="true"></video>" */
	TutorialBlogVideo = "Tutorial_Blog_Video",

	/** English translation: "Filter to meet your needs" */
	TutorialCalendarAvailable = "Tutorial_Calendar_Available",

	/** English translation: "Schedule your activities and events" */
	TutorialCalendarNav = "Tutorial_Calendar_Nav",

	/** English translation: "<video src="Content/Video/en_add_widget.mp4" controls="true"></video>" */
	TutorialDashboardAdminGif = "Tutorial_Dashboard_AdminGif",

	/** English translation: "Your dashboard starts here. Please click this icon to create a workspace that meets your needs." */
	TutorialDashboardAdminOpen = "Tutorial_Dashboard_AdminOpen",

	/** English translation: "Here you can create, update, and manage your dashboard widgets." */
	TutorialDashboardAdminPanel = "Tutorial_Dashboard_AdminPanel",

	/** English translation: "Done" */
	TutorialDone = "Tutorial_Done",

	/** English translation: "Mark your favorite documents" */
	TutorialFileFavorite1 = "Tutorial_File_Favorite1",

	/** English translation: "Here you can find them again" */
	TutorialFileFavorite2 = "Tutorial_File_Favorite2",

	/** English translation: "Manage your documents" */
	TutorialFileManagement = "Tutorial_File_Management",

	/** English translation: "Import and organize your documents" */
	TutorialFileNav = "Tutorial_File_Nav",

	/** English translation: "MyFile.pdf" */
	TutorialFileSample = "Tutorial_File_Sample",

	/** English translation: "In the notification center, you can see your daily activities." */
	TutorialGlobalNotificationCenter = "Tutorial_Global_NotificationCenter",

	/** English translation: "This is a quick access to advanced search." */
	TutorialGlobalSearchbar = "Tutorial_Global_Searchbar",

	/** English translation: "In this section, you can find the modules that are active in this hub." */
	TutorialGlobalSidebar = "Tutorial_Global_Sidebar",

	/** English translation: "Next" */
	TutorialNext = "Tutorial_Next",

	/** English translation: "Add and categorize your contacts" */
	TutorialPeopleNav = "Tutorial_People_Nav",

	/** English translation: "Previous" */
	TutorialPrevious = "Tutorial_Previous",

	/** English translation: "Important" */
	TutorialProjectFakeCategory = "Tutorial_Project_FakeCategory",

	/** English translation: "Task description" */
	TutorialProjectFakeDescription = "Tutorial_Project_FakeDescription",

	/** English translation: "Important" */
	TutorialProjectFakeTag = "Tutorial_Project_FakeTag",

	/** English translation: "My task" */
	TutorialProjectFakeTitle = "Tutorial_Project_FakeTitle",

	/** English translation: "Manage your time and your workload" */
	TutorialProjectMyTasks = "Tutorial_Project_MyTasks",

	/** English translation: "Organize your project and assign tasks to other users" */
	TutorialProjectNav = "Tutorial_Project_Nav",

	/** English translation: "Get full visibility on your projects" */
	TutorialProjectTable = "Tutorial_Project_Table",

	/** English translation: "<video src="Content/Video/en_project_timeline.mp4" controls="true"></video>" */
	TutorialProjectVideo = "Tutorial_Project_Video",

	/** English translation: "Search with multi-criteria in seconds" */
	TutorialSearchQuery = "Tutorial_Search_Query",

	/** English translation: "Two-factor authentication" */
	TwoFactorAuthentication = "Two_Factor_Authentication",

	/** English translation: "The two-factor authentication is already configured." */
	TwoFactorAuthenticationAlreadyConfigured = "Two_Factor_Authentication_Already_Configured",

	/** English translation: "Here you can configure the two-factor authentication (2FA) for the users in your organization." */
	TwoFactorAuthenticationCompanySettingsLabel = "Two_Factor_Authentication_Company_Settings_Label",

	/** English translation: "When you have set up your TOTP application with Hubshare authentication, you can complete the configuration with a code shown in your application" */
	TwoFactorAuthenticationConfigurationBottomDescription = "Two_Factor_Authentication_Configuration_Bottom_Description",

	/** English translation: "The two-factor authentication configuration failed." */
	TwoFactorAuthenticationConfigurationFailed = "Two_Factor_Authentication_Configuration_Failed",

	/** English translation: "Manual setup code" */
	TwoFactorAuthenticationConfigurationManualSetupCode = "Two_Factor_Authentication_Configuration_Manual_Setup_Code",

	/** English translation: "For security reasons, this page will only be accessible for a limited time ({0} minutes). Beyond that, you will have to enter your password and start the procedure again. If you had already scanned a QR code or entered a code manually, it will no longer be valid." */
	TwoFactorAuthenticationConfigurationPasswordExplanation = "Two_Factor_Authentication_Configuration_Password_Explanation",

	/** English translation: "The two-factor authentication is now configured." */
	TwoFactorAuthenticationConfigurationSuccess = "Two_Factor_Authentication_Configuration_Success",

	/** English translation: "Here you can configure the two-factor authentication with a QR code. You can do this with a TOTP mobile application, for example Google Authenticator or Microsoft Authenticator." */
	TwoFactorAuthenticationConfigurationTopDescription = "Two_Factor_Authentication_Configuration_Top_Description",

	/** English translation: "Configure two-factor authentication to continue using Hubshare product." */
	TwoFactorAuthenticationConfigurationTopMandatory = "Two_Factor_Authentication_Configuration_Top_Mandatory",

	/** English translation: "Manage two-factor authentication" */
	TwoFactorAuthenticationManage = "Two_Factor_Authentication_Manage",

	/** English translation: "Here you can configure the two-factor authentication (2FA) for your user account." */
	TwoFactorAuthenticationMySettingsLabel = "Two_Factor_Authentication_My_Settings_Label",

	/** English translation: "Reset 2FA configuration" */
	TwoFactorAuthenticationReset = "Two_Factor_Authentication_Reset",

	/** English translation: "The two-factor authentication is now reset." */
	TwoFactorAuthenticationResetSuccess = "Two_Factor_Authentication_Reset_Success",

	/** English translation: "Scan QR code" */
	TwoFactorAuthenticationScanQRCode = "Two_Factor_Authentication_Scan_QRCode",

	/** English translation: "Always force 2FA for new users" */
	TwoFactorForceUserKindLabel = "Two_Factor_Force_User_Kind_Label",

	/** English translation: "Security code" */
	TwoFactorSecurityCode = "Two_Factor_Security_Code",

	/** English translation: "You can't edit this field because this user is already forced to use 2FA in another organization." */
	TwoFactorSettingsNoRightToEdit = "Two_Factor_Settings_No_Right_To_Edit",

	/** English translation: "Add" */
	UiButtonAdd = "Ui_Button_Add",

	/** English translation: "Back" */
	UiButtonBack = "Ui_Button_Back",

	/** English translation: "Delete" */
	UiButtonDelete = "Ui_Button_Delete",

	/** English translation: "Edit" */
	UiButtonEdit = "Ui_Button_Edit",

	/** English translation: "Edit widgets" */
	UiButtonEditWidget = "Ui_Button_Edit_Widget",

	/** English translation: "Manage pages" */
	UiButtonManagePages = "Ui_Button_Manage_Pages",

	/** English translation: "New widget" */
	UiButtonNewWidget = "Ui_Button_New_Widget",

	/** English translation: "Search" */
	UiButtonSearch = "Ui_Button_Search",

	/** English translation: "See all" */
	UiButtonSeeAll = "Ui_Button_See_All",

	/** English translation: "Widget rights" */
	UiButtonWidgetsRights = "Ui_Button_Widgets_Rights",

	/** English translation: "Custom fields" */
	UiCustomFields = "Ui_Custom_Fields",

	/** English translation: "at" */
	UiDateAt = "Ui_Date_At",

	/** English translation: "From:" */
	UiDateFrom = "Ui_Date_From",

	/** English translation: "On" */
	UiDateOn = "Ui_Date_On",

	/** English translation: "To:" */
	UiDateTo = "Ui_Date_To",

	/** English translation: "Default language" */
	UiDefaultLanguage = "Ui_Default_Language",

	/** English translation: "Description:" */
	UiFormDescription = "Ui_Form_Description",

	/** English translation: "The email address is invalid." */
	UiFormValidationInvalidEmail = "Ui_Form_Validation_Invalid_Email",

	/** English translation: "The maximum length is" */
	UiFormValidationMaxLengthIs = "Ui_Form_Validation_Max_Length_Is",

	/** English translation: "This field is required." */
	UiFormValidationRequiredField = "Ui_Form_Validation_Required_Field",

	/** English translation: "Modified" */
	UiModified = "Ui_Modified",

	/** English translation: "Not modified" */
	UiNotModified = "Ui_Not_Modified",

	/** English translation: "Shared" */
	UiShared = "Ui_Shared",

	/** English translation: "Value" */
	UiValue = "Ui_Value",

	/** English translation: "bytes" */
	UnitB = "Unit_B",

	/** English translation: "EB" */
	UnitEB = "Unit_EB",

	/** English translation: "GB" */
	UnitGB = "Unit_GB",

	/** English translation: "KB" */
	UnitKB = "Unit_KB",

	/** English translation: "MB" */
	UnitMB = "Unit_MB",

	/** English translation: "PB" */
	UnitPB = "Unit_PB",

	/** English translation: "TB" */
	UnitTB = "Unit_TB",

	/** English translation: "The maximum file size is {1} MB. The size of this file is {0} MB." */
	UploadFileSizeError = "Upload_File_Size_Error",

	/** English translation: "The file type is not valid." */
	UploadFileTypeError = "Upload_File_Type_Error",

	/** English translation: "The file must be an image." */
	UploadFileTypeImageError = "Upload_File_Type_Image_Error",

	/** English translation: "The file must be a video." */
	UploadFileTypeVideoError = "Upload_File_Type_Video_Error",

	/** English translation: "If you delete this image and it is used in a widget, the image is deleted from the widget.Are you sure you want to delete this image?" */
	UploadImageDeleteConfirmText = "Upload_Image_Delete_Confirm_Text",

	/** English translation: "Remove picture" */
	UploadImageDeleteConfirmTitle = "Upload_Image_Delete_Confirm_Title",

	/** English translation: "If you delete this video and it is used in a widget, the video is deleted from the widget.Are you sure you want to delete this video?" */
	UploadVideoDeleteConfirmText = "Upload_Video_Delete_Confirm_Text",

	/** English translation: "Remove video" */
	UploadVideoDeleteConfirmTitle = "Upload_Video_Delete_Confirm_Title",

	/** English translation: "Username" */
	UserName = "UserName",

	/** English translation: "User activities" */
	UsersActivities = "Users_Activities",

	/** English translation: "User activities" */
	UserActivities = "User_Activities",

	/** English translation: "This user is already in the list." */
	UserAlreadyInList = "User_Already_In_List",

	/** English translation: "The user doesn't have access to the module." */
	UserNotAccessModule = "User_Not_Access_Module",

	/** English translation: "The description is too long." */
	UserProfileDescriptionLengthError = "User_Profile_Description_Length_Error",

	/** English translation: "The profile picture couldn't be saved." */
	UserProfilePictureUpdateError = "User_Profile_Picture_Update_Error",

	/** English translation: "Your profile picture is now updated." */
	UserProfilePictureUpdateSuccess = "User_Profile_Picture_Update_Success",

	/** English translation: "Changes to your profile couldn't be saved." */
	UserProfileUpdateError = "User_Profile_Update_Error",

	/** English translation: "Your user profile is now updated." */
	UserProfileUpdateSuccess = "User_Profile_Update_Success",

	/** English translation: "Copy the new API token" */
	UserSettingsApiTokensCopy = "User_Settings_Api_Tokens_Copy",

	/** English translation: "Copy and save your token. When you close this window, you can no longer retrieve it." */
	UserSettingsApiTokensCopySaveAlert = "User_Settings_Api_Tokens_Copy_Save_Alert",

	/** English translation: "The token was copied" */
	UserSettingsApiTokensCopyToClipboardSuccess = "User_Settings_Api_Tokens_Copy_To_Clipboard_Success",

	/** English translation: "Do you want to delete this token?" */
	UserSettingsApiTokensDeleteConfirmMessage = "User_Settings_Api_Tokens_Delete_Confirm_Message",

	/** English translation: "Delete API token" */
	UserSettingsApiTokensDeleteConfirmTitle = "User_Settings_Api_Tokens_Delete_Confirm_Title",

	/** English translation: "If you generate a new token, you can no longer use the old token. Do you want to generate a new token?" */
	UserSettingsApiTokensGenerateWarningMessage = "User_Settings_Api_Tokens_Generate_Warning_Message",

	/** English translation: "Generate API token" */
	UserSettingsApiTokensGenerateWarningTitle = "User_Settings_Api_Tokens_Generate_Warning_Title",

	/** English translation: "My API tokens" */
	UserSettingsApiTokensTitle = "User_Settings_Api_Tokens_Title",

	/** English translation: "Reset" */
	UserSettingsClientAutoAllowButton = "User_Settings_ClientAutoAllow_Button",

	/** English translation: "User consent for applications is now reset." */
	UserSettingsClientAutoAllowDone = "User_Settings_ClientAutoAllow_Done",

	/** English translation: "User consent for applications" */
	UserSettingsClientAutoAllowLabel = "User_Settings_ClientAutoAllow_Label",

	/** English translation: "Back to hub" */
	UserSettingsCompanyBackToHub = "User_Settings_Company_Back_To_Hub",

	/** English translation: "Your information might be shared with other organizations in M-Files Hubshare. If you are an external user in an organization, you can leave the organization. If you are an internal user, you can't leave the organization." */
	UserSettingsCompanyDescription = "User_Settings_Company_Description",

	/** English translation: "Disabled" */
	UserSettingsCompanyDisabled = "User_Settings_Company_Disabled",

	/** English translation: "Enabled" */
	UserSettingsCompanyEnabled = "User_Settings_Company_Enabled",

	/** English translation: "If you leave this organization, you can no longer access the shares and hubs of this organization. Are you sure you want to leave the organization?" */
	UserSettingsCompanyLeaveModalMessage = "User_Settings_Company_Leave_Modal_Message",

	/** English translation: "Leave the organization" */
	UserSettingsCompanyLeaveModalTitle = "User_Settings_Company_Leave_Modal_Title",

	/** English translation: "You are not a user in any organization in M-Files Hubshare." */
	UserSettingsCompanyNoCompany = "User_Settings_Company_No_Company",

	/** English translation: "My organizations" */
	UserSettingsCompanyTitle = "User_Settings_Company_Title",

	/** English translation: "Waiting" */
	UserSettingsCompanyWaiting = "User_Settings_Company_Waiting",

	/** English translation: "Refresh the token table" */
	UserSettingsRefreshApiTokens = "User_Settings_Refresh_Api_Tokens",

	/** English translation: "Your invitation has expired." */
	ValidationExpired = "Validation_Expired",

	/** English translation: "We have sent a new link to your email." */
	ValidationExpiredMailSent = "Validation_Expired_Mail_Sent",

	/** English translation: "Custom" */
	ValueModeCustom = "Value_Mode_Custom",

	/** English translation: "Prefill" */
	ValueModePrefill = "Value_Mode_Prefill",

	/** English translation: "You don't have the rights to see the version history of this file." */
	VersioningAccessDenied = "Versioning_Access_Denied",

	/** English translation: "Activate version history" */
	VersioningActivateVersioning = "Versioning_Activate_Versioning",

	/** English translation: "Add version description" */
	VersioningAddDescription = "Versioning_Add_Description",

	/** English translation: "Add new version" */
	VersioningAddNewVersion = "Versioning_Add_New_Version",

	/** English translation: "Always make latest version official" */
	VersioningAlwaysMakeLatestOfficial = "Versioning_Always_Make_Latest_Official",

	/** English translation: "and all its versions" */
	VersioningAndAllItsVersions = "Versioning_And_All_Its_Versions",

	/** English translation: "Copy as new document" */
	VersioningCopyAsNewDocument = "Versioning_Copy_As_New_Document",

	/** English translation: "The version is now copied." */
	VersioningCopySuccess = "Versioning_Copy_Success",

	/** English translation: "The version is now deleted." */
	VersioningDeleteMultipleSuccess = "Versioning_Delete_Multiple_Success",

	/** English translation: "You can't delete a version that someone is currently editing." */
	VersioningDeleteOfficeOnlineError = "Versioning_Delete_Office_Online_Error",

	/** English translation: "You can't delete the official version." */
	VersioningDeleteOfficialError = "Versioning_Delete_Official_Error",

	/** English translation: "You don't have the rights to delete this version." */
	VersioningDeleteRightsError = "Versioning_Delete_Rights_Error",

	/** English translation: "The version is now deleted." */
	VersioningDeleteSuccess = "Versioning_Delete_Success",

	/** English translation: "If you disable version history, all the previous versions are deleted from all hubs of the organization. Are you sure you want to disable version history?" */
	VersioningDesactivationCompanyMessage = "Versioning_Desactivation_Company_Message",

	/** English translation: "If you disable version history,all the previous versions are deleted from this hub. Are you sure you want to disable version history?" */
	VersioningDesactivationHubMessage = "Versioning_Desactivation_Hub_Message",

	/** English translation: "Version history deactivation" */
	VersioningDesactivationTitle = "Versioning_Desactivation_Title",

	/** English translation: "Edit version description" */
	VersioningEditDescription = "Versioning_Edit_Description",

	/** English translation: "You can't add or edit the description of this version." */
	VersioningErrorDescriptionUpdateForbidden = "Versioning_Error_Description_Update_Forbidden",

	/** English translation: "You must check out the file to add a new version." */
	VersioningErrorNotCheckedOut = "Versioning_Error_Not_Checked_Out",

	/** English translation: "You can't change the official version." */
	VersioningErrorOfficialChangeForbidden = "Versioning_Error_Official_Change_Forbidden",

	/** English translation: "This file is checked out, and all versions are read only." */
	VersioningInfoCheckedOut = "Versioning_Info_Checked_Out",

	/** English translation: "The maximum number of versions in the version history was decreased. Would you like to delete the extra versions? If not, the versions are kept but will automatically be deleted when a new version is uploaded." */
	VersioningLimitDecreaseMessage = "Versioning_Limit_Decrease_Message",

	/** English translation: "Decrease in the number of version" */
	VersioningLimitDecreaseTitle = "Versioning_Limit_Decrease_Title",

	/** English translation: "If there is no more space for new versions in the version history and a new version is created, the oldest non-official version is deleted." */
	VersioningLimitDescription = "Versioning_Limit_Description",

	/** English translation: "The minimum number of versions is 2." */
	VersioningLimitMinError = "Versioning_Limit_Min_Error",

	/** English translation: "There is no more space for new versions in the version history. You don't have rights to delete a version and add a new one." */
	VersioningLimitReachedBlockingError = "Versioning_Limit_Reached_Blocking_Error",

	/** English translation: "There is no more space for new versions in the version history.The oldest non.official version will be overwritten with the next created version." */
	VersioningLimitReachedError = "Versioning_Limit_Reached_Error",

	/** English translation: "Make this official version" */
	VersioningMakeOfficial = "Versioning_Make_Official",

	/** English translation: "Number of versions" */
	VersioningNumberOfVersions = "Versioning_Number_Of_Versions",

	/** English translation: "Official" */
	VersioningOfficial = "Versioning_Official",

	/** English translation: "The official version is now changed." */
	VersioningOfficialChanged = "Versioning_Official_Changed",

	/** English translation: "The official version is the version that the users can, for example,see in the hub, open in the viewer, and download." */
	VersioningOfficialExplanation = "Versioning_Official_Explanation",

	/** English translation: "Number of file versions" */
	VersioningPerFile = "Versioning_Per_File",

	/** English translation: "The current version will be available in the version history." */
	VersioningUpdateFileInfo = "Versioning_Update_File_Info",

	/** English translation: "The file is checked out when the new version is uploaded." */
	VersioningUpdateFileInfoLock = "Versioning_Update_File_Info_Lock",

	/** English translation: "Version" */
	VersioningVersion = "Versioning_Version",

	/** English translation: "Versions" */
	VersioningVersions = "Versioning_Versions",

	/** English translation: "Version size" */
	VersioningVersionsSize = "Versioning_Versions_Size",

	/** English translation: "Version description" */
	VersioningVersionDescription = "Versioning_Version_Description",

	/** English translation: "Version history" */
	VersioningVersionHistory = "Versioning_Version_History",

	/** English translation: "(V. {0})" */
	VersioningVersionNumberAbridged = "Versioning_Version_Number_Abridged",

	/** English translation: "Video browser" */
	VideoBrowserTitle = "Video_Browser_Title",

	/** English translation: "Changes detected" */
	ViewerChangesDetected = "Viewer_Changes_Detected",

	/** English translation: "Changes were made to this document. Do you want to {0} the original file or the changed file?" */
	ViewerChangesDetectedMessage = "Viewer_Changes_Detected_Message",

	/** English translation: "Changed file" */
	ViewerChoseModified = "Viewer_Chose_Modified",

	/** English translation: "Original" */
	ViewerChoseOriginal = "Viewer_Chose_Original",

	/** English translation: "Hubshare" */
	ViewerHubshare = "Viewer_Hubshare",

	/** English translation: "The document couldn't be loaded. Please contact our customer support if the problem continues." */
	ViewerLoadingErrorMessage = "Viewer_Loading_Error_Message",

	/** English translation: "Office desktop" */
	ViewerOfficeDesktop = "Viewer_OfficeDesktop",

	/** English translation: "Office online" */
	ViewerOfficeOnline = "Viewer_OfficeOnline",

	/** English translation: "Save as PDF" */
	ViewerSaveAsPDF = "Viewer_Save_As_PDF",

	/** English translation: "If you save this file with annotations as a PDF file, it will be saved as {0}. Do you want to save this file with annotations as a PDF file?" */
	ViewerSaveConfirmMessage = "Viewer_Save_Confirm_Message",

	/** English translation: "PDF save confirmation" */
	ViewerSaveConfirmTitle = "Viewer_Save_Confirm_Title",

	/** English translation: "Create watermark template" */
	WatermarkAddNewTemplate = "Watermark_Add_New_Template",

	/** English translation: "Here you can create and edit watermark templates. Watermarks are added to documents when they are viewed or printed." */
	WatermarkAddOrEditTemplate = "Watermark_Add_Or_Edit_Template",

	/** English translation: "Targeted users" */
	WatermarkAppliedTo = "Watermark_Applied_To",

	/** English translation: "Here you can change where on the page the watermark is added." */
	WatermarkChoosePosition = "Watermark_Choose_Position",

	/** English translation: "Watermark options" */
	WatermarkConfiguration = "Watermark_Configuration",

	/** English translation: "Watermarks are added to documents when users view or print them. Here you can control where and which watermarks are used, and for which users watermarks are used." */
	WatermarkConfigurationDescription = "Watermark_Configuration_Description",

	/** English translation: "Delete watermark template" */
	WatermarkDeleteSelected = "Watermark_Delete_Selected",

	/** English translation: "Please confirm that you want to remove this watermark." */
	WatermarkDeletionConfirmationMessage = "Watermark_Deletion_Confirmation_Message",

	/** English translation: "Here you can edit the name, content, and appearance of the template. In the Text field, you can use the placeholders to add dynamic content to the watermark." */
	WatermarkEditConfiguration = "Watermark_Edit_Configuration",

	/** English translation: "New watermark template" */
	WatermarkNewWatermark = "Watermark_New_Watermark",

	/** English translation: "Preview" */
	WatermarkPreviewOnSelectedFile = "Watermark_Preview_On_Selected_File",

	/** English translation: "Preview with a sample document" */
	WatermarkPreviewSampleDocument = "Watermark_Preview_Sample_Document",

	/** English translation: "Here you can test the watermark template with your own document." */
	WatermarkSelectFileAndPreview = "Watermark_Select_File_And_Preview",

	/** English translation: "The watermark templates are now saved." */
	WatermarkTemplatesSaved = "Watermark_Templates_Saved",

	/** English translation: "2. Edit watermark template" */
	WatermarkTemplatesStepEdition = "Watermark_Templates_Step_Edition",

	/** English translation: "3. Select watermark location" */
	WatermarkTemplatesStepPosition = "Watermark_Templates_Step_Position",

	/** English translation: "1. Select or create watermark template" */
	WatermarkTemplatesStepTemplates = "Watermark_Templates_Step_Templates",

	/** English translation: "This is the watermark content. Use the placeholders for dynamic values." */
	WatermarkTextDescription = "Watermark_Text_Description",

	/** English translation: "This watermark template is in use. Deleting it can cause unwanted effects. Are you sure you want to delete it?" */
	WatermarkUsedDeletionConfirmationMessage = "Watermark_Used_Deletion_Confirmation_Message",

	/** English translation: "You don't have any social activities yet." */
	WidgetBlogActivityNoActivity = "Widget_Blog_Activity_No_Activity",

	/** English translation: "Add a serie" */
	WidgetChartAddSerie = "Widget_Chart_Add_Serie",

	/** English translation: "Add collection of values" */
	WidgetChartAddValuesCollection = "Widget_Chart_Add_Values_Collection",

	/** English translation: "Select labels" */
	WidgetChartChooseLabelCollection = "Widget_Chart_Choose_Label_Collection",

	/** English translation: "Data from" */
	WidgetChartDataType = "Widget_Chart_Data_Type",

	/** English translation: "Collection" */
	WidgetChartDataTypeCollection = "Widget_Chart_Data_Type_Collection",

	/** English translation: "Form" */
	WidgetChartDataTypeForm = "Widget_Chart_Data_Type_Form",

	/** English translation: "Manual" */
	WidgetChartDataTypeManual = "Widget_Chart_Data_Type_Manual",

	/** English translation: "Please give the data type." */
	WidgetChartDataTypeRequired = "Widget_Chart_Data_Type_Required",

	/** English translation: "Please give a unique label text." */
	WidgetChartErrorLabelUnique = "Widget_Chart_Error_Label_Unique",

	/** English translation: "Label" */
	WidgetChartLabel = "Widget_Chart_Label",

	/** English translation: "Label (Collection: {0})" */
	WidgetChartLabelCollection = "Widget_Chart_Label_Collection",

	/** English translation: "Please select the label collection." */
	WidgetChartNeedLabelsCollection = "Widget_Chart_Need_Labels_Collection",

	/** English translation: "The values must be numbers." */
	WidgetChartNeedNumberValue = "Widget_Chart_Need_Number_Value",

	/** English translation: "Please select the value collection." */
	WidgetChartNeedValuesCollection = "Widget_Chart_Need_Values_Collection",

	/** English translation: "New slice" */
	WidgetChartNewSlice = "Widget_Chart_New_Slice",

	/** English translation: "New value" */
	WidgetChartNewValue = "Widget_Chart_New_Value",

	/** English translation: "Data series" */
	WidgetChartSeries = "Widget_Chart_Series",

	/** English translation: "Series name" */
	WidgetChartSerieName = "Widget_Chart_Serie_Name",

	/** English translation: "Value" */
	WidgetChartValue = "Widget_Chart_Value",

	/** English translation: "Value (Collection: {0})" */
	WidgetChartValueCollection = "Widget_Chart_Value_Collection",

	/** English translation: "Widgets connected with M-Files" */
	WidgetConnectedWithMFiles = "Widget_Connected_With_MFiles",

	/** English translation: "These widgets use the content and information stored in M-Files vaults." */
	WidgetConnectedWithMFilesDescription = "Widget_Connected_With_MFiles_Description",

	/** English translation: "The widget is now created." */
	WidgetCreated = "Widget_Created",

	/** English translation: "Value to insert" */
	WidgetCustomContentTitleInsertValue = "Widget_Custom_Content_Title_Insert_Value",

	/** English translation: "The custom content couldn't be updated." */
	WidgetCustomContentUpdateError = "Widget_Custom_Content_Update_Error",

	/** English translation: "The custom content is now updated." */
	WidgetCustomContentUpdateSuccess = "Widget_Custom_Content_Update_Success",

	/** English translation: "Show folders and files" */
	WidgetDefinitionDisplayDocuments = "Widget_Definition_Display_Documents",

	/** English translation: "Show user details" */
	WidgetDefinitionDisplayPeople = "Widget_Definition_Display_People",

	/** English translation: "Editable content" */
	WidgetDefinitionEditableContent = "Widget_Definition_Editable_Content",

	/** English translation: "Create a questionnaire" */
	WidgetDefinitionForm = "Widget_Definition_Form",

	/** English translation: "Create gallery" */
	WidgetDefinitionGallery = "Widget_Definition_Gallery",

	/** English translation: "Manage links" */
	WidgetDefinitionManageLinks = "Widget_Definition_Manage_Links",

	/** English translation: "Points to define" */
	WidgetDefinitionPointsToDefine = "Widget_Definition_Points_To_Define",

	/** English translation: "Preset widget" */
	WidgetDefinitionPreset = "Widget_Definition_Preset",

	/** English translation: "Show questionnaire results" */
	WidgetDefinitionRelativeForm = "Widget_Definition_RelativeForm",

	/** English translation: "Sectors to define" */
	WidgetDefinitionSectorsToDefine = "Widget_Definition_Sectors_To_Define",

	/** English translation: "Create to-do list" */
	WidgetDefinitionToDoList = "Widget_Definition_ToDoList",

	/** English translation: "Values to define" */
	WidgetDefinitionValuesToDefine = "Widget_Definition_Values_To_Define",

	/** English translation: "The widget is now removed." */
	WidgetDeletedSuccess = "Widget_Deleted_Success",

	/** English translation: "Are you sure you want to delete this widget?" */
	WidgetDeleteConfirmMessage = "Widget_Delete_Confirm_Message",

	/** English translation: "If you delete this input, the input is deleted in all linked forms. Are you sure you want to delete this input?" */
	WidgetDeleteConfirmMessageContent = "Widget_Delete_Confirm_Message_Content",

	/** English translation: "If you delete this form, the related forms are deleted also. Are you sure you wan to delete this widget?" */
	WidgetDeleteConfirmMessageForm = "Widget_Delete_Confirm_Message_Form",

	/** English translation: "Remove" */
	WidgetDeleteConfirmTitle = "Widget_Delete_Confirm_Title",

	/** English translation: "Add folders and files" */
	WidgetDocumentsAddFoldersFiles = "Widget_Documents_Add_Folders_Files",

	/** English translation: "Add folder content" */
	WidgetDocumentsAddFolderContent = "Widget_Documents_Add_Folder_Content",

	/** English translation: "Select folder contents" */
	WidgetDocumentsAddFolderContents = "Widget_Documents_Add_Folder_Contents",

	/** English translation: "Configure the display of the file in the widget" */
	WidgetDocumentsConfigureFileDisplay = "Widget_Documents_Configure_File_Display",

	/** English translation: "Configure the display of the folder in the widget" */
	WidgetDocumentsConfigureFolderDisplay = "Widget_Documents_Configure_Folder_Display",

	/** English translation: "Show content" */
	WidgetDocumentsDisplayContent = "Widget_Documents_Display_Content",

	/** English translation: "Show filter box" */
	WidgetDocumentsDisplayFilterBox = "Widget_Documents_Display_Filter_Box",

	/** English translation: "Please select at least one resource." */
	WidgetDocumentsSelectOneResource = "Widget_Documents_Select_One_Resource",

	/** English translation: "A copy of the widget was created." */
	WidgetDuplicatedSuccess = "Widget_Duplicated_Success",

	/** English translation: "Are you sure you want to create a copy of this widget?" */
	WidgetDuplicateConfirmMessage = "Widget_Duplicate_Confirm_Message",

	/** English translation: "Create a copy" */
	WidgetDuplicateConfirmTitle = "Widget_Duplicate_Confirm_Title",

	/** English translation: "If you leave the widget configuration now, your widget will be empty. Are you sure you want to leave the widget configuration?" */
	WidgetEmptyMessage = "Widget_Empty_Message",

	/** English translation: "There are no file activities." */
	WidgetFileActivityNoActivity = "Widget_File_Activity_No_Activity",

	/** English translation: "The shown forms couldn't be updated." */
	WidgetFormRelativeUpdateError = "Widget_FormRelative_Update_Error",

	/** English translation: "Select folder" */
	WidgetGalleryConfigurationAddFolder = "Widget_Gallery_Configuration_Add_Folder",

	/** English translation: "Add picture" */
	WidgetGalleryConfigurationAddPicture = "Widget_Gallery_Configuration_Add_Picture",

	/** English translation: "Enable auto-play" */
	WidgetGalleryConfigurationAutoplay = "Widget_Gallery_Configuration_Autoplay",

	/** English translation: "Stop auto-play with hover" */
	WidgetGalleryConfigurationAutoplayHover = "Widget_Gallery_Configuration_Autoplay_Hover",

	/** English translation: "Loop" */
	WidgetGalleryConfigurationLoop = "Widget_Gallery_Configuration_Loop",

	/** English translation: "Show navigation arrows" */
	WidgetGalleryConfigurationNav = "Widget_Gallery_Configuration_Nav",

	/** English translation: "Show pagination" */
	WidgetGalleryConfigurationPagination = "Widget_Gallery_Configuration_Pagination",

	/** English translation: "Slide margins in pixels" */
	WidgetGalleryConfigurationSpaceBetween = "Widget_Gallery_Configuration_Space_Between",

	/** English translation: "Scrolling speed in seconds" */
	WidgetGalleryConfigurationSpeed = "Widget_Gallery_Configuration_Speed",

	/** English translation: "Gallery configuration" */
	WidgetGalleryConfigurationTitle = "Widget_Gallery_Configuration_Title",

	/** English translation: "File configuration" */
	WidgetGalleryFileConfigurationTitle = "Widget_Gallery_File_Configuration_Title",

	/** English translation: "File module" */
	WidgetGalleryImportKindFILEMODULE = "Widget_Gallery_Import_Kind_FILE_MODULE",

	/** English translation: "File upload" */
	WidgetGalleryImportKindFILEUPLOAD = "Widget_Gallery_Import_Kind_FILE_UPLOAD",

	/** English translation: "When locked, only the hub owner can edit or delete the widget." */
	WidgetInfoLockOnceActivated = "Widget_Info_Lock_Once_Activated",

	/** English translation: "Bar chart" */
	WidgetKindTitleBarChart = "Widget_Kind_Title_BarChart",

	/** English translation: "Social" */
	WidgetKindTitleBlogActivity = "Widget_Kind_Title_BlogActivity",

	/** English translation: "Calendar" */
	WidgetKindTitleCalendar = "Widget_Kind_Title_Calendar",

	/** English translation: "Custom content" */
	WidgetKindTitleCustom = "Widget_Kind_Title_Custom",

	/** English translation: "Documents" */
	WidgetKindTitleDocuments = "Widget_Kind_Title_Documents",

	/** English translation: "File activity" */
	WidgetKindTitleFileActivity = "Widget_Kind_Title_FileActivity",

	/** English translation: "Questionnaire" */
	WidgetKindTitleForm = "Widget_Kind_Title_Form",

	/** English translation: "Gallery" */
	WidgetKindTitleGallery = "Widget_Kind_Title_Gallery",

	/** English translation: "Line chart" */
	WidgetKindTitleLineChart = "Widget_Kind_Title_LineChart",

	/** English translation: "Contacts" */
	WidgetKindTitlePeople = "Widget_Kind_Title_People",

	/** English translation: "Pie chart" */
	WidgetKindTitlePieChart = "Widget_Kind_Title_PieChart",

	/** English translation: "Project" */
	WidgetKindTitleProject = "Widget_Kind_Title_Project",

	/** English translation: "Questionnaire results" */
	WidgetKindTitleRelativeForm = "Widget_Kind_Title_RelativeForm",

	/** English translation: "Related hubs" */
	WidgetKindTitleRoomTree = "Widget_Kind_Title_RoomTree",

	/** English translation: "To-do list" */
	WidgetKindTitleToDoList = "Widget_Kind_Title_ToDoList",

	/** English translation: "Useful links" */
	WidgetKindTitleUsefulLinks = "Widget_Kind_Title_UsefulLinks",

	/** English translation: "You have not saved your changes. Are you sure you want to leave the widget configuration without saving?" */
	WidgetLeaveMessage = "Widget_Leave_Message",

	/** English translation: "Leave widget configuration" */
	WidgetLeaveTitle = "Widget_Leave_Title",

	/** English translation: "Only the hub owner can lock the widget." */
	WidgetLockableOnlyByTheHubOwner = "Widget_Lockable_Only_By_The_Hub_Owner",

	/** English translation: "Locked by hub creator" */
	WidgetLockedByHubOwner = "Widget_Locked_By_Hub_Owner",

	/** English translation: "Other widgets" */
	WidgetOtherWidgets = "Widget_Other_Widgets",

	/** English translation: "These widgets use the content and information stored in M-Files Hubshare." */
	WidgetOtherWidgetsDescription = "Widget_Other_Widgets_Description",

	/** English translation: "Add link to {0} module" */
	WidgetPeopleAddLinkToModule = "Widget_People_Add_Link_To_Module",

	/** English translation: "Independent" */
	WidgetPeopleIndependent = "Widget_People_Independent",

	/** English translation: "Linked to" */
	WidgetPeopleLinkedTo = "Widget_People_Linked_To",

	/** English translation: "Bound to module" */
	WidgetPeopleLinkedToModule = "Widget_People_Linked_To_Module",

	/** English translation: "Manage groups" */
	WidgetPeopleManageGroups = "Widget_People_Manage_Groups",

	/** English translation: "The shown contacts couldn't be updated." */
	WidgetPeopleUpdateError = "Widget_People_Update_Error",

	/** English translation: "The contacts to display have been successfully updated." */
	WidgetPeopleUpdateSuccess = "Widget_People_Update_Success",

	/** English translation: "Widget kind" */
	WidgetPeopleWidgetKind = "Widget_People_Widget_Kind",

	/** English translation: "From" */
	WidgetProjectFrom = "Widget_Project_From",

	/** English translation: "Late" */
	WidgetProjectLate = "Widget_Project_Late",

	/** English translation: "Status" */
	WidgetProjectStatus = "Widget_Project_Status",

	/** English translation: "To" */
	WidgetProjectTo = "Widget_Project_To",

	/** English translation: "Are you sure you want to reset the data?" */
	WidgetResetDataText = "Widget_Reset_Data_Text",

	/** English translation: "Reset data" */
	WidgetResetDataTitle = "Widget_Reset_Data_Title",

	/** English translation: "Shown dynamically by" */
	WidgetResourceDisplayedDynamicallyBy = "Widget_Resource_Displayed_Dynamically_By",

	/** English translation: "Manage dynamic folders" */
	WidgetResourceManageDynamicFolders = "Widget_Resource_Manage_Dynamic_Folders",

	/** English translation: "The shown documents couldn't be updated." */
	WidgetResourceUpdateError = "Widget_Resource_Update_Error",

	/** English translation: "The shown documents are now updated." */
	WidgetResourceUpdateSuccess = "Widget_Resource_Update_Success",

	/** English translation: "Here you can manage the rights that users have in the feed." */
	WidgetRightsSubtitle = "Widget_Rights_Subtitle",

	/** English translation: "Bar chart" */
	WidgetTitleBar = "Widget_Title_Bar",

	/** English translation: "Blog activity" */
	WidgetTitleBlogActivity = "Widget_Title_BlogActivity",

	/** English translation: "My events" */
	WidgetTitleCalendar = "Widget_Title_Calendar",

	/** English translation: "Presentation" */
	WidgetTitleCustom = "Widget_Title_Custom",

	/** English translation: "Documents" */
	WidgetTitleDocuments = "Widget_Title_Documents",

	/** English translation: "File activity" */
	WidgetTitleFileActivity = "Widget_Title_FileActivity",

	/** English translation: "Line chart" */
	WidgetTitleLine = "Widget_Title_Line",

	/** English translation: "Contacts" */
	WidgetTitlePeople = "Widget_Title_People",

	/** English translation: "Pie chart" */
	WidgetTitlePie = "Widget_Title_Pie",

	/** English translation: "My tasks" */
	WidgetTitleProject = "Widget_Title_Project",

	/** English translation: "Documents" */
	WidgetTitleResource = "Widget_Title_Resource",

	/** English translation: "Related hubs" */
	WidgetTitleRoomTree = "Widget_Title_RoomTree",

	/** English translation: "The widget title is too long. The maximum lenght is 63 characters." */
	WidgetTitleTooLong = "Widget_Title_Too_Long",

	/** English translation: "Useful links" */
	WidgetTitleUsefulLinks = "Widget_Title_UsefulLinks",

	/** English translation: "Unique name for API access" */
	WidgetUniqueName = "Widget_Unique_Name",

	/** English translation: "The unique name can't be empty." */
	WidgetUniqueNameEmpty = "Widget_Unique_Name_Empty",

	/** English translation: "The widget is now updated." */
	WidgetUpdated = "Widget_Updated",

	/** English translation: "Add useful link" */
	WidgetUsefulLinksAdd = "Widget_UsefulLinks_Add",

	/** English translation: "Useful link URL" */
	WidgetUsefulLinksLinkLabel = "Widget_UsefulLinks_Link_Label",

	/** English translation: "http://www.example.com" */
	WidgetUsefulLinksLinkPlaceholder = "Widget_UsefulLinks_Link_Placeholder",

	/** English translation: "Useful link name" */
	WidgetUsefulLinksNameLabel = "Widget_UsefulLinks_Name_Label",

	/** English translation: "My useful link" */
	WidgetUsefulLinksNamePlaceholder = "Widget_UsefulLinks_Name_Placeholder",

	/** English translation: "There are no useful links." */
	WidgetUsefulLinksNoUsefulLinks = "Widget_UsefulLinks_No_UsefulLinks",

	/** English translation: "Apply icon to all languages" */
	WidgetUsefulLinksSamePicture = "Widget_UsefulLinks_Same_Picture",

	/** English translation: "There is an error in the hub language form." */
	WidgetUsefulLinksStatusTooltip = "Widget_UsefulLinks_Status_Tooltip",

	/** English translation: "Do you want to delete these widgets?" */
	WidgetWithChildrenDeleteConfirmFooterMessage = "Widget_With_Children_Delete_Confirm_Footer_Message",

	/** English translation: "There are widgets that use this widget as their source. If you delete this widget, also these other widgets will be deleted:" */
	WidgetWithChildrenDeleteConfirmMessage = "Widget_With_Children_Delete_Confirm_Message",

	/** English translation: "If you go to the hub, your changes in this step are not saved. Are you sure you want to go to the hub?" */
	WizardConfirmGoToHubMessage = "Wizard_Confirm_Go_To_Hub_Message",

	/** English translation: "Confirm going to hub" */
	WizardConfirmGoToHubTitle = "Wizard_Confirm_Go_To_Hub_Title",

	/** English translation: "Your {0} is not ready until you validate this step. If you leave now, you have to complete this step when you try to launch your {1}." */
	WizardHubCreationLeavingAlert = "Wizard_Hub_Creation_Leaving_Alert",

	/** English translation: "Global settings for your hub" */
	WizardHubGeneralDesc = "Wizard_Hub_General_Desc",

	/** English translation: "Go to hub" */
	WizardHubGoTo = "Wizard_Hub_Go_To",

	/** English translation: "Go to hub template" */
	WizardHubGoToTemplate = "Wizard_Hub_Go_To_Template",

	/** English translation: "Default language and available languages for you hub" */
	WizardHubLanguageDesc = "Wizard_Hub_Language_Desc",

	/** English translation: "Module titles" */
	WizardHubModulesTitles = "Wizard_Hub_Modules_Titles",

	/** English translation: "Manage module titles in your hub" */
	WizardHubModulesTitlesDesc = "Wizard_Hub_Modules_Titles_Desc",

	/** English translation: "Set up your" */
	WizardHubModulesTitleLeft = "Wizard_Hub_Modules_Title_Left",

	/** English translation: "In a few steps" */
	WizardHubModulesTitleRight = "Wizard_Hub_Modules_Title_Right",

	/** English translation: "Manage People module" */
	WizardHubPeopleDesc = "Wizard_Hub_People_Desc",

	/** English translation: "Save and go to my hub" */
	WizardHubSaveAndGoTo = "Wizard_Hub_Save_And_Go_To",

	/** English translation: "Save and go to my hub template" */
	WizardHubSaveAndGoToTemplate = "Wizard_Hub_Save_And_Go_To_Template",

	/** English translation: "Manage hub users" */
	WizardHubUsersDesc = "Wizard_Hub_Users_Desc",

	/** English translation: "Is this a multi-language hub?" */
	WizardLanguagesMultiLanguage = "Wizard_Languages_Multi_Language",

	/** English translation: "Hub management wizard" */
	WizardPageTitle = "Wizard_Page_Title",

	/** English translation: "Adding files" */
	WizardTemplateAddingFiles = "Wizard_Template_Adding_Files",

	/** English translation: "Adding widgets" */
	WizardTemplateAddingWidgets = "Wizard_Template_Adding_Widgets",

	/** English translation: "Something went wrong during the creation." */
	WizardTemplateCopyError = "Wizard_Template_Copy_Error",

	/** English translation: "Create people" */
	WizardTemplateCreatingPeople = "Wizard_Template_Creating_People",

	/** English translation: "From template" */
	WizardTemplateFromTemplate = "Wizard_Template_From_Template",

	/** English translation: "Hub creation" */
	WizardTemplateHubCreation = "Wizard_Template_Hub_Creation",

	/** English translation: "Your hub is ready." */
	WizardTemplateHubReady = "Wizard_Template_Hub_Ready",

	/** English translation: "You will be redirected to the wizard when your hub is ready." */
	WizardTemplateHubStatusPresentationTextRedirection = "Wizard_Template_Hub_Status_Presentation_Text_Redirection",

	/** English translation: "Be patient, we are creating your hub." */
	WizardTemplateHubStatusPresentationTextWait = "Wizard_Template_Hub_Status_Presentation_Text_Wait",

	/** English translation: "Your template is ready." */
	WizardTemplateReady = "Wizard_Template_Ready",

	/** English translation: "You will be redirected to the wizard in a few seconds." */
	WizardTemplateReadyRedirection = "Wizard_Template_Ready_Redirection",

	/** English translation: "Create my" */
	WizardTemplateSelectionButtonCreate = "Wizard_Template_Selection_Button_Create",

	/** English translation: "Please check the sections that you would like to be copied from the template to your new" */
	WizardTemplateSelectionPresentationText = "Wizard_Template_Selection_Presentation_Text",

	/** English translation: "Please select at least one section." */
	WizardTemplateSelectOneSection = "Wizard_Template_Select_One_Section",

	/** English translation: "Setting up calendar" */
	WizardTemplateSettingUpCalendar = "Wizard_Template_Setting_Up_Calendar",

	/** English translation: "Setting up project" */
	WizardTemplateSettingUpProject = "Wizard_Template_Setting_Up_Project",

	/** English translation: "Setting up users" */
	WizardTemplateSettingUpUsers = "Wizard_Template_Setting_Up_Users",

	/** English translation: "Progress" */
	WizardTemplateStatusHeaderTitleRight = "Wizard_Template_Status_Header_Title_Right",

	/** English translation: "You will be redirected to the wizard when your template is ready." */
	WizardTemplateStatusPresentationTextRedirection = "Wizard_Template_Status_Presentation_Text_Redirection",

	/** English translation: "Be patient, we are creating your template." */
	WizardTemplateStatusPresentationTextWait = "Wizard_Template_Status_Presentation_Text_Wait",

	/** English translation: "Writing blogs" */
	WizardTemplateWritingBlogs = "Wizard_Template_Writing_Blogs",

	/** English translation: "ZIP file name" */
	ZipFilename = "Zip_Filename",

}